import React from 'react'
import './scss/RateAndReview.css'
const RateAndReview = () => {
    return (
        <>
            {/* <!-- Button trigger modal --> */}
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                Click Rate & Review
            </button>

            {/* <!-- Modal --> */}
            <div class="modal fade review-rate-modal-head-cls" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Write a Rate & Review</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body modal-review">

                            <div className='rating_class text-center'>
                                <p className='rating-news-p'><b>4.5</b>(Good/Amazing)</p>
                                <div className="rate">

                                    <input type="radio" id="star1" name="rate" defaultValue={1}/>
                                    <label htmlFor="star1" title="text">
                                        1 star
                                    </label>

                                    <input type="radio" id="star2" name="rate" defaultValue={2} />
                                    <label htmlFor="star2" title="text">
                                        2 stars
                                    </label>

                                    <input type="radio" id="star3" name="rate" defaultValue={3} />
                                    <label htmlFor="star3" title="text">
                                        3 stars
                                    </label>

                                    <input type="radio" id="star4" name="rate" defaultValue={4} />
                                    <label htmlFor="star4" title="text">
                                        4 stars
                                    </label>
                                    <input type="radio" id="star5" name="rate" defaultValue={5} />
                                    <label htmlFor="star5" title="text">
                                        5 stars
                                    </label>
                                </div>
                            </div>
                            <div className='rating_textarea'>
                                <label htmlFor='review_rate'>Feedback</label>
                                <textarea name="textarea" className='textarea-tag' rows="5" minlength="10" maxlength="20" placeholder="Write down your feedback here..."></textarea>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary later-btn-color" data-bs-dismiss="modal">Later</button>
                            <button type="button" class="btn btn-primary submit-btn-color">Submit Review</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RateAndReview