import React from 'react'
import './scss/homepage.css'
import { Link } from 'react-router-dom'
const Homebanner = () => {
    return (
        <>
            {/* <section className='header-banner'>
                
            </section> */}
            <section className='header-banner'>
                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" >
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={1} aria-label="Slide 2" />

                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src='/assets/images/homebanner.png' className='img-fluid' />
                            <div className='banner-text'>
                                <h1>Connecting talent to  opportunity </h1>

                                <Link to="/create-employer-account" className='find-job-btn'>Post A Job</Link>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src='/assets/images/homebanner1.png' className='img-fluid' />
                            <div className='banner-text'>
                                <h1>You have the talent, We have the tools</h1>
                                {/* <Link to="/find-jobs" className='find-job-btn'>Find a Job</Link> */}
                                <Link to="/career-start-search" className='find-job-btn'>Find A Job</Link>
                            </div>
                        </div>

                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" >
                        <span className="carousel-control-prev-icon" aria-hidden="true" />
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" >
                        <span className="carousel-control-next-icon" aria-hidden="true" />
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>

            </section>
        </>
    )
}

export default Homebanner