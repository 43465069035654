import React, { useState } from 'react';
import '../CvBuilderHeader/scss/registerheader.css';
import { Link } from 'react-router-dom/dist';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const RegisterHeader = props => {
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
    return (
        <>
            <section className='register-header-sec'>
                <section className="main-header">
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='head-sec-lft'>
                                    <div className='translator d-flex align-iten-center'>
                                        <div className='flag'>
                                            <img src='assets/images/homepage/flag.png' />
                                        </div>
                                        <ul className='mx-4'>
                                            <li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    English
                                                </a>
                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                    <li><a className="dropdown-item" href="#">French</a></li>
                                                    <li><a className="dropdown-item" href="#">Spanish</a></li>
                                                    <li><a className="dropdown-item" href="#">German</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='call-info d-flex'>
                                        <div className='icon'>
                                            <img src='assets/images/homepage/PhoneCall.png' />
                                        </div>
                                        <div className='mx-2'>
                                            <p>+1-202-555-0178</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='head-sec-rit'>
                                    <div className="dropdown">
                                        <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            For Recruiter
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><Link className="dropdown-item" to="/recruiter-login"><i className="fa-solid fa-user me-2"></i> Login</Link></li>
                                            <li><Link className="dropdown-item" to="/company-details"><i className="fa-solid fa-user me-2"></i> Register</Link></li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='header-second'>
                    <div className='container'>
                        <nav className="navbar navbar-expand-lg">
                            <div className="container-fluid">
                                <Link className="navbar-brand mx-2" to="/"><img src='assets/images/logo.png' /></Link>
                                <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarsExample07">
                                    <ul className="navbar-nav align-items-center ms-auto">
                                        {/* <li className="nav-item dropdown header-sec-btn1">
                                            <Link className="nav-link" to="/career-guide">
                                                Career Guide
                                            </Link>
                                             <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                <li><a className="dropdown-item" href="#">French</a></li>
                                                <li><a className="dropdown-item" href="#">Spanish</a></li>
                                                <li><a className="dropdown-item" href="#">German</a></li>
                                            </ul>
                                        </li> */}
                                        <li className="nav-item dropdown header-sec-btn1">
                                            <Link className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink21" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Career Guide
                                            </Link>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink21">
                                            <li><Link className="dropdown-item" to="/career-guide"> Career Guide</Link></li>
                                                <li><Link className="dropdown-item" to="/resume-listing">Resume</Link></li>
                                                <li><Link className="dropdown-item" to="/career-guide">Cover Latter</Link></li>
                                                <li><Link className="dropdown-item" to="/career-guide">Skill Test</Link></li>
                                                <li><Link className="dropdown-item" to="/career-guide">interview</Link></li>
                                            </ul>
                                        </li>
                                        <li className='header-sec-btn2 mx-2'>
                                            <Link to="/build-resume"><button>CV Builder</button></Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </section>
            </section>
        </>
    )
}

export default RegisterHeader