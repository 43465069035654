import React from 'react'
import { Link } from 'react-router-dom'

const FeatureBlog = () => {
    return (
        <section className="feature-blog">
            <div className="container py-5">
                <div className="row">
                    <div className="col-lg-9">
                        <div className="row custm-sec-lft">
                            <div className="title">
                                <h1>How to Start Looking for a New Job</h1>
                            </div>
                            <div className="col-lg-4 col-md-4 new-job-img py-4">
                                <img src="./assets/images/career-guide/job2.png" className='img-fluid' alt="" />
                            </div>
                            <div className="col-lg-6 col-md-8 new-job-info py-4">
                                <h5>Travel</h5>
                                <h2>Design is the Mix of emotions</h2>
                                <ul className='d-flex'>
                                    <li><img src="./assets/images/career-guide/ava.png" className='img-fluid' alt="" />Jenny kiaa</li>
                                    <li><img src="./assets/images/career-guide/calender.png" className='img-fluid' alt="" />02 december 2022</li>
                                    <li><img src="./assets/images/career-guide/time.png" className='img-fluid' alt="" />3 min. to read</li>
                                </ul>
                                <h4>Did you come here for something in particular or just general Riker-bashing? And blowing into</h4>
                            </div>

                            <div className="col-lg-4 col-md-4 new-job-img py-4">
                                <img src="./assets/images/career-guide/job1.png" className='img-fluid' alt="" />
                            </div>
                            <div className="col-lg-6 col-md-8 new-job-info py-4">
                                <h5>Travel</h5>
                                <h2>Design is the Mix of emotions</h2>
                                <ul className='d-flex'>
                                    <li><img src="./assets/images/career-guide/ava.png" className='img-fluid' alt="" />Jenny kiaa</li>
                                    <li><img src="./assets/images/career-guide/calender.png" className='img-fluid' alt="" />02 december 2022</li>
                                    <li><img src="./assets/images/career-guide/time.png" className='img-fluid' alt="" />3 min. to read</li>
                                </ul>
                                <h4>Did you come here for something in particular or just general Riker-bashing? And blowing into</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 pe-5 pb-5">
                        <div className="custm-sec-rit">
                            <div className="row">
                                <div className="recent-post">
                                    <h3>Feature Blogs</h3>
                                    <div className="custm-posts">
                                        <div className="post-detail mb-3 d-flex justify-content-around">
                                            <img src="./assets/images/blogs/p1.png" className='img-fluid' alt="" />
                                            <div className="post-text">
                                                <h4>It is a long established fact</h4>
                                                <h5>Aug 09 2020</h5>
                                            </div>
                                        </div>
                                        <div className="post-detail mb-3 d-flex justify-content-around">
                                            <img src="./assets/images/blogs/p2.png" className='img-fluid' alt="" />
                                            <div className="post-text">
                                                <h4>It is a long established fact</h4>
                                                <h5>Aug 09 2020</h5>
                                            </div>
                                        </div>
                                        <div className="post-detail mb-3 d-flex justify-content-around">
                                            <img src="./assets/images/blogs/p3.png" className='img-fluid' alt="" />
                                            <div className="post-text">
                                                <h4>It is a long established fact</h4>
                                                <h5>Aug 09 2020</h5>
                                            </div>
                                        </div>
                                        <div className="post-detail mb-3 d-flex justify-content-around">
                                            <img src="./assets/images/blogs/p4.png" className='img-fluid' alt="" />
                                            <div className="post-text">
                                                <h4>It is a long established fact</h4>
                                                <h5>Aug 09 2020</h5>
                                            </div>
                                        </div>
                                        <div className="post-detail mb-3 d-flex justify-content-around">
                                            <img src="./assets/images/blogs/p5.png" className='img-fluid' alt="" />
                                            <div className="post-text">
                                                <h4>It is a long established fact</h4>
                                                <h5>Aug 09 2020</h5>
                                            </div>
                                        </div>
                                        <div className="post-detail mb-4 d-flex justify-content-around">
                                            <img src="./assets/images/blogs/p6.png" className='img-fluid' alt="" />
                                            <div className="post-text">
                                                <h4>It is a long established fact</h4>
                                                <h5>Aug 09 2020</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="view-btn d-flex justify-content-center">
                        <Link to="/feature-blog-listing">View All</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FeatureBlog