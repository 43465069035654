import React from 'react'
import './scss/SavedSearch.css'
import { Link } from 'react-router-dom'
import $ from "jquery"
import Filters from '../Filters/Filters'

const SavedSearch = () => {
    // $("input:checkbox").on('click', function () {
    //     // in the handler, 'this' refers to the box clicked on
    //     var $box = $(this);
    //     if ($box.is(":checked")) {
    //         // the name of the box is retrieved using the .attr() method
    //         // as it is assumed and expected to be immutable
    //         var group = "input:checkbox[name='" + $box.attr("name") + "']";
    //         // the checked state of the group/box on the other hand will change
    //         // and the current value is retrieved using .prop() method
    //         $(group).prop("checked", false);
    //         $box.prop("checked", true);
    //     } else {
    //         $box.prop("checked", false);
    //     }
    // });
    return (
        <>
            <section className='saved-search-section-start'>
                <section className="search-resumes-section">
                    <div className="container">
                        <div className="search-resumes-bg">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="search-resumes-display">
                                        <div className="search-heading">
                                            <h3>
                                                Saved Search
                                            </h3>
                                        </div>
                                        <div className="srch mt-3">
                                            <Link to="/search-resumes" className="search-btn1 mx-1">Search Resume</Link>
                                            <Link to="/projects-search" className="search-btn2 mx-1">Projects</Link>
                                            <Link to="/post-job" className="search-btn3 mx-1">Post a Job</Link>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="mt-4">
                                            <div className="d-flex form-search-area">
                                                <div className="custom-form-section ">
                                                    <div className="search-custom">
                                                        <img src="./assets/images/search-resume/MagnifyingGlass.png" className="img-fluid" alt="" />
                                                        <input type="text" className="form-control form-custom form-cus-one"
                                                            placeholder="Job title, skill, company, or search syntax" />
                                                    </div>
                                                    <div>
                                                        <div className="search-custom">
                                                            <img src="./assets/images/search-resume/MapPinLine .png" className="img-fluid" alt="" />
                                                            <input type="text" className="form-control form-custom form-cus-two"
                                                                placeholder="City, state, or Postal" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <button className="form-custom-btn">Search job</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="advance-search-parent">
                                                    <div className="advance-icon">
                                                        <p> <img src="./assets/images/search-resume/mi_filter.png" className="fitter-icon" alt="" /> Advance
                                                            Search</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-area my-4">
                                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">All Saved Search</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Active Alerts</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Inactive Alerts</button>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="pills-tabContent">
                                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                                <div className="row mb-5">
                                                    <div className="col-lg-3 col-md-4">
                                                        <Filters />
                                                    </div>
                                                    <div className="col-lg-9 col-md-8">
                                                        <div className="candidates-heading">
                                                            <h3>
                                                                Save Candidates
                                                            </h3>
                                                        </div>
                                                        <div>
                                                            <div className="row  cust_row">
                                                                <div className="col-lg-2">
                                                                    <div className="photo-cus">
                                                                        <Link to='/rec-candidate-profile'>
                                                                            <img src="./assets/images/search-resume/Photo.png" />
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg">
                                                                    <div className="row">
                                                                        <div className="col-sm-auto col-lg-4 my-auto">
                                                                            <div className="text-left">
                                                                                <Link to='/rec-candidate-profile'><h5>Jessica Jana</h5></Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-auto col-lg-8">
                                                                            <div className="cust_up float-end">
                                                                                <ul>
                                                                                    <li className="list-inline-item">
                                                                                        <img src="./assets/images/search-resume/Vector.png" alt="" />
                                                                                    </li>

                                                                                    <li className="list-inline-item">
                                                                                        <img src="./assets/images/search-resume/email.png" alt="" />
                                                                                    </li>
                                                                                    <li className="list-inline-item">
                                                                                        <img src="./assets/images/search-resume/share.png" alt="" />
                                                                                    </li>
                                                                                    <li className="list-inline-item"><a href="#"
                                                                                        className="text-decoration-none"><strong>Comment | Delete</strong></a></li>
                                                                                    <li className="list-inline-item"><input type="checkbox" /></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <div className="text-left">
                                                                                <span className="cust_main">Software Engineer </span>
                                                                                <span className="bg-light p-1 ml-5 text-primary"> New Post</span>
                                                                            </div>
                                                                            <section className='search-candidate-ul-section'>
                                                                                <ul className="text-left cust_lis">
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/mappinline.png' /> Brusses</li>
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/clock.png' /> Full Time</li>
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/currencydollar.png' /> 50-55K</li>
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/calendarblank.png' /> 1 day ago</li>
                                                                                </ul>
                                                                            </section>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                                Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                                tempor Lorem incididunt.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-4">
                                                            <div className="row  cust_row">
                                                                <div className="col-lg-2">
                                                                    <div className="photo-cus">
                                                                        <Link to='/rec-candidate-profile'>
                                                                            <img src="./assets/images/search-resume/Photo2.png" />
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg">
                                                                    <div className="row">
                                                                        <div className="col-sm-auto col-lg-4 my-auto">
                                                                            <div className="text-left">
                                                                                <Link to='/rec-candidate-profile'>
                                                                                    <h5>Alex Stanton</h5>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-auto col-lg-8">
                                                                            <div className="cust_up float-end">
                                                                                <ul>
                                                                                    <li className="list-inline-item">
                                                                                        <img src="./assets/images/search-resume/Vector.png" alt="" />
                                                                                    </li>

                                                                                    <li className="list-inline-item">
                                                                                        <img src="./assets/images/search-resume/email.png" alt="" />
                                                                                    </li>
                                                                                    <li className="list-inline-item">
                                                                                        <img src="./assets/images/search-resume/share.png" alt="" />
                                                                                    </li>
                                                                                    <li className="list-inline-item"><a href="#"
                                                                                        className="text-decoration-none"><strong>Comment | Delete</strong></a></li>
                                                                                    <li className="list-inline-item"><input type="checkbox" /></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <div className="text-left">
                                                                                <span className="cust_main">Junior UI Designer </span>
                                                                            </div>
                                                                            <section className='search-candidate-ul-section'>
                                                                                <ul className="text-left cust_lis">
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/mappinline.png' /> Brusses</li>
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/clock.png' /> Full Time</li>
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/currencydollar.png' /> 50-55K</li>
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/calendarblank.png' /> 1 day ago</li>
                                                                                </ul>
                                                                            </section>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                                Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                                tempor Lorem incididunt.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-4">
                                                            <div className="row  cust_row">
                                                                <div className="col-lg-2">
                                                                    <div className="photo-cus">
                                                                        <Link to='/rec-candidate-profile'>
                                                                            <img src="./assets/images/search-resume/Photo3.png" />
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg">
                                                                    <div className="row">
                                                                        <div className="col-sm-auto col-lg-4 my-auto">
                                                                            <div className="text-left">
                                                                                <Link to='/rec-candidate-profile'>
                                                                                    <h5>Antoine Griezmann</h5>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-auto col-lg-8">
                                                                            <div className="cust_up float-end">
                                                                                <ul>
                                                                                    <li className="list-inline-item">
                                                                                        <img src="./assets/images/search-resume/Vector.png" alt="" />
                                                                                    </li>

                                                                                    <li className="list-inline-item">
                                                                                        <img src="./assets/images/search-resume/email.png" alt="" />
                                                                                    </li>
                                                                                    <li className="list-inline-item">
                                                                                        <img src="./assets/images/search-resume/share.png" alt="" />
                                                                                    </li>
                                                                                    <li className="list-inline-item"><a href="#"
                                                                                        className="text-decoration-none"><strong>Comment | Delete</strong></a></li>
                                                                                    <li className="list-inline-item"><input type="checkbox" /></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <div className="text-left">
                                                                                <span className="cust_main">Technical Support Engineer</span>
                                                                            </div>
                                                                            <section className='search-candidate-ul-section'>
                                                                                <ul className="text-left cust_lis">
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/mappinline.png' /> Brusses</li>
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/clock.png' /> Full Time</li>
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/currencydollar.png' /> 50-55K</li>
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/calendarblank.png' /> 1 day ago</li>
                                                                                </ul>
                                                                            </section>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                                Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                                tempor Lorem incididunt.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-4">
                                                            <div className="row  cust_row">
                                                                <div className="col-lg-2">
                                                                    <div className="photo-cus">
                                                                        <Link to='/rec-candidate-profile'>
                                                                            <img src="./assets/images/search-resume/Photo4.png" />
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg">
                                                                    <div className="row">
                                                                        <div className="col-sm-auto col-lg-4 my-auto">
                                                                            <div className="text-left">
                                                                                <Link to='/rec-candidate-profile'>
                                                                                    <h5>Anna White</h5>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-auto col-lg-8">
                                                                            <div className="cust_up float-end">
                                                                                <ul>
                                                                                    <li className="list-inline-item">
                                                                                        <img src="./assets/images/search-resume/Vector.png" alt="" />
                                                                                    </li>
                                                                                    <li className="list-inline-item">
                                                                                        <img src="./assets/images/search-resume/email.png" alt="" />
                                                                                    </li>
                                                                                    <li className="list-inline-item">
                                                                                        <img src="./assets/images/search-resume/share.png" alt="" />
                                                                                    </li>
                                                                                    <li className="list-inline-item"><a href="#"
                                                                                        className="text-decoration-none"><strong>Comment | Delete</strong></a></li>
                                                                                    <li className="list-inline-item"><input type="checkbox" /></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <div className="text-left">
                                                                                <span className="cust_main">Product Designer </span>
                                                                            </div>
                                                                            <section className='search-candidate-ul-section'>
                                                                                <ul className="text-left cust_lis">
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/mappinline.png' /> Brusses</li>
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/clock.png' /> Full Time</li>
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/currencydollar.png' /> 50-55K</li>
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/calendarblank.png' /> 1 day ago</li>
                                                                                </ul>
                                                                            </section>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                                Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                                tempor Lorem incididunt.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-4">
                                                            <div className="row  cust_row">
                                                                <div className="col-lg-2">
                                                                    <div className="photo-cus">
                                                                        <Link to='/rec-candidate-profile'>
                                                                            <img src="./assets/images/search-resume/Photo5.png" />
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg">
                                                                    <div className="row">
                                                                        <div className="col-sm-auto col-lg-4 my-auto">
                                                                            <div className="text-left">
                                                                                <Link to='/rec-candidate-profile'>
                                                                                    <h5>Richard Kyle</h5>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-auto col-lg-8">
                                                                            <div className="cust_up float-end">
                                                                                <ul>
                                                                                    <li className="list-inline-item">
                                                                                        <img src="./assets/images/search-resume/Vector.png" alt="" />
                                                                                    </li>
                                                                                    <li className="list-inline-item">
                                                                                        <img src="./assets/images/search-resume/email.png" alt="" />
                                                                                    </li>
                                                                                    <li className="list-inline-item">
                                                                                        <img src="./assets/images/search-resume/share.png" alt="" />
                                                                                    </li>
                                                                                    <li className="list-inline-item"><a href="#"
                                                                                        className="text-decoration-none"><strong>Comment | Delete</strong></a></li>
                                                                                    <li className="list-inline-item"><input type="checkbox" /></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <div className="text-left">
                                                                                <span className="cust_main">Copywriter (Growth) </span>
                                                                            </div>
                                                                            <section className='search-candidate-ul-section'>
                                                                                <ul className="text-left cust_lis">
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/mappinline.png' /> Brusses</li>
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/clock.png' /> Full Time</li>
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/currencydollar.png' /> 50-55K</li>
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/calendarblank.png' /> 1 day ago</li>
                                                                                </ul>
                                                                            </section>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                                Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                                tempor Lorem incididunt.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-4">
                                                            <div className="row  cust_row">
                                                                <div className="col-lg-2">
                                                                    <div className="photo-cus">
                                                                        <Link to='/rec-candidate-profile'>
                                                                            <img src="./assets/images/search-resume/Photo6.png" />
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg">
                                                                    <div className="row">
                                                                        <div className="col-sm-auto col-lg-4 my-auto">
                                                                            <div className="text-left">
                                                                                <Link to='/rec-candidate-profile'>
                                                                                    <h5>Julia Philips</h5>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-auto col-lg-8">
                                                                            <div className="cust_up float-end">
                                                                                <ul>
                                                                                    <li className="list-inline-item">
                                                                                        <img src="./assets/images/search-resume/Vector.png" alt="" />
                                                                                    </li>
                                                                                    <li className="list-inline-item">
                                                                                        <img src="./assets/images/search-resume/email.png" alt="" />
                                                                                    </li>
                                                                                    <li className="list-inline-item">
                                                                                        <img src="./assets/images/search-resume/share.png" alt="" />
                                                                                    </li>
                                                                                    <li className="list-inline-item"><a href="#"
                                                                                        className="text-decoration-none"><strong>Comment | Delete</strong></a></li>
                                                                                    <li className="list-inline-item"><input type="checkbox" /></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <div className="text-left">
                                                                                <span className="cust_main">Senior UX/UI Designer </span>
                                                                            </div>
                                                                            <section className='search-candidate-ul-section'>
                                                                                <ul className="text-left cust_lis">
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/mappinline.png' /> Brusses</li>
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/clock.png' /> Full Time</li>
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/currencydollar.png' /> 50-55K</li>
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/calendarblank.png' /> 1 day ago</li>
                                                                                </ul>
                                                                            </section>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                                Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                                tempor Lorem incididunt.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-4">
                                                            <div className="row  cust_row">
                                                                <div className="col-lg-2">
                                                                    <div className="photo-cus">
                                                                        <Link to='/rec-candidate-profile'>
                                                                            <img src="./assets/images/search-resume/Photo7.png" />
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg">
                                                                    <div className="row">
                                                                        <div className="col-sm-auto col-lg-4 my-auto">
                                                                            <div className="text-left">
                                                                                <Link to='/rec-candidate-profile'>
                                                                                    <h5>Curious George</h5>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-auto col-lg-8">
                                                                            <div className="cust_up float-end">
                                                                                <ul>
                                                                                    <li className="list-inline-item">
                                                                                        <img src="./assets/images/search-resume/Vector.png" alt="" />
                                                                                    </li>

                                                                                    <li className="list-inline-item">
                                                                                        <img src="./assets/images/search-resume/email.png" alt="" />
                                                                                    </li>
                                                                                    <li className="list-inline-item">
                                                                                        <img src="./assets/images/search-resume/share.png" alt="" />
                                                                                    </li>
                                                                                    <li className="list-inline-item"><a href="#"
                                                                                        className="text-decoration-none"><strong>Comment | Delete</strong></a></li>
                                                                                    <li className="list-inline-item"><input type="checkbox" /></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <div className="text-left">
                                                                                <span className="cust_main">Technical Support Engineer </span>
                                                                            </div>
                                                                            <section className='search-candidate-ul-section'>
                                                                                <ul className="text-left cust_lis">
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/mappinline.png' /> Brusses</li>
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/clock.png' /> Full Time</li>
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/currencydollar.png' /> 50-55K</li>
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/calendarblank.png' /> 1 day ago</li>
                                                                                </ul>
                                                                            </section>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                                Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                                tempor Lorem incididunt.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-4">
                                                            <div className="row  cust_row">
                                                                <div className="col-lg-2">
                                                                    <div className="photo-cus">
                                                                        <Link to='/rec-candidate-profile'>
                                                                            <img src="./assets/images/search-resume/Photo.png" />
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg">
                                                                    <div className="row">
                                                                        <div className="col-sm-auto col-lg-4 my-auto">
                                                                            <div className="text-left">
                                                                                <Link to='/rec-candidate-profile'>
                                                                                    <h5>Curious George</h5>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-auto col-lg-8">
                                                                            <div className="cust_up float-end">
                                                                                <ul>
                                                                                    <li className="list-inline-item">
                                                                                        <img src="./assets/images/search-resume/Vector.png" alt="" />
                                                                                    </li>

                                                                                    <li className="list-inline-item">
                                                                                        <img src="./assets/images/search-resume/email.png" alt="" />
                                                                                    </li>
                                                                                    <li className="list-inline-item">
                                                                                        <img src="./assets/images/search-resume/share.png" alt="" />
                                                                                    </li>
                                                                                    <li className="list-inline-item"><a href="#"
                                                                                        className="text-decoration-none"><strong>Comment | Delete</strong></a></li>
                                                                                    <li className="list-inline-item"><input type="checkbox" /></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <div className="text-left">
                                                                                <span className="cust_main">Technical Support Engineer</span>
                                                                            </div>
                                                                            <section className='search-candidate-ul-section'>
                                                                                <ul className="text-left cust_lis">
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/mappinline.png' /> Brusses</li>
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/clock.png' /> Full Time</li>
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/currencydollar.png' /> 50-55K</li>
                                                                                    <li className="list-inline-item ">
                                                                                        <img src='./assets/images/search-resume/calendarblank.png' /> 1 day ago</li>
                                                                                </ul>
                                                                            </section>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                                Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                                tempor Lorem incididunt.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* ===============Pagination================ */}
                                                        <nav aria-label="Page navigation example">
                                                            <ul className="pagination justify-content-center my-5">
                                                                <li className="page-item">
                                                                    <a className="page-link" href="#" aria-label="Previous">
                                                                        <span aria-hidden="true">&laquo;</span>
                                                                    </a>
                                                                </li>
                                                                <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                                <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                                <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                                <li className="page-item">
                                                                    <a className="page-link" href="#" aria-label="Next">
                                                                        <span aria-hidden="true">&raquo;</span>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </nav>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">...</div>
                                            <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">...</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </section>
        </>
    )
}

export default SavedSearch