import React from 'react'
import RegisterHeader from '../../Seeker/CvBuilder/CvBuilderHeader/RegisterHeader'
import RecForgetPasswordContent from './RecForgetPasswordContent'
import Footer from '../Commons/Footer'

const RecForgetPassword = () => {
    return (
        <>
            <RegisterHeader />
            <RecForgetPasswordContent />
            <Footer />
        </>
    )
}

export default RecForgetPassword