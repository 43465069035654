import React from 'react'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import './scss/careerguide.css'
import Footer from '../Commons/Footer'
import CareerGuideNav from './CareerGuideNav'
import CareerSearch from './CareerSearch'
import CareerAdvice from './CareerAdvice'
import CareerTest from './CareerTest'
import FeatureBlog from './FeatureBlog'
import InterviewQuestions from './InterviewQuestions'


import BeforeFooterPlayStore from '../Commons/BeforeFooter/BeforeFooterPlayStore'
import Resumes from './Resumes'


const CareerGuideHome = () => {
    return (
        <>
            <AfterNavbar />
            <CareerGuideNav />
            <CareerSearch />
            <CareerAdvice />
            <CareerTest />
            <FeatureBlog />
            <InterviewQuestions />
            <Resumes />
            <BeforeFooterPlayStore />
            <Footer />
        </>

    )
}

export default CareerGuideHome