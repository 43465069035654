import React from 'react'
import './scss/LikeToPostYourJob.css'
import { Link } from 'react-router-dom'
const LikeToPostYourJobContent = () => {
    return (
        <>
            <section className='like-post-your-job-sec'>
                <div className='container'>
                    <div className='create-an-emp-inner'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-12'>
                                    <div className='heading'>
                                        <h2>Create a job post</h2>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-12'>
                                    <div className='top-image'>
                                        <img src='./assets/recruiter-images/post-a-job/create-a-post.png' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading-inner'>
                                        <h3>How would you like to post your job<span className='text-danger'>*</span></h3>
                                    </div>
                                </div>
                            </div>
                            <div className='card-outer-dv'>
                                <div className='row justify-content-center align-items-center'>
                                    <div className='col-lg-8 col-md-8 col-12'>
                                        <div className='left-sd-content'>
                                            <h5>Use a previous job as a template</h5>
                                            <p>Copy a past job post and edit it as needed.</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-12 text-lg-end text-md-end text-center'>
                                        <div className='right-sd-btn'>
                                            <Link to='/add-job-basics' className='continue-btn'>Continue</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-outer-dv'>
                                <div className='row justify-content-center align-items-center'>
                                    <div className='col-lg-8 col-md-8 col-12'>
                                        <div className='left-sd-content'>
                                            <h5>Use smart recommendations to complete a new post</h5>
                                            <p>Generate a data-driven job post and edit form there.</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-12 text-lg-end text-md-end text-center'>
                                        <div className='right-sd-btn'>
                                            <Link to='/add-job-basics' className='continue-btn'>Continue</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-outer-dv'>
                                <div className='row justify-content-center align-items-center'>
                                    <div className='col-lg-8 col-md-8 col-12'>
                                        <div className='left-sd-content'>
                                            <h5>Start with a new post</h5>
                                            <p>Use our posting tool to create your job.</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-12 text-lg-end text-md-end text-center'>
                                        <div className='right-sd-btn'>
                                            <Link to='/add-job-basics' className='continue-btn'>Continue</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LikeToPostYourJobContent