import React from 'react'
import './scss/BrowseCompaniesbyIndustry.css'
import { Link } from 'react-router-dom'
const BrowseCompaniesbyIndustryContent = () => {
  return (
    <>
      <section className='browse-company-sec'>
        <section className="career-section">
          <div className="career-start-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="career-top-heading">
                    <h2>Explore the career you love </h2>
                  </div>
                  <div className="career-top-heading mt-4">
                    <h1>
                      Find Right <span> Places/Companies </span> to Work
                    </h1>
                  </div>
                  <div className="career-second-heading mt-4">
                    <h6><span>  1123 </span> Companies NOW Hiring in Afghanistan
                    </h6>
                  </div>
                  <div>
                    <div className="custom-form-section mt-4">
                      <div className="search-custom">
                        <img src=" ./assets/find-right-places-companies/magnifyingglass.png" className="img-fluid" alt="" />
                        <input
                          type="text"
                          className="form-control form-custom"
                          placeholder="company name or job title"
                        />
                      </div>
                      <div className="search-custom">
                        <img src="./assets/find-right-places-companies/mappinline.png" className="img-fluid" alt="" />
                        <input
                          type="text"
                          className="form-control form-custom"
                          placeholder="Location"
                        />
                      </div>
                      <div>
                        <div>
                          <Link to="/find-right-company"><button className="form-custom-btn" >Find Company</button></Link>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='browse-card-sec'>
          <div className="card-section mt-5">
            <div className="container">
              <div className="card-bg">
                <div className="row">
                  <div className="col-lg-12">
                    <div>
                      <h2 className="head">Browse Companies by Industry</h2>
                    </div>
                  </div>
                </div>
                <div className="card-custom">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="cards">
                        <div className="card-icon">
                          <img
                            src="./assets/images_another/bi_airplane-fill.png"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="card-para">
                          <p><Link to="/find-right-company">Aerospace & Defense</Link></p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="cards">
                        <div className="card-icon">
                          <img
                            src="./assets/images_another/foundation_trees.png"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="card-para">
                          <p><Link to="/find-right-company">Agriculture</Link></p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="cards">
                        <div className="card-icon">
                          <img src="./assets/images_another/mdi_art.png" className="img-fluid" alt="" />
                        </div>
                        <div className="card-para">
                          <p><Link to="/find-right-company">Arts, Entertainment & Recreation</Link></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="cards">
                        <div className="card-icon">
                          <img
                            src="./assets/images_another/wpf_maintenance.png"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="card-para">
                          <p><Link to="/find-right-company">Counteraction, Repair & Maintenance Services</Link></p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="cards">
                        <div className="card-icon">
                          <img
                            src="./assets/images_another/zondicons_education.png"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="card-para">
                          <p><Link to="/find-right-company">Education</Link></p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="cards">
                        <div className="card-icon">
                          <img
                            src="./assets/images_another/mdi_energy-circle.png"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="card-para">
                          <p><Link to="/find-right-company">Energy, Mining & Utilities</Link></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
      </section>
    </>
  )
}

export default BrowseCompaniesbyIndustryContent