import React from 'react'
import Header from '../Commons/Header'
import Homebanner from './Homebanner'
import Bannerafter from './Bannerafter'
import Worklocation from './Worklocation'
import Jobopening from './Jobopening'
import Benifits from './Benefits'
import Jobdemand from './Jobdemand'
import ImpaneledCompanies from './ImpaneledCompanies'
import JobFair from './JobFair'
import Testimonial from './Testimonial'
import Smartapp from './Smartapp'
import Footer from '../Commons/Footer'
import './scss/homeresponsive.css'

const Home = () => {
    return (
        <>
            <Header />
            <Homebanner />
            <Bannerafter />
            <Worklocation />
            <Jobopening />
            <Benifits />
            <Jobdemand />
            <ImpaneledCompanies />
            <JobFair />
            <Testimonial />
            <Smartapp />
            <Footer />
        </>
    )
}

export default Home