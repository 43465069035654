import React from 'react'
import './scss/RecMyProfile.css'
const RecMyProfileContent = () => {
    return (
        <>

            <section className='rec-myprofile-section'>
                <div className="main-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="main-head">
                                    <h2>My Profile</h2>
                                </div>
                            </div>
                        </div>
                        <div className="banner-section">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="bg-img profile-banner-head">
                                        <img src="./assets/my-profile/background.png" className="img-fluid" alt="" />
                                        <div className='profile-heading-top'>
                                            <h2>Profile Details</h2>
                                        </div>  
                                    </div>
                                    <div className="banner-bg">
                                        <div className="d-flex">
                                            <div className='profile-images'>
                                                <img src="./assets/my-profile/Ellipse.png" className="img-fluid" alt="" />
                                            </div>
                                            <div className="first-head-bg">
                                                <h4>Rohit Kumar</h4>
                                                <p>Product Designer</p>
                                            </div>
                                        </div>
                                        <div className="banner-right-bg">
                                            {/* <div>
                                                <a href='#' className='banner-play-btn'>
                                                    <img src="./assets/my-profile/play-btn.png" alt="" class="banner-right-img" />
                                                    <span>Video Profile</span>
                                                </a>
                                            </div> */}
                                            <div>
                                                <img src="./assets/my-profile/share.png" className="img-fluid" alt="" />
                                            </div>
                                            <div>
                                                <img src="./assets/my-profile/send.png" className="img-fluid" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="user-section mt-4">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="top-user-section">
                                            <div className="d-flex my-3">
                                                <div className="mx-2">
                                                    <img src="./assets/my-profile/user.png" className="img-fluid" alt="" />
                                                </div>
                                                <div className="mx-4 top-user-head">
                                                    <h4>Basic Information</h4>
                                                    <a href='#' className='first-para'>Update profile information</a>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <button className="edit-btn">Edit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-12">
                                        <div className="user-details">
                                            <div>
                                                <h6>Email Address</h6>
                                                <p>anamoulrouf.bd@gmail.com</p>
                                            </div>
                                            <div>
                                                <img src="./assets/my-profile/right-icon.png" className="img-fluid" alt="" />
                                            </div>
                                        </div>
                                        <div className="user-details">
                                            <div>
                                                <h6>Phone Number</h6>
                                                <p>+8801759693045</p>
                                            </div>
                                            <div>
                                                <img src="./assets/my-profile/right-icon.png" className="img-fluid" alt="" />
                                            </div>
                                        </div>
                                        <div className="user-details">
                                            <div>
                                                <h6>Website</h6>
                                                <p>www.anamoulrouf.com</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-12">
                                        <div className="user-details">
                                            <div>
                                                <h6>Working Status</h6>
                                                <p>Working</p>
                                            </div>
                                        </div>
                                        <div className="user-details">
                                            <div>
                                                <h6>Last Company Name</h6>
                                                <p>Alobha Technologies Pvt. Ltd.</p>
                                            </div>
                                        </div>
                                        <div className="user-details">
                                            <div>
                                                <h6>Notice Period</h6>
                                                <p>30 Days</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-12">
                                        <div className="user-details">
                                            <div>
                                                <h6>Gender</h6>
                                                <p>Male</p>
                                            </div>
                                        </div>
                                        <div className="user-details">
                                            <div>
                                                <h6>Location</h6>
                                                <p>Sector 63, Noida, U.p.</p>
                                            </div>
                                        </div>
                                        <div className="user-details">
                                            <div>
                                                <h6>latest salary</h6>
                                                <p>50,000</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-12">
                                        <div className="user-details">
                                            <div>
                                                <h6>Total Exp</h6>
                                                <p>20,000</p>
                                            </div>
                                        </div>
                                        <div className="user-details">
                                            <div>
                                                <h6>Last Update</h6>
                                                <p>20-06-2023</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="user-section mt-4">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 my-2">
                                        <div className="top-user-section">
                                            <div className="d-flex mt-3">
                                                <div className="mx-2">
                                                    <img src="./assets/my-profile/smart-bag.png" className="img-fluid" alt="" />
                                                </div>
                                                <div className="mx-4 top-user-head">
                                                    <h6>Experiences</h6>
                                                    <a href='#' className='first-para'>Add experience to increase the chance of hiring</a>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <button className="edit-btn">Add Experience</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border-bottom"></div>
                                    <div className="col-lg-12">
                                        <div className="top-user-section">
                                            <div className="d-flex mt-3">
                                                <div className="mx-2">
                                                    <img src="./assets/my-profile/job-info-card.png" alt="" />
                                                </div>
                                                <div className="mx-4 top-user-head">
                                                    <h4>Sr. Product Designer</h4>
                                                    <a href='#' className='first-para'>ShartTrip Inc.</a>
                                                    <p>
                                                        Dhaka, Bangladesh <span>January 2022 to Present</span>
                                                    </p>
                                                    <div>
                                                        <p className='see-more-para'>
                                                            ShareTrip is the country’s first and pioneer online travel
                                                            aggregator (OTA). My goal was to craft a functional and
                                                            delightful experience through web and mobile apps
                                                            currently consisting of 1.2M+ &amp; future billion users…
                                                            <span>
                                                                <a href="">see more</a>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex mt-3 display-btn-head">
                                                <button className="edit-btn2 mx-4">Delete</button>
                                                <button className="edit-btn2">Edit</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border-bottom"></div>
                                    <div className="col-lg-12">
                                        <div className="top-user-section">
                                            <div className="d-flex mt-3">
                                                <div className="mx-2">
                                                    <img src="./assets/my-profile/Rectangle-card.png" alt="" />
                                                </div>
                                                <div className="mx-4 top-user-head">
                                                    <h4>Product Designer</h4>
                                                    <a href='#' className='first-para'>ShartTrip Inc.</a>
                                                    <p>
                                                        Dhaka, Bangladesh <span>January 2022 to Present</span>
                                                    </p>
                                                    <div>
                                                        <p className='see-more-para'>
                                                            ShareTrip is the country’s first and pioneer online travel
                                                            aggregator (OTA). My goal was to craft a functional and
                                                            delightful experience through web and mobile apps
                                                            currently consisting of 1.2M+ &amp; future billion users…
                                                            <span>
                                                                <a href="">see more</a>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" d-flex mt-3 display-btn-head">
                                                <button className="edit-btn2 mx-4">Delete</button>
                                                <button className="edit-btn2">Edit</button>
                                            </div>
                                        </div>
                                        <div className="border-bottom"></div>
                                        <div className="col-lg-12 mt-3 mx-4">
                                            <div className='last-element-show-more'>
                                                <a href="">Show 2 more</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="user-section mt-4">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="top-user-section my-3">
                                            <div className="d-flex mt-3">
                                                <div className="mx-2">
                                                    <img
                                                        src="./assets/my-profile/education-card.png"
                                                        className="img-fluid"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="mx-4 top-user-head">
                                                    <h4>Certifications</h4>
                                                    <a href='#' className='first-para'>Add experience to increase the chance of hiring</a>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <button className="edit-btn">Add Education</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border-bottom"></div>
                                    <div className="col-lg-12">
                                        <div className="top-user-section">
                                            <div className="d-flex mt-3">
                                                <div className="mx-2">
                                                    <img src="./assets/my-profile/calarts-card.png" alt="" />
                                                </div>
                                                <div className="mx-4 top-user-head">
                                                    <h4>California Institute of the Arts</h4>
                                                    <a href='#' className='first-para'>
                                                        UX Design Fundamentals<span>UX Design</span>
                                                    </a>
                                                    <p>Grade: A+ 2020 - 2021 Address</p>
                                                    <div>
                                                        <p className='see-more-para'>
                                                            ShareTrip is the country’s first and pioneer online travel
                                                            aggregator (OTA). My goal was to craft a functional and
                                                            delightful experience through web and mobile apps
                                                            currently consisting of 1.2M+ &amp; future billion users…
                                                            <span>
                                                                <a href="">see more</a>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" d-flex mt-3 display-btn-head">
                                                <button className="edit-btn2 mx-4">Delete</button>
                                                <button className="edit-btn2">Edit</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="top-user-section my-3">
                                            <div className="d-flex mt-3">
                                                <div className="mx-2">
                                                    <img
                                                        src="./assets/my-profile/education-card.png"
                                                        className="img-fluid"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="mx-4 top-user-head">
                                                    <h4>Education Details</h4>
                                                    <a href='#' className='first-para'>Add experience to increase the chance of hiring</a>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <button className="edit-btn">Add Education</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border-bottom"></div>
                                    <div className="col-lg-12">
                                        <div className="top-user-section">
                                            <div className="d-flex mt-3">
                                                <div className="mx-2">
                                                    <img src="./assets/my-profile/university-card.png" alt="" />
                                                </div>
                                                <div className="mx-4 top-user-head">
                                                    <h4>University of Pennsylvania</h4>
                                                    <a href='#' className='first-para'>
                                                        Gamification<span>Game and Interactive Media Design</span>
                                                    </a>
                                                    <p>Grade: A+ 2020 - 2021 Address</p>
                                                    <div>
                                                        <p className='see-more-para'>
                                                            ShareTrip is the country’s first and pioneer online travel
                                                            aggregator (OTA). My goal was to craft a functional and
                                                            delightful experience through web and mobile apps
                                                            currently consisting of 1.2M+ &amp; future billion users…
                                                            <span>
                                                                <a href="">see more</a>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" d-flex mt-3 display-btn-head">
                                                <button className="edit-btn2 mx-4">Delete</button>
                                                <button className="edit-btn2">Edit</button>
                                            </div>
                                        </div>
                                        <div className="border-bottom"></div>
                                        <div className="col-lg-12 mt-3 mx-4">
                                            <div className='last-element-show-more'>
                                                <a href="">Show 2 more</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="user-section mt-4">
                            <div className="container">
                                <div className="row my-3">
                                    <div className="col-lg-12">
                                        <div className="top-user-section">
                                            <div className="d-flex mt-3">
                                                <div className="mx-2">
                                                    <img src="./assets/my-profile/skills-card.png" className="img-fluid" alt="" />
                                                </div>
                                                <div className="mx-4 top-user-head">
                                                    <h4>Key Skills</h4>
                                                    <a href='#' className='first-para'>Add experience to increase the chance of hiring</a>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <button className="edit-btn">Add Skills</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="border-bottom"></div>
                                <div className="row my-3">
                                    <div className="col-lg-6">
                                        <div>
                                            <div className="card card-custom ">
                                                <div className="card-icon-section mt-3 mx-3">
                                                    <div>
                                                        <h4>UX Design</h4>
                                                        <p>Expert</p>
                                                    </div>
                                                    <div>
                                                        <div className="d-flex align-items-center mx-3 trash-btn">
                                                            <a href='#'> <img src="./assets/my-profile/trush-square.png" alt="" /></a>
                                                            <a href=''> <img src="./assets/my-profile/Icon-pen.png" alt="" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div>
                                            <div className="card card-custom ">
                                                <div className="card-icon-section mt-3 mx-3">
                                                    <div>
                                                        <h4>UX Design</h4>
                                                        <p>Expert</p>
                                                    </div>
                                                    <div>
                                                        <div className="d-flex align-items-center mx-3 trash-btn">
                                                            <img src="./assets/my-profile/trush-square.png" alt="" />
                                                            <img src="./assets/my-profile/Icon-pen.png" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt-3">
                                        <div>
                                            <div className="card card-custom ">
                                                <div className="card-icon-section mt-3 mx-3">
                                                    <div>
                                                        <h4>User Research</h4>
                                                        <p>Expert</p>
                                                    </div>
                                                    <div>
                                                        <div className="d-flex align-items-center mx-3 trash-btn">
                                                            <img src="./assets/my-profile/trush-square.png" alt="" />
                                                            <img src="./assets/my-profile/Icon-pen.png" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt-3">
                                        <div>
                                            <div className="card card-custom">
                                                <div className="card-icon-section mt-3 mx-3">
                                                    <div>
                                                        <h4>Design System</h4>
                                                        <p>Expert</p>
                                                    </div>
                                                    <div>
                                                        <div className="d-flex align-items-center mx-3 trash-btn">
                                                            <img src="./assets/my-profile/trush-square.png" alt="" />
                                                            <img src="./assets/my-profile/Icon-pen.png" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt-4">
                                        <div className='last-element-show-more'>
                                            <a href="">Show 2 more</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="user-section mt-4">
                            <div className="container">
                                <div className="row my-3">
                                    <div className="col-lg-12">
                                        <div className="top-user-section">
                                            <div className="d-flex mt-3">
                                                <div className="mx-2">
                                                    <img src="./assets/my-profile/skills-card.png" className="img-fluid" alt="" />
                                                </div>
                                                <div className="mx-4 top-user-head">
                                                    <h4>IT Skills</h4>
                                                    <a href='#' className='first-para'>Add experience to increase the chance of hiring</a>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <button className="edit-btn">Add Skills</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="border-bottom"></div>
                                <div className="row my-3">
                                    <div className="col-lg-6">
                                        <div>
                                            <div className="card card-custom ">
                                                <div className="card-icon-section mt-3 mx-3">
                                                    <div>
                                                        <h4>UX Design</h4>
                                                        <p>Expert</p>
                                                    </div>
                                                    <div>
                                                        <div className="d-flex align-items-center mx-3 trash-btn">
                                                            <img src="./assets/my-profile/trush-square.png" alt="" />
                                                            <img src="./assets/my-profile/Icon-pen.png" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div>
                                            <div className="card card-custom ">
                                                <div className="card-icon-section mt-3 mx-3">
                                                    <div>
                                                        <h4>UX Design</h4>
                                                        <p>Expert</p>
                                                    </div>
                                                    <div>
                                                        <div className="d-flex align-items-center mx-3 trash-btn">
                                                            <img src="./assets/my-profile/trush-square.png" alt="" />
                                                            <img src="./assets/my-profile/Icon-pen.png" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt-4">
                                        <div className='last-element-show-more'>
                                            <a href="">Show 2 more</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="user-section mt-4">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="mx-3 mt-4">
                                            <h4>Social Media</h4>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 mt-3">
                                        <div className="card icon-card-custom">
                                            <div className="icon-card-section">
                                                <img src="./assets/my-profile/facebook-icon.png" className="img-fluid social-img" alt="" />
                                                <div className='icon-card-dv'>
                                                    <p>https://www.facebook.com</p>
                                                    <img src="./assets/my-profile/right-icon.png" className="" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 mt-3">
                                        <div className="card icon-card-custom">
                                            <div className="icon-card-section">
                                                <img src="./assets/my-profile/x.png" className="img-fluid social-img" alt="" />
                                                <div className='icon-card-dv'>
                                                    <p>https://www.twitter.com</p>
                                                    <img src="./assets/my-profile/black-right-icon.png" className="" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 mt-3">
                                        <div className="card icon-card-custom">
                                            <div className="icon-card-section">
                                                <img src="./assets/my-profile/linkedin.png" className="img-fluid social-img" alt="" />
                                                <div className='icon-card-dv'>
                                                    <p>https://www.linkedin.com</p>
                                                    <img src="./assets/my-profile/black-right-icon.png" className="" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 mt-3">
                                        <div className="card icon-card-custom">
                                            <div className="icon-card-section">
                                                <img src="./assets/my-profile/instagram.png" className="img-fluid social-img" alt="" />
                                                <div className='icon-card-dv'>
                                                    <p>https://www.instagram.com</p>
                                                    <img src="./assets/my-profile/black-right-icon.png" className="" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 mt-3 mt-lg-3 mt-md-3 mb-lg-0 mb-md-0 mb-3">
                                        <div className="card icon-card-custom">
                                            <div className="icon-card-section">
                                                <img src="./assets/my-profile/github.png" className="img-fluid social-img" alt="" />
                                                <div className='icon-card-dv'>
                                                    <p>https://www.github.com</p>
                                                    <img src="./assets/my-profile/black-right-icon.png" className="" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="user-section mt-4">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="top-user-section">
                                            <div className="d-flex mt-3">
                                                <div className="mx-3">
                                                    <h4>Attachments</h4>
                                                </div>
                                            </div>
                                            <div className="mt-0 mt-lg-3 mt-md-3">
                                                <button className="edit-btn">Add File</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex">
                                            <div className="mx-3">
                                                <img src="./assets/my-profile/attchment.png" className="img-fluid" alt="" />
                                            </div>
                                            <div>
                                                <h5>Resume-AnamoulRouf.pdf</h5>
                                                <p>Resume .121MB</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="trush-icon">
                                            <img src="./assets/my-profile/trush-square.png" alt="" />
                                            <img src="./assets/my-profile/eye-icon.png" alt="" />
                                            <img src="./assets/my-profile/Icon-pen.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default RecMyProfileContent