import React from 'react'
import './scss/JobsStatus.css'
import { Link } from 'react-router-dom'
const JobsStatusContent = () => {
    return (
        <>
            <section className='job-status-content-section'>
                <div className='container'>
                    <div className='candidate-pro-section-inner-dv'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-12'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6 col-md-6 col-6'>
                                        <div className='condidate-pro-heading'>
                                            <h2>Jobs Status</h2>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-6 text-lg-end text-md-end text-end'>
                                        <div className='candidate-pro-btn'>
                                            <a href='#' className='post-btn'>Post a Job</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='row align-items-center'>
                                    <div className='col-lg-4 col-md-12 col-12'>
                                        <div className='open-closed-btn mt-2'>
                                            <a href='#' className='a-opn-cls-btn active'>
                                                Open and Paused (100)
                                            </a>
                                            <a href='#' className='a-opn-cls-btn'>
                                                Closed(50)
                                            </a>
                                        </div>
                                    </div>
                                    <div className='col-lg-8 col-md-12 col-12'>
                                        <div className='right-side-search-filter-btns'>
                                            <div className='inpt-search margin-rgt-lft'>
                                                <input type='text' placeholder='Search' />
                                                <div className='search-icon'>
                                                    <a href='#'><img src='./assets/recruiter-images/job-status/search.png' className='img-fluid' /></a>
                                                </div>
                                            </div>
                                            <div class="dropdown margin-rgt-lft">
                                                <button class="border dropdown-toggle" type="button" id="filter-menu" data-bs-toggle="dropdown" aria-expanded="false"><img src='./assets/recruiter-images/job-status/filter.png' className='' /> Filter</button>
                                                <ul class="dropdown-menu" aria-labelledby="filter-menu">
                                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                            <div class="dropdown margin-rgt-lft">
                                                <button class="border dropdown-toggle" type="button" id="sort-by-menu" data-bs-toggle="dropdown" aria-expanded="false"><img src='./assets/recruiter-images/job-status/calendar.png' className='' /> Sort by</button>
                                                <ul class="dropdown-menu" aria-labelledby="sort-by-menu">
                                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                            <div class="dropdown margin-rgt-lft">
                                                <button class="border dropdown-toggle" type="button" id="order-des-menu" data-bs-toggle="dropdown" aria-expanded="false"> Order: Descending</button>
                                                <ul class="dropdown-menu" aria-labelledby="order-des-menu">
                                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='postwise-active-inactive-card-parent'>
                                    <div className='row align-items-center'>
                                        <div className='col-lg-5 col-lg-5 col-12 mt-lg-0 mt-md-3 mt-2'>
                                            <div className='row'>
                                                <div className='col-lg-1 col-md-1 col-1'>
                                                    <div className='inpt-checkbox'>
                                                        <input type='checkbox' className='inpt-checkbox' />
                                                    </div>
                                                </div>
                                                <div className='col-lg-11 col-md-11 col-11'>
                                                    <div className='rght-post-des'>
                                                        <h5>UX/UI designer</h5>
                                                        <h6><a href='#' className='post-a-tag'>Upwork</a> <a href='#' className='post-a-tag'>Remote Only</a></h6>
                                                        <div className='post-description-para'>
                                                            On Upwork you'll find a range of top freelancers and agencies, from developers and development agencies to designers and creative agencies, copywriters,
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-5 col-lg-5 col-12 mt-lg-0 mt-md-3 mt-2'>
                                            <div className='post-job-four-small-card'>
                                                <div className='count-card'>
                                                    <p>Active</p>
                                                    <p>10</p>
                                                </div>
                                                <div className='count-card'>
                                                    <p>Awaiting</p>
                                                    <p>10</p>
                                                </div>
                                                <div className='count-card'>
                                                    <p>Contacting</p>
                                                    <p>0</p>
                                                </div>
                                                <div className='count-card'>
                                                    <p>Hired</p>
                                                    <p>0 of 5</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-lg-2 col-12 mt-lg-0 mt-md-3 mt-2'>
                                            <div className='post-description-start-set'>
                                                <div className='post-select-dropdown'>
                                                    <div class="dropdown">
                                                        <button class=" dropdown-toggle" type="button" id="post-select-pause" data-bs-toggle="dropdown" aria-expanded="false"> Pause </button>
                                                        <ul class="dropdown-menu" aria-labelledby="post-select-pause">
                                                            <li><a class="dropdown-item" href="#">Action</a></li>
                                                            <li><a class="dropdown-item" href="#">Another action</a></li>
                                                            <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                        </ul>
                                                    </div>
                                                    <div className='three-dot-toggle'>
                                                        <a href='#'><img src='./assets/recruiter-images/job-status/side-toggle.png' /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ----------------start card------------------ */}
                                <div className='postwise-active-inactive-card-parent'>
                                    <div className='row align-items-center'>
                                        <div className='col-lg-5 col-lg-5 col-12 mt-lg-0 mt-md-3 mt-2'>
                                            <div className='row'>
                                                <div className='col-lg-1 col-md-1 col-1'>
                                                    <div className='inpt-checkbox'>
                                                        <input type='checkbox' className='inpt-checkbox' />
                                                    </div>
                                                </div>
                                                <div className='col-lg-11 col-md-11 col-11'>
                                                    <div className='rght-post-des'>
                                                        <h5>Product designer</h5>
                                                        <h6><a href='#' className='post-a-tag'>Facebook</a> <a href='#' className='post-a-tag'>CA, USA</a></h6>
                                                        <div className='post-description-para'>Founded in 2004, Facebook's mission is to give people the power to build community and bring the world closer together. People use our products to stay..</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-5 col-lg-5 col-12 mt-lg-0 mt-md-3 mt-2'>
                                            <div className='post-job-four-small-card'>
                                                <div className='count-card'>
                                                    <p>Active</p>
                                                    <p>10</p>
                                                </div>
                                                <div className='count-card'>
                                                    <p>Awaiting</p>
                                                    <p>10</p>
                                                </div>
                                                <div className='count-card'>
                                                    <p>Contacting</p>
                                                    <p>0</p>
                                                </div>
                                                <div className='count-card'>
                                                    <p>Hired</p>
                                                    <p>0 of 5</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-lg-2 col-12 mt-lg-0 mt-md-3 mt-2'>
                                            <div className='post-description-start-set'>
                                                <div className='post-select-dropdown'>
                                                    <div class="dropdown">
                                                        <button class=" dropdown-toggle" type="button" id="post-select-open" data-bs-toggle="dropdown" aria-expanded="false"> Open </button>
                                                        <ul class="dropdown-menu" aria-labelledby="post-select-open">
                                                            <li><a class="dropdown-item" href="#">Action</a></li>
                                                            <li><a class="dropdown-item" href="#">Another action</a></li>
                                                            <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                        </ul>
                                                    </div>
                                                    <div className='three-dot-toggle'>
                                                        <a href='#'><img src='./assets/recruiter-images/job-status/side-toggle.png' /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ----------------end card------------------ */}
                                {/* ----------------start card------------------ */}
                                <div className='postwise-active-inactive-card-parent'>
                                    <div className='row align-items-center'>
                                        <div className='col-lg-5 col-lg-5 col-12 mt-lg-0 mt-md-3 mt-2'>
                                            <div className='row'>
                                                <div className='col-lg-1 col-md-1 col-1'>
                                                    <div className='inpt-checkbox'>
                                                        <input type='checkbox' className='inpt-checkbox' />
                                                    </div>
                                                </div>
                                                <div className='col-lg-11 col-md-11 col-11'>
                                                    <div className='rght-post-des'>
                                                        <h5>UX designer</h5>
                                                        <h6><a href='#' className='post-a-tag'>Google</a> <a href='#' className='post-a-tag'>International</a></h6>
                                                        <div className='post-description-para'>Search the world's information, including webpages, images, videos and more. Google has many special features to help you find exactly what you're looking.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-5 col-lg-5 col-12 mt-lg-0 mt-md-3 mt-2'>
                                            <div className='post-job-four-small-card'>
                                                <div className='count-card'>
                                                    <p>Active</p>
                                                    <p>10</p>
                                                </div>
                                                <div className='count-card'>
                                                    <p>Awaiting</p>
                                                    <p>10</p>
                                                </div>
                                                <div className='count-card'>
                                                    <p>Contacting</p>
                                                    <p>0</p>
                                                </div>
                                                <div className='count-card'>
                                                    <p>Hired</p>
                                                    <p>0 of 5</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-lg-2 col-12 mt-lg-0 mt-md-3 mt-2'>
                                            <div className='post-description-start-set'>
                                                <div className='post-select-dropdown'>
                                                    <div class="dropdown">
                                                        <button class=" dropdown-toggle" type="button" id="post-select-closed" data-bs-toggle="dropdown" aria-expanded="false"> Closed </button>
                                                        <ul class="dropdown-menu" aria-labelledby="post-select-closed">
                                                            <li><a class="dropdown-item" href="#">Action</a></li>
                                                            <li><a class="dropdown-item" href="#">Another action</a></li>
                                                            <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                        </ul>
                                                    </div>
                                                    <div className='three-dot-toggle'>
                                                        <a href='#'><img src='./assets/recruiter-images/job-status/side-toggle.png' /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ----------------end card------------------ */}
                            </div>

                        </div>
                        <div className='row justify-content-center mt-lg-5 mt-md-3 mt-4'>
                            <div className='col-lg-12 col-md-12 col-12'>
                                <nav aria-label="Page navigation example text-center">
                                    <ul class="pagination justify-content-center">
                                        <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                        <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default JobsStatusContent