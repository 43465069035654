import React from 'react'
import './scss/ContactUs.css'
const ContactUsContent = () => {
    return (
        <>
            <section className='contact-us-section'>
                <div className="main-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="main-head">
                                    <h2>Contact Us</h2>
                                </div>
                            </div>
                        </div>
                        <div className="banner-section ">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="banner-head mt-5">
                                        <h2>Contact us</h2>
                                    </div>
                                    <form action=''>
                                        <div className="row">
                                            <div className="col-md-6 mt-3">
                                                <div className='form-field-class'>
                                                    <label htmlFor="name1" className="form-label">
                                                        First name
                                                    </label>
                                                    <input
                                                        className="form-control form-custom"
                                                        id="name1"
                                                        type="text"
                                                        name="text"
                                                        placeholder="First Name"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <div className='form-field-class'>
                                                    <label htmlFor="name2" className="form-label">
                                                        Last name
                                                    </label>
                                                    <input
                                                        className="form-control form-custom"
                                                        id="name2"
                                                        type="text"
                                                        name="text"
                                                        placeholder="last Name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 mt-3">
                                                <div className='form-field-class'>
                                                    <label htmlFor="mail" className="form-label">
                                                        Email
                                                    </label>
                                                    <input
                                                        type="mail"
                                                        className="form-control form-custom2"
                                                        id="mail"
                                                        name="mail"
                                                        placeholder="Enter your email address"
                                                        pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 mt-3">
                                                <div className='form-field-class'>
                                                    <label htmlFor="message" className="form-label">
                                                        What can we help you with ?
                                                    </label>
                                                    <textarea
                                                        className="form-control form-custom3"
                                                        id="exampleFormControlTextarea1"
                                                        rows={3}
                                                        placeholder="Type here your message"
                                                        defaultValue={""}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="join-btn text-start">
                                            <button>Submit</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-lg-6 ">
                                    <div className="first-head name">
                                        <div className="display-flex-custom">
                                            <div className="first-box">
                                                <div className="quote-box">
                                                    <div className="quote">
                                                        <p>We are always here to help</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="second-box">
                                                <div className="quote-box">
                                                    <div className="quote">
                                                        <p>Hello There !</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <img
                                            src="./assets/images_another/operator-img.png"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            {/* ================Contact Card=============== */}
            <section className='call-card py-5'>
                <div className="container">
                    <div className="row mx-4">
                        <div className="col-lg-4 col-md-6">
                            <div className="card-area">
                                <div className="d-flex align-items-center">
                                    <img src="./assets/images_another/mail.png" alt="" />
                                    <div className="textarea">
                                        <h4>Drop a line</h4>
                                        <h2>Mail Us</h2>
                                    </div>
                                </div>
                                <p>Support87@gmial.com</p>
                                <p>ijkuiu874@gmial.com</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="card-area">
                                <div className="d-flex align-items-center">
                                    <img src="./assets/images_another/phone-call.png" alt="" />
                                    <div className="textarea">
                                        <h4>24/7 Service</h4>
                                        <h2>Call Us</h2>
                                    </div>
                                </div>
                                <p>+880 265 98745 <span>(Toll free)</span></p>
                                <p>+895 855 85589</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="card-area">
                                <div className="d-flex align-items-center">
                                    <img src="./assets/images_another/map-pin.png" alt="" />
                                    <div className="textarea">
                                        <h4>Location</h4>
                                        <h2>Visit Us</h2>
                                    </div>
                                </div>
                                <p>158 ralegih sit, houston, yk 5896,uk</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactUsContent