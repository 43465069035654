import React from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import CareerSearchResultListingAndDetailsContent from './CareerSearchResultListingAndDetailsContent'
import Footer from '../../Commons/Footer'
import MostViewedJobsSlider from '../CareerStartHereSearch/MostViewedJobsSlider/MostViewedJobsSlider'
import RecommendedJobsSlider from '../CareerStartHereSearch/RecommendedJobsSlider/RecommendedJobsSlider'

const CareerSearchResultListingAndDetails = () => {
  return (
    <>
        <AfterNavbar />
        <CareerSearchResultListingAndDetailsContent />
        <RecommendedJobsSlider/>
        <Footer />
    </>
  )
}

export default CareerSearchResultListingAndDetails