import React from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import Footer from '../../Commons/Footer'
import TermsAndConditionContent from './TermsAndConditionContent'
const TermsAndCondition = () => {
  return (
    <>
      <AfterNavbar />
      <TermsAndConditionContent/>
      <Footer />
    </>
  )
}

export default TermsAndCondition