import React from 'react'
import { Link } from 'react-router-dom'

const CareerAdvice = () => {
    return (
        <section className="career-advice py-5">
            <div className="container">
                <div className="row">
                    <div className="title pb-3">
                        <h1>Career Advice</h1>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="custm-career-box">
                            <img src="./assets/images/career-guide/img1.png" className='img-fluid' alt="" />
                            <div className="textarea">
                                <h3>Career Test: Our Top 10</h3>
                                <p>Not sure what kind of job is right for you? A career test can give you some answers.</p>
                                <Link to={undefined}>Read More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="custm-career-box">
                            <img src="./assets/images/career-guide/img1.png" className='img-fluid' alt="" />
                            <div className="textarea">
                                <h3>How to Start Looking for a New Job</h3>
                                <p>Looking for a job? Here’s how to sort through the millions available to find the one that’s the right fit for you.</p>
                                <Link to={undefined}>Read More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="custm-career-box">
                            <img src="./assets/images/career-guide/img1.png" className='img-fluid' alt="" />
                            <div className="textarea">
                                <h3>Resumes</h3>
                                <p>Discover the ultimate guide to effective resume and curriculum vitae writing. Browse sample resumes, templates, and other tips to help build a strong resume and get your foot in the door.</p>
                                <Link to={undefined}>Read More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="custm-career-box">
                            <img src="./assets/images/career-guide/img1.png" className='img-fluid' alt="" />
                            <div className="textarea">
                                <h3>100 Interview Questions</h3>
                                <p>Interview questions can run the gamut. It's unlikely you'll face all 100 of these, but you should still be prepared to answer at least some of them.</p>
                                <Link to={undefined}>Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CareerAdvice