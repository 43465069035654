import React, { Component } from "react";
import './scss/Faq.css'
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
const FaqContent = () => {
    let data1 = [
        {
            id: 1,
            heading: "What is Webflow and why is it the best website builder?",
            content: "Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere."
        },
        {
            id: 2,
            heading: "What is your favorite template from BRIX Templates?",
            content: "Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere."
        },
        {
            id: 3,
            heading: "How do you clone a template from the Showcase?",
            content: "Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere."
        },
        {
            id: 4,
            heading: "Why is BRIX Templates the best Webflow agency?",
            content: "Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere."
        },
        {
            id: 5,
            heading: "When was Webflow officially launched?",
            content: "Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere."
        },
        {
            id: 6,
            heading: "How do you integrate Jetboost with Webflow?",
            content: "Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere."
        },
    ]

    let data2 = [
        {
            id: 1,
            heading: "What is your favorite template from BRIX Templates?",
            content: "Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere."
        },
        {
            id: 2,
            heading: "How do you clone a template from the Showcase?",
            content: "Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere."
        },
        {
            id: 3,
            heading: "What is Webflow and why is it the best website builder?",
            content: "Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere."
        },
        {
            id: 4,
            heading: "Why is BRIX Templates the best Webflow agency?",
            content: "Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere."
        },
        {
            id: 5,
            heading: "When was Webflow officially launched?",
            content: "Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere."
        },
        {
            id: 6,
            heading: "How do you integrate Jetboost with Webflow?",
            content: "Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere."
        },
    ]

    return (
        <>
            <section className='faq-sec'>
                {/* ===========================ciscle design======================== */}
                <div className="main-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="main-head">
                                    <h2>FAQ</h2>
                                </div>
                            </div>
                        </div>
                        <div className="banner-section">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <section className="main-container">
                                        <div className="main">
                                            <div className="big-circle">
                                                <div className="icon-block">
                                                    <img  src="./assets/faq/ellipse.png" alt="web design icon"
                                                    />
                                                </div>
                                                <div className="icon-block">
                                                    <img
                                                        src="./assets/faq/ellipse2.png"
                                                        alt="game design icon"
                                                    />
                                                </div>
                                                <div className="icon-block">
                                                    <img
                                                        src="./assets/faq/ellipse3.png"
                                                        alt="game dev icon"
                                                    />
                                                </div>
                                                <div className="icon-block">
                                                    <img
                                                        src="./assets/faq/ellipse4.png"
                                                        alt="ui-ux icon"
                                                    />
                                                </div>
                                            </div>
                                            <div className="circle circle-second">
                                                <div className="icon-block">
                                                    <img
                                                        src="./assets/faq/ellipse5.png"
                                                        alt="app icon"
                                                    />
                                                </div>
                                                <div className="icon-block">
                                                    <img
                                                        src="./assets/faq/ellipse6.png"
                                                        alt="blockchain icon"
                                                    />
                                                </div>
                                                <div className="icon-block">
                                                    <img
                                                        src="./assets/faq/ellipse7.png"
                                                        alt="ar-vr icon"
                                                    />
                                                </div>
                                                <div className="icon-block">
                                                    <img
                                                        src="./assets/faq/ellipse.png"
                                                        alt="artificial intelligence icon"
                                                    />
                                                </div>
                                            </div>
                                            <div className="center-logo">
                                                <img
                                                    src="./assets/faq/ellipse5.png"
                                                    alt="logo"
                                                />
                                            </div>
                                        </div>
                                    </section>

                                </div>
                                <div className="col-lg-6">
                                    <div className="first-head">
                                        <h2>
                                            {" "}
                                            You have questions,
                                            <br />
                                            <span>we have answers</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ===========================ciscle design======================== */}
                {/* ==============================acordian design start here ============================== */}
                <div class="second-section">
                    <div class="container">
                        <div class="banner-second">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-12">
                                    <div>
                                        <Accordion defaultActiveKey="0">
                                            {data1.map((e) => {
                                                return <Accordion.Item eventKey={e.id} key={e.id}>
                                                    <Accordion.Header>{e.heading}</Accordion.Header>
                                                    <Accordion.Body>
                                                        {e.content}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            })}
                                        </Accordion>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                    <div>
                                        <Accordion defaultActiveKey="0">
                                            {data2.map((e) => {
                                                return <Accordion.Item eventKey={e.id} key={e.id}>
                                                    <Accordion.Header>{e.heading}</Accordion.Header>
                                                    <Accordion.Body>
                                                        {e.content}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            })}
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ==============================acordian design end here ============================== */}
            </section>
        </>
    )
}

export default FaqContent