import React from 'react'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import MyProfileContent from './MyProfileContent'
import Footer from '../Commons/Footer'
import BeforeFooterPlayStore from '../Commons/BeforeFooter/BeforeFooterPlayStore'

const MyProfile = () => {
  return (
    <>
      <AfterNavbar />
      <MyProfileContent />
      <BeforeFooterPlayStore />
      <Footer />
    </>
  )
}

export default MyProfile