import React from 'react'
import RegisterHeader from './CvBuilderHeader/RegisterHeader'
import Footer from '../Commons/Footer'
import { Link } from 'react-router-dom';
import Steps from './Steps';

const ProfessionalDetail = () => {
    return (
        <>
            <RegisterHeader />

            <section className='professional-detail'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="stepper-area">
                                <Steps activeStepCount={2} />
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="form-area">
                                <div className="row">
                                    <div className="col-lg-2 profile-back-btn">
                                        <div className="back-btn">
                                            <Link to='/educational-details'><i className="fa-solid fa-arrow-left mx-2"></i>Back</Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 edu-title">
                                        <h1>Professional Details</h1>
                                    </div>
                                    <div className="col-lg-2 profile-edit-btn">
                                        <div className="edit-btn">
                                            <Link href={undefined}>Edit</Link>
                                        </div>
                                    </div>
                                </div>
                                <form action="" className='mt-5'>
                                    <div className="row">
                                        <div className="col-md-12 mb-3 work-confo d-flex justify-content-between">
                                            <label htmlFor="" className='mb-2'>Are you Working</label>
                                            <div>
                                                <input type="radio" id='working' checked name='yes' /><label for='working' className='mx-2'>Yes</label>
                                            </div>
                                            <div>
                                                <input type="radio" id='working2' name='yes' /><label for='working2' className='mx-2'>No</label>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="" className='mb-2'>Current / Last  Company<span>*</span></label>
                                            <input type="text" name='name' className='form-control' placeholder='Current / Last  Company' />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="" className='mb-2'>Type of Employment<span>*</span></label>
                                            <input type="text" name='name' className='form-control' placeholder='Type of Employment' />
                                        </div>
                                        <div className="col-md-6 mb-3 cus-pass-section">
                                            <label htmlFor="" className='mb-2'>Company City, State<span>*</span></label>
                                            <input type="pasword" name='name' className='form-control' placeholder='Company City, State' />
                                            {/* <select name="" className='form-control  form-select' id="">
                                                <option value="">Company City, State</option>
                                                <option value="graduate">Delhi</option>
                                                <option value="graduate">Delhi</option>
                                                <option value="graduate">Delhi</option>
                                                <option value="graduate">Delhi</option>
                                            </select> */}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="" className='mb-2'>Company Country<span>*</span></label>
                                            {/* <input type="text" name='name' className='form-control' placeholder='Company Country' /> */}
                                            <select name="" className='form-control  form-select' id="">
                                                <option value="">Company Country</option>
                                                <option value="graduate">Delhi</option>
                                                <option value="graduate">Delhi</option>
                                                <option value="graduate">Delhi</option>
                                                <option value="graduate">Delhi</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="" className='mb-2'>Postal Code<span>*</span></label>
                                            <input type="text" name='name' className='form-control' placeholder='Postal Code' />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="" className='mb-2'>Designation<span>*</span></label>
                                            <input type="text" name='name' className='form-control' placeholder='Designation' />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="" className='mb-2'>Joining Date</label>
                                            <input type="date" name='name' className='form-control' placeholder='Joining Date' />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="" className='mb-2'>Current / Last Salary</label>
                                            <input type="text" name='name' className='form-control' placeholder='Current / Last Salary' />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="" className='mb-2'>Industry</label>
                                            {/* <input type="text" name='name' className='form-control' placeholder='Industry' /> */}
                                            <select name="" className='form-control  form-select' id="">
                                                <option value="">Industry</option>
                                                <option value="graduate">Select</option>
                                                <option value="graduate">Select</option>
                                                <option value="graduate">Select</option>
                                                <option value="graduate">Select</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="" className='mb-2'>Relieving Date</label>
                                            <input type="text" name='name' className='form-control' placeholder='Relieving Date' onChange={(e) => console.log(e.target.value)} onFocus={(e) => (e.target.type = "date")} onBlur={(e) => (e.target.type = "text")} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="" className='mb-2'>Skills Used<span>*</span></label>
                                            <input type="text" name='name' className='form-control' placeholder='Skills Used' />
                                        </div>
                                        <div className="col-md-12 mb-3 work-confo  d-flex justify-content-between">
                                            <label htmlFor="" className='mb-2'>Are you Serving Notice Period?</label>
                                            <div>
                                                <input type="radio" id='rad' checked name='yes' /><label for='rad' className='mx-2'>Yes</label>
                                            </div>
                                            <div>
                                                <input type="radio" id='rad2' name='yes' /><label for='rad2' className='mx-2'>No</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Notice Period<span>*</span></label>
                                            <input type="date" name='name' className='form-control' placeholder='Notice Period' onChange={(e) => console.log(e.target.value)} onFocus={(e) => (e.target.type = "date")} onBlur={(e) => (e.target.type = "text")} />
                                        </div>
                                        <div className="col-lg-6 d-flex align-items-center justify-content-end cust-add-btn">
                                            <div className="add-btn mt-3">
                                                <Link href={undefined}><i className="fa-solid fa-plus"></i> Add More</Link>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-5 mb-5 custm-btn-sec d-flex justify-content-center">
                                            <div className='custm-botton-btn mb-2'>
                                                <Link to='/educational-details' className='skip mx-2'>Back</Link>
                                            </div>
                                            <div className='custm-botton-btn mb-2'>
                                                <Link to='/project-details' className='next mx-2'>Next</Link>
                                            </div>
                                            <div className='custm-botton-btn mb-2'>
                                                <Link to='/project-details' className='skip mx-2'>Skip</Link>
                                            </div>
                                            {/* <Link to='/educational-details'><button className='skip mb-2 mx-2'>Back</button></Link>
                                            <Link to='/project-details'><button className='next mb-2 mx-2'>Next</button></Link>
                                            <Link to='/project-details'><button className='skip mb-2 mx-2'>Skip</button></Link> */}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </section >

            <Footer />

        </>

    )
}

export default ProfessionalDetail