import React from 'react'
import './scss/RecCompanyProfileDetails.css'
const RecCompanyProfileDetailsContent = () => {
  return (
    <>
      <section className='company-profile-details-sec'>
        <section className="mt-5 mb-5 comp_dtl_page">
          <div className="main-sec-2">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="subscrip-card">
                    <div className="row mt-4">
                      <div className="col-md-6">
                        <div>
                          <h1>Company Details</h1>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex justify-content-md-end cus-btn justify-content-between">
                          <button type="button" className="btn btn-1   mx-2">
                            Edit Profile
                          </button>
                          <button type="button" className="btn btn-2   mx-2">
                            Post A job
                          </button>
                        </div>
                      </div>
                      {/* ===============Detail Sec======== */}
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div className="cus-msg">
                            <h3>Get To Know Deal Jobs:</h3>
                            <p>
                              Deal Jobs is Indonesia's largest job portal &amp;
                              mentoring platform. We help people easily find jobs to top
                              Indonesian companies for internship and full-time roles.
                              As you might have already heard about us, we are
                              revolutionizing how Indonesian engage with employers. Many
                              Indonesian students are talented, ambitious, but never
                              found a better opportunity for themselves. Our team is
                              driven by the vision to democratize information and
                              knowledge, thus opportunities. We think what we build will
                              allow Indonesian students to have a chance to self develop
                              and have a better future... <a href="">See More</a>
                            </p>
                          </div>
                        </div>
                        <div className="col-md-12 mt-3">
                          <div className="cus-msg">
                            <h3>Benefits:</h3>
                            <div className="row">
                              <div className="col-lg-3 col-md-4 mt-2">
                                <div className="d-flex cus-int">
                                  <div>
                                    <img src="./assets/images_another-ak/benicon.png" alt="" />
                                  </div>
                                  <p className="mx-2">Competitive Salary</p>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 mt-2">
                                <div className="d-flex cus-int">
                                  <div>
                                    <img src="./assets/images_another-ak/Ham2.png" alt="" />
                                  </div>
                                  <p className="mx-2">Free Food and Snack</p>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 mt-2">
                                <div className="d-flex cus-int">
                                  <div>
                                    <img src="./assets/images_another-ak/hem3.png" alt="" />
                                  </div>
                                  <p className="mx-1">International Exposure</p>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 mt-2">
                                <div className="d-flex cus-int">
                                  <div>
                                    <img src="./assets/images_another-ak/hem4.png" alt="" />
                                  </div>
                                  <p className="mx-2">Paid Sick Days</p>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 mt-2">
                                <div className="d-flex cus-int">
                                  <div>
                                    <img src="./assets/images_another-ak/hem5.png" alt="" />
                                  </div>
                                  <p className="mx-2">THR / Binus System</p>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 mt-2">
                                <div className="d-flex cus-int">
                                  <div>
                                    <img src="./assets/images_another-ak/hem6.png" alt="" />
                                  </div>
                                  <p className="mx-2">Casual Dress Code</p>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 mt-2">
                                <div className="d-flex cus-int">
                                  <div>
                                    <img src="./assets/images_another-ak/hem7.png" alt="" />
                                  </div>
                                  <p className="mx-2">Free Lunch</p>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 mt-2">
                                <div className="d-flex cus-int">
                                  <div>
                                    <img src="./assets/images_another-ak/hem8.png" alt="" />
                                  </div>
                                  <p className="mx-2">See All</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 mt-3">
                          <div className="cus-msg-2">
                            <h3>Team:</h3>
                          </div>
                          <div className="row mt-2">
                            <div className="col-lg-4 col-md-6 mt-2">
                              <a
                                href="#!"
                                className="d-flex justify-content-between cus-team"
                              >
                                <div className="d-flex flex-row">
                                  <img
                                    src="./assets/images_another-ak/user1.png"
                                    alt="avatar"
                                    className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                    width={50}
                                  />
                                  <div className="pt-1">
                                    <h5 className=" mb-0">Andhika Sudarman</h5>
                                    <p className="small">Chief Executive Officer</p>
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-2">
                              <a
                                href="#!"
                                className="d-flex justify-content-between cus-team"
                              >
                                <div className="d-flex flex-row">
                                  <img
                                    src="./assets/images_another-ak/user2.png"
                                    alt="avatar"
                                    className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                    width={50}
                                  />
                                  <div className="pt-1">
                                    <h5 className=" mb-0">Eleanor Pena</h5>
                                    <p className="small">Marketing Coordinator</p>
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-2">
                              <a
                                href="#!"
                                className="d-flex justify-content-between cus-team"
                              >
                                <div className="d-flex flex-row">
                                  <img
                                    src="./assets/images_another-ak/user3.png"
                                    alt="avatar"
                                    className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                    width={50}
                                  />
                                  <div className="pt-1">
                                    <h5 className=" mb-0">Jacob Jones</h5>
                                    <p className="small">Web Designer</p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 mt-3">
                          <div className="cus-msg-2">
                            <h3>Culture:</h3>
                          </div>
                          <div className="row mt-2">
                            <div className="col-lg-4 col-md-6 mt-2">
                              <a
                                href="#!"
                                className="d-flex justify-content-between cus-team-2"
                              >
                                <div className="d-flex flex-row">
                                  <img
                                    src="./assets/images_another-ak/cul1.png"
                                    alt="avatar"
                                    className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                    width={40}
                                  />
                                  <div className="pt-1">
                                    <h5 className=" mb-0">Innovative</h5>
                                    <p className="small">
                                      We strive to constantly pursue innovation of our
                                      products and services
                                    </p>
                                  </div>
                                </div>
                                <div className="vl mx-1" />
                              </a>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-2">
                              <a
                                href="#!"
                                className="d-flex justify-content-between cus-team-2"
                              >
                                <div className="d-flex flex-row">
                                  <img
                                    src="./assets/images_another-ak/cult-2.png"
                                    alt="avatar"
                                    className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                    width={40}
                                  />
                                  <div className="pt-1">
                                    <h5 className=" mb-0">Nurturing</h5>
                                    <p className="small">
                                      Our working environment prioritizes encouragement
                                      for employee's growth and development
                                    </p>
                                  </div>
                                </div>
                                <div className="vl mx-1" />
                              </a>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-2">
                              <a
                                href="#!"
                                className="d-flex justify-content-between cus-team-2"
                              >
                                <div className="d-flex flex-row">
                                  <img
                                    src="./assets/images_another-ak/cul3.png"
                                    alt="avatar"
                                    className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                    width={40}
                                  />
                                  <div className="">
                                    <h5 className=" mb-0">Energetic</h5>
                                    <p className="small">
                                      Here, we work together to make the dream work
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* =================================================cus---------------form */}
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div className="outer-form2 mt-4 mb-4">
                            <form method="post" id="donor_form">
                              <div className="row g-3">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Company Name<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control my-custom-form   "
                                      id="name"
                                      name="firn"
                                      placeholder=" Company Name"
                                      required=""
                                      readOnly=""
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Name of The CEO
                                      <span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control my-custom-form"
                                      id="name2"
                                      name="midn"
                                      placeholder="Name of The CEO"
                                      readOnly=""
                                      required=""
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Location<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control my-custom-form  "
                                      id="name3"
                                      name="lastn"
                                      placeholder="Location"
                                      required=""
                                      readOnly=""
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Founded Year<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control my-custom-form  "
                                      id="name3"
                                      name="lastn"
                                      placeholder="Founded Year"
                                      required=""
                                      readOnly=""
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Company Website
                                      <span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control my-custom-form  "
                                      id="name3"
                                      name="lastn"
                                      placeholder="Company Website"
                                      required=""
                                      readOnly=""
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Industry<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control my-custom-form"
                                      id="name3"
                                      name="lastn"
                                      placeholder="Industry"
                                      required=""
                                      readOnly=""
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Company Size<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control my-custom-form"
                                      id="name3"
                                      name="lastn"
                                      placeholder="Company Size"
                                      required=""
                                      readOnly=""
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Headquarters Location
                                      <span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control my-custom-form"
                                      id="name3"
                                      name="lastn"
                                      placeholder="Headquarters Location"
                                      required=""
                                      readOnly=""
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Company Logo<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control my-custom-form"
                                      id="name3"
                                      name="lastn"
                                      placeholder="Company Logo"
                                      required=""
                                      readOnly=""
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Net Worth<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control my-custom-form"
                                      id="name3"
                                      name="lastn"
                                      placeholder="Net Worth"
                                      required=""
                                      readOnly=""
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Business Type<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control my-custom-form"
                                      id="name3"
                                      name="lastn"
                                      placeholder="Business Type"
                                      required=""
                                      readOnly=""
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      List of Department
                                      <span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control my-custom-form"
                                      id="name3"
                                      name="lastn"
                                      placeholder="List of Department"
                                      required=""
                                      readOnly=""
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Benefits<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control my-custom-form"
                                      id="name3"
                                      name="lastn"
                                      placeholder="Benefits"
                                      required=""
                                      readOnly=""
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Landline No.<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control my-custom-form"
                                      id="name3"
                                      name="lastn"
                                      placeholder="Landline No"
                                      required=""
                                      readOnly=""
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Mobile No<span className="cus-astrik">*</span>
                                    </label>
                                    <div className='right-mark-dv'>
                                      <input
                                        type="text"
                                        className="form-control my-custom-form "
                                        id="mob1"
                                        name="mob1"
                                        placeholder=" Mobile No"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        maxLength={10}
                                        readOnly=""
                                      />
                                      <img src='./assets/images/right-mark.png' className='right-mark-img' />
                                    </div>
                                  </div>
                                </div>
                                <div className="outer-form-area2 mt-4">
                                  <h6>Contact Person Details</h6>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      First Name<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control my-custom-form "
                                      id="fir"
                                      name="fir"
                                      placeholder="First Name"
                                      readOnly=""
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Last Name<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control my-custom-form "
                                      id="lastn"
                                      name="lastn"
                                      placeholder="Last Name"
                                      readOnly=""
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Designation <span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control my-custom-form "
                                      id="desg"
                                      name="desg"
                                      placeholder="Designation"
                                      readOnly=""
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Phone No.<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control my-custom-form "
                                      id="mob1"
                                      name="mob1"
                                      placeholder=" Phone No"
                                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                      maxLength={10}
                                      readOnly=""
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Email Id<span className="cus-astrik">*</span>
                                    </label>
                                    <div className='right-mark-dv'>
                                      <input
                                        type="text"
                                        className="form-control my-custom-form "
                                        id="mob1"
                                        name="mob1"
                                        placeholder="Email Id"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        maxLength={10}
                                        readOnly=""
                                      />
                                      <img src='./assets/images/right-mark.png' className='right-mark-img' />
                                    </div>

                                  </div>
                                </div>
                                <div className="outer-form-area2 mt-4">
                                  <h6>Account Details</h6>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Email Id for Registration
                                      <span className="cus-astrik">*</span>
                                    </label>
                                    <div className='right-mark-dv'>
                                      <input
                                        type="text"
                                        className="form-control my-custom-form "
                                        id="mob1"
                                        name="mob1"
                                        placeholder="Email Id for Registration"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        maxLength={10}
                                        readOnly=""
                                      />
                                      <img src='./assets/images/right-mark.png' className='right-mark-img' />
                                    </div>

                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Create Password
                                      <span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control my-custom-form "
                                      id="desg"
                                      name="desg"
                                      placeholder="Create Password"
                                      readOnly=""
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Confirm Password
                                      <span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control my-custom-form "
                                      id="desg"
                                      name="desg"
                                      placeholder="Confirm Password"
                                      readOnly=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

    </>
  )
}

export default RecCompanyProfileDetailsContent