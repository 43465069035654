import React, { useState } from 'react'
import './scss/FindCandidates.css'
import { Link } from 'react-router-dom';
const FindCandidatesContent = () => {

    const [stylenone, setStylenone] = useState("none");
    const handlefiterStyle = () => {
        if (stylenone == "none") {
            setStylenone("block");
        } else if (stylenone == "block") {
            setStylenone("none");
        }
    }
    return (
        <>
            <section className='find-condidate-section'>
                <div className='container'>
                    <div className='candidate-pro-section-inner-dv'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-12'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6 col-md-6 col-sm-6 col-6 col-xs-12'>
                                        <div className='condidate-pro-heading'>
                                            <h2>Find Candidates</h2>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-6 col-6 col-xs-12 text-lg-end text-md-end text-end'>
                                        <div className='candidate-pro-btn'>
                                            <a href='#' className='post-btn'>Post a Job</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-lg-12 col-md-12 col-12'>
                                        <ul class="nav nav-tabs all-match-candidate-cls" id="myTab" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link candidate-btn active" id="all-candidates-tab" data-bs-toggle="tab" data-bs-target="#all-candidates" type="button" role="tab" aria-controls="all-candidates" aria-selected="true">All Candidates</button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link candidate-btn" id="match-candidates-tab" data-bs-toggle="tab" data-bs-target="#match-candidates" type="button" role="tab" aria-controls="match-candidates" aria-selected="false">Match Candidates</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {/* --------------pill tab body show start--------------- */}
                                <div class="tab-content" id="myTabContent" >
                                    <div class="pill-tab-body-show tab-pane fade show active" id="all-candidates" role="tabpanel" aria-labelledby="all-candidates-tab">
                                        <div className='row align-items-center'>
                                            <div className='col-lg-9 col-md-12 col-12'>
                                                <div className='open-closed-btn my-lg-2 my-md-2 my-3'>
                                                    <a href='#' className='a-opn-cls-btn active'> Active (50) </a>
                                                    <a href='#' className='a-opn-cls-btn'> Shortlisted(0) </a>
                                                    <a href='#' className='a-opn-cls-btn'> Awaiting Review(40) </a>
                                                    <a href='#' className='a-opn-cls-btn'> Reviewed(50) </a>
                                                    <a href='#' className='a-opn-cls-btn'> Contacting(0) </a>
                                                    <a href='#' className='a-opn-cls-btn'> Rejected(50) </a>
                                                    <a href='#' className='a-opn-cls-btn'> Hired(5) </a>
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-md-12 col-12'>
                                                <div className='right-side-search-filter-btns'>
                                                    <div class="dropdown margin-rgt-lft">
                                                        <button class="border dropdown-toggle" type="button"
                                                            onClick={handlefiterStyle}
                                                        >
                                                            <img src='./assets/recruiter-images/job-status/filter.png' className='' /> Filter</button>
                                                        {/* <ul class="dropdown-menu" aria-labelledby="filter-menu">
                                                            <li><a class="dropdown-item" href="#">Action</a></li>
                                                            <li><a class="dropdown-item" href="#">Another action</a></li>
                                                            <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                        </ul> */}
                                                    </div>
                                                    <div class="add-candidate margin-rgt-lft">
                                                        <button class="border add-candidate-btn" type="button" > Add Candidate</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        {/* ---------------filter value start----------------- */}
                                        <div className='filter-parent-dv' style={{ display: stylenone }}>
                                            <div className='column-parent'>
                                                {/* ----------------col-2 start---------------- */}
                                                <div className='column-inner'>
                                                    <div className='filter-button'>
                                                        <div class="dropdown">
                                                            <button class="btn btn-secondary dropdown-toggle filter-btn-cls" type="button" id="date-posted" data-bs-toggle="dropdown" aria-expanded="false"> Date posted </button>
                                                            <ul class="dropdown-menu" aria-labelledby="date-posted">
                                                                <li><a class="dropdown-item" href="#">Action</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ----------------col-2 end------------------ */}
                                                {/* ----------------col-2 start---------------- */}
                                                <div className='column-inner'>
                                                    <div className='filter-button'>
                                                        <div class="dropdown">
                                                            <button class="btn btn-secondary dropdown-toggle filter-btn-cls" type="button" id="miles" data-bs-toggle="dropdown" aria-expanded="false"> Miles </button>
                                                            <ul class="dropdown-menu" aria-labelledby="miles">
                                                                <li><a class="dropdown-item" href="#">Action</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ----------------col-2 end------------------ */}
                                                {/* ----------------col-2 start---------------- */}
                                                <div className='column-inner'>
                                                    <div className='filter-button'>
                                                        <div class="dropdown">
                                                            <button class="btn btn-secondary dropdown-toggle filter-btn-cls" type="button" id="pay-salary" data-bs-toggle="dropdown" aria-expanded="false">Pay / salary</button>
                                                            <ul class="dropdown-menu" aria-labelledby="pay-salary">
                                                                <li><a class="dropdown-item" href="#">Action</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ----------------col-2 end------------------ */}
                                                {/* ----------------col-2 start---------------- */}
                                                <div className='column-inner'>
                                                    <div className='filter-button'>
                                                        <div class="dropdown">
                                                            <button class="btn btn-secondary dropdown-toggle filter-btn-cls" type="button" id="job-type" data-bs-toggle="dropdown" aria-expanded="false">Job type </button>
                                                            <ul class="dropdown-menu" aria-labelledby="job-type">
                                                                <li><a class="dropdown-item" href="#">Action</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ----------------col-2 end------------------ */}
                                                {/* ----------------col-2 start---------------- */}
                                                <div className='column-inner'>
                                                    <div className='filter-button'>
                                                        <div class="dropdown">
                                                            <button class="btn btn-secondary dropdown-toggle filter-btn-cls" type="button" id="shift-schedule" data-bs-toggle="dropdown" aria-expanded="false"> Shift & schedule </button>
                                                            <ul class="dropdown-menu" aria-labelledby="shift-schedule">
                                                                <li><a class="dropdown-item" href="#">Action</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ----------------col-2 end------------------ */}
                                                {/* ----------------col-2 start---------------- */}
                                                <div className='column-inner'>
                                                    <div className='filter-button'>
                                                        <div class="dropdown">
                                                            <button class="btn btn-secondary dropdown-toggle filter-btn-cls" type="button" id="experience-level" data-bs-toggle="dropdown" aria-expanded="false">Experience level</button>
                                                            <ul class="dropdown-menu" aria-labelledby="experience-level">
                                                                <li><a class="dropdown-item" href="#">Action</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ----------------col-2 end------------------ */}
                                                {/* ----------------col-2 start---------------- */}
                                                <div className='column-inner'>
                                                    <div className='filter-button'>
                                                        <div class="dropdown">
                                                            <button class="btn btn-secondary dropdown-toggle filter-btn-cls" type="button" id="location" data-bs-toggle="dropdown" aria-expanded="false">Location</button>
                                                            <ul class="dropdown-menu" aria-labelledby="location">
                                                                <li><a class="dropdown-item" href="#">Action</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ----------------col-2 end------------------ */}
                                                {/* ----------------col-2 start---------------- */}
                                                <div className='column-inner'>
                                                    <div className='filter-button'>
                                                        <div class="dropdown">
                                                            <button class="btn btn-secondary dropdown-toggle filter-btn-cls" type="button" id="education" data-bs-toggle="dropdown" aria-expanded="false">Education</button>
                                                            <ul class="dropdown-menu" aria-labelledby="education">
                                                                <li><a class="dropdown-item" href="#">Action</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ----------------col-2 end------------------ */}
                                                {/* ----------------col-2 start---------------- */}
                                                <div className='column-inner'>
                                                    <div className='filter-button'>
                                                        <div class="dropdown">
                                                            <button class="btn btn-secondary dropdown-toggle filter-btn-cls" type="button" id="company-type" data-bs-toggle="dropdown" aria-expanded="false">Company type</button>
                                                            <ul class="dropdown-menu" aria-labelledby="company-type">
                                                                <li><a class="dropdown-item" href="#">Action</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ----------------col-2 end------------------ */}
                                            </div>
                                        </div>
                                        {/* ---------------filter value end----------------- */}
                                        <div className='candidates-details-card'>
                                            <div className='row'>
                                                <div className='col-lg-4 col-md-6 col-12 my-lg-3 my-md-3 my-3'>
                                                    <div className='candidates-card'>
                                                        <div className='candidate-img-details'>
                                                            <img src='./assets/recruiter-images/find-candidates/candi-img.png' className='img-fluid' />
                                                            <h5><Link to="/rec-candidate-profile">Robert Whistable</Link></h5>
                                                            <h6>Product manager</h6>
                                                        </div>
                                                        <div className='pro-details'>
                                                            <ul>
                                                                <li>
                                                                    <p><span>Experience:</span>
                                                                        <span>03 Years</span></p>
                                                                </li>
                                                                <li>
                                                                    <p> <span>Alobha Technologies Pvt. Ltd.</span> </p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Education:</span>
                                                                        <span>MCA</span></p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Skills:</span>
                                                                        <span>HTML, CSS3, Others...</span></p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className='candidate-accept-reject'>
                                                            <div className='candidate-accept-reject-btn'>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/cross-icon.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/user.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/right-check.png' className='img-fluid' />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className='navigation-tooltips'>
                                                            <a href='#'><img src='./assets/recruiter-images/find-candidates/three-dot.png' /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-6 col-12 my-lg-3 my-md-3 my-3'>
                                                    <div className='candidates-card'>
                                                        <div className='candidate-img-details'>
                                                            <img src='./assets/recruiter-images/find-candidates/candi-img1.png' className='img-fluid' />
                                                            <h5><Link to="/rec-candidate-profile">Francois Boateng</Link></h5>
                                                            <h6>Product manager</h6>
                                                        </div>
                                                        <div className='pro-details'>
                                                            <ul>
                                                                <li>
                                                                    <p><span>Experience:</span>
                                                                        <span>03 Years</span></p>
                                                                </li>
                                                                <li>
                                                                <p> <span>Alobha Technologies Pvt. Ltd.</span> </p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Education:</span>
                                                                        <span>MCA</span></p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Skills:</span>
                                                                        <span>HTML, CSS3, Others...</span></p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className='candidate-accept-reject'>
                                                            <div className='candidate-accept-reject-btn'>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/cross-icon.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/user.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/right-check.png' className='img-fluid' />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className='navigation-tooltips'>
                                                            <a href='#'><img src='./assets/recruiter-images/find-candidates/three-dot.png' /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-6 col-12 my-lg-3 my-md-3 my-3'>
                                                    <div className='candidates-card'>
                                                        <div className='candidate-img-details'>
                                                            <img src='./assets/recruiter-images/find-candidates/candi-img2.png' className='img-fluid' />
                                                            <h5><Link to="/rec-candidate-profile">Carlos Heroa</Link></h5>
                                                            <h6>Product manager</h6>
                                                        </div>
                                                        <div className='pro-details'>
                                                            <ul>
                                                                <li>
                                                                    <p><span>Experience:</span>
                                                                        <span>03 Years</span></p>
                                                                </li>
                                                                <li>
                                                                <p> <span>Alobha Technologies Pvt. Ltd.</span> </p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Education:</span>
                                                                        <span>MCA</span></p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Skills:</span>
                                                                        <span>HTML, CSS3, Others...</span></p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className='candidate-accept-reject'>
                                                            <div className='candidate-accept-reject-btn'>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/cross-icon.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/user.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/right-check.png' className='img-fluid' />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className='navigation-tooltips'>
                                                            <a href='#'><img src='./assets/recruiter-images/find-candidates/three-dot.png' /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-6 col-12 my-lg-3 my-md-3 my-3'>
                                                    <div className='candidates-card'>
                                                        <div className='candidate-img-details'>
                                                            <img src='./assets/recruiter-images/find-candidates/candi-img3.png' className='img-fluid' />
                                                            <h5><Link to="/rec-candidate-profile">Heroa</Link></h5>
                                                            <h6>Product manager</h6>
                                                        </div>
                                                        <div className='pro-details'>
                                                            <ul>
                                                                <li>
                                                                    <p><span>Experience:</span>
                                                                        <span>03 Years</span></p>
                                                                </li>
                                                                <li>
                                                                <p> <span>Alobha Technologies Pvt. Ltd.</span> </p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Education:</span>
                                                                        <span>MCA</span></p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Skills:</span>
                                                                        <span>HTML, CSS3, Others...</span></p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className='candidate-accept-reject'>
                                                            <div className='candidate-accept-reject-btn'>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/cross-icon.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/user.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/right-check.png' className='img-fluid' />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className='navigation-tooltips'>
                                                            <a href='#'><img src='./assets/recruiter-images/find-candidates/three-dot.png' /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-6 col-12 my-lg-3 my-md-3 my-3'>
                                                    <div className='candidates-card'>
                                                        <div className='candidate-img-details'>
                                                            <img src='./assets/recruiter-images/find-candidates/candi-img4.png' className='img-fluid' />
                                                            <h5><Link to="/rec-candidate-profile">Robert</Link></h5>
                                                            <h6>Product manager</h6>
                                                        </div>
                                                        <div className='pro-details'>
                                                            <ul>
                                                                <li>
                                                                    <p><span>Experience:</span>
                                                                        <span>03 Years</span></p>
                                                                </li>
                                                                <li>
                                                                <p> <span>Alobha Technologies Pvt. Ltd.</span> </p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Education:</span>
                                                                        <span>MCA</span></p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Skills:</span>
                                                                        <span>HTML, CSS3, Others...</span></p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className='candidate-accept-reject'>
                                                            <div className='candidate-accept-reject-btn'>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/cross-icon.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/user.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/right-check.png' className='img-fluid' />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className='navigation-tooltips'>
                                                            <a href='#'><img src='./assets/recruiter-images/find-candidates/three-dot.png' /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-6 col-12 my-lg-3 my-md-3 my-3'>
                                                    <div className='candidates-card'>
                                                        <div className='candidate-img-details'>
                                                            <img src='./assets/recruiter-images/find-candidates/candi-img5.png' className='img-fluid' />
                                                            <h5>Whistable</h5>
                                                            <h6>Product manager</h6>
                                                        </div>
                                                        <div className='pro-details'>
                                                            <ul>
                                                                <li>
                                                                    <p><span>Experience:</span>
                                                                        <span>03 Years</span></p>
                                                                </li>
                                                                <li>
                                                                <p> <span>Alobha Technologies Pvt. Ltd.</span> </p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Education:</span>
                                                                        <span>MCA</span></p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Skills:</span>
                                                                        <span>HTML, CSS3, Others...</span></p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className='candidate-accept-reject'>
                                                            <div className='candidate-accept-reject-btn'>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/cross-icon.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/user.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/right-check.png' className='img-fluid' />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className='navigation-tooltips'>
                                                            <a href='#'><img src='./assets/recruiter-images/find-candidates/three-dot.png' /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-6 col-12 my-lg-3 my-md-3 my-3'>
                                                    <div className='candidates-card'>
                                                        <div className='candidate-img-details'>
                                                            <img src='./assets/recruiter-images/find-candidates/candi-img6.png' className='img-fluid' />
                                                            <h5><Link to="/rec-candidate-profile">R Whistable</Link></h5>
                                                            <h6>Product manager</h6>
                                                        </div>
                                                        <div className='pro-details'>
                                                            <ul>
                                                                <li>
                                                                    <p><span>Experience:</span>
                                                                        <span>03 Years</span></p>
                                                                </li>
                                                                <li>
                                                                <p> <span>Alobha Technologies Pvt. Ltd.</span> </p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Education:</span>
                                                                        <span>MCA</span></p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Skills:</span>
                                                                        <span>HTML, CSS3, Others...</span></p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className='candidate-accept-reject'>
                                                            <div className='candidate-accept-reject-btn'>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/cross-icon.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/user.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/right-check.png' className='img-fluid' />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className='navigation-tooltips'>
                                                            <a href='#'><img src='./assets/recruiter-images/find-candidates/three-dot.png' /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-6 col-12 my-lg-3 my-md-3 my-3'>
                                                    <div className='candidates-card'>
                                                        <div className='candidate-img-details'>
                                                            <img src='./assets/recruiter-images/find-candidates/candi-img7.png' className='img-fluid' />
                                                            <h5><Link to="/rec-candidate-profile">Robert Whistable</Link></h5>
                                                            <h6>Product manager</h6>
                                                        </div>
                                                        <div className='pro-details'>
                                                            <ul>
                                                                <li>
                                                                    <p><span>Experience:</span>
                                                                        <span>03 Years</span></p>
                                                                </li>
                                                                <li>
                                                                <p> <span>Alobha Technologies Pvt. Ltd.</span> </p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Education:</span>
                                                                        <span>MCA</span></p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Skills:</span>
                                                                        <span>HTML, CSS3, Others...</span></p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className='candidate-accept-reject'>
                                                            <div className='candidate-accept-reject-btn'>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/cross-icon.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/user.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/right-check.png' className='img-fluid' />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className='navigation-tooltips'>
                                                            <a href='#'><img src='./assets/recruiter-images/find-candidates/three-dot.png' /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-6 col-12 my-lg-3 my-md-3 my-3'>
                                                    <div className='candidates-card'>
                                                        <div className='candidate-img-details'>
                                                            <img src='./assets/recruiter-images/find-candidates/candi-img8.png' className='img-fluid' />
                                                            <h5><Link to="/rec-candidate-profile">Robert Whistable</Link></h5>
                                                            <h6>Product manager</h6>
                                                        </div>
                                                        <div className='pro-details'>
                                                            <ul>
                                                                <li>
                                                                    <p><span>Experience:</span>
                                                                        <span>03 Years</span></p>
                                                                </li>
                                                                <li>
                                                                <p> <span>Alobha Technologies Pvt. Ltd.</span> </p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Education:</span>
                                                                        <span>MCA</span></p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Skills:</span>
                                                                        <span>HTML, CSS3, Others...</span></p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className='candidate-accept-reject'>
                                                            <div className='candidate-accept-reject-btn'>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/cross-icon.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/user.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/right-check.png' className='img-fluid' />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className='navigation-tooltips'>
                                                            <a href='#'><img src='./assets/recruiter-images/find-candidates/three-dot.png' /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pill-tab-body-show tab-pane fade" id="match-candidates" role="tabpanel" aria-labelledby="match-candidates-tab">
                                        <div className='row align-items-center'>
                                            <div className='col-lg-9 col-md-12 col-12'>
                                                <div className='open-closed-btn my-lg-2 my-md-2 my-4'>
                                                    <a href='#' className='a-opn-cls-btn active'> Active (50) </a>
                                                    <a href='#' className='a-opn-cls-btn'> Shortlisted(0) </a>
                                                    <a href='#' className='a-opn-cls-btn'> Awaiting Review(40) </a>
                                                    <a href='#' className='a-opn-cls-btn'> Reviewed(50) </a>
                                                    <a href='#' className='a-opn-cls-btn'> Contacting(0) </a>
                                                    <a href='#' className='a-opn-cls-btn'> Rejected(50) </a>
                                                    <a href='#' className='a-opn-cls-btn'> Hired(5) </a>
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-md-12 col-12'>
                                                <div className='right-side-search-filter-btns'>
                                                    <div class="dropdown margin-rgt-lft">
                                                        <button class="border dropdown-toggle" type="button" id="filter-menu" data-bs-toggle="dropdown" aria-expanded="false"><img src='./assets/recruiter-images/job-status/filter.png' className='' /> Filter</button>
                                                        <ul class="dropdown-menu" aria-labelledby="filter-menu">
                                                            <li><a class="dropdown-item" href="#">Action</a></li>
                                                            <li><a class="dropdown-item" href="#">Another action</a></li>
                                                            <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                        </ul>
                                                    </div>
                                                    <div class="add-candidate margin-rgt-lft">
                                                        <button class="border add-candidate-btn" type="button" > Add Candidate</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='candidates-details-card'>
                                            <div className='row'>
                                                <div className='col-lg-4 col-md-4 col-12 my-lg-3 my-md-3 my-3'>
                                                    <div className='candidates-card'>
                                                        <div className='candidate-img-details'>
                                                            <img src='./assets/recruiter-images/find-candidates/candi-img.png' className='img-fluid' />
                                                            <h5><Link to="/rec-candidate-profile">Robert Whistable</Link></h5>
                                                            <h6>Product manager</h6>
                                                        </div>
                                                        <div className='pro-details'>
                                                            <ul>
                                                                <li>
                                                                    <p><span>Experience</span>
                                                                        <span>03 Years</span></p>
                                                                </li>
                                                                <li>
                                                                    <p>Alobha Technologies Pvt. Ltd.</p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Education</span>
                                                                        <span>MCA</span></p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Skills :</span>
                                                                        <span>HTML, CSS3, Others...</span></p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className='candidate-accept-reject'>
                                                            <div className='candidate-accept-reject-btn'>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/cross-icon.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/user.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/right-check.png' className='img-fluid' />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className='navigation-tooltips'>
                                                            <a href='#'><img src='./assets/recruiter-images/find-candidates/three-dot.png' /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 col-12 my-lg-3 my-md-3 my-3'>
                                                    <div className='candidates-card'>
                                                        <div className='candidate-img-details'>
                                                            <img src='./assets/recruiter-images/find-candidates/candi-img1.png' className='img-fluid' />
                                                            <h5><Link to="/rec-candidate-profile">Francois Boateng</Link></h5>
                                                            <h6>Product manager</h6>
                                                        </div>
                                                        <div className='pro-details'>
                                                            <ul>
                                                                <li>
                                                                    <p><span>Experience</span>
                                                                        <span>03 Years</span></p>
                                                                </li>
                                                                <li>
                                                                    <p>Alobha Technologies Pvt. Ltd.</p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Education</span>
                                                                        <span>MCA</span></p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Skills :</span>
                                                                        <span>HTML, CSS3, Others...</span></p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className='candidate-accept-reject'>
                                                            <div className='candidate-accept-reject-btn'>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/cross-icon.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/user.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/right-check.png' className='img-fluid' />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className='navigation-tooltips'>
                                                            <a href='#'><img src='./assets/recruiter-images/find-candidates/three-dot.png' /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 col-12 my-lg-3 my-md-3 my-3'>
                                                    <div className='candidates-card'>
                                                        <div className='candidate-img-details'>
                                                            <img src='./assets/recruiter-images/find-candidates/candi-img2.png' className='img-fluid' />
                                                            <h5><Link to="">Carlos Heroa</Link></h5>
                                                            <h6>Product manager</h6>
                                                        </div>
                                                        <div className='pro-details'>
                                                            <ul>
                                                                <li>
                                                                    <p><span>Experience</span>
                                                                        <span>03 Years</span></p>
                                                                </li>
                                                                <li>
                                                                    <p>Alobha Technologies Pvt. Ltd.</p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Education</span>
                                                                        <span>MCA</span></p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Skills :</span>
                                                                        <span>HTML, CSS3, Others...</span></p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className='candidate-accept-reject'>
                                                            <div className='candidate-accept-reject-btn'>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/cross-icon.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/user.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/right-check.png' className='img-fluid' />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className='navigation-tooltips'>
                                                            <a href='#'><img src='./assets/recruiter-images/find-candidates/three-dot.png' /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 col-12 my-lg-3 my-md-3 my-3'>
                                                    <div className='candidates-card'>
                                                        <div className='candidate-img-details'>
                                                            <img src='./assets/recruiter-images/find-candidates/candi-img3.png' className='img-fluid' />
                                                            <h5><Link to="/rec-candidate-profile">Heroa</Link></h5>
                                                            <h6>Product manager</h6>
                                                        </div>
                                                        <div className='pro-details'>
                                                            <ul>
                                                                <li>
                                                                    <p><span>Experience</span>
                                                                        <span>03 Years</span></p>
                                                                </li>
                                                                <li>
                                                                    <p>Alobha Technologies Pvt. Ltd.</p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Education</span>
                                                                        <span>MCA</span></p>
                                                                </li>
                                                                <li>
                                                                    <p><span>Skills :</span>
                                                                        <span>HTML, CSS3, Others...</span></p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className='candidate-accept-reject'>
                                                            <div className='candidate-accept-reject-btn'>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/cross-icon.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/user.png' className='img-fluid' />
                                                                </a>
                                                                <a href='#' className=''>
                                                                    <img src='./assets/recruiter-images/find-candidates/right-check.png' className='img-fluid' />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className='navigation-tooltips'>
                                                            <a href='#'><img src='./assets/recruiter-images/find-candidates/three-dot.png' /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* --------------pill tab body show end--------------- */}

                            </div>

                        </div>



                        {/* -------------------pagination start--------------- */}
                        <div className='row justify-content-center mt-lg-5 mt-md-3 mt-4'>
                            <div className='col-lg-12 col-md-12 col-12'>
                                <nav aria-label="Page navigation example text-center">
                                    <ul class="pagination justify-content-center">
                                        <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                        <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        {/* -------------------pagination end--------------- */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default FindCandidatesContent