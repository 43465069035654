import React from 'react'
import { Link } from 'react-router-dom'

const Resumes = () => {
    return (
        <section className="resumes" id='resume-sec-hd'>
            <div className="container py-5">
                <div className="row">
                    <div className="title p3-5">
                        <h1>Resumes</h1>
                    </div>

                    <div className="col-lg-2 col-md-4 resume-img py-4">
                        <img src="./assets/images/career-guide/resume.png" className='img-fluid' alt="" />
                    </div>
                    <div className="col-lg-4 col-md-8 resume-info py-4">
                        <h5>Travel</h5>
                        <h2>Design is the Mix of emotions</h2>
                        <h4>Did you come here for something in particular or just general Riker-bashing? And blowing into</h4>
                    </div>

                    <div className="col-lg-2 col-md-4 resume-img py-4">
                        <img src="./assets/images/career-guide/resume.png" className='img-fluid' alt="" />
                    </div>
                    <div className="col-lg-4 col-md-8 resume-info py-4">
                        <h5>Travel</h5>
                        <h2>Design is the Mix of emotions</h2>
                        <ul className='d-flex'>
                            <li><img src="./assets/images/career-guide/ava.png" className='img-fluid' alt="" />Jenny kiaa</li>
                            <li><img src="./assets/images/career-guide/calender.png" className='img-fluid' alt="" />02 december 2022</li>
                            <li><img src="./assets/images/career-guide/time.png" className='img-fluid' alt="" />3 min. to read</li>
                        </ul>
                        <h4>Did you come here for something in particular or just general Riker-bashing? And blowing into</h4>
                    </div>

                    <div className="col-lg-2 col-md-4 resume-img py-4">
                        <img src="./assets/images/career-guide/resume.png" className='img-fluid' alt="" />
                    </div>
                    <div className="col-lg-4 col-md-8 resume-info py-4">
                        <h5>Travel</h5>
                        <h2>Design is the Mix of emotions</h2>
                        <ul className='d-flex'>
                            <li><img src="./assets/images/career-guide/ava.png" className='img-fluid' alt="" />Jenny kiaa</li>
                            <li><img src="./assets/images/career-guide/calender.png" className='img-fluid' alt="" />02 december 2022</li>
                            <li><img src="./assets/images/career-guide/time.png" className='img-fluid' alt="" />3 min. to read</li>
                        </ul>
                        <h4>Did you come here for something in particular or just general Riker-bashing? And blowing into</h4>
                    </div>

                    <div className="col-lg-2 col-md-4 resume-img py-4">
                        <img src="./assets/images/career-guide/resume.png" className='img-fluid' alt="" />
                    </div>
                    <div className="col-lg-4 col-md-8 resume-info py-4">
                        <h5>Travel</h5>
                        <h2>Design is the Mix of emotions</h2>
                        <ul className='d-flex'>
                            <li><img src="./assets/images/career-guide/ava.png" className='img-fluid' alt="" />Jenny kiaa</li>
                            <li><img src="./assets/images/career-guide/calender.png" className='img-fluid' alt="" />02 december 2022</li>
                            <li><img src="./assets/images/career-guide/time.png" className='img-fluid' alt="" />3 min. to read</li>
                        </ul>
                        <h4>Did you come here for something in particular or just general Riker-bashing? And blowing into</h4>
                    </div>

                    <div className="view-btn pt-4 d-flex justify-content-center">
                        <Link to="/resume-listing">View All</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Resumes