import React from 'react'
import './scss/AddJobBasics.css'
import { Link } from 'react-router-dom'
const AddJobBasicsContent = () => {
    return (
        <>
            <section className='add-job-basics-sec'>
                <div className='container'>
                    <div className='create-an-emp-inner'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading'>
                                        <h2>Add Job Basics</h2>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading-inner'>
                                        <h3>How would you like to post your job<span className='text-danger'>*</span></h3>
                                    </div>
                                </div>
                            </div> */}
                            <form action='' method=''>
                                <div className='card-outer-dv card-outer-dv1'>
                                    <div className='row justify-content-center align-items-center'>
                                        <div className='col-lg-8 col-md-8 col-12'>
                                            <div className='left-sd-content'>
                                                <h5>Job post will be in English in India</h5>
                                                {/* <p>Copy a past job post and edit it as needed.</p> */}
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-12 text-lg-end text-md-end text-center'>
                                            <div className='right-sd-btn'>
                                                <a href='#' className='continue-btn'><img src='./assets/recruiter-images/post-a-job/fe_edit.png' /><span>Edit</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-outer-dv'>
                                    <div className='inner-input'>
                                        <label htmlFor='job-title'>Job Title <span className='text-danger'>*</span></label>
                                        <input type='text' placeholder='HR Executive' className='form-control' id="job-title" />
                                    </div>
                                </div>
                                <div className='card-outer-dv'>
                                    <div className='inner-input'>
                                        <label htmlFor='job-title'>Where will an employee report to work? <span className='text-danger'>*</span></label>
                                        {/* <input type='text' placeholder='HR Executive' className='form-control' id="job-title"/> */}
                                        <div className='radio-inpt form-control my-2'>
                                            <input type='radio' className='me-2' id='emp-will' name="emp-report" />
                                            <label htmlFor='emp-will'>Employees will report to a specific address</label>
                                        </div>
                                        <div className='radio-inpt form-control my-2'>
                                            <input type='radio' className='me-2' id='emp-will-report' name="emp-report" />
                                            <label htmlFor='emp-will-report'>Employees will not report to a specific address</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-outer-dv'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='left-sd-content'>
                                                <h5>Do you want to show the job's street address in the job post? *</h5>
                                            </div>
                                        </div>
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='map-dv'>
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.141377528771!2d77.37508841202285!3d28.62552459885215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce557e773a5db%3A0xa4b4dea3f4ae56cb!2sAlobha%20Technologies%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1696936216995!5m2!1sen!2sin" width="100%" height="250" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='inner-input my-lg-4 my-md-4 my-1'>
                                        <label htmlFor='street-address'>Street address<span className='text-danger'>*</span></label>
                                        <input type='text' placeholder='We’ll include this information in your job post' className='form-control' id="street-address" />
                                    </div>
                                    <div className='inner-input my-lg-4 my-md-4 my-1'>
                                        <label htmlFor='sub-local'>Sub locality</label>
                                        <input type='text' placeholder='Noida' className='form-control' id="sub-local" />
                                    </div>
                                    <div className='row my-lg-4 my-md-4 my-1'>
                                        <div className='col-md-4 col-lg-4 col-12'>
                                            <div className='inner-input'>
                                                <label htmlFor='city'>City<span className='text-danger'>*</span></label>
                                                {/* <input type='text' placeholder='Noida' className='form-control' id="sub-local" /> */}
                                                <select className='form-control' id="city">
                                                    <option>Gautam</option>
                                                    <option>Gautam</option>
                                                    <option>Gautam</option>
                                                    <option>Gautam</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-lg-4 col-12'>
                                            <div className='inner-input'>
                                                <label htmlFor='Statedistrict'>State or district<span className='text-danger'>*</span></label>
                                                {/* <input type='text' placeholder='Noida' className='form-control' id="sub-local" /> */}
                                                <select className='form-control' id="Statedistrict">
                                                    <option>Gautam</option>
                                                    <option>Gautam</option>
                                                    <option>Gautam</option>
                                                    <option>Gautam</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-lg-4 col-12'>
                                            <div className='inner-input'>
                                                <label htmlFor='pin-code'>Postal Code</label>
                                                <input type='text' placeholder='65462' className='form-control' id="pin-code" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='card-outer-dv'>
                                    <div className='inner-input'>
                                        <label htmlFor='street-address'>Street address<span className='text-danger'>*</span></label>
                                        <input type='text' placeholder='We’ll include this information in your job post' className='form-control' id="street-address" />
                                    </div>
                                </div> */}
                                {/* <div className='card-outer-dv'>
                                    <div className='inner-input'>
                                        <label htmlFor='sub-local'>Sub locality</label>
                                        <input type='text' placeholder='Noida' className='form-control' id="sub-local" />
                                    </div>
                                </div> */}
                                {/* <div className='card-outer-dv'>
                                    <div className='row'>
                                        <div className='col-md-4 col-lg-4 col-12'>
                                            <div className='inner-input'>
                                                <label htmlFor='city'>City<span className='text-danger'>*</span></label>
                                                <select className='form-control' id="city">
                                                    <option>Gautam</option>
                                                    <option>Gautam</option>
                                                    <option>Gautam</option>
                                                    <option>Gautam</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-lg-4 col-12'>
                                            <div className='inner-input'>
                                                <label htmlFor='Statedistrict'>State or district<span className='text-danger'>*</span></label>
                                                <select className='form-control' id="Statedistrict">
                                                    <option>Gautam</option>
                                                    <option>Gautam</option>
                                                    <option>Gautam</option>
                                                    <option>Gautam</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-lg-4 col-12'>
                                            <div className='inner-input'>
                                                <label htmlFor='pin-code'>Postal Code</label>
                                                <input type='text' placeholder='65462' className='form-control' id="pin-code" />

                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className='bottom-button-dv'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-12 text-end'>
                                            <div className='bottom-btn'>
                                                <Link to='/like-to-post-job' type='submit' className='a-tag-btn1'>Back</Link>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12 text-start'>
                                            <div className='bottom-btn'>
                                                <Link to='/planned-start-date-job' type='submit' className='a-tag-btn2'>Continue</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AddJobBasicsContent