import React from 'react'
import './scss/FindRightPlacesCompanyListing.css'
import { Link } from 'react-router-dom';
const FindRightPlacesCompanyListingcontent = () => {
    return (
        <>
            <section className='find-company-listing-section'>
                <section className="career-section">
                    <div className="career-start-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="career-top-heading">
                                        <h2>Explore the career you love </h2>
                                    </div>
                                    <div className="career-top-heading mt-4">
                                        <h1>
                                            Find Right <span> Places/Companies </span> to Work
                                        </h1>
                                    </div>
                                    <div className="career-second-heading mt-4">
                                        <h6><span>  1123 </span> Companies NOW Hiring in Afghanistan
                                        </h6>
                                    </div>
                                    <div>
                                        <div className="custom-form-section mt-4">
                                            <div className="search-custom">
                                                <img src=" ./assets/find-right-places-companies/magnifyingglass.png" className="img-fluid" alt="" />
                                                <input
                                                    type="text"
                                                    className="form-control form-custom"
                                                    placeholder="company name or job title"
                                                />
                                            </div>
                                            <div className="search-custom">
                                                <img src="./assets/find-right-places-companies/mappinline.png" className="img-fluid" alt="" />
                                                <input
                                                    type="text"
                                                    className="form-control form-custom"
                                                    placeholder="Location"
                                                />
                                            </div>
                                            <div>
                                                <div>
                                                    <button className="form-custom-btn">Find Company</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" dropdown-custom-btn">
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-custom dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Company Type
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Last 24 hours
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Last 3 days
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Last 7 days
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Last 14 days
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Since your last visit
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-custom dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Location
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Another action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Something else here
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-custom dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Nature of Business
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Another action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Something else here
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-custom dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Department
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Another action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Something else here
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-custom dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Experience
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Another action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Something else here
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="main-section">
                    <div className="container">
                        <div className="card-bg">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card-heading">
                                        <h3>Information Technologies Companies</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-4">
                                    <div className="card card-custom">
                                        <div className="d-flex display-card">
                                            <div className='company-logo-dv'>
                                                <img src="./assets/find-right-places-companies/frame-card.png" alt="" />
                                            </div>
                                            <div className="footer-heading footer-icon mx-2">
                                                <div className='company-heading-icon'>
                                                    <div className='company-card'>
                                                        <h3><Link to="/particular-company-homepage">HCL Technologies Ltd.</Link></h3>
                                                    </div>
                                                    <div className='bookmark-img'>
                                                        <img src="./assets/find-right-places-companies/bookmarksimple.png" alt="" />
                                                    </div>
                                                </div>
                                                <p>
                                                    Evinquer
                                                    <span>
                                                        <a href="" className="mx-5">
                                                            Start up
                                                        </a>
                                                    </span>
                                                    <span>
                                                        <img src="./assets/find-right-places-companies/star.png" alt="" />
                                                        2.7
                                                    </span>
                                                </p>
                                            </div>

                                        </div>
                                        <div className="footer-list-card mt-2">
                                            <div>
                                                <h6>Frontend Developer (Website)</h6>
                                                <p>
                                                    Our remote company is seeking a passionate person to become
                                                    the lead developer on our website.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card card-custom mt-4">
                                        <div className="d-flex display-card">
                                            <div className='company-logo-dv'>
                                                <img src="./assets/find-right-places-companies/card-2.png" alt="" />
                                            </div>
                                            <div className="footer-heading footer-icon mx-2">
                                                <div className='company-heading-icon'>
                                                    <div className='company-card'>
                                                        <h3><Link to="/particular-company-homepage">HCL Technologies Ltd.</Link></h3>
                                                    </div>
                                                    <div className='bookmark-img'>
                                                        <img src="./assets/find-right-places-companies/bookmarksimple.png" alt="" />
                                                    </div>
                                                </div>
                                                <p>
                                                    Evinquer
                                                    <span>
                                                        <a href="" className="mx-5">
                                                            Start up
                                                        </a>
                                                    </span>
                                                    <span>
                                                        <img src="./assets/find-right-places-companies/star.png" alt="" />
                                                        2.7
                                                    </span>
                                                </p>
                                            </div>

                                        </div>
                                        <div className="footer-list-card mt-2">
                                            <div>
                                                <h6>Frontend Developer (Website)</h6>
                                                <p>
                                                    Our remote company is seeking a passionate person to become
                                                    the lead developer on our website.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card card-custom mt-4">
                                        <div className="d-flex display-card">
                                            <div className='company-logo-dv'>
                                                <img src="./assets/find-right-places-companies/frame7.png" alt="" />
                                            </div>
                                            <div className="footer-heading footer-icon mx-2">
                                                <div className='company-heading-icon'>
                                                    <div className='company-card'>
                                                        <h3><Link to="/particular-company-homepage">HCL Technologies Ltd.</Link></h3>
                                                    </div>
                                                    <div className='bookmark-img'>
                                                        <img src="./assets/find-right-places-companies/bookmarksimple.png" alt="" />
                                                    </div>
                                                </div>
                                                <p>
                                                    Evinquer
                                                    <span>
                                                        <a href="" className="mx-5">
                                                            Start up
                                                        </a>
                                                    </span>
                                                    <span>
                                                        <img src="./assets/find-right-places-companies/star.png" alt="" />
                                                        2.7
                                                    </span>
                                                </p>
                                            </div>

                                        </div>
                                        <div className="footer-list-card mt-2">
                                            <div>
                                                <h6>Frontend Developer (Website)</h6>
                                                <p>
                                                    Our remote company is seeking a passionate person to become
                                                    the lead developer on our website.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-4">
                                    <div className="card card-custom">
                                        <div className="d-flex display-card">
                                            <div className='company-logo-dv'>
                                                <img src="./assets/find-right-places-companies/card-third-2.png" alt="" />
                                            </div>
                                            <div className="footer-heading footer-icon mx-2">
                                                <div className='company-heading-icon'>
                                                    <div className='company-card'>
                                                        <h3><Link to="/particular-company-homepage">Mahindra & Mahindra Ltd.</Link></h3>
                                                    </div>
                                                    <div className='bookmark-img'>
                                                        <img src="./assets/find-right-places-companies/bookmarksimple.png" alt="" />
                                                    </div>
                                                </div>
                                                <p>
                                                    Evinquer
                                                    <span>
                                                        <a href="" className="mx-5">
                                                            MNC
                                                        </a>
                                                    </span>
                                                    <span>
                                                        <img src="./assets/find-right-places-companies/star.png" alt="" />
                                                        2.7
                                                    </span>
                                                </p>
                                            </div>

                                        </div>
                                        <div className="footer-list-card mt-2">
                                            <div>
                                                <h6>Senior Staff React Developer</h6>
                                                <p>
                                                    Our remote company is seeking a passionate person to become
                                                    the lead developer on our website.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card card-custom mt-4">
                                        <div className="d-flex display-card">
                                            <div className='company-logo-dv'>
                                                <img src="./assets/find-right-places-companies/card-2.png" alt="" />
                                            </div>
                                            <div className="footer-heading footer-icon mx-2">
                                                <div className='company-heading-icon'>
                                                    <div className='company-card'>
                                                        <h3><Link to="/particular-company-homepage">Mahindra & Mahindra Ltd.</Link></h3>
                                                    </div>
                                                    <div className='bookmark-img'>
                                                        <img src="./assets/find-right-places-companies/bookmarksimple.png" alt="" />
                                                    </div>
                                                </div>
                                                <p>
                                                    Evinquer
                                                    <span>
                                                        <a href="" className="mx-5">
                                                            MNC
                                                        </a>
                                                    </span>
                                                    <span>
                                                        <img src="./assets/find-right-places-companies/star.png" alt="" />
                                                        2.7
                                                    </span>
                                                </p>
                                            </div>

                                        </div>
                                        <div className="footer-list-card mt-2">
                                            <div>
                                                <h6>Senior Staff React Developer</h6>
                                                <p>
                                                    Our remote company is seeking a passionate person to become
                                                    the lead developer on our website.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card card-custom mt-4">
                                        <div className="d-flex display-card">
                                            <div className='company-logo-dv'>
                                                <img src="./assets/find-right-places-companies/card-third-2.png" alt="" />
                                            </div>
                                            <div className="footer-heading footer-icon mx-2">
                                                <div className='company-heading-icon'>
                                                    <div className='company-card'>
                                                        <h3><Link to="/particular-company-homepage">Mahindra & Mahindra Ltd.</Link></h3>
                                                    </div>
                                                    <div className='bookmark-img'>
                                                        <img src="./assets/find-right-places-companies/bookmarksimple.png" alt="" />
                                                    </div>
                                                </div>
                                                <p>
                                                    Evinquer
                                                    <span>
                                                        <a href="" className="mx-5">
                                                            MNC
                                                        </a>
                                                    </span>
                                                    <span>
                                                        <img src="./assets/find-right-places-companies/star.png" alt="" />
                                                        2.7
                                                    </span>
                                                </p>
                                            </div>

                                        </div>
                                        <div className="footer-list-card mt-2">
                                            <div>
                                                <h6>Senior Staff React Developer</h6>
                                                <p>
                                                    Our remote company is seeking a passionate person to become
                                                    the lead developer on our website.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row justify-content-center'>
                                <div className='col-lg-12 col-md-12 mt-2 mt-lg-4 mt-md-3'>
                                    <nav aria-label="Page navigation example ">
                                        <ul className="pagination justify-content-center">
                                            <li className="page-item">
                                                <a className="page-link" href="#" aria-label="Previous">
                                                    <span aria-hidden="true">«</span>
                                                    <span className="sr-only">Previous</span>
                                                </a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">
                                                    1
                                                </a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">
                                                    2
                                                </a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">
                                                    3
                                                </a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#" aria-label="Next">
                                                    <span aria-hidden="true">»</span>
                                                    <span className="sr-only">Next</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </section>
        </>
    )
}

export default FindRightPlacesCompanyListingcontent