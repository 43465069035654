import React from 'react'
import RecHeader from '../../Commons/AfterLogin/RecHeader'
import Stepper from '../Stepper/Stepper'
import Footer from '../../Commons/Footer'
import RecChooseYourPlanContent from './RecChooseYourPlanContent'

const RecChooseYourPlan = () => {
  return (
    <>
        <RecHeader/>
        <Stepper/>
        <RecChooseYourPlanContent/>
        <Footer/>
    </>
  )
}

export default RecChooseYourPlan