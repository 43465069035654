import React from 'react'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'
import DescribeTheJobContent from './DescribeTheJobContent'
import Footer from '../../Commons/Footer'

const DescribeTheJob = () => {
    return (
        <>
            <RecAfterLoginNavbar />
            <DescribeTheJobContent />
            <Footer />
        </>
    )
}

export default DescribeTheJob