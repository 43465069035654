import React from 'react'
import { Link } from 'react-router-dom'

const CareerTest = () => {
    return (
        <section className="career-test">
            <div className="container py-5 px-3">
                <div className="row">
                    <div className="title d-flex justify-content-between mb-4">
                        <h1>Career Tests: Our Top 10</h1>
                        <Link to="/career-test-listing">View All</Link>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-2">
                        <div className="custm-info-box">
                            <img src="./assets/images/career-guide/ct.png" className='img-fluid' alt="" />
                            <div className="textarea">
                                <h3>The Architectural Imagination</h3>
                                <p>Learn fundamental principles of architecture as an academic subject or a professional career by studying some of history’s most important buildings.</p>
                                <ul className='d-flex justify-content-around'>
                                    <li>HarvardX</li>
                                    <li><img src="./assets/images/career-guide/v1.png" className='img-fluid' alt="" />Beginner</li>
                                    <li><img src="./assets/images/career-guide/v2.png" className='img-fluid' alt="" />10 Weeks</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-2">
                        <div className="custm-info-box">
                            <img src="./assets/images/career-guide/ct.png" className='img-fluid' alt="" />
                            <div className="textarea">
                                <h3>The Architectural Imagination</h3>
                                <p>Learn fundamental principles of architecture as an academic subject or a professional career by studying some of history’s most important buildings.</p>
                                <ul className='d-flex justify-content-around'>
                                    <li>HarvardX</li>
                                    <li><img src="./assets/images/career-guide/v1.png" className='img-fluid' alt="" />Beginner</li>
                                    <li><img src="./assets/images/career-guide/v2.png" className='img-fluid' alt="" />10 Weeks</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-2">
                        <div className="custm-info-box">
                            <img src="./assets/images/career-guide/ct.png" className='img-fluid' alt="" />
                            <div className="textarea">
                                <h3>The Architectural Imagination</h3>
                                <p>Learn fundamental principles of architecture as an academic subject or a professional career by studying some of history’s most important buildings.</p>
                                <ul className='d-flex justify-content-around'>
                                    <li>HarvardX</li>
                                    <li><img src="./assets/images/career-guide/v1.png" className='img-fluid' alt="" />Beginner</li>
                                    <li><img src="./assets/images/career-guide/v2.png" className='img-fluid' alt="" />10 Weeks</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CareerTest