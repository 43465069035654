import React from 'react';
import RecHeader from '../../Commons/AfterLogin/RecHeader';
import Footer from '../../Commons/Footer';
import './scss/CompanyDetailscss.css';
import Stepper from '../Stepper/Stepper';
import CompanyDetailsForm from './CompanyDetailsForm';
const CompanyDetails = () => {
  return (
    <>
        <RecHeader/>
        <Stepper/>
        <CompanyDetailsForm/>
        <Footer/>
    </>
  )
}

export default CompanyDetails