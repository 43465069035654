import React, { useState, useEffect } from 'react'
import './scss/SignIn.css';
import $ from "jquery";
import { Link } from 'react-router-dom';
const SignInContent = () => {

   const [faIcon, setfaIcon] = useState('fa-eye-slash');
   const togglePasswordType = () => {
      let input_pass = document.getElementById('input_pass');
      const toggle_pass = document.getElementById("toggle_pass");
      let input_type = input_pass.getAttribute('type');
      if (input_type == "password") {
         setfaIcon('fa-eye');
         input_pass.type = "text";
      } else {
         setfaIcon('fa-eye-slash')
         input_pass.type = "password"
      }
   }


   return (
      <>
         <section className="signin-section-seeker">
            <div className="container">
               <div className="second-section">
                  <div className="row align-items-center">
                     <div className="col-lg-6 col-md-12 col-12 order-lg-1 order-md-1 order-2">
                        <form>
                           <div className='text-start mt-custon-1'>
                              <h3>Sign in</h3>
                              <span className="para-3">If you don’t have an account</span> 
                              <Link to="/create-an-account"> <b>Register here !</b></Link>
                           </div>
                           <div>
                              <div className="row">
                                 <p className="para">Email</p>
                                 <div className="col-12">
                                    <div className='user-envlop-cls'>
                                       <span><img src='././././assets/recruiter-images/singin/Vector1.png' /></span>
                                       <input className="input-text" type="email" name="email" placeholder="Enter your email address" pattern=".+@gmail.com" />
                                    </div>
                                 </div>
                              </div>
                              <div className="row">
                                 <p className="para">Password</p>
                                 <div className="col-12 password-custom user-envlop-cls2 cus-pass-section">
                                    <img src='././././assets/recruiter-images/singin/Vector2.png' className='img' />
                                    <input className="input-text" type="password" name="password"
                                       placeholder="Enter your password" id="input_pass" />
                                    <span toggle="#password-field" className={`fa fa-fw ${faIcon} field_icon toggle-password`} id="toggle_pass" onClick={togglePasswordType}></span>
                                 </div>
                              </div>
                              <div className="row row-top">
                                 <div className="col-lg-6 col-md-6 col-12 ">
                                    <div className='text-start'>
                                       <input className='me-1' type="checkbox" id="remember" name="" />
                                       <label htmlFor="remember" className="pt-2"> Remember me </label>
                                       <br />
                                    </div>

                                 </div>
                                 <div className="col-lg-6 col-md-6 col-12">
                                    <div className='text-end'>
                                       <p className="pt-2">
                                          <Link to='/rec-forget-password'>Forgot Password ?</Link>
                                       </p>
                                    </div>
                                 </div>
                              </div>
                              <div className="row">
                                 <div className="col-12 text-center">
                                    <div className='mt-5 login-btn'>
                                       <Link to='/seeker-login-profile'> Login</Link>
                                    </div>
                                 </div>
                              </div>
                              <div className='row'>
                                 <div className='col-lg-12 col-md-12 col-12 text-center'>
                                    <p className="pt-3">or continue with</p>
                                    <ul>
                                       <li>
                                          <a href="">
                                             <img src="././././assets/recruiter-images/singin/Vector5.png" alt="" />
                                          </a>
                                       </li>
                                       <li>
                                          <a href="">
                                             <img
                                                src="././././assets/recruiter-images/singin/v6.png"
                                                width="40px"
                                                height="40px"
                                                alt=""
                                             />
                                          </a>
                                       </li>
                                       <li>
                                          <a href="">
                                             <img
                                                src="././././assets/recruiter-images/singin/intragram.png"
                                                width="40px"
                                                height="40px"
                                                alt=""
                                             />
                                          </a>
                                       </li>
                                       <li>
                                          <a href="">
                                             <img
                                                src="././././assets/recruiter-images/singin/google-icon.png"
                                                width="40px"
                                                height="40px"
                                                alt=""
                                             />
                                          </a>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>



                        </form>
                     </div>
                     <div className="col-lg-6 col-md-12 col-12 order-lg-2 order-md-2 order-1">
                        <div>
                           <img
                              src="././././assets/recruiter-images/singin/back-girl.png" width="100%" height="100%"
                              alt="" className='img-fluid' />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   )
}
export default SignInContent