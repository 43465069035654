import React from 'react'

const SeeBudgetAction = () => {
    return (
        <>
            <section className='see-budget-action py-5'>
                <div className="container">
                    <div className="row">
                        <div className="head-area mb-5">
                            <h1>See your budget in action</h1>
                        </div>
                        <div className="col-lg-6 col-md-6 mb-5">
                            <div className="custm-bud-text">
                                <h4>Better job visibility in search</h4>
                                <p>Sponsored Jobs have higher visibility in search, so they’re more likely to get seen by people looking for jobs like yours.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 mb-5 my-md-auto">
                            <div className="custm-bud-img text-lg-center">
                                <img src="./assets/images/matching-hiring/spns.png" className='img-fluid' alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 my-md-auto">
                            <div className="custm-bud-img">
                                <img src="./assets/images/matching-hiring/spns.png" className='img-fluid' alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="custm-bud-text">
                                <h4>Find the right people</h4>
                                <ul>
                                    <li>Use screening questions and deal breakers to add your mandatory job requirements, like years of experience or education level.</li>
                                    <li>We’ll show you people who meet those requirements.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 mb-5">
                            <div className="custm-bud-text">
                                <h4>Less searching, better matching</h4>
                                <li>Get access to matched candidates and see a list of people whose resumes on Indeed meet your job description.
                                </li>
                                <li>Invite them to apply and only pay when they submit their application and you decide they’re qualified.</li>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 mb-5 my-md-auto">
                            <div className="custm-bud-img text-lg-center">
                                <img src="./assets/images/matching-hiring/spns.png" className='img-fluid' alt="" />
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default SeeBudgetAction