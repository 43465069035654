import React from 'react'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import RecCompanyProfileDetailsContent from './RecCompanyProfileDetailsContent'
import Footer from '../Commons/Footer'

const RecCompanyProfileDetails = () => {
    return (
        <>
            <RecAfterLoginNavbar />
            <RecCompanyProfileDetailsContent />
            <Footer />
        </>
    )
}

export default RecCompanyProfileDetails