import React from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import PrivacyPolicyContent from './PrivacyPolicyContent'
import Footer from '../../Commons/Footer'
const PrivacyPolicy = () => {
  return (
    <>
      <AfterNavbar />
      <PrivacyPolicyContent />
      <Footer />
    </>

  )
}

export default PrivacyPolicy