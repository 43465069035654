import React from 'react'
import './scss/SetPreferences.css'
import { Link } from 'react-router-dom'
const SetPreferencesContent = () => {
    return (
        <>
            <section className='set-preferences-sec'>
                <div className='container'>
                    <div className='create-an-emp-inner'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading'>
                                        <h2>Set preferences</h2>
                                    </div>
                                </div>
                            </div>
                            <form action='' method=''>
                                <div className='card-outer-parent-dv'>
                                    <div className='card-outer-dv'>
                                        <div className='inner-input'>
                                            <div className='inner-input-dv'>
                                                <label htmlFor='' className='my-2'>Let applicants call you directly for this job </label>
                                                <input type='text' name="" className='form-control' placeholder='Mobile number' />
                                                <p className='mob-para'><span>+91- 9311646959</span><span>+91- 9311646959</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='emp-have-closed-parent'>
                                        <div className='emp-have-closed'>
                                            <p>+ Employers have closed the hire faster by 6 days' by sharing their mobile numbers on Karlatoon </p>
                                        </div>
                                    </div>
                                    <div className='card-outer-dv'>
                                        <div className='inner-input checkbox-size-dv'>
                                            <div className='inner-input-dv'>
                                                <input type='checkbox' name="" id='send-whatsapp' />
                                                <label htmlFor='send-whatsapp' className='my-2'>Send WhatsApp updates on the same number </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-outer-parent-dv'>
                                    <div className='card-outer-dv'>
                                        <div className='inner-input'>
                                            <div className='inner-input-dv'>
                                                <label htmlFor='' className='my-2'>Communication Preferences</label>
                                                <input type='text' name="" className='form-control mb-2' placeholder='Send daily updates to' />
                                                <a href='' className='mob-para'>+ Add E-mail</a>
                                            </div>
                                        </div>

                                        <div className='inner-input checkbox-size-dv'>
                                            <div className='inner-input-dv'>
                                                <input type='checkbox' name="" id='plus-send' />
                                                <label htmlFor='plus-send' className='my-2'>Plus, send an individual email update each time someone applies.</label>
                                            </div>
                                            <p className='mb-0'>Let potential candidates contact you about this job</p>
                                            <div className='inner-input-dv'>
                                                <input type='checkbox' name="" id='by-email' />
                                                <label htmlFor='by-email' className='my-2'>By email to the address provided</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-outer-parent-dv'>
                                    <div className='card-outer-dv'>
                                        <div className='inner-input'>
                                            <div className='inner-input-dv'>
                                                <label htmlFor='' className='my-2'>Application Preferences</label>
                                                {/* <input type='text' name="" className='form-control mb-2' placeholder='Send daily updates to' /> */}
                                                <select className='form-control mb-2'>
                                                    <option>Give the option to include a CV</option>
                                                    <option>Give the option to include a CV</option>
                                                    <option>Give the option to include a CV</option>
                                                    <option>Give the option to include a CV</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className='inner-input check-y-n-parent'>
                                            <div className='inner-input-dv'>
                                                <label className='my-2'>Is there an application deadline?</label>
                                                <div className='check-yes-no'>
                                                    <div className='check-yes'>
                                                        <input type='checkbox' name="" id='plus-yes' />
                                                        <label htmlFor='plus-yes' className='my-2'>Yes</label>
                                                    </div>
                                                    <div className='check-no'>
                                                        <input type='checkbox' name="" id='plus-no' />
                                                        <label htmlFor='plus-no' className='my-2'>No</label>
                                                    </div>
                                                </div>
                                                <div className='inner-input my-2'>
                                                    <input type='date' className='form-control' placeholder=''/>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <div className='card-outer-parent-dv'>
                                    <div className='card-outer-dv'>
                                        <div className='inner-input'>
                                            <div className='inner-input-dv'>
                                                <label htmlFor='' className=''>Hire Settings</label>
                                                <p className='mob-para mb-2'>Hiring timeline for this job</p>
                                                <select className='form-control'>
                                                    <option>Select an option</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className='bottom-button-dv'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-12 text-end'>
                                            <div className='bottom-btn'>
                                                <Link to='/describe-the-job' type='submit' className='a-tag-btn1'>Back</Link>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12 text-start'>
                                            <div className='bottom-btn'>
                                                <Link to='/make-it-deal-breaker' type='submit' className='a-tag-btn2'>Continue</Link>
                                            </div>
                                        </div>
                                        {/* <div className='col-lg-5 col-md-5 col-12 text-start'>
                                            <div className='bottom-btn'>
                                                <Link to='/add-pay-benefits' type='submit' className='a-tag-btn1'>Preview</Link>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SetPreferencesContent