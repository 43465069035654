import React from 'react'
import RegisterHeader from './CvBuilderHeader/RegisterHeader'
import Footer from '../Commons/Footer'
import { Link } from 'react-router-dom';
import Steps from './Steps';


const SocialMedia = () => {
    return (
        <>
            <RegisterHeader />
            <section className='social-media'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="stepper-area">
                                <Steps activeStepCount={6} />
                            </div>
                        </div>
                        <div className="col-lg-9 my-lg-0 my-md-0 my-2">
                            <div className="form-area">
                                <div className="row align-items-center">
                                    <div className="col-lg-2 col-12 profile-back-btn">
                                        <div className="back-btn">
                                            <Link to='/certifications'><i className="fa-solid fa-arrow-left mx-2"></i>Back</Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-6 project-title">
                                        <h1>Social Media</h1>
                                    </div>
                                    <div className="col-lg-2 col-6 profile-edit-btn">
                                        <div className="edit-btn">
                                            <Link href={undefined}>Edit</Link>
                                        </div>
                                    </div>
                                </div>
                                <form action="" className='mt-5'>
                                    <div className="row">
                                        <div className="col-lg-6 social-icons">
                                            <div className="social-lft mb-3 d-flex">
                                                <img src="assets/images/cv-builder/f.png" alt="" />
                                                <p className='mx-2'>https://example.com/article/social-share-modal</p>
                                                <img src="assets/images/cv-builder/check.png" className='check' alt="" />
                                            </div>
                                            <div className="social-lft mb-3 d-flex">
                                                <img src="assets/images/cv-builder/in.png" alt="" />
                                                <p className='mx-2'>https://example.com/article/social-share-modal</p>
                                                <img src="assets/images/cv-builder/check2.png" className='check' alt="" />
                                            </div>
                                            <div className="social-lft mb-3 d-flex">
                                                <img src="assets/images/cv-builder/c.png" alt="" />
                                                <p className='mx-2'>https://example.com/article/social-share-modal</p>
                                                <img src="assets/images/cv-builder/check2.png" className='check' alt="" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 social-icons">
                                            <div className="social-lft mb-3 d-flex">
                                                <img src="assets/images/cv-builder/t.png" alt="" />
                                                <p className='mx-2'>https://example.com/article/social-share-modal</p>
                                                <img src="assets/images/cv-builder/check2.png" className='check' alt="" />
                                            </div>
                                            <div className="social-lft mb-3 d-flex">
                                                <img src="assets/images/cv-builder/i.png" alt="" />
                                                <p className='mx-2'>https://example.com/article/social-share-modal</p>
                                                <img src="assets/images/cv-builder/check2.png" className='check' alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-5 mb-5 d-flex justify-content-center">
                                            <div className='custm-botton-btn mb-2'>
                                                <Link to="/you-have-registred-successfully" className='next mx-2'>Submit</Link>
                                            </div>
                                            {/* <button className='next mx-2'><Link to={undefined}>Submit</Link></button> */}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
            <Footer />
        </>
    )
}

export default SocialMedia