import React from 'react'
import './scss/onboard.css'
import { Link } from 'react-router-dom'

const OnboardingGuide = () => {
    return (
        <section className="onboarding-guide py-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="img-area">
                            <img src="./assets/images/matching-hiring/onb2.png" className='img-fluid' alt="image not found!" />
                            <h3>Onboarding Guide: Setting Your Job Up for Success on Karlatoon</h3>
                            <Link to={undefined}>Read More</Link>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="img-area">
                            <img src="./assets/images/matching-hiring/onb1.png" className='img-fluid' alt="image not found!" />
                            <h3>What is an Inclusive Workplace?</h3>
                            <Link to={undefined}>Read More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OnboardingGuide