import React, { useState, useEffect } from 'react'
import './scss/OtpSendEnter.css';
import $ from "jquery";
import { Link } from 'react-router-dom';
const OtpSendEnterContent = () => {
    useEffect(() => {

        const inputs = document.querySelectorAll(".otp-field > input");
        const button = document.querySelector(".btn");

        window.addEventListener("load", () => inputs[0].focus());
        button.setAttribute("disabled", "disabled");

        inputs[0].addEventListener("paste", function (event) {
            event.preventDefault();

            const pastedValue = (event.clipboardData || window.clipboardData).getData(
                "text"
            );
            const otpLength = inputs.length;

            for (let i = 0; i < otpLength; i++) {
                if (i < pastedValue.length) {
                    inputs[i].value = pastedValue[i];
                    inputs[i].removeAttribute("disabled");
                    inputs[i].focus();
                } else {
                    inputs[i].value = ""; // Clear any remaining inputs
                    inputs[i].focus();
                }
            }
        });

        inputs.forEach((input, index1) => {
            input.addEventListener("keyup", (e) => {
                const currentInput = input;
                const nextInput = input.nextElementSibling;
                const prevInput = input.previousElementSibling;

                if (currentInput.value.length > 1) {
                    currentInput.value = "";
                    return;
                }

                if (
                    nextInput &&
                    nextInput.hasAttribute("disabled") &&
                    currentInput.value !== ""
                ) {
                    nextInput.removeAttribute("disabled");
                    nextInput.focus();
                }

                if (e.key === "Backspace") {
                    inputs.forEach((input, index2) => {
                        if (index1 <= index2 && prevInput) {
                            input.setAttribute("disabled", true);
                            input.value = "";
                            prevInput.focus();
                        }
                    });
                }

                button.classList.remove("active");
                button.setAttribute("disabled", "disabled");

                const inputsNo = inputs.length;
                if (!inputs[inputsNo - 1].disabled && inputs[inputsNo - 1].value !== "") {
                    button.classList.add("active");
                    button.removeAttribute("disabled");

                    return;
                }
            });
        });
    })

    return (
        <>
            <section className="otp-password-section">
                <div className="container">
                    <div className="second-section">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12 col-12 order-lg-1 order-md-1 order-2">
                                <form>
                                    <div className='text-start mt-custon-1'>
                                        <h3>Enter OTP</h3>
                                        <span className="para-3">Your code was sent to you via email</span>
                                    </div>
                                    <div className='otp-custom-dv'>

                                        <div className="row ">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div
                                                    className="card border-0"
                                                    style={{ boxShadow: "0 12px 15px rgba(0, 0, 0, 0.02)" }}
                                                >
                                                    <div className="card-body">
                                                        <div className="otp-field mb-4">
                                                            <input type="number" />
                                                            <input type="number" disabled="" />
                                                            <input type="number" disabled="" />
                                                            <input type="number" disabled="" />
                                                            <input type="number" disabled="" />
                                                            <input type="number" disabled="" />
                                                        </div>
                                                        <p className="resend text-muted mb-0">
                                                            Didn't receive code? <a href="">Request again</a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        
                                        <div className="row row-top">
                                            
                                        </div>
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <div className='mt-1 login-btn'>
                                                    <Link to='/rec-enter-new-password'> Submit OTP</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12 col-12 text-center'>
                                                <p className="bottm-signup"><Link to="">New here? Sign Up.</Link></p>
                                                <p className='bottm-signin'><Link to="/signin">Already have an account? Sign In.</Link></p>
                                                <ul>
                                                    <li>
                                                        <a href="">
                                                            <img src="././././assets/recruiter-images/singin/Vector5.png" alt="" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="">
                                                            <img
                                                                src="././././assets/recruiter-images/singin/v6.png"
                                                                width="40px"
                                                                height="40px"
                                                                alt=""
                                                            />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="">
                                                            <img
                                                                src="././././assets/recruiter-images/singin/intragram.png"
                                                                width="40px"
                                                                height="40px"
                                                                alt=""
                                                            />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="">
                                                            <img
                                                                src="././././assets/recruiter-images/singin/google-icon.png"
                                                                width="40px"
                                                                height="40px"
                                                                alt=""
                                                            />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>



                                </form>
                            </div>
                            <div className="col-lg-6 col-md-12 col-12 order-lg-2 order-md-2 order-1">
                                <div>
                                    <img
                                        src="././././assets/recruiter-images/singin/back-girl.png" width="100%" height="100%"
                                        alt="" className='img-fluid' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OtpSendEnterContent