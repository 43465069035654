import React from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import FindRightPlacesCompanyListingcontent from './FindRightPlacesCompanyListingcontent'
import Footer from '../../Commons/Footer'
const FindRightPlacesCompanyListing = () => {
  return (
    <>
        <AfterNavbar />
        <FindRightPlacesCompanyListingcontent />
        <Footer />
    </>
  )
}

export default FindRightPlacesCompanyListing