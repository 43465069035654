import React from 'react'
import './scss/AddPayAndBenefits.css'
import { Link } from 'react-router-dom'
const AddPayAndBenefitsContent = () => {
    return (
        <>
            <section className='add-pay-benefits'>
                <div className='container'>
                    <div className='create-an-emp-inner'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading'>
                                        <h2>Add Pay and Benefits</h2>
                                    </div>
                                </div>
                            </div>
                            <form action='' method=''>
                                <div className='card-outer-dv'>
                                    <div className='inner-input'>
                                        <div className='inner-input my-2 my-md-2 my-lg-4'>
                                            <label htmlFor='no-peaple'>Show Pay by</label>

                                            <select className='form-control' id="no-peaple">
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                            </select>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-4'>
                                                <div className='inner-input my-2 my-md-2 my-lg-4'>
                                                    <label htmlFor='minimum-tab'>Minimum</label>
                                                    <input type='text' className='form-control me-2' id='minimum-tab' name="emp-report" />
                                                </div>
                                            </div>
                                            <div className='col-lg-4'>
                                                <div className='inner-input my-2 my-md-2 my-lg-4'>
                                                    <label htmlFor='maximum-tab'>Maximum</label>
                                                    <input type='text' className='form-control me-2' id='maximum-tab' name="emp-report" />
                                                </div>
                                            </div>
                                            <div className='col-lg-4'>
                                                <div className='inner-input my-2 my-md-2 my-lg-4'>
                                                    <label htmlFor='rate-tab'>Rate</label>
                                                    <select className='form-control' id="rate-tab">
                                                        <option>Per Hour</option>
                                                        <option>Per Hour</option>
                                                        <option>Per Hour</option>
                                                        <option>Per Hour</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-outer-dv card-outer-dv1'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='left-sd-content'>
                                                <h5>Supplement Pay</h5>
                                            </div>
                                        </div>
                                        <div className='supplement-pay-btn'>
                                            <div className='supplement-item my-lg-2 my-md-2 my-1'>
                                                <div className='job-type-btn'>
                                                    <button className='btn-circle'>+ Performance Bonus</button>
                                                </div>
                                            </div>
                                            <div className='supplement-item my-lg-2 my-md-2 my-1'>
                                                <div className='job-type-btn'>
                                                    <button className='btn-circle'>+ Yearly Bonus</button>
                                                </div>
                                            </div>
                                            <div className='supplement-item my-lg-2 my-md-2 my-1'>
                                                <div className='job-type-btn'>
                                                    <button className='btn-circle'>+ Commission Pay</button>
                                                </div>
                                            </div>
                                            <div className='supplement-item my-lg-2 my-md-2 my-1'>
                                                <div className='job-type-btn'>
                                                    <button className='btn-circle'>+ Joining Bonus</button>
                                                </div>
                                            </div>
                                            <div className='supplement-item my-lg-2 my-md-2 my-1'>
                                                <div className='job-type-btn'>
                                                    <button className='btn-circle'>+ Over Time Pay</button>
                                                </div>
                                            </div>
                                            <div className='supplement-item my-lg-2 my-md-2 my-1'>
                                                <div className='job-type-btn'>
                                                    <button className='btn-circle'>+ Quarterly Bonus</button>
                                                </div>
                                            </div>
                                            <div className='supplement-item my-lg-2 my-md-2 my-1'>
                                                <div className='job-type-btn'>
                                                    <button className='btn-circle'>+ Others</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-outer-dv card-outer-dv1'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='left-sd-content'>
                                                <h5>Benefits</h5>
                                            </div>
                                        </div>
                                        <div className='supplement-pay-btn'>
                                        <div className='supplement-item my-lg-2 my-md-2 my-1'>
                                            <div className='job-type-btn'>
                                                <button className='btn-circle'>+ Health Insurance</button>
                                            </div>
                                        </div>
                                        <div className='supplement-item my-lg-2 my-md-2 my-1'>
                                            <div className='job-type-btn'>
                                                <button className='btn-circle'>+ Provident Fund</button>
                                            </div>
                                        </div>
                                        <div className='supplement-item my-lg-2 my-md-2 my-1'>
                                            <div className='job-type-btn'>
                                                <button className='btn-circle'>+ Cell Phone Reimbursement</button>
                                            </div>
                                        </div>
                                        <div className='supplement-item my-lg-2 my-md-2 my-1'>
                                            <div className='job-type-btn'>
                                                <button className='btn-circle'>+ Paid Sick Time</button>
                                            </div>
                                        </div>
                                        <div className='supplement-item my-lg-2 my-md-2 my-1'>
                                            <div className='job-type-btn'>
                                                <button className='btn-circle'>+ Work From Home</button>
                                            </div>
                                        </div>
                                        <div className='supplement-item my-lg-2 my-md-2 my-1'>
                                            <div className='job-type-btn'>
                                                <button className='btn-circle'>+ Paid Time Off</button>
                                            </div>
                                        </div>
                                        <div className='supplement-item my-lg-2 my-md-2 my-1'>
                                            <div className='job-type-btn'>
                                                <button className='btn-circle'>+ Food Provided</button>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>


                                <div className='bottom-button-dv'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-12 text-end'>
                                            <div className='bottom-btn'>
                                                <Link to='/add-job-details' type='submit' className='a-tag-btn1'>Back</Link>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12 text-start'>
                                            <div className='bottom-btn'>
                                                <Link to='/describe-the-job' type='submit' className='a-tag-btn2'>Continue</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AddPayAndBenefitsContent