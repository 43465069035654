import React from 'react'
import './scss/SubscriptionPlan.css'
const SubscriptionPlanContent = () => {
    return (
        <>
            <section className='subscription-plan-section'>
                <div className="main-section">
                    <div className="container">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="main-head">
                                    <h2>Subscription Plan</h2>
                                </div>
                                <div className="Subscrip-card">
                                    <div className="card-custom">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 col-12">
                                                <div className="card-1">
                                                    <div className="first-card">
                                                        <p>Monthly Silver Pack</p>
                                                    </div>
                                                    <div className="para">
                                                        <p>
                                                            Display stars in Google organic search risult and showcase
                                                            reviews on your website
                                                        </p>
                                                    </div>
                                                    <div className="border-bottom"></div>
                                                    <div className="list-1">
                                                        <ul>
                                                            <li>
                                                                <img src="./assets/images_another/check-1.png" />5 User
                                                            </li>
                                                            <li>
                                                                
                                                                <img src="./assets/images_another/check-1.png" />
                                                                Scrum and Kanban boards
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/check-1.png" />
                                                                Backlog
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/check-1.png" />
                                                                Agile reporting
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/check-1.png" />
                                                                Customizable workflows
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/check-1.png" />
                                                                Roadmap projects
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="border-bottom"></div>
                                                    <div className="last-head">
                                                        <h2>  $12.41 <span> /project</span>
                                                        </h2>
                                                    </div>
                                                    <div>
                                                        <a href="" className="btn last-btn">Choose Plan</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12">
                                                <div className="card-1">
                                                    <div className="second-card">
                                                        <p>Monthly Gold Pack</p>
                                                    </div>
                                                    <div className="para">
                                                        <p>
                                                            Display stars in Google organic search risult and showcase
                                                            reviews on your website
                                                        </p>
                                                    </div>
                                                    <div className="border-bottom"></div>
                                                    <div className="list-1">
                                                        <ul>
                                                            <li>
                                                                <img src="./assets/images_another/check-2.png" />5 User
                                                            </li>
                                                            <li>
                                                                
                                                                <img src="./assets/images_another/check-2.png" />
                                                                Scrum and Kanban boards
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/check-2.png" />
                                                                Backlog
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/check-2.png" />
                                                                Agile reporting
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/check-2.png" />
                                                                Customizable workflows
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/check-2.png" />
                                                                Roadmap projects
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="border-bottom"></div>
                                                    <div className="last-head">
                                                        <h2>  $12.41 <span> /project</span>
                                                        </h2>
                                                    </div>
                                                    <div>
                                                        <a href="" className="btn last-btn">Choose Plan</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12">
                                                <div className="card-1">
                                                    <div className="third-card">
                                                        <p>Monthly Platinum Pack</p>
                                                    </div>
                                                    <div className="para">
                                                        <p>
                                                            Display stars in Google organic search risult and showcase
                                                            reviews on your website
                                                        </p>
                                                    </div>
                                                    <div className="border-bottom"></div>
                                                    <div className="list-1">
                                                        <ul>
                                                            <li>
                                                                <img src="./assets/images_another/check-3.png" />5 User
                                                            </li>
                                                            <li>
                                                                
                                                                <img src="./assets/images_another/check-3.png" />
                                                                Scrum and Kanban boards
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/check-3.png" />
                                                                Backlog
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/check-3.png" />
                                                                Agile reporting
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/check-3.png" />
                                                                Customizable workflows
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/check-3.png" />
                                                                Roadmap projects
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="border-bottom"></div>
                                                    <div className="last-head">
                                                        <h2>  $12.41 <span> /project</span>
                                                        </h2>
                                                    </div>
                                                    <div>
                                                        <a href="" className="btn last-btn">Choose Plan</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default SubscriptionPlanContent