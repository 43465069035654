import React from 'react'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'
import LikeToPostYourJobContent from './LikeToPostYourJobContent'
import Footer from '../../Commons/Footer'

const LikeToPostYourJob = () => {
  return (
    <>
        <RecAfterLoginNavbar/>
        <LikeToPostYourJobContent/>
        <Footer/>
    </>
  )
}

export default LikeToPostYourJob