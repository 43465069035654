import React from 'react'
import AddPersonalDetails from './AddPersonalDetails/AddPersonalDetails'
import YourCareerCompanyDetailsCard from './YourCareerCompanyDetailsCard/YourCareerCompanyDetailsCard'


const YourCareerStartHere = () => {
  return (
    <>
        {/* <YourCareerCompanyDetailsCard/> */}
        <AddPersonalDetails/>
    </>
  )
}

export default YourCareerStartHere