import React from 'react'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'
import FreeJobPostCanOnlyGetApplicationsContent from './FreeJobPostCanOnlyGetApplicationsContent'
import Footer from '../../Commons/Footer'

const FreeJobPostCanOnlyGetApplications = () => {
  return (
    <>
    <RecAfterLoginNavbar/>
    <FreeJobPostCanOnlyGetApplicationsContent/>
    <Footer/>
    </>
  )
}

export default FreeJobPostCanOnlyGetApplications