import React from 'react'

import Footer from '../../Commons/Footer'
import AddPersonalDetailsContent from './AddPersonalDetailsContent'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import CareerStepper from '../CareerStepper/CareerStepper'
import YourCareerCompanyDetailsCard from '../YourCareerCompanyDetailsCard/YourCareerCompanyDetailsCard'
const AddPersonalDetails = () => {
    return (
        <>
            <AfterNavbar />
            <CareerStepper />
            <YourCareerCompanyDetailsCard/>
            <AddPersonalDetailsContent />
            <Footer />
        </>
    )
}

export default AddPersonalDetails