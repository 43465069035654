import React from 'react'
import { Link } from 'react-router-dom'

const DocumentVarificationForm = () => {
    return (
        <>
            <section className='rec-document-varification-form-sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='form-head-dv'>
                                <form method='' action=''>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <div className='cd-heading'>
                                                <h2>Documents Verification Details</h2>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Registration Number<span className='text-danger'>*</span></label>
                                                <input type='text' placeholder='Registration Number' name='' />
                                            </div>
                                        </div>
                                        {/* <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Upload Registration Certificate<span className='text-danger'>*</span></label>
                                                <input type='file' placeholder='Upload Registration Certificate' name='' />
                                            </div>
                                        </div> */}
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f cd-form-file'>
                                                <label className='d-block'>Upload Registration Certificate<span className='text-danger'>*</span></label>
                                                <label class="label">
                                                    <input type="file" required />
                                                    <span>Upload</span>
                                                    <span className='upload-icon'><img src='././././assets/recruiter-images/recruiter/ooui_upload.png' /></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Official Address</label>
                                                <input type='text' placeholder='Official Address' name='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>GST Number<span className='text-danger'>*</span></label>
                                                <input type='text' placeholder='GST Number' name='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>TIN Number<span className='text-danger'>*</span></label>
                                                <input type='text' placeholder='TIN Number' name='' />
                                            </div>
                                        </div>

                                        <div className='col-lg-12 my-2'>
                                            <div className='next-pre-btn'>
                                                <div className='prev-button'>
                                                    {/* <input type='button' value='Previous' name='' /> */}
                                                    <Link to="/company-details" className='prev-btn'>Previous</Link>
                                                </div>
                                                <div className='next-button'>
                                                    {/* <input type='button' value='Next' name='' /> */}
                                                    <Link to="/confirmation" className='next-btn'>Next</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DocumentVarificationForm