import React from 'react'
import './scss/PostAJobOverview.css'
import { Link } from 'react-router-dom'
const PostAJobOverviewContent = () => {
    return (
        <>
            <section className='post-a-joboverview-sec'>
                <div className='container'>
                    <div className='create-an-emp-inner'>
                        <div className='container'>
                            <div className='row my-lg-2 my-md-2 my-1'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading'>
                                        <h2>Overview</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='row my-lg-2 my-md-2 my-1'>
                                <div className='col-md-8 col-lg-8 col-8'>
                                    <div className='overview-top-head'>
                                        <h4>Laravel Developer <small>(Full Time)</small> - Match Company Limited</h4>
                                    </div>
                                </div>
                                <div className='col-md-4 col-lg-4 col-4 text-end'>
                                    <div className='overview-top-head-icon'>
                                        <a href='#'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></a>
                                    </div>
                                </div>
                            </div>
                            <div className='row my-lg-2 my-md-2 my-1'>
                                <div className='col-md-8 col-lg-8 col-8'>
                                    <div className='overview-top-head'>
                                        <h4>Minimum Qualification: <small>Bechelor</small></h4>
                                    </div>
                                </div>
                                <div className='col-md-4 col-lg-4 col-4 text-end'>
                                    <div className='overview-top-head-icon'>
                                        <a href='#'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></a>
                                    </div>
                                </div>
                            </div>
                            <div className='row my-lg-2 my-md-2 my-1'>
                                <div className='col-md-8 col-lg-8 col-8'>
                                    <div className='overview-top-head'>
                                        <h4>Experience Level: <small>Mid level</small></h4>
                                    </div>
                                </div>
                                <div className='col-md-4 col-lg-4 col-4 text-end'>
                                    <div className='overview-top-head-icon'>
                                        <a href='#'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></a>
                                    </div>
                                </div>
                            </div>
                            <div className='row my-lg-2 my-md-2 my-1'>
                                <div className='col-md-8 col-lg-8 col-8'>
                                    <div className='overview-top-head'>
                                        <h4>Experience Length: <small>2 years</small></h4>
                                    </div>
                                </div>
                                <div className='col-md-4 col-lg-4 col-4 text-end'>
                                    <div className='overview-top-head-icon'>
                                        <a href='#'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></a>
                                    </div>
                                </div>
                            </div>
                            <div className='row my-lg-2 my-md-2 my-1'>
                                <div className='col-md-8 col-lg-8 col-8'>
                                    <div className='overview-top-head'>
                                        <h4>Location: <small>San Francisco, USA</small></h4>
                                    </div>
                                </div>
                                <div className='col-md-4 col-lg-4 col-4 text-end'>
                                    <div className='overview-top-head-icon'>
                                        <a href='#'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></a>
                                    </div>
                                </div>
                            </div>
                            <div className='row my-lg-2 my-md-2 my-1'>
                                <div className='col-md-8 col-lg-8 col-8'>
                                    <div className='overview-top-head'>
                                        <h4>Application Deadline: <small>12/08/2023</small></h4>
                                    </div>
                                </div>
                                <div className='col-md-4 col-lg-4 col-4 text-end'>
                                    <div className='overview-top-head-icon'>
                                        <a href='#'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></a>
                                    </div>
                                </div>
                            </div>
                            <div className='row my-lg-2 my-md-2 my-1'>
                                <div className='col-md-8 col-lg-8 col-8'>
                                    <div className='overview-top-head'>
                                        <h4>Salary Range: <small>$ 105,00 - 150,000</small></h4>
                                    </div>
                                </div>
                                <div className='col-md-4 col-lg-4 col-4 text-end'>
                                    <div className='overview-top-head-icon'>
                                        <a href='#'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></a>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-lg-5 mt-md-2 my-1'>
                                <div className='col-md-8 col-lg-8 col-8'>
                                    <div className='overview-top-head'>
                                        <h4>Job Description</h4>
                                    </div>
                                </div>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='job-description-list'>
                                        <ul>
                                            <li>A Degree in programming, computer science, or a related field.</li>
                                            <li>Experience workingwith PHP, performing unit testing, and managing APIs such as REST.</li>
                                            <li>A solid understanding of application design using Laravel</li>
                                            <li>Knowledge of database design and querying using SQL</li>
                                            <li>Proficiency in HTML and JavaScript. Experience developing in Vue is considered a plus.</li>
                                            <li>A Degree in programming, computer science, or a related field.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='row my-lg-1 my-md-1 my-1'>
                                <div className='col-md-8 col-lg-8 col-8'>
                                    <div className='overview-top-head'>
                                        <h4>Responsibilities</h4>
                                    </div>
                                </div>
                                <div className='col-md-4 col-lg-4 col-4 text-end'>
                                    <div className='overview-top-head-icon'>
                                        <a href='#'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></a>
                                    </div>
                                </div>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='job-description-list'>
                                        <ul>
                                            <li>Discussing project aims with the client and development team.</li>
                                            <li>Designing and building web application using Laravel.</li>
                                            <li>Troubleshooting issue in the implementation and debug builds.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='row my-lg-3 my-md-3 my-1'>
                                <div className='col-md-12 col-lg-12 col-12 text-center'>
                                    <div className='overview-button'>
                                        <Link to="" className='see-full-descriptions'>See full descriptions</Link>
                                    </div>
                                </div>
                            </div>
                            <div className='row my-lg-2 my-md-2 my-1'>
                                <div className='col-md-12 col-lg-12 col-12'>
                                    <div className='overview-top-head'>
                                        <h5>Settings</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='setting-row-parent'>
                                <div className='row my-lg-2 my-md-2 my-1'>
                                    <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                        <div className="setting-row">
                                            <div className='setting-column1'>
                                                <div className='overview-top-head'>
                                                    <h6>Country & Language</h6>
                                                </div>
                                            </div>
                                            <div className='setting-column1'>
                                                <div className='overview-top-head-icon'>
                                                    <a href='#'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' className='img-fluid' /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='inpt-data-rpint'>
                                            <input className="int-tag" type='text' placeholder='India (Hindi)' Value="India (Hindi)" readOnly/>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                        <div className="setting-row">
                                            <div className='setting-column1'>
                                                <div className='overview-top-head'>
                                                    <h6>Application Method</h6>
                                                </div>
                                            </div>
                                            <div className='setting-column1'>
                                                <div className='overview-top-head-icon'>
                                                    <a href='#'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' className='img-fluid' /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='inpt-data-rpint'>
                                            <input className="int-tag" type='text' placeholder='Email' Value="Email" readOnly/>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                        <div className="setting-row">
                                            <div className='setting-column1'>
                                                <div className='overview-top-head'>
                                                    <h6>Require Resume</h6>
                                                </div>
                                            </div>
                                            <div className='setting-column1'>
                                                <div className='overview-top-head-icon'>
                                                    <a href='#'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' className='img-fluid' /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='inpt-data-rpint'>
                                            <input className="int-tag" type='text' placeholder='Yes' Value="Yes" readOnly/>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                        <div className="setting-row">
                                            <div className='setting-column1'>
                                                <div className='overview-top-head'>
                                                    <h6>Application Update</h6>
                                                </div>
                                            </div>
                                            <div className='setting-column1'>
                                                <div className='overview-top-head-icon'>
                                                    <a href='#'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' className='img-fluid' /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='inpt-data-rpint'>
                                            <input className="int-tag" type='text' placeholder='info@karlatoon.com' Value="info@karlatoon.com" readOnly/>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                        <div className="setting-row">
                                            <div className='setting-column1'>
                                                <div className='overview-top-head'>
                                                    <h6>Candidate Contact you on (Mail)</h6>
                                                </div>
                                            </div>
                                            <div className='setting-column1'>
                                                <div className='overview-top-head-icon'>
                                                    <a href='#'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' className='img-fluid' /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='inpt-data-rpint'>
                                            <input className="int-tag" type='text' placeholder='Yes' Value="Yes" readOnly/>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                        <div className="setting-row">
                                            <div className='setting-column1'>
                                                <div className='overview-top-head'>
                                                    <h6>Candidate Contact you on (Phone)</h6>
                                                </div>
                                            </div>
                                            <div className='setting-column1'>
                                                <div className='overview-top-head-icon'>
                                                    <a href='#'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' className='img-fluid' /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='inpt-data-rpint'>
                                            <input className="int-tag" type='text' placeholder='No' Value="No" readOnly/>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                        <div className="setting-row">
                                            <div className='setting-column1'>
                                                <div className='overview-top-head'>
                                                    <h6>Hiring Timeline</h6>
                                                </div>
                                            </div>
                                            <div className='setting-column1'>
                                                <div className='overview-top-head-icon'>
                                                    <a href='#'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' className='img-fluid' /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='inpt-data-rpint'>
                                            <input className="int-tag" type='text' placeholder='7 Days' Value="7 Days" readOnly/>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                        <div className="setting-row">
                                            <div className='setting-column1'>
                                                <div className='overview-top-head'>
                                                    <h6>Expected Start Date</h6>
                                                </div>
                                            </div>
                                            <div className='setting-column1'>
                                                <div className='overview-top-head-icon'>
                                                    <a href='#'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' className='img-fluid' /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='inpt-data-rpint'>
                                            <input className="int-tag" type='text' placeholder='25-06-2023' Value="25-06-2023" readOnly/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row my-lg-2 my-md-2 my-1'>
                                <div className='col-md-12 col-lg-12 col-12'>
                                    <div className='overview-top-head'>
                                        <h5>Account</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='setting-row-parent'>
                                <div className='row my-lg-2 my-md-2 my-1'>
                                    <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                        <div className="setting-row">
                                            <div className='setting-column1'>
                                                <div className='overview-top-head'>
                                                    <h6>Email-ID for Registration</h6>
                                                </div>
                                            </div>
                                            <div className='setting-column1'>
                                                <div className='overview-top-head-icon'>
                                                    <a href='#'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' className='img-fluid' /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='inpt-data-rpint'>
                                            <input className="int-tag" type='text' placeholder='Email-ID for Registraion' Value="Email-ID for Registraion" readOnly/>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                        <div className="setting-row">
                                            <div className='setting-column1'>
                                                <div className='overview-top-head'>
                                                    <h6>Phone Number</h6>
                                                </div>
                                            </div>
                                            <div className='setting-column1'>
                                                <div className='overview-top-head-icon'>
                                                    <a href='#'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' className='img-fluid' /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='inpt-data-rpint'>
                                            <input className="int-tag" type='text' placeholder='Phone Number' Value="Phone Number" readOnly/>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                        <div className="setting-row">
                                            <div className='setting-column1'>
                                                <div className='overview-top-head'>
                                                    <h6>Your Company Name</h6>
                                                </div>
                                            </div>
                                            <div className='setting-column1'>
                                                <div className='overview-top-head-icon'>
                                                    <a href='#'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' className='img-fluid' /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='inpt-data-rpint'>
                                            <input className="int-tag" type='text' placeholder='Alobha Technologies' Value="Alobha Technologies" readOnly/>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                        <div className="setting-row">
                                            <div className='setting-column1'>
                                                <div className='overview-top-head'>
                                                    <h6>Employees</h6>
                                                </div>
                                            </div>
                                            <div className='setting-column1'>
                                                <div className='overview-top-head-icon'>
                                                    <a href='#'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' className='img-fluid' /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='inpt-data-rpint'>
                                            <input className="int-tag" type='text' placeholder='10 to 50' Value="10 to 50" readOnly/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className='bottom-button-dv'>
                                <div className='row my-lg-2 my-md-2 my-1'>
                                    <div className='col-lg-6 col-md-6 col-12 text-end'>
                                        <div className='bottom-btn'>
                                            <Link to='/make-it-deal-breaker' type='submit' className='a-tag-btn1'>Back</Link>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12 text-start'>
                                        <div className='bottom-btn'>
                                            <Link to='/get-job-front-people' type='submit' className='a-tag-btn2'>Continue</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PostAJobOverviewContent