import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
const Jobdemand = () => {
    return (
        <section className='job-demand'>
            <div className='container'>
                <div className='row'>
                    <div className='heading-txt text-center'>
                        <h1>Highest Paying in Demand jobs</h1>
                        <p className='mb-5'>Hand-picked jobs featured depending on popularity and benifits</p>
                    </div>
                    <div className='col-lg-4'>
                        <div className='job-box'>
                            <h4>Visual Design-Landing page Team</h4>
                            <div className='job-type d-flex mt-4'>
                                <div className='d-flex mx-2'>
                                    <i className="fa-solid fa-location-dot"></i>
                                    <p className='mx-2'>Copenhagen</p>
                                </div>
                                <div className='d-flex mx-2'>
                                    <i className="fa-regular fa-clock"></i>
                                    <p className='mx-2'>Part time</p>
                                </div>
                            </div>
                            <hr></hr>
                            <div className='company d-flex justify-content-between'>
                                <div className='company-logo d-flex'>
                                    <img src="assets/images/homepage/micro.png" className='img-fluid mx-2' />
                                    <div className='company-txt'>
                                        <h5>Microsoft</h5>
                                        <p>5 days ago</p>
                                    </div>
                                </div>
                                <button>Apply Now</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='job-box'>
                            <h4>Senior UI Design-Web App Team</h4>
                            <div className='job-type d-flex mt-4'>
                                <div className='d-flex mx-2'>
                                    <i className="fa-solid fa-location-dot"></i>
                                    <p className='mx-2'>Copenhagen</p>
                                </div>
                                <div className='d-flex mx-2'>
                                    <i className="fa-regular fa-clock"></i>
                                    <p className='mx-2'>Full time</p>
                                </div>
                            </div>
                            <hr></hr>
                            <div className='company d-flex justify-content-between'>
                                <div className='company-logo d-flex'>
                                    <img src="assets/images/homepage/logo.png" className='img-fluid mx-2' />
                                    <div className='company-txt'>
                                        <h5>Behance</h5>
                                        <p>7 days ago</p>
                                    </div>
                                </div>
                                <button>Apply Now</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='job-box'>
                            <h4>Product Manager-Mobile Apps Team</h4>
                            <div className='job-type d-flex mt-4'>
                                <div className='d-flex mx-2'>
                                    <i className="fa-solid fa-location-dot"></i>
                                    <p className='mx-2'>Copenhagen</p>
                                </div>
                                <div className='d-flex mx-2'>
                                    <i className="fa-regular fa-clock"></i>
                                    <p className='mx-2'>Full time</p>
                                </div>
                            </div>
                            <hr></hr>
                            <div className='company d-flex justify-content-between'>
                                <div className='company-logo d-flex'>
                                    <img src="assets/images/homepage/logo2.png" className='img-fluid mx-2' />
                                    <div className='company-txt'>
                                        <h5>Daily Mail</h5>
                                        <p>2 days ago</p>
                                    </div>
                                </div>
                                <button>Apply Now</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='job-box'>
                            <h4>Product Designer-Web app Team</h4>
                            <div className='job-type d-flex mt-4'>
                                <div className='d-flex mx-2'>
                                    <i className="fa-solid fa-location-dot"></i>
                                    <p className='mx-2'>Copenhagen</p>
                                </div>
                                <div className='d-flex mx-2'>
                                    <i className="fa-regular fa-clock"></i>
                                    <p className='mx-2'>Full time</p>
                                </div>
                            </div>
                            <hr></hr>
                            <div className='company d-flex justify-content-between'>
                                <div className='company-logo d-flex'>
                                    <img src="assets/images/homepage/logo3.png" className='img-fluid mx-2' />
                                    <div className='company-txt'>
                                        <h5>Etsy</h5>
                                        <p>3 days ago</p>
                                    </div>
                                </div>
                                <button>Apply Now</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='job-box'>
                            <h4>Product Manager-Mobile Apps Team</h4>
                            <div className='job-type d-flex mt-4'>
                                <div className='d-flex mx-2'>
                                    <i className="fa-solid fa-location-dot"></i>
                                    <p className='mx-2'>Copenhagen</p>
                                </div>
                                <div className='d-flex mx-2'>
                                    <i className="fa-regular fa-clock"></i>
                                    <p className='mx-2'>Full time</p>
                                </div>
                            </div>
                            <hr></hr>
                            <div className='company d-flex justify-content-between'>
                                <div className='company-logo d-flex'>
                                    <img src="assets/images/homepage/logo4.png" className='img-fluid mx-2' />
                                    <div className='company-txt'>
                                        <h5>Apple</h5>
                                        <p>5 days ago</p>
                                    </div>
                                </div>
                                <button>Apply Now</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='job-box'>
                            <h4>Product Manager-Mobile Apps Team</h4>
                            <div className='job-type d-flex mt-4'>
                                <div className='d-flex mx-2'>
                                    <i className="fa-solid fa-location-dot"></i>
                                    <p className='mx-2'>Copenhagen</p>
                                </div>
                                <div className='d-flex mx-2'>
                                    <i className="fa-regular fa-clock"></i>
                                    <p className='mx-2'>Full time</p>
                                </div>
                            </div>
                            <hr></hr>
                            <div className='company d-flex justify-content-between'>
                                <div className='company-logo d-flex'>
                                    <img src="assets/images/homepage/logo5.png" className='img-fluid mx-2' />
                                    <div className='company-txt'>
                                        <h5>Patch</h5>
                                        <p>4 days ago</p>
                                    </div>
                                </div>
                                <button>Apply Now</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className='find-job-btn m-5 text-center'>
                <Link className='find-more-jobs-btn' to="/find-jobs">Find More Jobs <span><i className="fa-solid fa-arrow-right"></i></span></Link>
            </div>
        </section>
    )
}

export default Jobdemand
