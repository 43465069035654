import React from 'react'

import './scss/YouRegistereredSucJobSearch.css'
const RegisterPopups = () => {
    return (
        <>
            <div className="modal fade" id="show-rating" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content popup-bg">
                        <div className="modal-header modal-header-custom ">
                            <button
                                type="button"
                                className="btn-close btn-popup"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body modal-body-custom">
                            <div className="popup-section">
                                <div className="pop-custom">
                                    <p>
                                        Trusted by 1.5 millions <br />
                                        people worldwide
                                    </p>
                                    <button>Get App</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default RegisterPopups