import React from 'react'
import './scss/AnalysisChart.css'
import { Link } from 'react-router-dom'

const Performance = () => {
    return (
        <>
            <section className="performance py-5">
                <div className="container pb-5">
                    <div className="row justify-content-between">
                        <div className="heading mb-4">
                            <h4>Performance summary</h4>
                        </div>
                        <div className="col-lg-3">
                            <div className="campaign-card text-center">
                                <h4>Jobs and campaigns</h4>
                                <h1>17</h1>
                                <div className="dropdown">
                                    <button className="btn dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                        applications
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                        <li><button className="dropdown-item" type="button">Action</button></li>
                                        <li><button className="dropdown-item" type="button">Another action</button></li>
                                        <li><button className="dropdown-item" type="button">Something else here</button></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 custn-line">
                            <div className="per-crd">
                                <h4>Measure key performance indicators for your jobs and campaigns</h4>
                                <p>Evaluaate your keymetrics over time identify underperforming jobs that need your attention.</p>
                                <button className='sum-btn'>Go to summary</button>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="per-crd">
                                <h4>Searchh, filter and group data to meet your business needs</h4>
                                <p>Dive deep into your performance by comparing key metrics, like clicksand applications,across jobs and compaigns</p>
                                <button className='sum-btn'>Go to detailed report</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container pt-4">
                    <div className="d-flex justify-content-evenly">
                        <div className="bottom-link"><Link to={undefined}>View Billing Summary <img src="./assets/images/url.png" className='url-img-logo'/></Link></div>
                        <div className="bottom-link"><Link to={undefined}>View Employer Tools <img src="./assets/images/url.png" className='url-img-logo'/></Link></div>
                        <div className="bottom-link"><Link to={undefined}>View Hiring Insight</Link></div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Performance