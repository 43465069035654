import React from 'react'
import './scss/JobFindDescription.css'
const JobFindDescriptionContent = () => {
    return (
        <>
            <section className='jobfind-description-section'>
                <div className="main-section">
                    <div className="container">
                        <div className="second-section">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="top-header">
                                        <div className="d-flex">
                                            <div>
                                                <img src="./assets/images_another/frame-17.png" className="img-fluid" alt="" />
                                            </div>
                                            <div className="top-head mx-3">
                                                <h3>Software Engineer</h3>
                                                <p>
                                                    <span>At Facebook</span> <span>Full-Time</span>{" "}
                                                    <span>Featured</span>{" "}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="top-btn">
                                            <button className='top-btn-tag'>
                                                <img src="./assets/images_another/mdi_check-bold.png" alt="" /> Applied
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-lg-7">
                                    <div className="banner-section">
                                        <div className='job-dep-parent'>
                                            <h3>Job Description</h3>
                                            <p>
                                                Velstar is a Shopify Plus agency, and we partner with brands to
                                                help them grow, we also do the same with our people!
                                            </p>
                                            <p>
                                                Here at Velstar, we don't just make websites, we create
                                                exceptional digital experiences that consumers love. Our team of
                                                designers, developers, strategists, and creators work together
                                                to push brands to the next level. From Platform Migration, User
                                                Experience &amp; User Interface Design, to Digital Marketing, we
                                                have a proven track record in delivering outstanding eCommerce
                                                solutions and driving sales for our clients.
                                            </p>
                                            <p>
                                                The role will involve translating project specifications into
                                                clean, test-driven, easily maintainable code. You will work with
                                                the Project and Development teams as well as with the Technical
                                                Director, adhering closely to project plans and delivering work
                                                that meets functional &amp; non-functional requirements. You
                                                will have the opportunity to create new, innovative, secure and
                                                scalable features for our clients on the Shopify platform
                                            </p>
                                            <p>Want to work with us? You're in good company!</p>
                                        </div>
                                        <div className='requirement-par'>
                                            <h3>Requirements</h3>
                                            <ul>
                                                <li>
                                                    Great troubleshooting and analytical skills combined with the
                                                    desire to tackle challenges head-on
                                                </li>
                                                <li>
                                                    3+ years of experience in back-end development working either
                                                    with multiple smaller projects simultaneously or large-scale
                                                    applications
                                                </li>
                                                <li>
                                                    Experience with HTML, JavaScript, CSS, PHP, Symphony and/or
                                                    Laravel
                                                </li>
                                                <li>
                                                    Working regularly with APIs and Web Services (REST, GrapthQL,
                                                    SOAP, etc)
                                                </li>
                                                <li>
                                                    Have experience/awareness in Agile application development,
                                                    commercial off-the-shelf software, middleware, servers and
                                                    storage, and database management.
                                                </li>
                                                <li>
                                                    Familiarity with version control and project management
                                                    systems (e.g., Github, Jira)
                                                </li>
                                                <li>
                                                    Great troubleshooting and analytical skills combined with the
                                                    desire to tackle challenges head-on
                                                </li>
                                                <li>
                                                    Ambitious and hungry to grow your career in a fast-growing
                                                    agency
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='desirable-dev'>
                                            <h3>Desirable:</h3>
                                            <ul>
                                                <li>
                                                    Working knowledge of eCommerce platforms, ideally Shopify but
                                                    also others e.g. Magento, WooCommerce, Visualsoft to enable
                                                    seamless migrations.
                                                </li>
                                                <li>Working knowledge of payment gateways</li>
                                                <li>API platform experience / Building restful APIs</li>
                                            </ul>
                                        </div>
                                        <div className='benefits-dev'>
                                            <h3>Benefits</h3>
                                            <ul>
                                                <li>
                                                    Early finish on Fridays for our end of week catch up (4:30
                                                    finish, and drink of your choice from the bar)
                                                </li>
                                                <li>
                                                    28 days holiday (including bank holidays) rising by 1 day per
                                                    year PLUS an additional day off on your birthday
                                                </li>
                                                <li>Generous annual bonus.</li>
                                                <li>Healthcare package</li>
                                                <li>
                                                    Paid community days to volunteer for a charity of your choice
                                                </li>
                                                <li>
                                                    £100 contribution for your own personal learning and
                                                    development
                                                </li>
                                                <li>Free Breakfast on Mondays and free snacks in the office</li>
                                                <li>
                                                    Access to Perkbox with numerous discounts plus free points
                                                    from the company to spend as you wish.
                                                </li>
                                                <li>Cycle 2 Work Scheme</li>
                                                <li>Brand new MacBook Pro</li>
                                                <li>
                                                    Joining an agency on the cusp of exponential growth and being
                                                    part of this exciting story.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="left-card-section">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 border-right">
                                                <div className="left-section">
                                                    <h6>Salary (USD)</h6>
                                                    <h5>$100,000 - $120,000</h5>
                                                    <p>Yearly salary</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="left-section2 text-center">
                                                    <img src="./assets/images_another/map-tri-fold.png" alt="" />
                                                    <h5>Job Location</h5>
                                                    <p>Dhaka, Bangladesh</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="left-card-second mt-5">
                                        <div className="mt-5 mx-4">
                                            <div className="col-lg-12">
                                                <div className="list-head">
                                                    <h3>Job Overview</h3>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4 col-md-3 col-6">
                                                    <div className="card-pad">
                                                        <img
                                                            src="./assets/images_another/calendar-card.png"
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                        <p>Job Posted:</p>
                                                        <h6>14 Jun, 2021</h6>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-3 col-6">
                                                    <div className="card-pad">
                                                        <img src="./assets/images_another/timer.png" className="img-fluid" alt="" />
                                                        <p>Job expire:</p>
                                                        <h6>14 Jun, 2021</h6>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-3 col-6">
                                                    <div className="card-pad">
                                                        <img src="./assets/images_another/stack.png" className="img-fluid" alt="" />
                                                        <p>Job Level:</p>
                                                        <h6>Entry Level</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4 col-md-3 col-6">
                                                    <div className="card-pad">
                                                        <img src="./assets/images_another/wallet.png" className="img-fluid" alt="" />
                                                        <p>Experience</p>
                                                        <h6>$50k-80k/month</h6>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-3 col-6">
                                                    <div className="card-pad">
                                                        <img
                                                            src="./assets/images_another/calendar-card.png"
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                        <p>Education</p>
                                                        <h6>Graduation</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="border-bottom"></div>
                                        <div className="col-lg-12">
                                            <div className="list-top-heading">
                                                <div className="list-head">
                                                    <h3>Share this job:</h3>
                                                </div>
                                                <div className="card-list mx-2">
                                                    <div className="card-list-child-dv mx-2">
                                                        <img src="./assets/images_another/icon/copy-link.png" alt="" />
                                                    </div>
                                                    <div className="card-list-child-dv mx-2">
                                                        <img src="./assets/images_another/icon/twitter.png" alt="" />
                                                    </div>
                                                    <div className="card-list-child-dv mx-2">
                                                        <img src="./assets/images_another/icon/facebook.png" alt="" />
                                                    </div>
                                                    <div className="card-list-child-dv mx-2">
                                                        <img src="./assets/images_another/icon/envlp.png" alt="" />
                                                    </div>
                                                    <div className="card-list-child-dv mx-2">
                                                        <img src="./assets/images_another/icon/linkedin.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default JobFindDescriptionContent