import React, { Component } from 'react'
import CanvasJSReact from '@canvasjs/react-charts';
import './scss/AnalysisChart.css'
const JobsOverview = () => {
    var CanvasJS = CanvasJSReact.CanvasJS;
    var CanvasJSChart = CanvasJSReact.CanvasJSChart;
    CanvasJS.addColorSet("dataColor",
        [//colorSet Array

            "#f5fbff",
        ]);
    const options3 = {
        animationEnabled: true,

        colorSet: "dataColor",
        // type: "line",
        title: {
            text: "Activity Frequently"
        },
        axisX: {
            // lineThickness: 3,
            // lineColor: "blue"
            prefix: "Day ",
            minimum: 0,
            valueFormatString: "#"
        },
        axisY: {
            gridThickness: 1,
            gridColor: "#e8e7fe",
            minimum: 0
        },

        data: [{
            type: "splineArea",
            xValueFormatString: "Day #",
            // xValueFormatString: "MMM YYYY",
            yValueFormatString: "#,##0.## bn kW⋅h",
            showInLegend: false,
            lineColor: "#08aafd",
            lineThickness: 4,
            markerColor: "#FFF",
            markerBorderColor: "#08aafd",
            markerBorderThickness: 2,
            // legendText: "kWh = one kilowatt hour",
            dataPoints: [
                { x: 0, y: 600 },
                { x: 1, y: 400 },
                { x: 2, y: 650 },
                { x: 3, y: 420 },
                { x: 4, y: 430 },
                { x: 5, y: 300 },
                { x: 6, y: 650 },
                { x: 7, y: 250 },
                // { x: new Date(2009, 0), y: 74.102 },
                // { x: new Date("2017- 01- 01"), y: 1792 },
            ]
        }]
    }
    return (
        <>
            <section className="custm-chart">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="overview-head mb-4 d-flex justify-content-between">
                                <div className="textarea">
                                    <h4>Jobs Overview</h4>
                                    <p>Monitor and analyse performance to optimise your spend across products</p>
                                </div>
                                <div className="calender">
                                    <form action="">
                                        <input type="date" className='form-control' placeholder='' />
                                    </form>
                                </div>
                            </div>
                            <div className="review d-flex justify-content-between">
                                <h5>Jobs Overview</h5>
                                <input type="month" className='form-control' placeholder='This Month' onChange={(e) => console.log(e.target.value)} onFocus={(e) => (e.target.type = "date")} onBlur={(e) => (e.target.type = "text")} />
                            </div>
                            <div>
                                <CanvasJSChart options={options3} />
                            </div>
                        </div>
                    </div>
            </section>
        </>
    )
}

export default JobsOverview