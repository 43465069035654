import React from 'react'

const Smartapp = () => {
    return (
        <section className='smart-app'>
            <img src='assets/images/homepage/cta.png' className='img-fluid' />
        </section>
    )
}

export default Smartapp