import React from 'react'
import './scss/PlannedStartDateThisJob.css'
import { Link } from 'react-router-dom'
const PlannedStartDateThisJobContent = () => {
    return (
        <>
            <section className='planned-start-date-this-job-sec'>
                <div className='container'>
                    <div className='create-an-emp-inner'>
                        <div className='container'>
                            {/* <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading'>
                                        <h2>Add Job Basics</h2>
                                    </div>
                                </div>
                            </div> */}

                            <form action='' method=''>
                                <div className='card-outer-dv card-outer-dv1'>
                                    <div className='row justify-content-center align-items-center'>
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='left-sd-content'>
                                                <h5>Is there a planned start date for this job?</h5>
                                            </div>
                                            <div className='radio-btn-dv'>
                                                <div className='radio-btn'>
                                                    <input type='radio' id='rdo-yes' className='rdo-inpt'/>
                                                    <label htmlFor='rdo-yes'>Yes</label>
                                                </div>
                                                <div className='radio-btn'>
                                                    <input type='radio' id='rdo-no' className='rdo-inpt'/>
                                                    <label htmlFor='rdo-no'>No</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='date-inpt-dv my-lg-4 my-md-4 my-1'>
                                                <input type='date' className='date-input-field form-control'/>
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>
  

                                <div className='bottom-button-dv'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-12 text-end'>
                                            <div className='bottom-btn'>
                                                <Link to='/add-job-basics' type='submit' className='a-tag-btn1'>Back</Link>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12 text-start'>
                                            <div className='bottom-btn'>
                                                <Link to='/add-job-details' type='submit' className='a-tag-btn2'>Continue</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PlannedStartDateThisJobContent