import React from 'react'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'
import AddJobDetailsContent from './AddJobDetailsContent'
import Footer from '../../Commons/Footer'

const AddJobDetails = () => {
  return (
    <>
    <RecAfterLoginNavbar/>
    <AddJobDetailsContent/>
    <Footer/>
    </>
  )
}

export default AddJobDetails