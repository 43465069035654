import React from 'react'
import EnterOTPScreen from '../EnterOTPScreen/EnterOTPScreen'
import { Link } from 'react-router-dom'

const CompanyDetailsForm = () => {
    return (
        <>
            <section className='company-details-form'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='form-head-dv'>
                                <form method='' action=''>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <div className='cd-heading'>
                                                <h2>Company Details</h2>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Company Name<span className='text-danger'>*</span></label>
                                                <input type='text' placeholder='Company Name' name='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Name of The CEO<span className='text-danger'>*</span></label>
                                                <input type='text' placeholder='Name of The CEO' name='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Location<span className='text-danger'>*</span></label>
                                                <input type='text' placeholder='Location' name='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Founded Year<span className='text-danger'>*</span></label>
                                                <input type='text' placeholder='Founded Year' name='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Company Website<span className='text-danger'>*</span></label>
                                                <input type='text' placeholder='Company Website' name='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Industry<span className='text-danger'>*</span></label>
                                                <input type='text' placeholder='Industry' name='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Company Size<span className='text-danger'>*</span></label>
                                                <input type='text' placeholder='Company Size' name='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Headquarters Location<span className='text-danger'>*</span></label>
                                                <input type='text' placeholder='Headquarters Location' name='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Company Logo<span className='text-danger'>*</span></label>
                                                <input type='text' placeholder='Company Logo' name='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Net Worth</label>
                                                <input type='text' placeholder='Net Worth' name='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Business Type</label>
                                                <input type='text' placeholder='Business Type' name='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>List Of The Department</label>
                                                <input type='text' placeholder='List Of The Department' name='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Benefits<span className='text-danger'>*</span></label>
                                                <input type='text' placeholder='Benefits' name='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Land Line Number</label>
                                                <input type='text' placeholder='Land Line Number' name='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-9 my-2'>
                                            <div className='cd-form-f check-circle-parent'>
                                                <label className='d-block'>Mobile Number</label>
                                                <div className='check-circle-relative'>
                                                    <input type='text' placeholder='Mobile Number' name='' />
                                                    <div className='greencheck-circle-absolute'>
                                                        <img src='./assets/images_another-ak/green-check.png' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-1'></div>
                                        <div className='col-lg-2 my-2'>
                                            <div className='cd-form-f cd-form-f-btn'>
                                                {/* <label className='d-block'></label> */}
                                                <input type='button' value='Verified' name='' data-bs-toggle="modal" data-bs-target="#enter-otp" />
                                            </div>
                                        </div>
                                        <div className='col-lg-12 mt-4'>
                                            <div className='cd-heading'>
                                                <h2>Contact Person Details</h2>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>First Name</label>
                                                <input type='text' placeholder='First Name' name='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Last Name</label>
                                                <input type='text' placeholder='Last Name' name='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Designation</label>
                                                <input type='text' placeholder='Designation' name='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Phone Number</label>
                                                <input type='text' placeholder='Phone Number' name='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-9 my-2'>
                                            <div className='cd-form-f check-circle-parent'>
                                            <label className='d-block'>Email-ID</label>
                                                <div className='check-circle-relative'>
                                                    <input type='email' placeholder='Email-ID' name='' />
                                                    <div className='greencheck-circle-absolute'>
                                                        <img src='./assets/images_another-ak/green-check.png' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-1'></div>
                                        <div className='col-lg-2 my-2'>
                                            <div className='cd-form-f cd-form-f-btn'>
                                                {/* <label className='d-block'></label> */}
                                                <input type='button' value='Verified' name='' data-bs-toggle="modal" data-bs-target="#enter-otp" />
                                            </div>
                                        </div>
                                        <div className='col-lg-12 mt-4'>
                                            <div className='cd-heading'>
                                                <h2>Account Details</h2>
                                            </div>
                                        </div>
                                        <div className='col-lg-9 my-2'>
                                            {/* <div className='cd-form-f'>
                                                <label className= 'd-block'>Email-ID for Registration</label>
                                                <input type='email' placeholder='Email-ID for Registration' name='' />
                                            </div> */}
                                            <div className='cd-form-f check-circle-parent'>
                                            <label className= 'd-block'>Email-ID for Registration</label>
                                                <div className='check-circle-relative'>
                                                    <input type='email' placeholder='Email-ID for Registration' name='' />
                                                    <div className='greencheck-circle-absolute'>
                                                        <img src='./assets/images_another-ak/green-check.png' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-1'></div>
                                        <div className='col-lg-2 my-2'>
                                            <div className='cd-form-f cd-form-f-btn'>
                                                {/* <label className='d-block'></label> */}
                                                <input type='button' value='Verified' name='' data-bs-toggle="modal" data-bs-target="#enter-otp" />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Create Password</label>
                                                <input type='password' placeholder='Create Password' name='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Confirm Password</label>
                                                <input type='password' placeholder='Confirm Password' name='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-12 my-2 text-center'>
                                            <div className='next-button'>
                                                {/* <input type='button' value='Next' name='' /> */}
                                                <Link to='/document-varification' className='next-btn'>Next</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <EnterOTPScreen />
        </>
    )
}

export default CompanyDetailsForm