import React from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import Footer from '../../Commons/Footer'
import FindJobListing from './FindJobListing'
import SimilerPostedJob from '../../SimilerPostedJob/SimilerPostedJob'

const FindJob = () => {
  return (
    <>
      <AfterNavbar />
      <FindJobListing />
      {/* <SimilerPostedJob/> */}
      <Footer />
    </>
  )
}

export default FindJob