import React from 'react'
import AfterNavbar from '../../../Commons/AfterLoginHeader/AfterNavbar'
import Footer from '../../../Commons/Footer'
import AssessmentScoreContent from './AssessmentScoreContent'

const AssessmentScore = () => {
    return (
        <>
            <AfterNavbar />
            <AssessmentScoreContent />
            <Footer />
        </>
    )
}

export default AssessmentScore