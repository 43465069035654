import React from 'react'
import './scss/CareerswithLargeEmployment.css'
import Slider from "react-slick";
const CareerswithLargeEmployment = () => {
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                    arrows: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false
                }
            }

        ]
    }
    return (
        <>
            <section className='career-with-large-employement'>
                <div className="main-section">
                    <div className="container">
                        <div className="second-section">
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='similer-post-heading'>
                                        <h2 className='ms-3'>Careers with Large Employment</h2>
                                    </div>
                                </div>
                            </div>
                            <Slider {...settings} className='mb-5'>
                                <div className="col-lg-4">
                                    <div className="card card-first-custom">
                                        <div className="text-center">
                                            <img src="./assets/career-start/wpf_maintenance.png" alt="" />
                                        </div>
                                        <div className="card-second-heading">
                                            <h6>Counteraction, Repair &amp; Maintenance Services</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card card-second-custom">
                                        <div className="text-center">
                                            <img src="./assets/career-start/zondicons_education.png" alt="" />
                                        </div>
                                        <div className="card-second-heading">
                                            <h6>Education</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card card-second-custom">
                                        <div className="text-center">
                                            <img src="./assets/career-start/mdi_energy-circle.png" alt="" />
                                        </div>
                                        <div className="card-second-heading">
                                            <h6>Energy, Mining &amp; Utilities</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card card-second-custom">
                                        <div className="text-center">
                                            <img src="./assets/career-start/mdi_energy-circle.png" alt="" />
                                        </div>
                                        <div className="card-second-heading">
                                            <h6>Energy, Mining &amp; Utilities</h6>
                                        </div>

                                    </div>
                                </div>
                            </Slider>

                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default CareerswithLargeEmployment