import React from 'react'
import RegisterHeader from './CvBuilderHeader/RegisterHeader'
import Footer from '../Commons/Footer'
import { Link } from 'react-router-dom';
import Steps from './Steps';
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar';

const Certification = () => {
    return (
        <>
            <RegisterHeader />
            {/* <AfterNavbar/> */}
            <section className='certification'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="stepper-area">
                                <Steps activeStepCount={5} />
                            </div>
                        </div>
                        <div className="col-lg-9 my-lg-0 my-md-0 my-2">
                            <div className="form-area">
                                <div className="row align-items-center">
                                    <div className="col-lg-2 col-12 profile-back-btn">
                                        <div className="back-btn">
                                            <Link to='/key-skills'><i className="fa-solid fa-arrow-left mx-2"></i>Back</Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-6 project-title">
                                        <h1>Certification</h1>
                                    </div>
                                    <div className="col-lg-2 col-6 profile-edit-btn">
                                        <div className="edit-btn">
                                            <Link href={undefined}>Edit</Link>
                                        </div>
                                    </div>
                                </div>
                                <form action="" className='mt-5'>
                                    <div className="row">
                                        <div className="col-md-12 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Certificate Name</label>
                                            <input type="text" name='name' className='form-control' placeholder='Certificate Name' />
                                        </div>
                                        <div className="col-md-12 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Institute / Organization</label>
                                            <input type="text" name='name' className='form-control' placeholder='Institute / Organization' />
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Start Date</label>
                                            <input type="date" name='name' className='form-control' placeholder='Start Date' onChange={(e) => console.log(e.target.value)} onFocus={(e) => (e.target.type = "date")} onBlur={(e) => (e.target.type = "text")} />
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>End Date</label>
                                            <input type="date" name='name' className='form-control' placeholder='End date' onChange={(e) => console.log(e.target.value)} onFocus={(e) => (e.target.type = "date")} onBlur={(e) => (e.target.type = "text")} />
                                        </div>
                                        <div className="col-lg-12 d-flex align-items-center justify-content-end cust-add-btn">
                                            <div className="add-btn mt-3">
                                                <Link href={undefined}><i className="fa-solid fa-plus"></i> Add More</Link>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-5 mb-5 custm-btn-sec d-flex justify-content-center">
                                            <div className='custm-botton-btn mb-2'>
                                                <Link to='/key-skills' className='skip mx-2'>Back</Link>
                                            </div>
                                            <div className='custm-botton-btn mb-2'>
                                                <Link to='/social-media' className='next mx-2'>Next</Link>
                                            </div>
                                            <div className='custm-botton-btn mb-2'>
                                                <Link to='/social-media' className='skip mx-2'>Skip</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
            <Footer />
        </>
    )
}

export default Certification