import React from 'react'
import './scss/CompanyTopNavbar.css'
const CompanyTopNavbar = () => {
  return (
    <section className='company-top-navbar'>
      <div className='container'>
          <div className='row align-items-center'>
              <div className='col-lg-6 col-md-6 col-4'>
                <div className='menu-left-logo'>
                  <img src='./assets/company_listing/company-listing-logo.png' className='img-fluid' />
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-8'>
                <div className='menu-right-content text-end'>
                      <div className='write-review'>
                        <a href='#'>Write a review</a>
                      </div>
                      <div className='follow-btn'>
                        <a href='#'>Follow</a>
                      </div>
                </div>
              </div>
          </div>
      </div>
    </section>
  )
}

export default CompanyTopNavbar