import React from 'react'
import './scss/profile.css'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import Footer from '../Commons/Footer'
import ProfileTitle from './ProfileTitle'
import ProfileViewer from './ProfileViewer'
import ProfileChart from './ProfileChart'

const ProfilePerformanceHome = () => {
    return (
        <>
            <AfterNavbar />
            <ProfileTitle />
            <ProfileChart />
            <ProfileViewer />
            <Footer />
        </>
    )
}

export default ProfilePerformanceHome