import React from 'react'
import './scss/SponsorYourJobwithFreeCredit.css'
import { Link } from 'react-router-dom'
const SponsorYourJobwithFreeCreditContent = () => {
    return (
        <>
            <section className='sponsor-your-job-free-credit-sec'>
                <div className='container'>
                    <div className='choose-you-plan-internal'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-12 text-center'>
                                <div className='heading-dv'>
                                    <h1>Sponsor Your Job with a Free $150 Credit</h1>
                                    <div className='row justify-content-md-center align-items-center'>
                                        <div className='col-lg-8 col-md-8 offset-lg-2 offset-lg-2'>
                                            <p>That's your first 3 applications free! After the credit, its $49 per application. Your sponsored job will run until it receives 50 applications, but can be canceled anytime. Payment information required. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-lg-4 mt-md-4 mt-1 justify-content-md-center align-items-center'>
                            <div className='col-lg-6 col-md-12 col-12 mt-md-0 mt-lg-0 mt-3'>
                                <div className='left-side-dv text-center'>
                                    <img src='./assets/recruiter-images/post-a-job/sponsor.png' className='img-fluid'/>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12 col-12 mt-md-0 mt-lg-0 mt-3'>
                                <div className='right-side-dv'>
                                    <div className='right-dv-sponor'>
                                        <h2>With a Sponsored Job:</h2>
                                        <p>Praesent congue ornare nibh sed ullamcorper. Proin venenatis tellus non turpis scelerisque, vitae auctor arcu ornare. Cras vitae nulla a purus mollis venenatis.
                                            <ul>
                                                <li>Get better visibility, and show up more often in relevant search results</li>
                                                <li>Pay only for applications you receive</li>
                                                <li>Sponsored Jobs are 4.5 times more likely to result in a hire</li>
                                            </ul>
                                        </p>
                                        <h4>No upfront fees. Cancel any time <span><img src='./assets/recruiter-images/post-a-job/info.png' /></span></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-lg-5 mt-md-5 mt-5'>
                            <div className='col-lg-12 col-md-12 col-12 text-center'>
                                <div className='next-button'>
                                    <Link to="/free-job-post-get-application" className='next-a'>Continue</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SponsorYourJobwithFreeCreditContent