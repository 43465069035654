import React from 'react'
import './scss/RecCandidateProfile.css'
const RecCandidateProfileContent = () => {
    return (
        <>
            <section className='rec-candidate-profile-sec'>
                <div className='container'>
                    <div className='candidate-pro-section-inner-dv'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-12'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-4 col-md-4 col-12'>
                                        <div className='condidate-pro-heading'>
                                            <h2>Candidate Profile</h2>
                                        </div>
                                    </div>
                                    <div className='col-lg-8 col-md-8 col-12'>
                                        <div className='candidate-pro-btn'>
                                            <div className='candidate-pro-atag'>
                                                <a href='#'><img src='./assets/recruiter-images/candidate-profile/phon-icon.png' className='img-fluid' />Call Candidate</a>
                                                <a href='#'>View Phone Number</a>
                                            </div>
                                            <div className='candidate-pro-icons'>
                                                <a href='#'><img src='./assets/recruiter-images/candidate-profile/message.png' className='img-fluid' /></a>
                                                <a href='#'><img src='./assets/recruiter-images/candidate-profile/share.png' className='img-fluid' /></a>
                                                <a href='#'><img src='./assets/recruiter-images/candidate-profile/more-circle.png' className='img-fluid' /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-4 col-12 mt-lg-5 mt-md-3 mt-3'>
                                <div className='sidepanel-img-tabs-parent'>
                                    <div className='sidepanel-img'>
                                        <img src='./assets/recruiter-images/candidate-profile/pic.png' className='img-fluid' alt='' />
                                    </div>
                                    <div className='skill-heading mt-4'>
                                        <h2 className='skill-head'>Hard Skills</h2>
                                        <p className='skill-para'>lorem ipsum</p>
                                        <button className='skill-btn'>Javascript</button>
                                        <button className='skill-btn'>GSAP</button>
                                        <button className='skill-btn'>Webflow</button>
                                        <button className='skill-btn'>UI/UX</button>
                                        <button className='skill-btn'>English(B2)</button>
                                        <button className='skill-btn'>Next.Js</button>
                                        <button className='skill-btn'>React</button>
                                    </div>
                                    <div className='skill-heading mt-lg-4 mt-md-3'>
                                        <h2 className='skill-head'>Soft Skills</h2>
                                        <p className='skill-para'>lorem ipsum</p>
                                        <button className='skill-btn'>Javascript</button>
                                        <button className='skill-btn'>GSAP</button>
                                        <button className='skill-btn'>Webflow</button>
                                        <button className='skill-btn'>UI/UX</button>
                                        <button className='skill-btn'>English(B2)</button>
                                        <button className='skill-btn'>Next.Js</button>
                                        <button className='skill-btn'>React</button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-8 col-md-8 col-12 mt-lg-5 mt-md-3 mt-3'>
                                <div className='rght-dv-profile-details'>
                                    <div className='top-heading'>
                                        <h2>Naveen Pandey</h2>
                                        <h5>Frontend Designer</h5>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-6 col-12'>
                                                <div className='social-details'>
                                                    <h6><strong>Phone:</strong><a href='tel:9889468998'>+91 9889468998</a></h6>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-12'>
                                                <div className='social-details'>
                                                    <h6><strong>Website:</strong><a href='https://www.pandeytech.in'>https://www.pandeytech.in</a></h6>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-12'>
                                                <div className='social-details'>
                                                    <h6><strong>Email:</strong><a href='mailto:naveen.alobha@gmail.com'>naveen.alobha@gmail.com</a></h6>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-12'>
                                                <div className='social-details'>
                                                    <h6><strong>Portfolio:</strong><a href='https://naveenpandey98.github.io/Naveenportfolio/'>https://naveenpandey98.github.io/Naveenportfolio/</a></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='top-heading top-heading2'>
                                        <h2>Experience</h2>
                                        <div className='resume-details-company'>
                                            <p>2021 - Present</p>
                                            <h5>Webflow Development for alobha technologies @Noida</h5>
                                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                            <div className='head2-links'>
                                                <a href='#' className='head2-links-atag'>Wildfires.org</a>,
                                                <a href='#' className='head2-links-atag'>DeMuro Das</a>,
                                                <a href='#' className='head2-links-atag'>Measured Financial</a>,
                                            </div>
                                        </div>
                                        <div className='resume-details-company'>
                                            <p>2019 - 2020</p>
                                            <h5>Webflow Development for alobha technologies @Noida</h5>
                                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                            <div className='head2-links'>
                                                <a href='#' className='head2-links-atag'>Wildfires.org</a>,
                                                <a href='#' className='head2-links-atag'>DeMuro Das</a>,
                                                <a href='#' className='head2-links-atag'>Measured Financial</a>,
                                            </div>
                                        </div>
                                        <div className='resume-details-company'>
                                            <p>2018 - 2019</p>
                                            <h5>Webflow Development for alobha technologies @Noida</h5>
                                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                            <div className='head2-links'>
                                                <a href='#' className='head2-links-atag'>Wildfires.org</a>,
                                                <a href='#' className='head2-links-atag'>DeMuro Das</a>,
                                                <a href='#' className='head2-links-atag'>Measured Financial</a>,
                                            </div>
                                        </div>
                                    </div>
                                    <div className='top-heading top-heading2'>
                                        <h2>Personal Project</h2>
                                        <div className='resume-details-company'>
                                            <h5>YouTube Channel</h5>
                                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                            <div className='head2-links'>
                                                <a href='#' className='head2-links-atag'>Wildfires.org</a>,
                                                <a href='#' className='head2-links-atag'>DeMuro Das</a>,
                                                <a href='#' className='head2-links-atag'>Measured Financial</a>,
                                            </div>
                                        </div>
                                        <div className='resume-details-company'>
                                            <h5>Fluid Blocks</h5>
                                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                            <div className='head2-links'>
                                                <a href='#' className='head2-links-atag'>Wildfires.org</a>,
                                                <a href='#' className='head2-links-atag'>DeMuro Das</a>,
                                                <a href='#' className='head2-links-atag'>Measured Financial</a>,
                                            </div>
                                        </div>
                                        <div className='resume-details-company'>
                                            <h5>Webflow</h5>
                                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                            <div className='head2-links'>
                                                <a href='#' className='head2-links-atag'>Wildfires.org</a>,
                                                <a href='#' className='head2-links-atag'>DeMuro Das</a>,
                                                <a href='#' className='head2-links-atag'>Measured Financial</a>,
                                            </div>
                                        </div>
                                    </div>
                                    <div className='top-heading top-heading2'>
                                        <h2>Education</h2>
                                        <div className='resume-details-company'>
                                            <p>2018</p>
                                            <h5>Interaction Design Foundation</h5>
                                            
                                        </div>
                                        <div className='resume-details-company'>
                                            <p>2018</p>
                                            <h5>UX Studio Workshop</h5>
                                            
                                        </div>
                                        <div className='resume-details-company'>
                                            <p>2011 - 2015</p>
                                            <h5>Bussiness IT</h5>
                                        </div>
                                        <div className='resume-details-company'>
                                            <p>2010 - 2011</p>
                                            <h5>Cisco CCNA Certification</h5>
                                        </div>
                                        <div className='resume-details-company'>
                                            <p>2006 - 2010</p>
                                            <h5>Technocal Machinery</h5>
                                            <p>Informatic Faculty</p>
                                        </div>
                                    </div>
                                    <div className='top-heading top-heading2 border-bottom-0'>
                                        {/* <h2>Education</h2> */}
                                        <div className='resume-details-company'>
                                            <h5 className='mb-0'>Naveen Pandey</h5>
                                            <p>Alobha technologies pvt ltd</p>
                                            <div className='border-bottom py-1 mb-1'></div>
                                            <h6 className='mb-0 last-a-tag'>Phone:<a href='tel:9889468998'>+91 9889468998</a></h6>
                                            <h6 className='mb-0 last-a-tag'>Email:<a href='tel:9889468998'>naveen.alobha@gmail.com</a></h6>
                                            <h6 className='mb-0 last-a-tag'>Website:<a href='tel:9889468998'>www.pandeytech.in</a></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default RecCandidateProfileContent