import React from 'react'
import './scss/ChooseYourSubscriptionPlan.css'
const ChooseYourSubscriptionPlanContent = () => {
    return (
        <>
            <section className='choose-your-subscription-plan-sec'>
                <section className="my-5 main-subs-plan">
                    <div className="main-section mt-5">
                        <div className="container">
                            <div className="Subscrip-card">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="text-center">
                                            <h1>Choose Your Subscription Plan</h1>
                                            <p>
                                                Whether you want to get organized, keep your professional life
                                                on track, or boost workplace productivity, SearchandApply.io has
                                                the right plan for you.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cus-subs-2">
                                    <div className="row mt-4">
                                        {/* ============================card1 */}
                                        <div className="col-lg-3 col-md-6 mt-2">
                                            <div className="card cus-sec">
                                                <div className="basic">
                                                    <h3>Basic Account</h3>
                                                    <p>
                                                        Monthly review invites <br /> Plus 300 free SMS Invites
                                                    </p>
                                                    <div className="cus-gap" />
                                                    <div>
                                                        <p className="cus-price">
                                                            $59 <span className="cus-mon">/mo</span>
                                                        </p>
                                                        <p className="new-p">Excl. Vat</p>
                                                        <div className="cus-bottom" />
                                                        <div className="cus-inner-p">
                                                            <p>
                                                                Display stars in Google organic search risult and
                                                                showcase reviews on your website
                                                            </p>
                                                        </div>
                                                        <div className="cus-btn">
                                                            <button className="btn mt-3 mb-3 btn-inner w-100">
                                                                Free Trial
                                                            </button>
                                                        </div>
                                                        <div className="cus-list mt-2">
                                                            <div className="cus-head">
                                                                <h2>Features Included :</h2>
                                                            </div>
                                                            <ul className="list-unstyled cus-inner-list">
                                                                <li className="mb-3">
                                                                    <span className="small">Company Reviews</span>
                                                                    <span className=" cus-tab">New</span>
                                                                </li>
                                                                <li className="mb-3">
                                                                    <i className="fas fa-check-circle icon-color" />
                                                                    <span className="small">Product Reviews</span>
                                                                </li>
                                                                <li className="mb-3">
                                                                    <i className="fas fa-check-circle icon-color" />
                                                                    <span className="small">Video Reviews</span>
                                                                    <span className=" cus-tab">New</span>
                                                                </li>
                                                                <li className="mb-3">
                                                                    <i className="fas fa-check-circle icon-color" />
                                                                    <span className="small">Google Seller Rating</span>
                                                                </li>
                                                                <li className="mb-3">
                                                                    <i className="fas fa-check-circle icon-color" />
                                                                    <span className="small">Goggle Rich Snippets</span>
                                                                    <span className=" cus-tab">New</span>
                                                                </li>
                                                                <li className="mb-3">
                                                                    <i className="fas fa-check-circle icon-color" />
                                                                    <span className="small">Social Proff Banners</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ===============================card2 */}
                                        <div className="col-lg-3 col-md-6 mt-2">
                                            <div className="card cus-sec">
                                                <div className="cus-ribbon">
                                                    <span>Simple pack</span>
                                                </div>
                                                <div className="basic">
                                                    <h3>Standard Account</h3>
                                                    <p>
                                                        Monthly review invites <br /> Plus 300 free SMS Invites
                                                    </p>
                                                    <div className="cus-gap" />
                                                    <div>
                                                        <p className="cus-price">
                                                            $99 <span className="cus-mon">/mo</span>
                                                        </p>
                                                        <p className="new-p">Excl. Vat</p>
                                                        <div className="cus-bottom" />
                                                        <div className="cus-inner-p">
                                                            <p>
                                                                Display stars in Google organic search risult and
                                                                showcase reviews on your website
                                                            </p>
                                                        </div>
                                                        <div className="cus-btn">
                                                            <button className="btn mt-3 mb-3 btn-inner w-100">
                                                                Free Trial
                                                            </button>
                                                        </div>
                                                        <div className="cus-list mt-2">
                                                            <div className="cus-head">
                                                                <h2>Features Included :</h2>
                                                            </div>
                                                            <ul className="list-unstyled cus-inner-list">
                                                                <li className="mb-3">
                                                                    <span className="small">Company Reviews</span>
                                                                    <span className=" cus-tab">New</span>
                                                                </li>
                                                                <li className="mb-3">
                                                                    <i className="fas fa-check-circle icon-color" />
                                                                    <span className="small">Product Reviews</span>
                                                                </li>
                                                                <li className="mb-3">
                                                                    <i className="fas fa-check-circle icon-color" />
                                                                    <span className="small">Video Reviews</span>
                                                                    <span className=" cus-tab">New</span>
                                                                </li>
                                                                <li className="mb-3">
                                                                    <i className="fas fa-check-circle icon-color" />
                                                                    <span className="small">Google Seller Rating</span>
                                                                </li>
                                                                <li className="mb-3">
                                                                    <i className="fas fa-check-circle icon-color" />
                                                                    <span className="small">Goggle Rich Snippets</span>
                                                                    <span className=" cus-tab">New</span>
                                                                </li>
                                                                <li className="mb-3">
                                                                    <i className="fas fa-check-circle icon-color" />
                                                                    <span className="small">Social Proff Banners</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ================================card3 */}
                                        <div className="col-lg-3 col-md-6 mt-2">
                                            <div className="card cus-sec cus-sec-3">
                                                <div className="cus-ribbon">
                                                    <span>Favourite</span>
                                                </div>
                                                <div className="basic">
                                                    <h3>Professional Account</h3>
                                                    <p>
                                                        Monthly review invites <br /> Plus 300 free SMS Invites
                                                    </p>
                                                    <div className="cus-gap" />
                                                    <div>
                                                        <p className="cus-price">
                                                            $199 <span className="cus-mon">/mo</span>
                                                        </p>
                                                        <p className="new-p">Excl. Vat</p>
                                                        <div className="cus-bottom" />
                                                        <div className="cus-inner-p">
                                                            <p>
                                                                Display stars in Google organic search risult and
                                                                showcase reviews on your website
                                                            </p>
                                                        </div>
                                                        <div className="cus-btn">
                                                            <button className="btn mt-3 mb-3 btn-inner w-100">
                                                                Free Trial
                                                            </button>
                                                        </div>
                                                        <div className="cus-list mt-2">
                                                            <div className="cus-head">
                                                                <h2>Features Included :</h2>
                                                            </div>
                                                            <ul className="list-unstyled cus-inner-list">
                                                                <li className="mb-3">
                                                                    <span className="small">Company Reviews</span>
                                                                    <span className=" cus-tab">New</span>
                                                                </li>
                                                                <li className="mb-3">
                                                                    <i className="fas fa-check-circle icon-color" />
                                                                    <span className="small">Product Reviews</span>
                                                                </li>
                                                                <li className="mb-3">
                                                                    <i className="fas fa-check-circle icon-color" />
                                                                    <span className="small">Video Reviews</span>
                                                                    <span className=" cus-tab">New</span>
                                                                </li>
                                                                <li className="mb-3">
                                                                    <i className="fas fa-check-circle icon-color" />
                                                                    <span className="small">Google Seller Rating</span>
                                                                </li>
                                                                <li className="mb-3">
                                                                    <i className="fas fa-check-circle icon-color" />
                                                                    <span className="small">Goggle Rich Snippets</span>
                                                                </li>
                                                                <li className="mb-3">
                                                                    <i className="fas fa-check-circle icon-color" />
                                                                    <span className="small">Social Proff Banners</span>
                                                                    <span className=" cus-tab">New</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ===================================card4 */}
                                        <div className="col-lg-3 col-md-6 mt-2 cus-sec-forth">
                                            <div className="card cus-sec">
                                                <div className="cus-ribbon">
                                                    <span>Most Popular</span>
                                                </div>
                                                <div className="basic">
                                                    <h3>
                                                        All In One Account <br />
                                                        (sponsor your post)
                                                    </h3>
                                                    <p>
                                                        Monthly review invites <br /> Plus 300 free SMS Invites
                                                    </p>
                                                    <div className="cus-gap" />
                                                    <div>
                                                        <p className="cus-price">
                                                            $299 <span className="cus-mon">/mo</span>
                                                        </p>
                                                        <p className="new-p">Excl. Vat</p>
                                                        <div className="cus-bottom" />
                                                        <div className="cus-inner-p">
                                                            <p>
                                                                Display stars in Google organic search risult and
                                                                showcase reviews on your website
                                                            </p>
                                                        </div>
                                                        <div className="cus-btn">
                                                            <button className="btn mt-3 mb-3 btn-inner w-100">
                                                                Free Trial
                                                            </button>
                                                        </div>
                                                        <div className="cus-list mt-2">
                                                            <div className="cus-head">
                                                                <h2>Features Included :</h2>
                                                            </div>
                                                            <ul className="list-unstyled cus-inner-list">
                                                                <li className="mb-3">
                                                                    <span className="small">Company Reviews</span>
                                                                    <span className=" cus-tab">New</span>
                                                                </li>
                                                                <li className="mb-3">
                                                                    <span className="small">Product Reviews</span>
                                                                    <span className=" cus-tab">New</span>
                                                                </li>
                                                                <li className="mb-3">
                                                                    <span className="small">Video Reviews</span>
                                                                    <span className=" cus-tab">New</span>
                                                                </li>
                                                                <li className="mb-3">
                                                                    <span className="small">Google Seller Rating</span>
                                                                    <span className=" cus-tab">New</span>
                                                                </li>
                                                                <li className="mb-3">
                                                                    <span className="small">Goggle Rich Snippets</span>
                                                                    <span className=" cus-tab">New</span>
                                                                </li>
                                                                <li className="mb-3">
                                                                    <span className="small">Social Proff Banners</span>
                                                                    <span className="cus-tab">New</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default ChooseYourSubscriptionPlanContent