import React from 'react'
import './scss/ResumeListing.css'
import { Link } from 'react-router-dom'
const ResumeListingContent = () => {
    return (
        <>
            <section className="resumes-listing-sec my-5">
                <div className="container py-5">
                    <div className="row">
                        <div className="title p3-5">
                            <h1>Resumes</h1>
                        </div>

                        <div className="col-lg-2 col-md-4 resume-img py-4">
                            <img src="./assets/images/career-guide/resume.png" className='img-fluid' alt="" />
                        </div>
                        <div className="col-lg-4 col-md-8 resume-info py-4">
                            <h5>Travel</h5>
                            <h2>Design is the Mix of emotions</h2>
                            <h4>Did you come here for something in particular or just general Riker-bashing? And blowing into</h4>
                        </div>

                        <div className="col-lg-2 col-md-4 resume-img py-4">
                            <img src="./assets/images/career-guide/resume.png" className='img-fluid' alt="" />
                        </div>
                        <div className="col-lg-4 col-md-8 resume-info py-4">
                            <h5>Travel</h5>
                            <h2>Design is the Mix of emotions</h2>
                            <ul className='d-flex'>
                                <li><img src="./assets/images/career-guide/ava.png" className='img-fluid' alt="" />Jenny kiaa</li>
                                <li><img src="./assets/images/career-guide/calender.png" className='img-fluid' alt="" />02 december 2022</li>
                                <li><img src="./assets/images/career-guide/time.png" className='img-fluid' alt="" />3 min. to read</li>
                            </ul>
                            <h4>Did you come here for something in particular or just general Riker-bashing? And blowing into</h4>
                        </div>

                        <div className="col-lg-2 col-md-4 resume-img py-4">
                            <img src="./assets/images/career-guide/resume.png" className='img-fluid' alt="" />
                        </div>
                        <div className="col-lg-4 col-md-8 resume-info py-4">
                            <h5>Travel</h5>
                            <h2>Design is the Mix of emotions</h2>
                            <ul className='d-flex'>
                                <li><img src="./assets/images/career-guide/ava.png" className='img-fluid' alt="" />Jenny kiaa</li>
                                <li><img src="./assets/images/career-guide/calender.png" className='img-fluid' alt="" />02 december 2022</li>
                                <li><img src="./assets/images/career-guide/time.png" className='img-fluid' alt="" />3 min. to read</li>
                            </ul>
                            <h4>Did you come here for something in particular or just general Riker-bashing? And blowing into</h4>
                        </div>

                        <div className="col-lg-2 col-md-4 resume-img py-4">
                            <img src="./assets/images/career-guide/resume.png" className='img-fluid' alt="" />
                        </div>
                        <div className="col-lg-4 col-md-8 resume-info py-4">
                            <h5>Travel</h5>
                            <h2>Design is the Mix of emotions</h2>
                            <ul className='d-flex'>
                                <li><img src="./assets/images/career-guide/ava.png" className='img-fluid' alt="" />Jenny kiaa</li>
                                <li><img src="./assets/images/career-guide/calender.png" className='img-fluid' alt="" />02 december 2022</li>
                                <li><img src="./assets/images/career-guide/time.png" className='img-fluid' alt="" />3 min. to read</li>
                            </ul>
                            <h4>Did you come here for something in particular or just general Riker-bashing? And blowing into</h4>
                        </div>

                        <div className="col-lg-12 col-md-12 col-12 mt-3">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination justify-content-center">
                                    <li class="page-item">
                                        <a class="page-link" href="#" aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                        </a>
                                    </li>
                                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                    <li class="page-item">
                                        <a class="page-link" href="#" aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ResumeListingContent