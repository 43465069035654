import React from 'react'
import './scss/AddJobDetails.css'
import { Link } from 'react-router-dom'
const AddJobDetailsContent = () => {
    return (
        <>
            <section className='add-job-details-sec'>
                <div className='container'>
                    <div className='create-an-emp-inner'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading'>
                                        <h2>Add Job Details</h2>
                                    </div>
                                </div>
                            </div>
                            <form action='' method=''>
                                <div className='card-outer-dv card-outer-dv1'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='left-sd-content'>
                                                <h5>Job Type</h5>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-4 col-6 my-lg-2 my-md-2 my-1'>
                                            <div className='job-type-btn'>
                                                <button className='btn-circle'>+ Full Time</button>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-4 col-6 my-lg-2 my-md-2 my-1'>
                                            <div className='job-type-btn'>
                                                <button className='btn-circle'>+ Permanent</button>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-4 col-6 my-lg-2 my-md-2 my-1'>
                                            <div className='job-type-btn'>
                                                <button className='btn-circle'>+ Fresher</button>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-4 col-6 my-lg-2 my-md-2 my-1'>
                                            <div className='job-type-btn'>
                                                <button className='btn-circle'>+ Part Time</button>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-4 col-6 my-lg-2 my-md-2 my-1'>
                                            <div className='job-type-btn'>
                                                <button className='btn-circle'>+ Volunteer</button>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-4 col-6 my-lg-2 my-md-2 my-1'>
                                            <div className='job-type-btn'>
                                                <button className='btn-circle'>+ Temporary</button>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-4 col-6 my-lg-2 my-md-2 my-1'>
                                            <div className='job-type-btn'>
                                                <button className='btn-circle'>+ Freelancer</button>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-4 col-6 my-lg-2 my-md-2 my-1'>
                                            <div className='job-type-btn'>
                                                <button className='btn-circle'>+ Internship</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-outer-dv card-outer-dv1'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='left-sd-content'>
                                                <h5>Schedule</h5>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-4 col-6 my-lg-2 my-md-2 my-1'>
                                            <div className='job-type-btn'>
                                                <button className='btn-circle'>+ Day Shift</button>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-4 col-6 my-lg-2 my-md-2 my-1'>
                                            <div className='job-type-btn'>
                                                <button className='btn-circle'>+ Morning Shift</button>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-4 col-6 my-lg-2 my-md-2 my-1'>
                                            <div className='job-type-btn'>
                                                <button className='btn-circle'>+ Rotational Shift</button>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-4 col-6 my-lg-2 my-md-2 my-1'>
                                            <div className='job-type-btn'>
                                                <button className='btn-circle'>+ Night Shift</button>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-4 col-6 my-lg-2 my-md-2 my-1'>
                                            <div className='job-type-btn'>
                                                <button className='btn-circle'>+ Evening Shift</button>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-4 col-6 my-lg-2 my-md-2 my-1'>
                                            <div className='job-type-btn'>
                                                <button className='btn-circle'>+ US Shift</button>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-4 col-6 my-lg-2 my-md-2 my-1'>
                                            <div className='job-type-btn'>
                                                <button className='btn-circle'>+ UK Shift</button>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-4 col-6 my-lg-2 my-md-2 my-1'>
                                            <div className='job-type-btn'>
                                                <button className='btn-circle'>+ Fixed Shift</button>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-4 col-6 my-lg-2 my-md-2 my-1'>
                                            <div className='job-type-btn'>
                                                <button className='btn-circle'>+ Others</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-6 my-lg-2 my-md-2 my-1'>
                                            <div className='check-type-btn'>
                                                <input type='checkbox' id='yes-btn-sche' className='me-2'/>
                                                <label htmlFor='yes-btn-sche'>Yes</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-6 my-lg-2 my-md-2 my-1 text-start'>
                                            <div className='check-type-btn'>
                                                <input type='checkbox' id='no-btn-sche' className='me-2'/>
                                                <label htmlFor='no-btn-sche'>No</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-outer-dv'>
                                    <div className='inner-input'>
                                        <div className='inner-input'>
                                            <label htmlFor='no-peaple'>Number of people you wish to hire for this Job<span className='text-danger'>*</span></label>

                                            <select className='form-control' id="no-peaple">
                                                <option>Gautam</option>
                                                <option>Gautam</option>
                                                <option>Gautam</option>
                                                <option>Gautam</option>
                                            </select>
                                        </div>
                                        <div className='inner-input my-4'>
                                            <label htmlFor='emp-will-report'>Recruitment timeline for this job<span className='text-danger'>*</span></label>
                                            <input type='text' className='form-control me-2' id='emp-will-report' name="emp-report" placeholder="1 to 5 Days" required/>
                                        </div>
                                    </div>
                                </div>

                                <div className='bottom-button-dv'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-12 text-end'>
                                            <div className='bottom-btn'>
                                                <Link to='/planned-start-date-job' type='submit' className='a-tag-btn1'>Back</Link>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12 text-start'>
                                            <div className='bottom-btn'>
                                                <Link to='/add-pay-benefits' type='submit' className='a-tag-btn2'>Continue</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AddJobDetailsContent