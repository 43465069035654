import React from 'react'
import './scss/CvForEmployer.css'
import { Link } from 'react-router-dom'
const CvForEmployerContent = () => {
    return (
        <>
            <section className='cv-for-emp-content-sec'>
                <div className="form-section mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className=''>
                                    <div className="bg-form">
                                        <div className="arror-icon">
                                            <a href="">
                                                <img src="./assets/images_another/ion_arrow-back-outline.png" alt="" /> Back
                                            </a>
                                        </div>
                                        <div className="first-head">
                                            <h2>Add a CV for the employer</h2>
                                        </div>
                                        <div className="form-banner">
                                            <label htmlFor='cv-for-employer-input' className='w-100'>
                                                <div className="col-md-12 cv-upload mb-3 d-flex justify-content-between align-items-center">
                                                    <div className="resume">
                                                        <h4>Upload Resume</h4>
                                                        <p>8Doc, Docx, RTF, PDF (Max file size-6MB)</p>
                                                    </div>
                                                    <div className="upload">
                                                        <input type='file' id='cv-for-employer-input' className='cv-for-employer-input'/>
                                                        <img src="./assets/images_another/radix-icons_upload.png" alt="" />
                                                    </div>
                                                </div>
                                                
                                            </label>
                                            <div className="upload-para">
                                                <p>
                                                    Build an Karlatoon Resume
                                                    <span>
                                                        <a href="#">Click Here</a>
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="upload-btn">
                                                    <Link to="/answer-questions-from-employer" className='btn upload-btn-a'>Continue</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CvForEmployerContent