import React from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import CareerStartHereSearchContent from './CareerStartHereSearchContent'
import Footer from '../../Commons/Footer'
import PopularJobs from './PopularJobs/PopularJobs'
import CareerswithLargeEmployment from './CareerswithLargeEmployment/CareerswithLargeEmployment'
import MostCommonOccupations from './MostCommonOccupations/MostCommonOccupations'
import MostViewedJobsSlider from './MostViewedJobsSlider/MostViewedJobsSlider'

const CareerStartHereSearch = () => {
  return (
    <>
        <AfterNavbar/>
        <CareerStartHereSearchContent/>
        <PopularJobs/>
        <CareerswithLargeEmployment/>
        <MostCommonOccupations/>
        <MostViewedJobsSlider/>
        <Footer/>
    </>
  )
}

export default CareerStartHereSearch