import React from 'react'
import Header from '../Commons/Header'
import RegisterHeader from '../CvBuilder/CvBuilderHeader/RegisterHeader'
import CreateAnAccountContent from './CreateAnAccountContent'
import Footer from '../Commons/Footer'

const CreateAnAccount = () => {
  return (
    <>
        <RegisterHeader />
        <CreateAnAccountContent/>
        <Footer/>
    </>
  )
}

export default CreateAnAccount