import React from 'react'
import './scss/LearningVideo.css'
import { Link } from 'react-router-dom'
const LearningVideoContent = () => {
    return (
        <>
            <section className='learning-video-section'>
                <section className="my-5 main-subs-plan-lernvido">
                    <div className="main-sec-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="cus-head">
                                        <h5 className="text-center">Learning Video</h5>
                                    </div>
                                    <div className="subscrip-card">
                                        <div className="row mt-4 justify-content-center align-items-center">
                                            <div className="col-lg-4 col-md-6 p-2 mt-2 p-lg-4 mt-lg-3 p-md-4 mt-md-3">
                                                <div className="card cus-card">
                                                    <div className="card-video">
                                                        <video>
                                                            <source
                                                                src="./assets/images_another-ak/production_id_4201543-240p.mp4"
                                                                type="video/mp4"
                                                            />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                        <div className="cus-sec-vid">
                                                            <Link to='/learning-video-details' className='a-tag-link'><p>Creating Awesome Mobile Apps</p></Link>
                                                            <span>UI UX Design</span>
                                                        </div>
                                                    </div>
                                                    <div className="card-body cus-b">
                                                        <h5 className="card-title mt-2">Progress</h5>
                                                        <div className="progress">
                                                            <div
                                                                className="progress-bar cus-bar"
                                                                role="progressbar"
                                                                style={{ width: "0%" }}
                                                                aria-valuenow={0}
                                                                aria-valuemin={0}
                                                                aria-valuemax={100}
                                                            >
                                                                <span className="progress-percent">0%</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="d-flex cus-sec-2 mt-2">
                                                                    <div className="mb-1">
                                                                        <img src="./assets/images_another-ak/timee.png" alt="" srcSet="" />
                                                                    </div>
                                                                    <div>
                                                                        <span className="ps-2">1 Hour</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="d-flex justify-content-end mt-2">
                                                                    <div className="mb-1">
                                                                        <img src="./assets/images_another-ak/Student.png" alt="" srcSet="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4  col-md-6 p-2 mt-2 p-lg-4 mt-lg-3 p-md-4 mt-md-3">
                                                <div className="card cus-card">
                                                    <div className="card-video">
                                                        <video>
                                                            <source
                                                                src="./assets/images_another-ak/production_id_4201543-240p.mp4"
                                                                type="video/mp4"
                                                            />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                        <div className="cus-sec-vid">
                                                            <Link to='/learning-video-details' className='a-tag-link'><p>Creating Fresh Website</p></Link>
                                                            <span>Web Developer</span>
                                                        </div>
                                                    </div>
                                                    <div className="card-body cus-b">
                                                        <h5 className="card-title mt-2">Progress</h5>
                                                        <div className="progress">
                                                            <div
                                                                className="progress-bar cus-bar"
                                                                role="progressbar"
                                                                style={{ width: "85%" }}
                                                                aria-valuenow={0}
                                                                aria-valuemin={0}
                                                                aria-valuemax={100}
                                                            >
                                                                <span className="progress-percent">85%</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="d-flex cus-sec-2 mt-2">
                                                                    <div className="mb-1">
                                                                        <img src="./assets/images_another-ak/timee.png" alt="" srcSet="" />
                                                                    </div>
                                                                    <div>
                                                                        <span className="ps-2">2 Hour</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="d-flex justify-content-end mt-2">
                                                                    <div className="mb-1">
                                                                        <img src="./assets/images_another-ak/Student.png" alt="" srcSet="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 p-2 mt-2 p-lg-4 mt-lg-3 p-md-4 mt-md-3">
                                                <div className="card cus-card">
                                                    <div className="card-video">
                                                        <video>
                                                            <source
                                                                src="./assets/images_another-ak/production_id_4201543-240p.mp4"
                                                                type="video/mp4"
                                                            />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                        <div className="cus-sec-vid">
                                                            <Link to='/learning-video-details' className='a-tag-link'><p>Creating Color Palletes</p></Link>
                                                            <span>UI UX Design</span>
                                                        </div>
                                                    </div>
                                                    <div className="card-body cus-b">
                                                        <h5 className="card-title mt-2">Progress</h5>
                                                        <div className="progress">
                                                            <div
                                                                className="progress-bar cus-bar"
                                                                role="progressbar"
                                                                style={{ width: "100%" }}
                                                                aria-valuenow={0}
                                                                aria-valuemin={0}
                                                                aria-valuemax={100}
                                                            >
                                                                <span className="progress-percent">100%</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="d-flex cus-sec-2 mt-2">
                                                                    <div className="mb-1">
                                                                        <img src="./assets/images_another-ak/timee.png" alt="" srcSet="" />
                                                                    </div>
                                                                    <div>
                                                                        <span className="ps-2">1 Hour</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="d-flex justify-content-end mt-2">
                                                                    <div className="mb-1">
                                                                        <img src="./assets/images_another-ak/Student.png" alt="" srcSet="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4  col-md-6 p-2 mt-2 p-lg-4 mt-lg-3 p-md-4 mt-md-3">
                                                <div className="card cus-card">
                                                    <div className="card-video">
                                                        <video>
                                                            <source
                                                                src="./assets/images_another-ak/production_id_4201543-240p.mp4"
                                                                type="video/mp4"
                                                            />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                        <div className="cus-sec-vid">
                                                            <Link to='/learning-video-details' className='a-tag-link'><p>Creating Mobile App Design</p></Link>
                                                            <span>UI UX Design</span>
                                                        </div>
                                                    </div>
                                                    <div className="card-body cus-b">
                                                        <h5 className="card-title mt-2">Progress</h5>
                                                        <div className="progress">
                                                            <div
                                                                className="progress-bar cus-bar"
                                                                role="progressbar"
                                                                style={{ width: "75%" }}
                                                                aria-valuenow={0}
                                                                aria-valuemin={0}
                                                                aria-valuemax={100}
                                                            >
                                                                <span className="progress-percent">75%</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="d-flex cus-sec-2 mt-2">
                                                                    <div className="mb-1">
                                                                        <img src="./assets/images_another-ak/timee.png" alt="" srcSet="" />
                                                                    </div>
                                                                    <div>
                                                                        <span className="ps-2">3 Days Left</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="d-flex justify-content-end mt-2">
                                                                    <div className="mb-1">
                                                                        <img src="./assets/images_another-ak/Student.png" alt="" srcSet="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 p-2 mt-2 p-lg-4 mt-lg-3 p-md-4 mt-md-3">
                                                <div className="card cus-card">
                                                    <div className="card-video">
                                                        <video>
                                                            <source
                                                                src="./assets/images_another-ak/production_id_4201543-240p.mp4"
                                                                type="video/mp4"
                                                            />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                        <div className="cus-sec-vid">
                                                            <Link to='/learning-video-details' className='a-tag-link'><p>Creating Perfect Website</p></Link>
                                                            <span>Web Developer</span>
                                                        </div>
                                                    </div>
                                                    <div className="card-body cus-b">
                                                        <h5 className="card-title mt-2">Progress</h5>
                                                        <div className="progress">
                                                            <div
                                                                className="progress-bar cus-bar"
                                                                role="progressbar"
                                                                style={{ width: "85%" }}
                                                                aria-valuenow={0}
                                                                aria-valuemin={0}
                                                                aria-valuemax={100}
                                                            >
                                                                <span className="progress-percent">85%</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="d-flex cus-sec-2 mt-2">
                                                                    <div className="mb-1">
                                                                        <img src="./assets/images_another-ak/timee.png" alt="" srcSet="" />
                                                                    </div>
                                                                    <div>
                                                                        <span className="ps-2">4 Days Left</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="d-flex justify-content-end mt-2">
                                                                    <div className="mb-1">
                                                                        <img src="./assets/images_another-ak/Student.png" alt="" srcSet="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 p-2 mt-2 p-lg-4 mt-lg-3 p-md-4 mt-md-3">
                                                <div className="card cus-card">
                                                    <div className="card-video">
                                                        <video>
                                                            <source
                                                                src="./assets/images_another-ak/production_id_4201543-240p.mp4"
                                                                type="video/mp4"
                                                            />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                        <div className="cus-sec-vid">
                                                            <Link to='/learning-video-details' className='a-tag-link'><p>Mobile App Design</p></Link>
                                                            <span>UI UX Design</span>
                                                        </div>
                                                    </div>
                                                    <div className="card-body cus-b">
                                                        <h5 className="card-title mt-2">Progress</h5>
                                                        <div className="progress">
                                                            <div
                                                                className="progress-bar cus-bar"
                                                                role="progressbar"
                                                                style={{ width: "65%" }}
                                                                aria-valuenow={0}
                                                                aria-valuemin={0}
                                                                aria-valuemax={100}
                                                            >
                                                                <span className="progress-percent">65%</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="d-flex cus-sec-2 mt-2">
                                                                    <div className="mb-1">
                                                                        <img src="./assets/images_another-ak/timee.png" alt="" srcSet="" />
                                                                    </div>
                                                                    <div>
                                                                        <span className="ps-2">3 Days Left</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="d-flex justify-content-end mt-2">
                                                                    <div className="mb-1">
                                                                        <img src="./assets/images_another-ak/Student.png" alt="" srcSet="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default LearningVideoContent