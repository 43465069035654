import React from 'react'
import './scss/SimilerPostedJob.css'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import Footer from '../Commons/Footer'
import SimilerPostedJobContent from './SimilerPostedJobContent'
const SimilerPostedJob = () => {
  return (
    <>
        <SimilerPostedJobContent/>
    </>
  )
}

export default SimilerPostedJob