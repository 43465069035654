import React from 'react'
import { Link } from 'react-router-dom'

const InterviewQuestions = () => {
    return (
        <section className="interview-question">
            <div className="container py-5">
                <div className="row">
                    <div className="title p3-5">
                        <h1>100 interview questions</h1>
                    </div>
                    <div className="col-lg-4 col-md-6 custm-ques-sec">
                        <img src="./assets/images/career-guide/group1.png" className='img-fluid' alt="" />
                        <h3>Immigration assistance</h3>
                        <p>Lorem ipsum dolor sit amet consectetur. Vel mattis vel quam at.</p>
                    </div>
                    <div className="col-lg-4 col-md-6 custm-ques-sec">
                        <img src="./assets/images/career-guide/group2.png" className='img-fluid' alt="" />
                        <h3>Immigration assistance</h3>
                        <p>Lorem ipsum dolor sit amet consectetur. Vel mattis vel quam at.</p>
                    </div>
                    <div className="col-lg-4 col-md-6 custm-ques-sec">
                        <img src="./assets/images/career-guide/group3.png" className='img-fluid' alt="" />
                        <h3>Immigration assistance</h3>
                        <p>Lorem ipsum dolor sit amet consectetur. Vel mattis vel quam at.</p>
                    </div>
                    <div className="col-lg-4 col-md-6 custm-ques-sec">
                        <img src="./assets/images/career-guide/group4.png" className='img-fluid' alt="" />
                        <h3>Immigration assistance</h3>
                        <p>Lorem ipsum dolor sit amet consectetur. Vel mattis vel quam at.</p>
                    </div>
                    <div className="col-lg-4 col-md-6 custm-ques-sec">
                        <img src="./assets/images/career-guide/group5.png" className='img-fluid' alt="" />
                        <h3>Immigration assistance</h3>
                        <p>Lorem ipsum dolor sit amet consectetur. Vel mattis vel quam at.</p>
                    </div>
                    <div className="col-lg-4 col-md-6 custm-ques-sec">
                        <img src="./assets/images/career-guide/group6.png" className='img-fluid' alt="" />
                        <h3>Immigration assistance</h3>
                        <p>Lorem ipsum dolor sit amet consectetur. Vel mattis vel quam at.</p>
                    </div>

                    <div className="view-btn pt-4 d-flex justify-content-center">
                        <Link to="/interview-question-listing">View All</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default InterviewQuestions