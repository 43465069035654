import React from 'react'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'
import SetPreferencesContent from './SetPreferencesContent'
import Footer from '../../Commons/Footer'

const SetPreferences = () => {
  return (
    <div>
        <RecAfterLoginNavbar/>
        <SetPreferencesContent/>
        <Footer/>
    </div>
  )
}

export default SetPreferences