import React from 'react'
import './scss/news.css'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import Footer from '../Commons/Footer'
import NewsBanner from './NewsBanner'
import Entertainment from './Entertainment'
import NewsHeader from './NewsHeader'
import NewsSports from './NewsSports'


const NewsHome = () => {
    return (
        <>
            <AfterNavbar />
            <NewsHeader />
            <NewsBanner />
            <Entertainment />
            <NewsBanner />
            <NewsSports />
            <Footer />
        </>
    )
}

export default NewsHome