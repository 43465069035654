import React, { Component } from 'react'
import CanvasJSReact from '@canvasjs/react-charts';
import { Link } from 'react-router-dom';
const ProfileChart = () => {
    var CanvasJS = CanvasJSReact.CanvasJS;
    var CanvasJSChart = CanvasJSReact.CanvasJSChart;
    CanvasJS.addColorSet("dataColor",
        [//colorSet Array

            "#f5fbff",
        ]);
    const options = {
        animationEnabled: true,

        colorSet: "dataColor",
        // type: "line",

        axisX: {
            // lineThickness: 3,
            // lineColor: "blue"
            prefix: "Day ",
            minimum: 0,
            valueFormatString: "#"
        },
        axisY: {
            gridThickness: 1,
            gridColor: "#e8e7fe",
            minimum: 0
        },

        data: [{
            type: "splineArea",
            xValueFormatString: "Day #",
            // xValueFormatString: "MMM YYYY",
            yValueFormatString: "#,##0.## bn kW⋅h",
            showInLegend: false,
            lineColor: "#08aafd",
            lineThickness: 4,
            markerColor: "#FFF",
            markerBorderColor: "#08aafd",
            markerBorderThickness: 2,
            // legendText: "kWh = one kilowatt hour",
            dataPoints: [
                { x: 0, y: 600 },
                { x: 1, y: 400 },
                { x: 2, y: 650 },
                { x: 3, y: 420 },
                { x: 4, y: 430 },
                { x: 5, y: 300 },
                { x: 6, y: 650 },
                { x: 7, y: 250 },
                // { x: new Date(2009, 0), y: 74.102 },
                // { x: new Date("2017- 01- 01"), y: 1792 },
            ]
        }]
    }
    return (
        <>
            <section className="custm-chart">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-9">

                            <div className='heading-freq mb-3'>
                                <h2>Activity Frequently</h2>
                                <small>(This Month)</small>
                            </div>
                            <div>
                                <CanvasJSChart options={options} />
                            </div>

                        </div>
                        <div className="col-lg-3">
                            <div className="chart-data">
                                <h2>Job Applying</h2>
                                <p>(This Month)</p>

                                <h1>1300</h1>

                                <h2>Response Rate</h2>
                                <p>(This Month)</p>

                                <h1>55%</h1>

                                <h2>Profile Sharing</h2>
                                <p>(This Month)</p>

                                <h1>20%</h1>
                            </div>
                        </div>
                    </div>
                    <div className='row total-counts-dv'>
                        <div className='col-lg-3 col-md-4 col-12 my-lg-0 my-md-0 my-1'>
                            <div className='total-view-count'>
                                <div className='total-cv-downloads'>
                                    <Link to=""><p>Total Resume Downloaded (11)</p></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-12 my-lg-0 my-md-0 my-1'>
                            <div className='total-view-count'>
                                <div className='total-cv-downloads'>
                                    <Link to=""><p>Total Actions (39)</p></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-12 my-lg-0 my-md-0 my-1'>
                            <div className='total-view-count'>
                                <div className='total-cv-downloads'>
                                    <Link to=""><p>Resume Viewed (20)</p></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-12 my-lg-0 my-md-0 my-1'>
                            <div className='total-view-count'>
                                <div className='total-cv-downloads'>
                                    <Link to=""><p>Contacted By Email (10)</p></Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>

    )
}

export default ProfileChart