import React from 'react'
import './scss/sponsor.css'

const SponsorJob = () => {
    return (
        <section className='sponsor py-5'>
            <div className="container">
                <div className="row">
                    <div className="head-area mb-5">
                        <h2>Why sponsor a job?</h2>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-5">
                        <div className="custm-bud-text">
                            <h4>Speed up your candidate search</h4>
                            <p>Sponsored Jobs appear more often and for longer in search results than non-sponsored jobs, so they’re more likely to be seen by potential candidates.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-5">
                        <div className="custm-bud-img">
                            <img src="./assets/images/matching-hiring/bud1.png" className='img-fluid' alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="custm-bud-img">
                            <img src="./assets/images/matching-hiring/bud3.png" className='img-fluid' alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="custm-bud-text">
                            <h4>Instantly match with talent</h4>
                            <p>See a list of candidates whose resumes on Karlatoon fit your job criteria as soon as you pay to sponsor a job.</p>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default SponsorJob