import React from 'react'
import { Link } from 'react-router-dom'

const NewsBanner = () => {
    return (
        <section className="news-banner">
            <div className="container">
                <div className="row">
                    <div className="custm-banner-area my-4">
                        <div className="col-lg-7 banner-info">
                            <div className="holy-btn">
                                <Link to={undefined}>Hollywood</Link>
                            </div>
                            <div className="banner-text">
                                <h2>Amanda Seyfried became ‘really obsessed’ with ghost stories</h2>
                                <h3>Hollywood actress Amanda Seyfried has recalled the time when she became obsessed with ghost stories</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row banner-cards mb-4">
                        <div className="col-lg-4 d-flex">
                            <div className="card-img c1">
                                <Link to={undefined}>Hollywood</Link>
                                <img src="./assets/images/news/card1.png" className='img-fluid' alt="" />
                            </div>
                            <div className="upper-title px-2">
                                <ul className='d-flex'>
                                    <li>Craig Bator - </li>
                                    <li>27 Dec 2020</li>
                                </ul>
                                <div className="para">
                                    <h3>Amanda Seyfried became ‘really obsessed’ with ghost stories</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 d-flex">
                            <div className="card-img c2">
                                <Link to={undefined}>Bollywood</Link>
                                <img src="./assets/images/news/card2.png" className='img-fluid' alt="" />
                            </div>
                            <div className="upper-title px-2">
                                <ul className='d-flex'>
                                    <li>Craig Bator - </li>
                                    <li>27 Dec 2020</li>
                                </ul>
                                <div className="para">
                                    <h3>Irrfan Khan’s Last film “ The Song of Scorpions” to release in 2021</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 d-flex">
                            <div className="card-img c3">
                                <Link to={undefined}>Entertainment</Link>
                                <img src="./assets/images/news/card3.png" className='img-fluid' alt="" />
                            </div>
                            <div className="upper-title px-2">
                                <ul className='d-flex'>
                                    <li>Craig Bator - </li>
                                    <li>27 Dec 2020</li>
                                </ul>
                                <div className="para">
                                    <h3>Apee Karim blessed with a daughter</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NewsBanner