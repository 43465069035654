import React from 'react'
import './scss/BuildYourResume.css'
const BuildResumeBannner = () => {
    return (
        <>
            <section className='build-resume-sec'>
                {/*------------ banner-section-start ----------------*/}
                <section className="bulid-section build-resume-banner mt-5">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="bulid-heading">
                                    <h3>
                                    Your career is <span>your story, </span> make it a bestseller with
                                        <span> Karlatoon’s resume</span>
                                    </h3>
                                </div>
                                <div className="bulid-para mt-4">
                                    <p>
                                        Build a resume thats piques the interest of recruiters and gets
                                        you hired. It’s fast and easy to use.
                                    </p>
                                </div>
                                <div className="bulid-btn">
                                    <button>Try for free</button>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div>
                                    <img
                                        src="./assets/build-resume/Resume-image.png"
                                        className="img-fluid display-img"
                                        alt=""
                                    />
                                </div>
                            </div>

                        </div>
                        <div className='icons-float-banner'>
                            <div className="position-section1">
                                <img src="./assets/build-resume/position-1.png" className="img-fluid" alt="" />
                            </div>
                            <div className="position-third-section1">
                                <img src="./assets/build-resume/position-3.png" className="img-fluid" alt="" />
                            </div>
                            <div className="position-second-section1">
                                <img src="./assets/build-resume/position-2.png" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default BuildResumeBannner