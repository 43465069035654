import React from 'react'
import { Link } from 'react-router-dom'
import './scss/InterViewQuestionListing.css'
const InterViewQuestionListingContent = () => {
    return (
        <>
            <section className="interview-question-listing">
                <div className="container py-5">
                    <div className="row">
                        <div className="title p3-5">
                            <h1>100 interview questions</h1>
                        </div>
                        <div className="col-lg-4 col-md-6 custm-ques-sec custm-ques-sec-bottom ">
                            <img src="./assets/images/career-guide/group1.png" className='img-fluid' alt="" />
                            <h3>Immigration assistance</h3>
                            <p>Lorem ipsum dolor sit amet consectetur. Vel mattis vel quam at.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 custm-ques-sec custm-ques-sec-bottom ">
                            <img src="./assets/images/career-guide/group2.png" className='img-fluid' alt="" />
                            <h3>Immigration assistance</h3>
                            <p>Lorem ipsum dolor sit amet consectetur. Vel mattis vel quam at.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 custm-ques-sec custm-ques-sec-bottom ">
                            <img src="./assets/images/career-guide/group3.png" className='img-fluid' alt="" />
                            <h3>Immigration assistance</h3>
                            <p>Lorem ipsum dolor sit amet consectetur. Vel mattis vel quam at.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 custm-ques-sec">
                            <img src="./assets/images/career-guide/group4.png" className='img-fluid' alt="" />
                            <h3>Immigration assistance</h3>
                            <p>Lorem ipsum dolor sit amet consectetur. Vel mattis vel quam at.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 custm-ques-sec">
                            <img src="./assets/images/career-guide/group5.png" className='img-fluid' alt="" />
                            <h3>Immigration assistance</h3>
                            <p>Lorem ipsum dolor sit amet consectetur. Vel mattis vel quam at.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 custm-ques-sec">
                            <img src="./assets/images/career-guide/group6.png" className='img-fluid' alt="" />
                            <h3>Immigration assistance</h3>
                            <p>Lorem ipsum dolor sit amet consectetur. Vel mattis vel quam at.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 custm-ques-sec custm-ques-sec-bottom ">
                            <img src="./assets/images/career-guide/group1.png" className='img-fluid' alt="" />
                            <h3>Immigration assistance</h3>
                            <p>Lorem ipsum dolor sit amet consectetur. Vel mattis vel quam at.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 custm-ques-sec custm-ques-sec-bottom ">
                            <img src="./assets/images/career-guide/group2.png" className='img-fluid' alt="" />
                            <h3>Immigration assistance</h3>
                            <p>Lorem ipsum dolor sit amet consectetur. Vel mattis vel quam at.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 custm-ques-sec custm-ques-sec-bottom ">
                            <img src="./assets/images/career-guide/group3.png" className='img-fluid' alt="" />
                            <h3>Immigration assistance</h3>
                            <p>Lorem ipsum dolor sit amet consectetur. Vel mattis vel quam at.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 custm-ques-sec">
                            <img src="./assets/images/career-guide/group4.png" className='img-fluid' alt="" />
                            <h3>Immigration assistance</h3>
                            <p>Lorem ipsum dolor sit amet consectetur. Vel mattis vel quam at.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 custm-ques-sec">
                            <img src="./assets/images/career-guide/group5.png" className='img-fluid' alt="" />
                            <h3>Immigration assistance</h3>
                            <p>Lorem ipsum dolor sit amet consectetur. Vel mattis vel quam at.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 custm-ques-sec">
                            <img src="./assets/images/career-guide/group6.png" className='img-fluid' alt="" />
                            <h3>Immigration assistance</h3>
                            <p>Lorem ipsum dolor sit amet consectetur. Vel mattis vel quam at.</p>
                        </div>

                        <div className="col-lg-12 col-md-12 col-12 mt-3">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination justify-content-center">
                                    <li class="page-item">
                                        <a class="page-link" href="#" aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                        </a>
                                    </li>
                                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                    <li class="page-item">
                                        <a class="page-link" href="#" aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default InterViewQuestionListingContent