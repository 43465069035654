import React from 'react'
import './scss/FindJob.css'
import AllJob from './AllJobs/AllJob'
import AppliedCard from './AllJobs/AppliedCard/AppliedCard'
import ShortListed from './AllJobs/ShortListed/ShortListed'
import RejectedCard from './AllJobs/RejectedCard/RejectedCard'
import SavedCard from './AllJobs/SavedCard/SavedCard'
import JobFromRecruiter from './AllJobs/JobFromRecruiter/JobFromRecruiter'
const FindJobListing = () => {
    return (
        <>
            <section className='findjoblisting-first-sec'>
                <div className='container'>
                    <div className='pill-tabs-dv'>
                        <ul className="nav nav-pills " id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-all-job-tab" data-bs-toggle="pill" data-bs-target="#pills-all-job" type="button" role="tab" aria-controls="pills-all-job" aria-selected="true"> All Job </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-applied-tab" data-bs-toggle="pill" data-bs-target="#pills-applied" type="button" role="tab" aria-controls="pills-applied" aria-selected="false"> Applied </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-short-listed-tab" data-bs-toggle="pill" data-bs-target="#pills-short-listed" type="button" role="tab" aria-controls="pills-short-listed" aria-selected="false"> Short Listed </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-rejected-tab" data-bs-toggle="pill" data-bs-target="#pills-rejected" type="button" role="tab" aria-controls="pills-rejected" aria-selected="false"> Rejected </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-saved-job-tab" data-bs-toggle="pill" data-bs-target="#pills-saved-job" type="button" role="tab" aria-controls="pills-saved-job" aria-selected="false"> Saved Job </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-job-from-recruiter-tab" data-bs-toggle="pill" data-bs-target="#pills-job-from-recruiter" type="button" role="tab" aria-controls="pills-job-from-recruiter" aria-selected="false"> Job from Recruiter </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className='findjoblisting-second-sec mb-5'>
                <div className='container'>
                    <div className='after-find-job-listing'>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-all-job" role="tabpanel" aria-labelledby="pills-all-job-tab">
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12 col-12 text-start'>
                                            <div className='job-heading'>
                                                <h2>25 Jobs</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <AllJob />
                            </div>
                            <div className="tab-pane fade" id="pills-applied" role="tabpanel" aria-labelledby="pills-applied-tab">
                                <AppliedCard />
                            </div>
                            <div className="tab-pane fade" id="pills-short-listed" role="tabpanel" aria-labelledby="pills-short-listed-tab">
                                <ShortListed />
                            </div>
                            <div className="tab-pane fade" id="pills-rejected" role="tabpanel" aria-labelledby="pills-rejected-tab">
                                <RejectedCard />
                            </div>
                            <div className="tab-pane fade" id="pills-saved-job" role="tabpanel" aria-labelledby="pills-saved-job-tab">
                                <SavedCard />
                            </div>
                            <div className="tab-pane fade" id="pills-job-from-recruiter" role="tabpanel" aria-labelledby="pills-job-from-recruiter-tab">
                                <JobFromRecruiter />
                            </div>
                        </div>
                        <section className='pagination-section'>
                            <div className='container'>
                                <nav aria-label="Page navigation example" className='nav-class'>
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                                <span class="sr-only"></span>
                                            </a>
                                        </li>
                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                        <li class="page-item"><a class="page-link" href="#">4</a></li>
                                        <li class="page-item"><a class="page-link" href="#">5</a></li>
                                        <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                                <span class="sr-only"></span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </section>
                    </div>

                </div>
            </section>

        </>
    )
}

export default FindJobListing