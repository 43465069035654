import React from 'react'
import Slider from "react-slick";
import './scss/PopularCompaniesSlider.css'
import { Link } from 'react-router-dom';
const PopularCompnaiesSlider = () => {
    const settingsPopularCompanies = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                    arrows: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false
                }
            }

        ]
    }
    return (
        <>
            <section className='popular-companies-slider-section'>
                <div className="main-section">
                    <div className="container">
                        <div className="second-section">
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='similer-post-heading'>
                                        <h2 className='ms-3'>Popular Companies</h2>
                                    </div>
                                </div>
                            </div>
                            <Slider {...settingsPopularCompanies} className='mb-5'>
                                <div className="col-lg-4">
                                    <div className="card ">
                                        <div className="d-flex">
                                            <div>
                                                <img src="./assets/career-start/card-third-1.png" alt="" />
                                            </div>
                                            <div className="footer-heading footer-icon mx-2">
                                                <div className='d-flex mb-2 align-items-start justify-content-between'>
                                                    <h3><Link to="/particular-company-homepage">HCL Technologies Ltd.</Link></h3>
                                                    <img src="./assets/career-start/bookmarksimple.png" alt="" />
                                                </div>
                                                <p>
                                                    <span>Evinquer</span>
                                                    <span>
                                                        <a href="">Start up</a>
                                                    </span>
                                                    <span className='d-flex'>
                                                        <img src="./assets/career-start/star2.png" alt="" />
                                                        <span>2.7</span>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="footer-list-card mt-2">
                                            <div>
                                                <h6>Frontend Developer (Website)</h6>
                                                <p>Our remote company is seeking a passionate person to become the lead developer on our website.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card ">
                                        <div className="d-flex">
                                            <div>
                                                <img src="./assets/career-start/card-third-2.png" alt="" />
                                            </div>
                                            <div className="footer-heading footer-icon mx-2">
                                                <div className='d-flex mb-2 align-items-start justify-content-between'>
                                                    <h3><Link to="/particular-company-homepage">Product Designer</Link></h3>
                                                    <img src="./assets/career-start/bookmarksimple.png" alt="" />
                                                </div>
                                                <p>
                                                    <span>Train Line</span>
                                                    <span>
                                                        <a href="">Start up</a>
                                                    </span>
                                                    <span className='d-flex'>
                                                        <img src="./assets/career-start/star2.png" alt="" />
                                                        <span>2.7</span>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="footer-list-card mt-2">
                                            <div>
                                                <h6>Senior Staff React Developer</h6>
                                                <p>Our remote company is seeking a passionate person to become the lead developer on our website.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card ">
                                        <div className="d-flex">
                                            <div>
                                                <img src="./assets/career-start/frame-7.png" alt="" />
                                            </div>
                                            <div className="footer-heading footer-icon mx-2">
                                                <div className='d-flex mb-2 align-items-start justify-content-between'>
                                                    <h3><Link to="/particular-company-homepage">Tech Mahindra</Link></h3>
                                                    <img src="./assets/career-start/bookmarksimple.png" alt="" />
                                                </div>
                                                <p>
                                                    <span>Evinquer</span>
                                                    <span>
                                                        <a href="">Start up</a>
                                                    </span>
                                                    <span className='d-flex'>
                                                        <img src="./assets/career-start/star2.png" alt="" />
                                                        <span>2.7</span>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="footer-list-card mt-2">
                                            <div>
                                                <h6>Frontend Developer (Website)</h6>
                                                <p>Our remote company is seeking a passionate person to become the lead developer on our website.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card ">
                                        <div className="d-flex">
                                            <div>
                                                <img src="./assets/career-start/frame-7.png" alt="" />
                                            </div>
                                            <div className="footer-heading footer-icon mx-2">
                                                <div className='d-flex mb-2 align-items-start justify-content-between'>
                                                    <h3><Link to="/particular-company-homepage">Tech Mahindra</Link></h3>
                                                    <img src="./assets/career-start/bookmarksimple.png" alt="" />
                                                </div>
                                                <p>
                                                    <span>Evinquer</span>
                                                    <span>
                                                        <a href="">Start up</a>
                                                    </span>
                                                    <span className='d-flex'>
                                                        <img src="./assets/career-start/star2.png" alt="" />
                                                        <span>2.7</span>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="footer-list-card mt-2">
                                            <div>
                                                <h6>Frontend Developer (Website)</h6>
                                                <p>Our remote company is seeking a passionate person to become the lead developer on our website.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Slider>

                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default PopularCompnaiesSlider