import React, { useState } from 'react'
import './scss/CreateAnAccount.css'
import $ from 'jquery';
import CreateAnAccountPopup from './CreateAnAccountPopup/CreateAnAccountPopup';
import { Link } from 'react-router-dom';
const CreateAnAccountContent = () => {

    const [faIcon, setfaIcon] = useState('fa-eye-slash');
    const togglePasswordType = () => {
        let input_pass1 = document.getElementById('input_pass1');
        const toggle_pass = document.getElementById("toggle_pass");
        let input_type = input_pass1.getAttribute('type');
        if (input_type == "password") {
            setfaIcon('fa-eye');
            input_pass1.type = "text";
        } else {
            setfaIcon('fa-eye-slash')
            input_pass1.type = "password"
        }
    }
    const [faIcon2, setfaIcon2] = useState('fa-eye-slash');
    const togglePasswordType2 = () => {
        let input_pass1 = document.getElementById('input_pass12');
        const toggle_pass = document.getElementById("toggle_pass2");
        let input_type = input_pass1.getAttribute('type');
        if (input_type == "password") {
            setfaIcon2('fa-eye');
            input_pass1.type = "text";
        } else {
            setfaIcon2('fa-eye-slash')
            input_pass1.type = "password"
        }
    }
    return (
        <>
            <section className='create-an-account-section'>
                <section className="main-section">
                    <div className="container">
                        <div className="second-section py-5">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="first-head">
                                        <h2>Create an account</h2>
                                        <p>It only takes a couple of minutes to get started</p>
                                    </div>
                                    <div className="icon-section">
                                        <ul>
                                            <li>
                                                <a href="">
                                                    <img src="./assets/images_another/google-icon.png" width="40px" height="40px" alt="google" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="">
                                                    <img src="./assets/images_another/phone-t.png" width="45px" height="45px" alt="phone" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="">
                                                    <img src="./assets/images_another/instagram.png" width="40px" height="40px" alt="instagram" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="">
                                                    <img src="./assets/images_another/linkedin.png" alt="linkedin" width="40px" height="40px" />
                                                </a>
                                            </li>


                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className='before-form-dv'>
                                        <form className="craete-form">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="name1" className="form-label">
                                                        First name<span className="text-danger">*</span>
                                                    </label>
                                                    <input className="form-control form-custom" id="name1" type="text" name="text" placeholder="Enter First Name" />
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="name2" className="form-label">
                                                        Last name<span className="text-danger">*</span>
                                                    </label>
                                                    <input className="form-control form-custom" id="name2" type="text" name="text" placeholder="Enter Last Name" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-10 col-md-8 col-12 mt-3 verify-icon">
                                                    <label htmlFor="phone" className="form-label">
                                                        Mobile Number<span className="text-danger">*</span>
                                                    </label>
                                                    <input type="tel" className="form-control form-custom2" id="phone" name="phone" placeholder="Mobile Number" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" />
                                                    <i class="fa-solid fa-circle-check"></i>
                                                </div>
                                                <div className="col-lg-2 col-md-4 col-12 mt-3  mt-md-5 mt-lg-5">
                                                    <div className="top-btn verified-btn">
                                                        <button type="button" className=" form-btn"  data-bs-toggle="modal"
                                                        data-bs-target="#signup-otp">
                                                            Verified
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-10 col-md-8 col-12 mt-3 verify-icon">
                                                    <label htmlFor="mail" className="form-label">
                                                        Email<span className="text-danger">*</span>
                                                    </label>
                                                    <input type="mail" className="form-control form-custom2" id="mail" name="mail" placeholder="Enter your email address" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" />
                                                    <i class="fa-solid fa-circle-check"></i>
                                                </div>
                                                <div className="col-lg-2 col-md-4 col-12 mt-3 mt-md-5 mt-lg-5">
                                                    <div className="top-btn verified-btn">
                                                        <button type="button" className="form-btn"  data-bs-toggle="modal"
                                                        data-bs-target="#signup-otp">
                                                            Verified
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mt-3 ">
                                                    <div className='password-custom cus-pass-section'>
                                                        <label htmlFor="password" className="form-label">
                                                            Password<span className="text-danger">*</span>
                                                        </label>
                                                        {/* <span toggle="#password-field" className="fa fa-fw fa-eye-slash field_icon toggle-password slash-icon" >
                                                            <span className='hide-text'>Hide</span>
                                                        </span> */}
                                                        <span toggle="#password-field" className={`fa fa-fw ${faIcon} field_icon slash-icon toggle-password`} id="toggle_pass" onClick={togglePasswordType}></span>
                                                    </div>
                                                    <div className='input-position-ab'>
                                                        <input className="form-control form-custom" type="password" name="password" placeholder="Enter your password" id="input_pass1" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <div className='password-custom'>
                                                        <label htmlFor="password2" className="form-label">
                                                            Confirm Password<span className="text-danger">*</span>
                                                        </label>
                                                        {/* <span toggle="#password-field" className="fa fa-fw fa-eye-slash field_icon toggle-password slash-icon">
                                                            <span className='hide-text'>Hide</span>
                                                        </span> */}
                                                        <span toggle="#password-field" className={`fa fa-fw ${faIcon2} field_icon slash-icon toggle-password`} id="toggle_pass" onClick={togglePasswordType2}></span>
                                                    </div>
                                                    <div className='input-position-ab'>
                                                        <input className="form-control form-custom" type="password" id="input_pass12" name="password" placeholder="Enter your password" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="date" className="form-label">
                                                        D.O.B.<span className="text-danger">*</span>
                                                    </label>
                                                    <input className="form-control form-custom" id="date" type="date" name="date" placeholder="D.O.B." />
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="City1" className="form-label">
                                                        Gender<span className="text-danger">*</span>
                                                    </label>
                                                    <select className="form-control cus-titlesal form-select w-100 form-custom" id="City1" placeholder="gender" name="city1" >
                                                        <option value="">Gender</option>
                                                        <option value={1}>Mr.</option>
                                                        <option value={2}>Mrs.</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="City2" className="form-label">
                                                        Marital Status<span className="text-danger">*</span>
                                                    </label>
                                                    <select className="form-control cus-titlesal form-select w-100 form-custom" id="City2" placeholder="city" name="city1" >
                                                        <option value="">Marital Status</option>
                                                        <option value={1}>Mr.</option>
                                                        <option value={2}>Mrs.</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="City3" className="form-label">
                                                        Select Experience Level<span className="text-danger">*</span>
                                                    </label>
                                                    <select className="form-control cus-titlesal form-select w-100 form-custom" id="City3" placeholder="city" name="city1" >
                                                        <option value="">Experience Level</option>
                                                        <option value={1}>Mr.</option>
                                                        <option value={2}>Mrs.</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="City4" className="form-label">
                                                        Select Current Designation
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <select className="form-control cus-titlesal form-select w-100 form-custom" id="City4" placeholder="city" name="city1" >
                                                        <option value="">Select Current Designation</option>
                                                        <option value={1}>Mr.</option>
                                                        <option value={2}>Mrs.</option>
                                                    </select>
                                                </div>
                                                {/* <div className="col-md-6 mt-3">
                                                    <label htmlFor="Experience" className="form-label">
                                                        Total Experience<span className="text-danger">*</span>
                                                    </label>
                                                    <input className="form-control form-custom" type="month" id="Experience" name="date" placeholder="Years" />
                                                </div> */}
                                                <div className="col-md-6 mt-3 exp-input ">
                                                    <div className='row row align-items-end'>
                                                    <div className="col-md-6">
                                                        <label htmlFor="" className='mb-2'>Total Experience</label>
                                                        <select name="" className='form-control  form-select form-custom' id="">
                                                            <option value="">Years</option>
                                                            <option value="male">1 Year</option>
                                                            <option value="female">2 Year</option>
                                                            <option value="other">3 Year</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6 mt-lg-0 mt-md-0 mt-1">
                                                        <select name="" className='form-control  form-select form-custom' id="">
                                                            <option value="">Months</option>
                                                            <option value="male">1 Year</option>
                                                            <option value="female">2 Year</option>
                                                            <option value="other">3 Year</option>
                                                        </select>
                                                    </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="Industry" className="form-label">
                                                        Select Industry<span className="text-danger">*</span>
                                                    </label>
                                                    <input className="form-control form-custom" type="text" id="Industry" name="text" placeholder="Select Industry"
                                                    />
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="job" className="form-label">
                                                        Type of Job<span className="text-danger">*</span>
                                                    </label>
                                                    <input className="form-control form-custom" type="text" id="job" name="text" placeholder="Type of Job"
                                                    />
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="City5" className="form-label">
                                                        Select Job Role<span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        className="form-control cus-titlesal form-select w-100 form-custom"
                                                        id="City5"
                                                        placeholder="city"
                                                        name="city1"
                                                    >
                                                        <option value="">Select Job Role</option>
                                                        <option value={1}>Mr.</option>
                                                        <option value={2}>Mrs.</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="City6" className="form-label">
                                                        Language Known<span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        className="form-control cus-titlesal form-select w-100 form-custom"
                                                        id="City6"
                                                        placeholder="city"
                                                        name="city1"
                                                    >
                                                        <option value="">Language Known</option>
                                                        <option value={1}>Mr.</option>
                                                        <option value={2}>Mrs.</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="address" className="form-label">
                                                        Address<span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control form-custom"
                                                        type="address"
                                                        id="address"
                                                        name="address"
                                                        placeholder="Address"
                                                    />
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="City7" className="form-label">
                                                        Country <span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        className="form-control cus-titlesal form-select w-100 form-custom"
                                                        id="City7"
                                                        placeholder="city"
                                                        name="city1"
                                                    >
                                                        <option value="">Country </option>
                                                        <option value={1}>Mr.</option>
                                                        <option value={2}>Mrs.</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="City8" className="form-label">
                                                        City/State<span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        className="form-control cus-titlesal form-select w-100 form-custom"
                                                        id="City8"
                                                        placeholder="city"
                                                        name="city1"
                                                    >
                                                        <option value="">City/State</option>
                                                        <option value={1}>Mr.</option>
                                                        <option value={2}>Mrs.</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="phone" className="form-label">
                                                        Postal Code<span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control form-custom"
                                                        type="text"
                                                        name="pin"
                                                        pattern="[0-9]{4}"
                                                        maxLength={6}
                                                        placeholder="Postal Code*"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className='checkbox-dv'>
                                                        <input type="checkbox" id="share-check" name="" className='checkbox-input' />
                                                        <label htmlFor="share-check"> Share my registration data with our content providers for marketing purposes. </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-9">
                                                    <div className='by-policy-dv'>
                                                        <p> By creating an account, you agree to the
                                                            <Link to="/terms-conditions"> Terms of Conditions </Link> and <Link to="/privacy-policy"> Privacy Policy </Link>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="top-btn">
                                                    <Link to='/personal-details' type="submit" className="form-btn2">
                                                        Sign up
                                                    </Link>
                                                </div>
                                                <p className="text-center already-account-dv">
                                                    Already have an account?
                                                    <Link to='/signin' className='login-a-tag' >Login</Link>
                                                </p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <CreateAnAccountPopup />
            </section>
        </>
    )
}

export default CreateAnAccountContent