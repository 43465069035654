import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

const AfterLoginProfile = () => {

    // const [faIcon, setfaIcon] = useState('fa-eye-slash');
    // const togglePasswordType = () => {
    //     let input_pass = document.getElementById('input_pass');
    //     const toggle_pass = document.getElementById("toggle_pass");
    //     let input_type = input_pass.getAttribute('type');
    //     if (input_type == "password") {
    //         setfaIcon('fa-eye');
    //         input_pass.type = "text";
    //     } else {
    //         setfaIcon('fa-eye-slash')
    //         input_pass.type = "password"
    //     }
    // }

    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false
                }
            }

        ]
    }
    return (
        <section className='after-login-dash'>
            <div className="container">
                <div className="row prof-main">
                    <div className="profile-head">
                        <img src="./assets/images/after-login-dash/rectangle.png" className='img-fluid' alt="" />
                        <div className="edit-prfile">
                            <button><img src="./assets/images/after-login-dash/edit.png" className='img-fluid' alt="" /> Edit Profile</button>
                        </div>
                        <div className="dots">
                            <img src="./assets/images/after-login-dash/dot.png" className='img-fluid' alt="" />
                        </div>
                    </div>
                    <div className="profile-info col-lg-12 mb-5">
                        <div className="row">
                            <div className="col-lg-2 col-md-6 col-sm-12">
                                <div className="profile-img">
                                    <img src="./assets/images/after-login-dash/Ellipse.png" className='img-fluid' alt="" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-12 mt-3">
                                <ul>
                                    <li><b>Rohit Kumar</b></li>
                                    <li>Project Manger</li>
                                    <li>Alobha Technologies</li>
                                    <li>B.Tech</li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mt-3">
                                <ul>
                                    <li><img src="./assets/images/after-login-dash/navigation.png" alt="" /> Noida U.P.<img src="./assets/images/after-login-dash/navigation.png" alt="" /></li>
                                    <li><img src="./assets/images/after-login-dash/navigation.png" alt="" /> +91-897645462 <img src="./assets/images/after-login-dash/verified.png" alt="" /></li>
                                    <li><img src="./assets/images/after-login-dash/navigation.png" alt="" /> info@alobhatechnologies <img src="./assets/images/after-login-dash/verified.png" alt="" /></li>
                                    <li>Last Update 2d ago</li>
                                </ul>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12 pro-complete p-4">
                                <div className="row">
                                    <div className="col-lg-7 sec-lft">
                                        <h3>Profile Complete</h3>
                                        <div className="row">
                                            <div className="col-lg-6 d-flex mb-3 profile-skill">
                                                <img src="./assets/images/after-login-dash/tick.png" alt="" />
                                                <div>
                                                    <p>It Skills</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 d-flex mb-3 profile-skill">
                                                <img src="./assets/images/after-login-dash/tick.png" alt="" />
                                                <div>
                                                    <p>It Skills</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 d-flex mb-3 profile-skill">
                                                <img src="./assets/images/after-login-dash/tick.png" alt="" />
                                                <div>
                                                    <p>It Skills</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 sec-rit">
                                        <div className="img">
                                            <img src="./assets/images/after-login-dash/chart.png" alt="" />
                                        </div>
                                        <div className="mission-btn">
                                            <Link to={undefined}><button>Add Missing Details</button></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row custm-pro-det">
                    <div className="col-lg-4 col-sm-4 performance">
                        <div className='rec-actn'>
                            <div className="row">
                                <div className="col-lg-12 head">
                                    <h4>Profile Performance</h4>
                                </div>
                                <div className="col-lg-6">
                                    <h5>Resume Downloaded</h5>
                                    <h2>230</h2>
                                </div>
                                <div className="col-lg-6">
                                    <h5>Recruiter Actions</h5>
                                    <h2>350</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-4 resume">
                        <div className="custm-resume">
                            <img src="./assets/images/after-login-dash/target.png" className='img-fluid' alt="" />
                            <h3>Highlight Your Resume From Others</h3>
                            <p>Our remote company is seeking a passionate person to become the lead developer on our website.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-4 apk">
                        <div className="custm-apk">
                            <div className="row">
                                <div className="col-lg-12 head">
                                    <h4>Track your activities at your smartphone now</h4>
                                </div>
                                <div className="col-lg-6 custm-qr">
                                    <img src="./assets/images/after-login-dash/qr.png" className='img-fluid' alt="" />
                                </div>
                                <div className="col-lg-6 custm-download">
                                    <img src="./assets/images/after-login-dash/store1.png" className='img-fluid' alt="" />
                                    <img src="./assets/images/after-login-dash/store2.png" className='img-fluid' alt="" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row custm-jobs">
                    <div className="col-lg-4">
                        <div className="custm-left-sec">
                            <div className="desc">
                                <img src="./assets/images/after-login-dash/vec1.png" alt="" />
                                <div className="textarea">
                                    <h5>Company</h5>
                                    <h6>Alobha Technologies Pvt. Ltd.</h6>
                                </div>
                            </div>
                            <div className="desc">
                                <img src="./assets/images/after-login-dash/vec2.png" alt="" />
                                <div className="textarea">
                                    <h5>Total Experience</h5>
                                    <h6>11 month</h6>
                                </div>
                            </div>
                            <div className="desc">
                                <img src="./assets/images/after-login-dash/vec3.png" alt="" />
                                <div className="textarea">
                                    <h5>Annual Salary</h5>
                                    <h6>3,00,000 LPA</h6>
                                </div>
                            </div>
                            <div className="desc">
                                <img src="./assets/images/after-login-dash/vec4.png" alt="" />
                                <div className="textarea">
                                    <h5>Notice Period</h5>
                                    <h6>1 Month</h6>
                                </div>
                            </div>
                            <div className="desc justify-content-between">
                                <div className='d-flex'>
                                    <img src="./assets/images/after-login-dash/vec5.png" alt="" />
                                    <div className="textarea">
                                        <h5>Recruiter View</h5>
                                    </div>
                                </div>

                                <i class="fa-solid fa-eye-slash fa-flip-horizontal pt-2"></i>
                                {/* <h6><span toggle="#password-field" className={`fa fa-fw ${faIcon} field_icon toggle-password`} id="toggle_pass" onClick={togglePasswordType}></span></h6> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="custm-right-sec">
                            <div className="head-area">
                                <h3>50 Recommended Jobs</h3>
                            </div>
                            <Slider {...settings} className='mb-4'>
                                <div className="col-lg-3">
                                    <div className="latest-job-card">
                                        <div className="company-group d-flex justify-content-between">
                                            <div className="job-role d-flex">
                                                <img className='comp-logo' src="./assets/images/after-login-dash/stock.png" />
                                                <div className="comp-text ms-3">
                                                    <h5>Slack</h5>
                                                    <h6>$10k - $25k</h6>
                                                </div>
                                            </div>
                                            <h4>5d ago</h4>
                                        </div>
                                        <div className="job-title">
                                            <h5>Sr. Product Designer</h5>
                                            <h6>Full Time - New York City</h6>
                                        </div>
                                        <div className="job-loc d-flex">
                                            <button><a href={undefined}>Remote</a></button>
                                            <button><a href={undefined}>Anywhere</a></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="latest-job-card-two">
                                        <div className="company-group d-flex justify-content-between">
                                            <div className="job-role d-flex">
                                                <img className='comp-logo' src="./assets/images/after-login-dash/google.png" />
                                                <div className="comp-text ms-3">
                                                    <h5>Google</h5>
                                                    <h6>$10k - $25k</h6>
                                                </div>
                                            </div>
                                            <h4>5d ago</h4>
                                        </div>
                                        <div className="job-title">
                                            <h5>UX Auditor</h5>
                                            <h6>Full Time - United States</h6>
                                        </div>
                                        <div className="job-loc d-flex">
                                            <button><a href={undefined}>Full Time</a></button>
                                            <button><a href={undefined}>Anywhere</a></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="latest-job-card-two">
                                        <div className="company-group d-flex justify-content-between">
                                            <div className="job-role d-flex">
                                                <img className='comp-logo' src="./assets/images/after-login-dash/indeed.png" />
                                                <div className="comp-text ms-3">
                                                    <h5>Indeed</h5>
                                                    <h6>$10k - $25k</h6>
                                                </div>
                                            </div>
                                            <h4>5d ago</h4>
                                        </div>
                                        <div className="job-title">
                                            <h5>Project Manager</h5>
                                            <h6>Full Time - Singapore</h6>
                                        </div>
                                        <div className="job-loc d-flex">
                                            <button><a href={undefined}>Full Time</a></button>
                                            <button><a href={undefined}>Anywhere</a></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="latest-job-card-two">
                                        <div className="company-group d-flex justify-content-between">
                                            <div className="job-role d-flex">
                                                <img className='comp-logo' src="./assets/images/after-login-dash/google.png" />
                                                <div className="comp-text ms-3">
                                                    <h5>Google</h5>
                                                    <h6>$10k - $25k</h6>
                                                </div>
                                            </div>
                                            <h4>5d ago</h4>
                                        </div>
                                        <div className="job-title">
                                            <h5>UX Auditor</h5>
                                            <h6>Full Time - United States</h6>
                                        </div>
                                        <div className="job-loc d-flex">
                                            <button><a href={undefined}>Full Time</a></button>
                                            <button><a href={undefined}>Anywhere</a></button>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                            <div className="show-btn d-flex justify-content-lg-start">
                                <Link to="/career-start-search"><button>Show All (12)</button></Link>
                            </div>
                        </div>
                        {/* ============Rating Area============ */}
                        <div className="rating-head mt-4 text-center">
                            <h3>How Likely are you to recommend us to a friend of family?</h3>
                            <div className='rating_scale'>
                                <label>
                                    <input type="radio" name="rad" /><span>1</span>
                                </label>
                                <label>
                                    <input type="radio" name="rad" /><span>2</span>
                                </label>
                                <label>
                                    <input type="radio" name="rad" /><span>3</span>
                                </label>
                                <label>
                                    <input type="radio" name="rad" /><span>4</span>
                                </label>
                                <label>
                                    <input type="radio" name="rad" /><span>5</span>
                                </label>
                                <label>
                                    <input type="radio" name="rad" /><span>6</span>
                                </label>
                                <label>
                                    <input type="radio" name="rad" /><span>7</span>
                                </label>
                                <label>
                                    <input type="radio" name="rad" /><span>8</span>
                                </label>
                                <label>
                                    <input type="radio" name="rad" /><span>9</span>
                                </label>
                                <label>
                                    <input type="radio" name="rad" /><span>10</span>
                                </label>
                            </div>
                            <Link to={undefined}><button>Submit</button></Link>
                        </div>
                        {/* ========Recruiters are invitingyou to apply!======== */}
                        <div className="recruiters d-flex mt-4">
                            <div className="col-lg-6 left-sec d-flex align-items-center">
                                <div className="lft-detail d-grid">
                                    <img src="./assets/images/after-login-dash/rec1.png" className='img-fluid' alt="" />
                                    <h4>Recruiters are inviting you to apply!</h4>
                                </div>
                            </div>
                            <div className="col-lg-6 p-4">
                                <div className="right-sec d-flex mb-4">
                                    <img src="./assets/images/after-login-dash/rec2.png" className='img-fluid' alt="" />
                                    <div className="text-area ms-4">
                                        <h3>Product Designer</h3>
                                        <div className="loc d-flex">
                                            <h4>Grameenphone</h4>
                                            <h5>Dhaka, Bangladesh</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="right-sec d-flex mb-4">
                                    <img src="./assets/images/after-login-dash/rec3.png" className='img-fluid' alt="" />
                                    <div className="text-area ms-4">
                                        <h3>Product Designer</h3>
                                        <div className="loc d-flex">
                                            <h4>Grameenphone</h4>
                                            <h5>Dhaka, Bangladesh</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="right-sec d-flex">
                                    <img src="./assets/images/after-login-dash/rec4.png" className='img-fluid' alt="" />
                                    <div className="text-area ms-4">
                                        <h3>Product Designer</h3>
                                        <div className="loc d-flex">
                                            <h4>Grameenphone</h4>
                                            <h5>Dhaka, Bangladesh</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ===============Famous Company============= */}
                        <div className="famous-coumpany mt-4">
                            <div className="head-area mb-4 d-flex justify-content-between align-items-center">
                                <h2>Famous Company</h2>
                                <Link to="/browse-company-by-industry"><button>Show All(12)</button></Link>
                            </div>
                            <Slider {...settings} className='mb-4'>
                                <div className="custm-fam-comp mx-2">
                                    <div className='pb-3 d-flex justify-content-between align-items-center'>
                                        <div className="custm-company-info d-flex align-items-center">
                                            <img src="./assets/images/after-login-dash/avatar.png" className='img-fluid me-3' alt="" />
                                            <h3>AgencyAnalytics</h3>
                                            <h4>• Kiev</h4>
                                        </div>
                                        <h5>New</h5>
                                    </div>
                                    <div className='comp-title'>
                                        <h2>Frontend Developer (Website)</h2>
                                        <p>Our remote company is seeking a passionate person to become the lead developer on our website.</p>
                                    </div>
                                </div>
                                <div className="custm-fam-comp mx-2">
                                    <div className='pb-3 d-flex justify-content-between align-items-center'>
                                        <div className="custm-company-info d-flex align-items-center">
                                            <img src="./assets/images/after-login-dash/avatar1.png" className='img-fluid me-3' alt="" />
                                            <h3>Clevertech</h3>
                                            <h4>• Medelin</h4>
                                        </div>
                                        <h5>1 d</h5>
                                    </div>
                                    <div className='comp-title'>
                                        <h2>Senior Staff React Developer</h2>
                                        <p>Clevertech is a leading consultancy that operates fully remote.</p>
                                    </div>
                                </div>
                                <div className="custm-fam-comp mx-2">
                                    <div className='pb-3 d-flex justify-content-between align-items-center'>
                                        <div className="custm-company-info d-flex align-items-center">
                                            <img src="./assets/images/after-login-dash/avatar.png" className='img-fluid me-3' alt="" />
                                            <h3>AgencyAnalytics</h3>
                                            <h4>• Kiev</h4>
                                        </div>
                                        <h5>New</h5>
                                    </div>
                                    <div className='comp-title'>
                                        <h2>Frontend Developer (Website)</h2>
                                        <p>Our remote company is seeking a passionate person to become the lead developer on our website.</p>
                                    </div>
                                </div>
                                <div className="custm-fam-comp mx-2">
                                    <div className='pb-3 d-flex justify-content-between align-items-center'>
                                        <div className="custm-company-info d-flex align-items-center">
                                            <img src="./assets/images/after-login-dash/avatar1.png" className='img-fluid me-3' alt="" />
                                            <h3>Clevertech</h3>
                                            <h4>• Medelin</h4>
                                        </div>
                                        <h5>1 d</h5>
                                    </div>
                                    <div className='comp-title'>
                                        <h2>Senior Staff React Developer</h2>
                                        <p>Clevertech is a leading consultancy that operates fully remote.</p>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                        {/* ===============Invite Friends================ */}
                        <div className="row invite my-4">
                            <div className="col-lg-6 mb-3">
                                <div className="custm-invite wtsp-update">
                                    <img src="./assets/images/after-login-dash/invite.png" className='img-fluid me-3' alt="" />
                                    <div className="share-txt">
                                        <h2>Get update directly on <br /> WhatsApp!</h2>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="custm-invite d-flex">
                                    <div className="col-lg-6 share-txt">
                                        <h2>Invite Friends</h2>
                                        <p>Invite your friends and earn referal bonus from us.</p>
                                        <Link to={undefined}><button>Invite</button></Link>
                                    </div>
                                    <div className="col-lg-6">
                                        <img src="./assets/images/after-login-dash/share.png" className='img-fluid me-3' alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </section>
    )
};

export default AfterLoginProfile