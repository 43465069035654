import React from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import CareerStepper from '../CareerStepper/CareerStepper'
import YourCareerCompanyDetailsCard from '../YourCareerCompanyDetailsCard/YourCareerCompanyDetailsCard'
import Footer from '../../Commons/Footer'
import CvForEmployerContent from './CvForEmployerContent'

const CvForEmployer = () => {
  return (
    <>
      <AfterNavbar/>
      <CareerStepper/>
      <YourCareerCompanyDetailsCard/>
      <CvForEmployerContent/>
      <Footer/>
    </>
  )
}

export default CvForEmployer