import { React, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './scss/AfterNavbar.css';
import './scss/AfterNavbarResponsive.css';
import { Link } from 'react-router-dom/dist';
import $ from 'jquery';
const AfterNavbar = props => {

    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    useEffect(() => {
        $('.menu-tab-btn').on("click", function () {
            $('.sidebar-menu').addClass('sidebar-menu-toggle');
        });
        $('.sidebar-menu-btn').on("click", function () {
            $('.sidebar-menu').removeClass('sidebar-menu-toggle');
        });

    }, [])
    let navigate = useNavigate();
    const logoutBtn = () => {
        let path = `/`;
        navigate(path);
    }
    return (
        <>
            <section className='after-login-sec'>
                <section className="main-header">
                    <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-lg-6 col-md-6 col-12 '>
                                    <div className='head-sec-lft'>
                                        <div className='translator d-flex align-iten-center'>
                                            <div className='flag'>
                                                <img src='assets/images/homepage/flag.png' />
                                            </div>
                                            <ul className='mx-4'>
                                                <li className="nav-item dropdown">
                                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        English
                                                    </a>
                                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                        <li><a className="dropdown-item" href="#">French</a></li>
                                                        <li><a className="dropdown-item" href="#">Spanish</a></li>
                                                        <li><a className="dropdown-item" href="#">German</a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-12'>
                                    <div className='head-sec-rit'>
                                        <div className='head-right-user'>
                                            <div className='user-name-add'>
                                                <h3>Arvind Rathor</h3>
                                                <p>Shouth Breeze Center,Banani11</p>
                                            </div>
                                            <div className='user-img'>
                                                <Link to="/seeker-login-profile"><img src='../assets/images/user-img/profile.png' className='img-fluid' /></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </section>
                <section className='header-second'>
                    <div className='container'>
                        <nav className="navbar navbar-expand-lg">
                            <div className="container-fluid">
                                <span className='menu-tab-btn'>
                                    <img src='../assets/images/menu.png' className='menu-tab' />
                                </span>
                                <Link className="navbar-brand mx-0" to="/"><img src='assets/images/logo.png' /></Link>
                                <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarsExample08">
                                    <ul className="navbar-nav align-items-center ms-auto">
                                        <li className="nav-item">
                                            <Link className="nav-link active" aria-current="page" to="/career-start-search">Find Job</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/browse-company-by-industry">Find Company</Link>
                                        </li>
                                        <li className="nav-item dropdown after-login-feature-dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Features
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><Link className="dropdown-item" to="/matching-hiring"><i className="fa-solid fa-user me-2"></i> Feature For Job Seeker</Link></li>
                                                <li><Link className="dropdown-item" to="/rec-matching-hiring-sec"><i className="fa-solid fa-user me-2"></i> Feature For Recruiter</Link></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item nav-item-custom-space">
                                        </li>

                                        <li className='nav-item mx-2'>
                                            <Link className="nav-link" to="/build-resume">CV Builder</Link>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink21" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Career Guide
                                            </Link>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink21">
                                                <li><Link className="dropdown-item" to="/career-guide"> Career Guide</Link></li>
                                                <li><Link className="dropdown-item" to="/resume-listing">Resume</Link></li>
                                                <li><Link className="dropdown-item" to="/career-guide">Cover Latter</Link></li>
                                                <li><Link className="dropdown-item" to="/career-guide">Skill Test</Link></li>
                                                <li><Link className="dropdown-item" to="/career-guide">interview</Link></li>
                                                
                                            </ul>
                                        </li>

                                        <li className='header-sec-btn2 mx-2'>
                                            <Link to='/notification-view' className='nav-link'>
                                                <img src='../assets/images/notification-bel.png' />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </section>
                <section className='sidebar-menu-parent'>
                    <div className='sidebar-menu'>
                        <div className='sidebar-menu-btn'>
                            <img src='../assets/images/sidebar-icons/cross.png' alt='' />
                            <p>Close</p>
                        </div>
                        <div className='profile-images profile-image-parent'>
                            {/* =============================================== */}
                            <div className="box">
                                <div className="shadow"></div>
                                <div className="content">
                                    <div className="percent" data-text="HTML" >
                                        <div className="dot" style={{ transform: 'rotate(calc(3.6deg * 50))' }}>
                                            <span className='profile-com-number' style={{ transform: 'rotate(calc(3.6deg * 50))' }}>50%</span>
                                        </div>
                                        <svg>
                                            <circle cx="70" cy="70" r="70"></circle>
                                            <circle cx="70" cy="70" r="70" style={{ strokeDashoffset: 'calc(440 - (440 * 50) / 100)' }}></circle>
                                        </svg>
                                        <div className='circle-img'>
                                            <img src='../assets/images/user.png' className='img-fluid' />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* =============================================== */}
                        </div>
                        <div className='user-name'>
                            <h4>Rohit Kumar (Job Seeker)</h4>
                        </div>
                        <div className='sidebar-link'>
                            <ul className='sidebar-unlist'>
                                <li>
                                    <img src='../assets/images/sidebar-icons/my-jobs.png' className='img-fluid' />
                                    <span><Link to='/find-jobs'>My Jobs</Link></span>
                                </li>
                                <li>
                                    <img src='../assets/images/sidebar-icons/my-profile.png' className='img-fluid' />
                                    <span><Link to='/my-profile'>My Profile</Link></span>
                                </li>
                                <li>
                                    <img src='../assets/images/sidebar-icons/my-subscription.png' className='img-fluid' />
                                    <span><Link to='/choose-your-plan'>Pricing</Link></span>
                                </li>
                                <li>
                                    <img src='../assets/images/sidebar-icons/my-subscription.png' className='img-fluid' />
                                    <span><Link to='/subscription-plan'>My Subscription</Link></span>
                                </li>
                                <li>
                                    <img src='../assets/images/sidebar-icons/cv-builder.png' className='img-fluid' />
                                    <span><Link to='/build-resume'>CV Builder</Link></span>
                                </li>
                                <li>
                                    <img src='../assets/images/sidebar-icons/my-profile.png' className='img-fluid' />
                                    <span><Link to='/profile-performance'>Profile Performance</Link></span>
                                </li>
                                <li>
                                    <img src='../assets/images/sidebar-icons/faq.png' className='img-fluid' />
                                    <span><Link to='/faq'>FAQ</Link></span>
                                </li>
                                <li>
                                    <img src='../assets/images/sidebar-icons/blog.png' className='img-fluid' />
                                    <span><Link to='/blogs'>Blog</Link></span>
                                </li>
                                <li>
                                    <img src='../assets/images/sidebar-icons/logout.png' className='img-fluid' />
                                    <span><a href='#' data-bs-toggle="modal" data-bs-target="#logout-modal">Logout</a></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className='logout-modal'>
                    <div className="modal fade" id="logout-modal" tabIndex={-1} aria-labelledby="exampleModalLabel"
                        aria-hidden="true" >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='modal-body-content'>
                                        <div className='modal-body-content-body'>
                                            <h3>Are you sure you want to Logout</h3>
                                        </div>
                                        <div className='modal-body-btn'>
                                            <button className='logout-cancel-btn' data-bs-dismiss="modal">Cancel</button>
                                            <Link to="/" data-bs-dismiss="modal" className='btn logout-btn' onClick={logoutBtn}>Logout</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </section >
        </>
    )
}

export default AfterNavbar