import React from 'react'
import './scss/YourCareerCompanyDetailsCard.css'
import { Link } from 'react-router-dom'
const YourCareerCompanyDetailsCard = () => {
    return (
        <>
            <section className='careercompany-card-details'>
                <section className="linear-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="bg-second">
                                    <div className="banner-section">
                                        <div className='container'>
                                            <div className="row">
                                                <div className="col-lg-1 col-md-2 col-12">
                                                    <div className="career-img">
                                                        <img
                                                            src="./assets/images_another/frame-17.png"
                                                            className="img-fluid frame-img"
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-11 col-md-10 col-12 mt-2 text-start">
                                                    <div className="company-logo-name">
                                                        <p className='company-name'>Linear company</p>
                                                        <div className="heading">
                                                            <h2>
                                                                Software Engineer{" "}
                                                                <span>
                                                                    {" "}
                                                                    <a href="">New Post</a>
                                                                </span>
                                                            </h2>
                                                        </div>
                                                        <div className="list-section">
                                                            <ul>
                                                                <li>
                                                                    <img src="./assets/images_another/mappinline.png" alt="" />
                                                                    <span> Brussels</span>
                                                                </li>
                                                                <li>
                                                                    <img src="./assets/images_another/clock.png" alt="" />
                                                                    <span> Full time</span>
                                                                </li>
                                                                <li>
                                                                    <img src="./assets/images_another/currency-dollar.png" alt="" />
                                                                    <span> 50-55k</span>
                                                                </li>
                                                                <li>
                                                                    <img src="./assets/images_another/calendarblank.png" alt="" />
                                                                    <span> 29 min ago</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="para">
                                                            <p>
                                                                Mollit in laborum tempor Lorem incididunt irure. Aute eu
                                                                ex ad sunt. Pariatur sint culpa do incididunt eiusmod
                                                                eiusmod culpa. laborum tempor Lorem incididunt.
                                                                <span>
                                                                    <Link to="/job-description-apply"> See Full Job Description</Link>
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default YourCareerCompanyDetailsCard