import React from 'react'
import './scss/ChooseYourPlan.css'
import { Link } from 'react-router-dom'
const RecChooseYourPlanContent = () => {
  return (
    <>
      <section className='rec-choose-your-plan'>
        <div className='container'>
          <div className='choose-you-plan-internal'>
            <div className='row'>
              <div className='col-lg-12 col-md-12 col-12 text-center'>
                <div className='heading-dv'>
                  <h1>Choose Your Plan</h1>
                  <div className='row'>
                    <div className='col-lg-8 col-md-8 offset-lg-2 offset-lg-2'>
                      <p>Whether you want to get organized, keep your professional life on track, or boost workplace productivity, SearchandApply.io has the right plan for you.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-lg-4 mt-md-4 mt-1 align-items-center'>
              <div className='col-lg-6 col-md-6 col-12 mt-md-0 mt-lg-0 mt-3'>
                <div className='left-side-dv'>
                  <div className='content'>
                    <h3>Basic Advertising</h3>
                    <p className='second-p'>Boost your job post with a daily or monthly budget</p>
                    <h2>$15</h2>
                    <p>Suggest per day</p>
                    <ul>
                      <li>Increases job post visibility Appear more often in of people looking for job like yours.</li>
                    </ul>
                  </div>
                  <div className='content-bottom-btn'>
                    <a href='' className='get-started'>Get Started</a>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-12 mt-md-0 mt-lg-0 mt-3'>
                <div className='right-side-dv'>
                  <div className='content'>
                    <h3>Pay per application</h3>
                    <p className='second-p'>Appear more often in of people looking for job like yours.</p>
                    <h2>$20</h2>
                    <p>Per application</p>
                    <ul>
                      <li>Increases job post visibility</li>
                      <li>Create a curated list of jobs</li>
                      <li>Job seeker reviews curated list and select jobs</li>
                      <li>Apply to selected jobs</li>
                      <li>Provide a spreadsheet of applied jobs</li>
                    </ul>
                  </div>
                  <div className='content-bottom-btn'>
                    <a href='' className='get-started'>Get Started</a>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-lg-5 mt-md-5 mt-5'>
              <div className='col-lg-4 col-md-4 offset-lg-4 offset-md-4'>
                <div className='next-button'>
                  <Link to="/rec-you-register-successfully" className='next-a'>Skip</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default RecChooseYourPlanContent