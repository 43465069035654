import React from 'react'
import RecHeader from '../../Commons/AfterLogin/RecHeader';
import Stepper from '../Stepper/Stepper';
import Footer from '../../Commons/Footer';
import ConfirmationForm from './ConfirmationForm';
import './scss/Confirmation.css'

const Confirmation = () => {
  return (
    <>
        <RecHeader/>
        <Stepper/>
        <ConfirmationForm/>
        <Footer/>
    </>
  )
}

export default Confirmation