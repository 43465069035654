import React from 'react'
import './scss/AboutUs.css'
import OurPartnerSlider from './OurPartnerSlider/OurPartnerSlider'
const AboutUsContent = () => {
    return (
        <>
            <section className='about-us-content-sec'>
                <div className="main-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="main-head">
                                    <h2>About us</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*-------------------- banner-section-start --------------*/}
                <section className="banner-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <div className="about-us-heading mt-3">
                                    <h2>Get Business Solutions with Karlatoon.</h2>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry. Lorem Ipsum has been the industry's standard dummy.
                                    </p>
                                    <button className="mx-4 get-in-touch-btn">Get in Touch</button>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="first-head">
                                    <img src="./assets/about-us/about-img.png" className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*--------------- slider-section-start -----------------------*/}
                <OurPartnerSlider />
                {/*------------------ about-section-section ---------------*/}
                <section className="about-section mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="about-second-section">
                                    <h2>About Karlatoon</h2>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry. Lorem Ipsum has been the industry's standard dummy text
                                        ever since the 1500s, Lorem Ipsum is simply dummy text of the
                                        printing and typesetting industry. Lorem Ipsum has been the
                                        industry's standard dummy text ever since the 1500s, Lorem Ipsum
                                        is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard dummy text ever since
                                        the 1500s, Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. Lorem Ipsum has been the industry's standard
                                        dummy text ever since the 1500s,
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-list-section">
                                    <ul>
                                        <li>Loren rasion gravida auem is bibenua tase</li>
                                        <li>Lorem Ipsum is simply dummy text of the.</li>
                                        <li>Printing and typesetting industry. Lorem Ipsum</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-list-section">
                                    <ul>
                                        <li>
                                            When an unknown printer took a galley of type and scrambled it
                                        </li>
                                        <li>
                                            When an unknown printer took a galley of type and scrambled it
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*--------------------------- card-section-start -----------------------------*/}
                <section className="card-section mt-5">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div>
                                            <div className="card card-custom">
                                                <div className="card-img">
                                                    <img
                                                        src="./assets/about-us/group-card-1.png"
                                                        className="img-fluid"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="card-head">
                                                    <h5>Caoching</h5>
                                                    <p>Loren rasion gravida auem is bibenua tase</p>
                                                </div>
                                            </div>
                                            <div className="card card-custom mt-5">
                                                <div className="card-img">
                                                    <img
                                                        src="./assets/about-us/group-card-3.png"
                                                        className="img-fluid"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="card-head">
                                                    <h5>SEO</h5>
                                                    <p>Loren rasion gravida auem is bibenua tase</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="card card-custom">
                                            <div className="card-img">
                                                <img
                                                    src="./assets/about-us/group-card-2.png"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="card-head">
                                                <h5>Digital Partner</h5>
                                                <p>Loren rasion gravida auem is bibenua tase</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card-second-section">
                                    <h2> Perfect Solution for Your Business</h2>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry. Lorem Ipsum has been the industry's standard dummy text
                                        ever since the 1500s, when an unknown printer took a galley of
                                        type and scrambled it to make a type specimen book. It has
                                        survived not only five centuries,
                                    </p>
                                    <button>Read More</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*------------- brand-section-start -------------------*/}
                <section className="brand-section mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="brand-top-heading">
                                    <h2>Our Leadership</h2>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry. Lorem Ipsum has been the industry's standard dummy text
                                        ever since the 1500s, when an unknown printer took a galley of
                                        type and scrambled it to make a type specimen book. It has
                                        survived not only five centuries,
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="brand-section-bg mt-5">
                            <div className="brand-section-background">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="brand-image-bg">
                                            <div>
                                                <img
                                                    src="./assets/about-us/brand-img.png"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="group-brand">
                                            <div className='group-brand-img'>
                                                <img src="./assets/about-us/group-brand-img.png" alt="" />
                                            </div>
                                            <div className="brand-last-head mx-3 mt-2">
                                                <h3>Branding</h3>
                                                <p>
                                                    Lorem Ipsum is simply dummy text of the printing and
                                                    typesetting industry. Lorem Ipsum has been the industry
                                                    standard dummy text ever since the 1500s, when an unknown
                                                    printer took a galley of type
                                                </p>
                                                <button>Read More</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*----------------- Research-section-start----------------------*/}
                <section className="research-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="research-heading">
                                    <h2>Our Research &amp; Case Studies</h2>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry. Lorem Ipsum has been the industry's standard dummy text
                                        ever since the 1500s, when an unknown printer took a galley of
                                        type and scrambled it to make a type specimen book. It has
                                        survived not only five centuries,
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="research-custom">
                                    <h3>Our commitments</h3>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry. Lorem Ipsum has been the industry standard dummy text
                                        ever since the 1500s, when an unknown printer took a galley of
                                        type Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. Lorem Ipsum has been the industry's standard
                                        dummy text ever since the 1500s, when an unknown printer took.
                                    </p>
                                    <button>Read More</button>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="reseach-second-bg">
                                    <img src="./assets/about-us/reseach-img.png" className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default AboutUsContent