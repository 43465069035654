import React from 'react'
import './scss/DescribeTheJob.css'
import { Link } from 'react-router-dom'
const DescribeTheJobContent = () => {
    return (
        <>
            <section className='describe-the-job-sec'>
                <div className='container'>
                    <div className='create-an-emp-inner'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading'>
                                        <h2>Describe the Job</h2>
                                    </div>
                                </div>
                            </div>
                            <form action='' method=''>
                                <div className='card-outer-dv'>
                                    <div className='inner-input'>
                                        <div className='inner-input mb-2 mb-md-2 mb-lg-4'>
                                            <label htmlFor='no-peaple' className='my-2'>Job Descriptions<span className='text-danger'>*</span></label>
                                            <div className='text-area-custom'>
                                                <div className='textarea-combo-dv'>
                                                    <textarea rows="10" placeholder="Enter something funny." name="text" ></textarea>
                                                    <div className='text-icons'>
                                                        <div className='type-sub-task'>
                                                            <img src='./assets/recruiter-images/post-a-job/dot.png' />
                                                            <span>|</span>
                                                            <a href='#' className='type-sub-task-a'>Type Sub Task</a>
                                                        </div>
                                                        <div className='three-icons'>
                                                            {/* <img src="./assets/recruiter-images/post-a-job/flag.png" /> */}
                                                            <label htmlFor='file-lbl'><img src="./assets/recruiter-images/post-a-job/pin.png" />
                                                            <input type="file" id="file-lbl" style={{display: "none"}} name="image" accept="image/gif,image/jpeg,image/jpg,image/png" multiple="" data-original-title="upload photos"></input>
                                                            </label>
                                                            {/* <input type='file' id='file-lbl' visibility="hidden"/> */}
                                                            {/* <img src="./assets/recruiter-images/post-a-job/watch.png" /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className='bottom-button-dv'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-12 text-end'>
                                            <div className='bottom-btn'>
                                                <Link to='/add-pay-benefits' type='submit' className='a-tag-btn1'>Back</Link>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12 text-start'>
                                            <div className='bottom-btn'>
                                                <Link to='/set-preferences' type='submit' className='a-tag-btn2'>Continue</Link>
                                            </div>
                                        </div>
                                        {/* <div className='col-lg-5 col-md-5 col-12 text-start'>
                                            <div className='bottom-btn'>
                                                <Link to='/add-pay-benefits' type='submit' className='a-tag-btn1'>Preview</Link>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DescribeTheJobContent