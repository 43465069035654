import React, { useState } from 'react';
import './scss/HeaderFooter.css';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { LinkOffRounded } from '@mui/icons-material';

const Navbar = props => {

    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
    return (
        <>
            <section className='rec-header-footer'>
                <section className='header-second'>
                    <div className='container'>
                        <nav className="navbar navbar-expand-lg">
                            <div className="container-fluid">
                                <Link className="navbar-brand mx-2" to="/"><img src='assets/images/logo.png' /></Link>
                                <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarsExample09">
                                    <ul className="navbar-nav align-items-center mx-auto">
                                        <li className="nav-item">
                                            <Link className="nav-link active" aria-current="page" to="/post-job">Find Jobs</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/browse-company-by-industry">Find Company</Link>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Features
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><Link to='/matching-hiring' className="dropdown-item"><i className="fa-solid fa-user me-2"></i>Feature for Job Seeker</Link></li>
                                                <li>
                                                <Link className="dropdown-item" to="/rec-matching-hiring-sec"><i className="fa-solid fa-user me-2"></i>Feature for Recruiter</Link></li>
                                            </ul>
                                        </li>
                                        {/* <li className="nav-item">
                                            <Link className="nav-link" to="/career-guide">Career Guide</Link>
                                        </li> */}
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink21" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Career Guide
                                            </Link>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink21">
                                            <li><Link className="dropdown-item" to="/career-guide"> Career Guide</Link></li>
                                                <li><Link className="dropdown-item" to="/resume-listing">Resume</Link></li>
                                                <li><Link className="dropdown-item" to="/career-guide">Cover Latter</Link></li>
                                                <li><Link className="dropdown-item" to="/career-guide">Skill Test</Link></li>
                                                <li><Link className="dropdown-item" to="/career-guide">interview</Link></li>
                                            </ul>
                                        </li>
                                        
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/find-your-next-great-hire">Recruiter Advice</Link>
                                        </li>
                                        <li className='header-sec-btn1 mx-2 my-md-2 my-2 my-lg-0'>
                                            <Link to="/build-resume">CV Builder</Link>
                                        </li>
                                        <li className='header-sec-btn2 mx-2 my-md-3 my-3 my-lg-0'>
                                            <Link to="/post-job">Employers / Post Job</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </section >
            </section>

        </>
    )
}

export default Navbar