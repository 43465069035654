import React from 'react'
import { Link } from 'react-router-dom'

const ConfirmationForm = () => {
    return (
        <>
            <section className='rec-confirmation-sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='form-head-dv'>
                                <form method='' action=''>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <div className='cd-heading'>
                                                <h2>Confirmations</h2>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2 my-md-2 my-lg-2'>
                                            <div className='cd-form-f form-control'>
                                                <div className='confirm-icon-content'>
                                                    <div className='icon'>
                                                        <img src='./assets/recruiter-images/confirmation/facebook.png'/>
                                                    </div>
                                                    <div className='content'> 
                                                        <a href='#' readonly>https://example.com/article/social-share-modal</a>
                                                    </div>
                                                </div>
                                                <div className='confirm-right-icon'>
                                                <img src='./assets/recruiter-images/confirmation/right.png'/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2 my-md-2 my-lg-2'>
                                            <div className='cd-form-f form-control'>
                                                <div className='confirm-icon-content'>
                                                    <div className='icon'>
                                                        <img src='./assets/recruiter-images/confirmation/linkedin.png'/>
                                                    </div>
                                                    <div className='content'> 
                                                        <a href='#' readonly>https://example.com/article/social-share-modal</a>
                                                    </div>
                                                </div>
                                                <div className='confirm-right-icon'>
                                                <img src='./assets/recruiter-images/confirmation/right.png'/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-lg-12'>
                                            <div className='checkbox-dv'>
                                                <div className='checkbox-1'>
                                                    <input type='checkbox' value='' name='' id='privacy-pol'/>
                                                    <label htmlFor='privacy-pol'> Accept all <a href='' className='a-tag-color'>Privacy & Policy</a></label>
                                                </div>
                                                <div className='checkbox-2'>
                                                    <input type='checkbox' value='' name='' id='term-con'/>
                                                    <label htmlFor='term-con'> Accept all <a href='' className='a-tag-color'>Terms & Conditions</a></label>
                                                </div>
                                            </div>
                                        </div>


                                        <div className='col-lg-12 my-2'>
                                            <div className='next-pre-btn'>
                                                <div className='prev-button'>
                                                    {/* <input type='button' value='Previous' name='' /> */}
                                                    <Link to="/document-varification" className='prev-btn'> Previous</Link>
                                                </div>
                                                <div className='next-button'>
                                                    {/* <input type='button' value='Next' name='' /> */}
                                                    <Link to="/rec-choose-your-plan" className='next-btn'> Next</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ConfirmationForm