import React from 'react'
import './scss/AddPersonalDetails.css'
import YourCareerCompanyDetailsCard from '../YourCareerCompanyDetailsCard/YourCareerCompanyDetailsCard'
import { Link } from 'react-router-dom'
const AddPersonalDetailsContent = () => {
    return (
        <>
            <section className='personal-details-section'>
                {/* <YourCareerCompanyDetailsCard/> */}
                <section className="form-section my-5 text-start">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="bg-form">
                                    <div className='container'>
                                        <div className="arror-icon">
                                            <a href="">
                                                <img src="./assets/images_another/ion_arrow-back-outline.png" alt="" /> Back
                                            </a>
                                        </div>
                                        <div className="first-head">
                                            <h2>Review Your Profile</h2>
                                        </div>
                                    </div>
                                    <div className="form-banner">
                                        <form>
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="name1" className="form-label">
                                                        First name<span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control form-custom"
                                                        id="name1"
                                                        type="text"
                                                        name="text"
                                                        placeholder="Enter first name"
                                                    />
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="name2" className="form-label">
                                                        Last name
                                                    </label>
                                                    <input
                                                        className="form-control form-custom"
                                                        id="name2"
                                                        type="text"
                                                        name="text"
                                                        placeholder="Enter last name"
                                                    />
                                                </div>
                                                <div className='col-lg-12 col-md-12 col-12 mt-3'>
                                                    <label htmlFor="phone" className="form-label">
                                                        Mobile Number<span className="text-danger">*</span>
                                                    </label>
                                                </div>
                                                <div className="col-lg-10 col-md-8 col-12 verify-icon">
                                                    <input
                                                        type="tel"
                                                        className="form-control form-custom2"
                                                        id="phone"
                                                        name="phone"
                                                        placeholder="Mobile Number"
                                                        pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                                    />
                                                    <i class="fa-solid fa-circle-check"></i>
                                                </div>
                                                <div className="col-lg-2 col-md-4 col-12">
                                                    <div className="top-btn">
                                                        <button type="submit" className="form-btn form-control">
                                                            Verified
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='col-lg-12 col-md-12 col-12 mt-3'>
                                                    <label htmlFor="mail" className="form-label">
                                                        Email<span className="text-danger">*</span>
                                                    </label>
                                                </div>
                                                <div className="col-lg-10 col-md-8 col-12 verify-icon">

                                                    <input
                                                        type="mail"
                                                        className="form-control form-custom2"
                                                        id="mail"
                                                        name="mail"
                                                        placeholder="Enter your email address"
                                                        pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                                    />
                                                    <i class="fa-solid fa-circle-check"></i>
                                                </div>
                                                <div className="col-lg-2 col-md-4 col-12">
                                                    <div className="top-btn">
                                                        <button type="submit" className="form-btn form-control">
                                                            Verified
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="address" className="form-label">
                                                        Address
                                                    </label>
                                                    <input
                                                        className="form-control form-custom"
                                                        type="address"
                                                        id="address"
                                                        name="address"
                                                        placeholder="Address"
                                                    />
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="City7" className="form-label">
                                                        Country
                                                    </label>
                                                    <select
                                                        className="form-control cus-titlesal form-select w-100 form-custom"
                                                        id="City7"
                                                        placeholder="city"
                                                        name="city1"
                                                    >
                                                        <option value="">Country</option>
                                                        <option value={1}>Mr.</option>
                                                        <option value={2}>Mrs.</option>
                                                    </select>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="top-btn top-btn-continue">
                                                        <Link type="submit" className="form-btn2" to="/cv-for-employer">
                                                            Continue
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default AddPersonalDetailsContent