import React from 'react'
import './scss/RecMySubsCriptionPlan.css'
import { Link } from 'react-router-dom'
const RecMySubsCriptionPlanContent = () => {
    return (
        <>
            <section className='rec-my-subscription-plan'>
                <section className="my-5 main-subs-plan-2">
                    <div className="main-sec-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="cus-head">
                                        <h5 className="text-center">My Subscription Plan</h5>
                                    </div>
                                    <div className="subscrip-card">
                                        <div className="cus-btn">
                                            <button className="btn">Post a Job</button>
                                        </div>
                                        <div className="sub-inner-sec">
                                            <div className="d-flex justify-content-between">
                                                <p className="cus-par-1">Pay per application</p>
                                                <p className="cus-spa-2">
                                                    Expire Date-<span>1 Year</span>
                                                </p>
                                            </div>
                                            <div>
                                                <p className="cus-p">
                                                    Appear more often in of people <br /> looking for job like
                                                    yours.
                                                </p>
                                            </div>
                                            <div>
                                                <h4>$199</h4>
                                                <p className="inner-p">Per application</p>
                                            </div>
                                            <div className="cus-list mt-2">
                                                <ul className="list-unstyled">
                                                    <li className="mb-2">Increases job post visibility</li>
                                                    <li className="mb-2">Create a curated list of jobs</li>
                                                    <li className="mb-2">
                                                        Job seeker reviews curated list and select jobs
                                                    </li>
                                                    <li className="mb-2">Apply to selected jobs</li>
                                                    <li className="mb-2">
                                                        Provide a spreadsheet of applied jobs
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </section>
        </>
    )
}

export default RecMySubsCriptionPlanContent