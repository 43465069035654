import { React, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './RecAfterLoginNavbarCSS/RecAfterLoginNavbar.css';
import './RecAfterLoginNavbarCSS/RecAfterLoginNavbarResponsive.css';
import { Link } from 'react-router-dom/dist';
import $ from 'jquery';
const RecAfterLoginNavbar = props => {

  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  useEffect(() => {
    $('.menu-tab-btn').on("click", function () {
      $('.sidebar-menu').addClass('sidebar-menu-toggle');
    });
    $('.sidebar-menu-btn').on("click", function () {
      $('.sidebar-menu').removeClass('sidebar-menu-toggle');
    });

  }, [])
  let navigate = useNavigate();
  const logoutBtn1 = () => {
    let path = `/rec-matching-hiring`;
    navigate(path);
  }
  return (
    <>
      <section className='rec-after-login-sec'>
        <section className="main-header">
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-6 col-md-12 col-12 '>
                <div className='head-sec-lft'>
                  <div className='translator d-flex align-iten-center'>
                    <div className='flag'>
                      <img src='assets/images/homepage/flag.png' />
                    </div>
                    <ul className='mx-1 mx-lg-4 mx-md-4'>
                      <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                          English
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                          <li><a className="dropdown-item" href="#">French</a></li>
                          <li><a className="dropdown-item" href="#">Spanish</a></li>
                          <li><a className="dropdown-item" href="#">German</a></li>
                        </ul>
                      </li>
                    </ul>
                    <ul className='mx-1 mx-lg-4 mx-md-4'>
                      <li className="nav-item">
                        <a className="nav-link" href="tel:+1-202-555-0178" ><img src="./assets/images/phonecall.png"/> +1-202-555-0178</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-12 col-12'>
                <div className='head-sec-rit'>
                      <div className='search-top-area-dv'>
                        <div className='recent-search-dv'>
                          <label htmlFor='recent-input'><img src="./assets/images/refresh.png" className='me-2'/> Recent Search</label>
                        </div>
                        <div className='recent-input-dv'>
                          <input type="text" placeholder='Search' className='recent-input' id='recent-input'/>
                          <Link to='' className='recent-input-atag'><img src="./assets/images/search.png"/></Link>
                        </div>
                      </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='header-second'>
          <div className='container'>
            <nav className="navbar navbar-expand-lg">
              <div className="container-fluid">
                <span className='menu-tab-btn'>
                  <img src='../assets/images/menu.png' className='menu-tab' />
                </span>
                <Link className="navbar-brand mx-0" to="/"><img src='assets/images/logo.png' /></Link>
                <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarsExample08">
                  <ul className="navbar-nav ms-auto navbar-ul-custom">
                    <li className="nav-item">
                      <Link className="nav-link active" aria-current="page" to="/post-job">Post a job</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/find-candidate">Find Candidates</Link>
                    </li>
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Features
                      </a>
                      <ul className="dropdown-menu rec-drop-down-ul" aria-labelledby="dropdownMenuButton1">
                        <li><Link to='/matching-hiring' className="dropdown-item"><i className="fa-solid fa-user me-2"></i>Feature for Job Seeker</Link></li>
                        <li>
                          <Link className="dropdown-item" to="/rec-matching-hiring-sec"><i className="fa-solid fa-user me-2"></i>Feature for Recruiter</Link></li>
                      </ul>
                    </li>
                    {/* <li className="nav-item nav-item-custom-space">
                    </li> */}

                    <li className='nav-item mx-2'>
                      <Link className="nav-link" to="/find-your-next-great-hire">Recruiter advice</Link>
                    </li>
                    <li className='nav-item mx-2'>
                      <Link className="nav-link" to="/transparent-pricing">Pricing</Link>
                    </li>
                    <li className='nav-item mx-2'>
                      <Link className="nav-link" to="/choose-subscription-plan">Subscription Plans</Link>
                    </li>
                    <li className='nav-item mx-2'>
                      <div className='after-login-nav-icon'>
                        <Link className="nav-link" to="/rec-chat">
                          <img src='./assets/navbar-icons/message.png' />
                        </Link>
                        <a className="nav-link" href="#">
                          <img src='./assets/navbar-icons/watch.png' />
                        </a>
                        <Link className="nav-link" to="/recruiter-notification">
                          <img src='./assets/navbar-icons/notification.png' />
                        </Link>
                        <Link className="nav-link" to="/rec-my-profile">
                          <img src='./assets/navbar-icons/user.png' />
                        </Link>
                      </div>
                    </li>
                    {/* <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Career Guide
                      </a>
                      <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <li><a className="dropdown-item" href="#">Action</a></li>
                        <li><a className="dropdown-item" href="#">Another action</a></li>
                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                      </ul>
                    </li> */}

                    {/* <li className='header-sec-btn2 mx-2'>
                      <a href='#' className='nav-link'>
                        <img src='../assets/images/notification-bel.png' />
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </section>
        <section className='sidebar-menu-parent'>
          <div className='sidebar-menu'>
            <div className='sidebar-menu-btn'>
              <img src='../assets/images/sidebar-icons/cross.png' alt='' />
              <p>Close</p>
            </div>
            <div className='profile-images profile-image-parent'>
              {/* <img src='../assets/images/user.png' className='img-fluid' /> */}
              {/* =============================================== */}
              <div className="box">
                <div className="shadow"></div>
                <div className="content">
                  <div className="percent" data-text="HTML" >
                    <div className="dot" style={{ transform: 'rotate(calc(3.6deg * 50))' }}>
                      <span className='profile-com-number' style={{ transform: 'rotate(calc(3.6deg * 50))' }}>50%</span>
                    </div>
                    <svg>
                      <circle cx="70" cy="70" r="70"></circle>
                      <circle cx="70" cy="70" r="70" style={{ strokeDashoffset: 'calc(440 - (440 * 50) / 100)' }}></circle>
                    </svg>
                    <div className='circle-img'>
                      <img src='../assets/images/user.png' className='img-fluid' />
                    </div>
                  </div>

                  {/* <div className="number">
                                            <h2>85<span>%</span></h2>
                                        </div> */}
                </div>
              </div>


              {/* =============================================== */}
            </div>
            <div className='user-name'>
              <h4>Rohit Kumar</h4>
            </div>
            <div className='sidebar-link'>
              <ul className='sidebar-unlist'>
                <li>
                  <img src='../assets/images/sidebar-icons/my-jobs.png' className='img-fluid' />
                  <span><Link to='/job-status'>Job Status</Link></span>
                </li>
                <li>
                  <img src='../assets/images/sidebar-icons/my-subscription.png' className='img-fluid' />
                  <span><Link to='/find-candidate'>Find Candidates</Link></span>
                </li>
                <li>
                  <img src='../assets/images/sidebar-icons/my-profile.png' className='img-fluid' />
                  <span><Link to='/search-resumes'>Search Resume</Link></span>
                </li>
                <li>
                  <img src='../assets/images/sidebar-icons/my-jobs.png' className='img-fluid' />
                  <span><a href='#'>Interviews</a></span>
                </li>
                <li>
                  <img src='../assets/images/sidebar-icons/cv-builder.png' className='img-fluid' />
                  <span><Link to='/rec-company-pro-details'>Company Details</Link></span>
                </li>
                <li>
                  <img src='../assets/images/sidebar-icons/analysis.png' className='img-fluid' />
                  <span><Link to='/analysis-chart'>Analysis</Link></span>
                </li>
                <li>
                  <img src='../assets/images/sidebar-icons/learning.png' className='img-fluid' />
                  <span><Link to='/learning-video'>Learning Video</Link></span>
                </li>
                <li>
                  <img src='../assets/images/sidebar-icons/my-subscription.png' className='img-fluid' />
                  <span><Link to='/rec-my-subscription-plan'>My Subscription Plan</Link></span>
                </li>
                <li>
                  <img src='../assets/images/sidebar-icons/contact-us.png' className='img-fluid' />
                  <span><Link to='/recruiter-contact-us'>Contact Us</Link></span>
                </li>
                <li>
                  <img src='../assets/images/sidebar-icons/logout.png' className='img-fluid' />
                  <span><a href='#' data-bs-toggle="modal" data-bs-target="#logout-modal">Logout</a></span>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className='logout-modal'>
          <div className="modal fade" id="logout-modal" tabIndex={-1} aria-labelledby="exampleModalLabel"
            aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <div className='modal-body-content'>
                    <div className='modal-body-content-body'>
                      <h3>Are you sure you want to Logout</h3>
                    </div>
                    <div className='modal-body-btn'>
                      <button className='logout-cancel-btn' data-bs-dismiss="modal">Cancel</button>
                      <button className='logout-btn' data-bs-dismiss="modal" onClick={logoutBtn1}>Logout</button>
                    </div>
                  </div>
                </div>
                {/* <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                        >
                                            Close
                                        </button>
                                        <button type="button" className="btn btn-primary">
                                            Save changes
                                        </button>
                                    </div> */}
              </div>
            </div>
          </div>

        </section>
      </section >
    </>
  )
}

export default RecAfterLoginNavbar