import React, { useState } from 'react'
import '../CvBuilder/scss/cv.css';
import '../CvBuilder/scss/cvresponsive.css';
import { Link } from 'react-router-dom';
import RegisterHeader from './CvBuilderHeader/RegisterHeader';
import Footer from '../Commons/Footer';
import Steps from './Steps';
import {
    Button,
    Stepper,
    Step,
    StepLabel,
    Typography,
    TextField,
} from '@mui/material';

// const steps = ['Personal Details', 'Educational Details', 'Professional Details', 'Project Details', 'Key Skills', 'Certifications', 'Social Links'];

const PersonalDetail = () => {



    // ========================Stepper Start=============================

    // const [activeStep, setActiveStep] = useState(0);
    // const [formData, setFormData] = useState({
    //     // Initialize your form data here
    //     step1Data: '',
    //     step2Data: '',
    //     step3Data: '',
    // });

    // const handleNext = () => {
    //     setActiveStep((prevStep) => prevStep + 1);
    // };

    // const handleBack = () => {
    //     setActiveStep((prevStep) => prevStep - 1);
    // };

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         [name]: value,
    //     }));
    // };

    // const renderStepContent = (step) => {
    //     switch (step) {
    //         case 0:
    //             return (
    //                 <TextField
    //                     label="Personal Details"
    //                     name="step1Data"
    //                     value={formData.step1Data}
    //                     onChange={handleInputChange}
    //                 />
    //             );
    //         case 1:
    //             return (
    //                 <TextField
    //                     label="Step 2 Data"
    //                     name="step2Data"
    //                     value={formData.step2Data}
    //                     onChange={handleInputChange}
    //                 />
    //             );
    //         case 2:
    //             return (
    //                 <TextField
    //                     label="Step 3 Data"
    //                     name="step3Data"
    //                     value={formData.step3Data}
    //                     onChange={handleInputChange}
    //                 />
    //             );
    //         default:
    //             return 'Unknown step';
    //     }
    // };

    // ========================Stepper End=============================


    const stepButtons = document.querySelectorAll('.step-button');
    const progress = document.querySelector('#progress');

    Array.from(stepButtons).forEach((button, index) => {
        button.addEventListener('click', () => {
            progress.setAttribute('value', index * 100 / (stepButtons.length - 1));

            stepButtons.forEach((item, secindex) => {
                if (index > secindex) {
                    item.classList.add('done');
                }
                if (index < secindex) {
                    item.classList.remove('done');
                }
            })
        })
    })

    const [faIcon, setfaIcon] = useState('fa-eye-slash');
    const togglePasswordType = () => {
        let input_pass = document.getElementById('input_pass');
        const toggle_pass = document.getElementById("toggle_pass");
        let input_type = input_pass.getAttribute('type');
        if (input_type == "password") {
            setfaIcon('fa-eye');
            input_pass.type = "text";
        } else {
            setfaIcon('fa-eye-slash')
            input_pass.type = "password"
        }
    }
    const [faIcon2, setfaIcon2] = useState('fa-eye-slash');
    const togglePasswordType2 = () => {
        let input_pass = document.getElementById('input_pass2');
        const toggle_pass = document.getElementById("toggle_pass2");
        let input_type = input_pass.getAttribute('type');
        if (input_type == "password") {
            setfaIcon2('fa-eye');
            input_pass.type = "text";
        } else {
            setfaIcon2('fa-eye-slash')
            input_pass.type = "password"
        }
    }

    return (

        <>
            <RegisterHeader />
            <section className='personal-detail'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="stepper-area">

                                <Steps activeStepCount={0} />
                            </div>
                        </div>
                        <div className="col-lg-9 my-lg-0 my-md-0 my-2">
                            <div className="form-area">
                                <div className="row justify-content-md-center justify-content-center text-center">
                                    <div className="col-lg-4"></div>
                                    <div className="col-lg-5 col-md-6 avatar">
                                        <img src="assets/images/cv-builder/avatar.png" alt="" />
                                    </div>
                                    <div className="col-lg-3 col-md-6 profile-edit-btn justify-content-center text-center">
                                        <div className="edit-btn d-flex justify-content-md-end mb-2 justify-content-center">
                                            <Link href={undefined}>Edit</Link>
                                        </div>

                                        <div className="vodeo-btn d-flex justify-content-md-end">
                                            <Link href={undefined}>Create Video Profile</Link>
                                        </div>
                                    </div>
                                </div>
                                <form action="" className='mt-5'>
                                    <div className="row">
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>First Name<span>*</span></label>
                                            <input type="text" name='name' className='form-control' placeholder='Enter first name' />
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Last Name<span>*</span></label>
                                            <input type="text" name='name' className='form-control' placeholder='Enter last name' />
                                        </div>
                                        <div className="col-md-9 mb-3 d-grid cus-verify">
                                            <label htmlFor="" className='mb-2'>Mobile Number<span>*</span></label>
                                            <input type="text" name='name' className='form-control' placeholder='Enter your mobile number' />
                                            <img src="assets/images/cv-builder/verified.png" alt="" />
                                        </div>
                                        <div className="col-md-3 mb-3 d-flex align-items-end">
                                            <Link href={undefined} className='verify-btn'>Verified</Link>
                                        </div>
                                        <div className="col-md-9 mb-3 d-grid cus-verify">
                                            <label htmlFor="" className='mb-2'>Email<span>*</span></label>
                                            <input type="text" name='name' className='form-control' placeholder='Enter your email address' />
                                            <img src="assets/images/cv-builder/verified.png" alt="" />
                                        </div>
                                        <div className="col-md-3 mb-3 d-flex align-items-end">
                                            <Link href={undefined} className='verify-btn'>Verified</Link>
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid cus-pass-section">
                                            <label htmlFor="" className='mb-2'>Password<span>*</span></label>
                                            <input type="password" name='name' className='form-control' placeholder='Enter your password' id="input_pass" />
                                            <span toggle="#password-field" className={`fa fa-fw ${faIcon} field_icon toggle-password`} id="toggle_pass" onClick={togglePasswordType}></span>
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid cus-pass-section">
                                            <label htmlFor="" className='mb-2'>Confirm Password<span>*</span></label>
                                            <input type="password" name='name' className='form-control' placeholder='Confirm your password' id="input_pass2" />
                                            <span toggle="#password-field" className={`fa fa-fw ${faIcon2} field_icon toggle-password`} id="toggle_pass" onClick={togglePasswordType2}></span>
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>D.O.B.<span>*</span></label>
                                            <input type="date" name='name' className='form-control' placeholder='Enter your password' />
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Gender<span>*</span></label>
                                            <select name="" className='form-control form-select' id="">
                                                <option value="">Gender</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="other">Other</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Marital Status</label>
                                            <select name="" className='form-control  form-select' id="">
                                                <option value="">Marital Status</option>
                                                <option value="male">Married</option>
                                                <option value="female">Unmarried</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Select Experience Level</label>
                                            <select name="" className='form-control  form-select' id="">
                                                <option value="">Experience Level</option>
                                                <option value="male">1 Year</option>
                                                <option value="female">2 Year</option>
                                                <option value="other">3 Year</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Select Current Designation</label>
                                            <input type="text" name='name' className='form-control' placeholder='Current Designation' />
                                        </div>
                                        <div className="col-md-6 mb-3 d-flex exp-input align-items-end">
                                            <div className="col-md-6">
                                                <label htmlFor="" className='mb-2'>Total Experience</label>
                                                <select name="" className='form-control  form-select' id="">
                                                    <option value="">Years</option>
                                                    <option value="male">1 Year</option>
                                                    <option value="female">2 Year</option>
                                                    <option value="other">3 Year</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 mx-lg-2 mx-md-2 mx-0 my-lg-0 my-md-0 my-2">
                                                <select name="" className='form-control  form-select' id="">
                                                    <option value="">Months</option>
                                                    <option value="male">1 Year</option>
                                                    <option value="female">2 Year</option>
                                                    <option value="other">3 Year</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Current or Last Company</label>
                                            <input type="pasword" name='name' className='form-control' placeholder='Current or Last Company' />
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Select Industry</label>
                                            <input type="text" name='name' className='form-control' placeholder='Select Industry' />
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Type of Job</label>
                                            <input type="text`" name='name' className='form-control' placeholder='Type of Job' />
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Select Job Role</label>
                                            <select name="" className='form-control form-select' id="">
                                                <option value="">Select Job Role</option>
                                                <option value="male">Web Developer</option>
                                                <option value="female">Flutter Developer</option>
                                                <option value="other">Mobile App Developer</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Language Known</label>
                                            <select name="" className='form-control form-select' id="">
                                                <option value="">Language Known</option>
                                                <option value="male">Hindi</option>
                                                <option value="female">English</option>
                                                <option value="other">French</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Address<span>*</span></label>
                                            <input type="text" name='name' className='form-control' placeholder='Address' />
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Country<span>*</span></label>
                                            <select name="" className='form-control form-select' id="">
                                                <option value="">Country</option>
                                                <option value="male">Hindi</option>
                                                <option value="female">English</option>
                                                <option value="other">French</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>City/State</label>
                                            <select name="" className='form-control form-select' id="">
                                                <option value="">City/State</option>
                                                <option value="male">Delhi</option>
                                                <option value="female">Mumbai</option>
                                                <option value="other">Haryana</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Postal Code<span>*</span></label>
                                            <input type="text" name='name' className='form-control' placeholder='Postal code' />
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor='cv-upload-input-id' className='cv-upload-input-cls'>
                                                <div className=' cv-upload mb-3 d-flex justify-content-between align-items-center'>
                                                    <div className="resume">
                                                        <h4>Upload Resume</h4>
                                                        <p>8Doc, Docx, RTF, PDF (Max file size-6MB)</p>
                                                    </div>
                                                    <div className="upload">
                                                        <input type='file' className='cv-upload-input-cus' id='cv-upload-input-id'/>
                                                        <img src="assets/images/cv-builder/upload.png" alt="" />
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-12 mb-5 text-center">
                                            <h6>If you don’t have a Resume <span><Link to={undefined}>Click Here</Link></span></h6>
                                        </div>
                                        <div className="mb-5 custm-btn-sec d-flex justify-content-center">
                                            <div className='custm-botton-btn mb-2'>
                                                <Link to='/educational-details' className='skip mx-2'>Skip</Link>
                                            </div>
                                            <div className='custm-botton-btn mb-2'>
                                                <Link to='/educational-details' className='next mx-2'>Next</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </section >

            <Footer />
        </>
    )
}

export default PersonalDetail