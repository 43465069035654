import React from 'react'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'
import Footer from '../../Commons/Footer'
import PostAJobOverviewContent from './PostAJobOverviewContent'

const PostAJobOverview = () => {
  return (
    <>
    <RecAfterLoginNavbar/>
    <PostAJobOverviewContent/>
    <Footer/>
    </>
  )
}

export default PostAJobOverview