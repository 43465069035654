import React from 'react'
import RegisterHeader from './CvBuilderHeader/RegisterHeader'
import Footer from '../Commons/Footer'
import { Link } from 'react-router-dom';
import Steps from './Steps';
import { useEffect } from 'react';

const EducationalDetails = () => {
useEffect(() => {

        document.getElementById('customFile').onchange = function() {
            var fullName = getFileName(document.getElementById('customFile').value);
            document.getElementById("change").innerHTML= fullName;
          };
          var getFileName = function(fullPath) {
            if (!fullPath) return null;
            var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
            var filename = fullPath.substring(startIndex);
            if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
              return filename.substring(1);
            }
            return null;
          }

}, [])

    return (
        <>
            <RegisterHeader />

            <section className='educational-detail'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="stepper-area">
                                <Steps activeStepCount={1} />
                            </div>
                        </div>
                        <div className="col-lg-9 my-lg-0 my-md-0 my-2">
                            <div className="form-area">
                                <div className="row align-items-center">
                                    <div className="col-lg-2 col-12 profile-back-btn">
                                        <div className="back-btn">
                                            <Link to='/personal-details'><i className="fa-solid fa-arrow-left mx-2"></i>Back</Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-6 edu-title">
                                        <h1>Educational Details</h1>
                                    </div>
                                    <div className="col-lg-2 col-6 profile-edit-btn">
                                        <div className="edit-btn">
                                            <Link href={undefined}>Edit</Link>
                                        </div>
                                    </div>
                                </div>
                                <form action="" className='mt-5'>
                                    <div className="row">
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Education Level<span>*</span></label>
                                            <select name="" className='form-control  form-select' id="">
                                                <option value="">Education Level</option>
                                                <option value="graduate">Graduate</option>
                                                <option value="pg">Post Graduate</option>
                                                <option value="diploma">Diploma</option>
                                                <option value="btech">B Tech</option>
                                                <option value="pg">MBA</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Specialization<span>*</span></label>
                                            <input type="text" name='name' className='form-control' placeholder='Specialization' />
                                        </div>
                                        <div className="col-md-12 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>University/Institute<span>*</span></label>
                                            <select name="" className='form-control  form-select' id="">
                                                <option value="">University/Institute</option>
                                                <option value="graduate">Delhi University</option>
                                                <option value="pg">MDU</option>
                                                <option value="diploma">Guru Gobind Singh University</option>
                                                <option value="btech">SGT</option>
                                                <option value="pg">LPU</option>
                                            </select>
                                        </div>
                                        <div className="col-md-12 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Address of the University/Institute</label>
                                            <input type="text" name='name' className='form-control' placeholder='Address of the University/Institute' />
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Course Type<span>*</span></label>
                                            <input type="text" name='name' className='form-control' placeholder='Course Type' />
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label  className='upload-docu-icn-cls'>
                                                <label className='mb-2'>Upload Document</label>
                                                <div className='upload-docu-icon'>
                                                    <input type="file" id='customFile' name='name' className='' placeholder='Upload Document' onchange="loadFile(event)" />
                                                    <label htmlFor="customFile" id="change" className='form-control p-3 upload-docu-label'>Upload Document  </label>
                                                    <label htmlFor="customFile" className='label-img'><img src='./assets/images/cv-builder/link.png' /></label>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-12 mb-3 d-flex check-box">
                                            <input type="checkbox" /><span><label htmlFor="" className='mx-2'>Pursuing</label></span>
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Course Duration<span>*</span></label>
                                            <input type='text' name='name' className='form-control' placeholder='Start Month & Year' onChange={(e) => console.log(e.target.value)} onFocus={(e) => (e.target.type = "date")} onBlur={(e) => (e.target.type = "text")} />
                                        </div>
                                        <div className="col-md-6 mb-3 d-flex align-items-end">
                                            <input type='text' name='name' className='form-control' placeholder='End Month & Year' onChange={(e) => console.log(e.target.value)} onFocus={(e) => (e.target.type = "date")} onBlur={(e) => (e.target.type = "text")} />
                                        </div>
                                        <div className="col-lg-12 d-flex align-items-center justify-content-end cust-add-btn">
                                            <div className="add-btn mt-3">
                                                <Link href={undefined}><i className="fa-solid fa-plus"></i> Add More</Link>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-5 mb-5 custm-btn-sec d-flex justify-content-center">
                                            <div className='custm-botton-btn mb-2'>
                                                <Link to='/personal-details' className='skip mx-2'>Back</Link>
                                            </div>
                                            <div className='custm-botton-btn mb-2'>
                                                <Link to='/professional-details' className='next mx-2'>Next</Link>
                                            </div>
                                            <div className='custm-botton-btn mb-2'>
                                                <Link to='/professional-details' className='skip mx-2'>Skip</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </section >

            <Footer />
        </>
    )
}

export default EducationalDetails