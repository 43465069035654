import React from 'react'
import './RecPostAJobBanner.css'
import { Link } from 'react-router-dom'
const RecPostAJobBanner = () => {
    return (
        <>
            <section className='rec-post-mt-hi-banner'>
                <div className="container-fluid">
                    <div className="row banner-area">
                        <div className="col-lg-6 banner-sec-lft">
                            <div className="banner-text">
                                <h1>Let’s make your next  <br /> great hire fast.</h1>
                                <p>No matter the skills, experience, or qualifications you’re looking for, you’ll find the right people on Karlatoon’s matching and hiring platform.</p>
                                <Link to="/create-employer-account">Post A Job</Link>
                            </div>
                        </div>
                        <div className="col-lg-6">
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RecPostAJobBanner