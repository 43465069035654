import React from 'react';
import Footer from '../Commons/Footer';
import Header from '../Commons/Header';
import Navbar from '../Commons/Navbar';
import RecLogin from '../SignIn/RecLogin';

const RecHomePage = () => {
  return (
    <>
        <RecLogin/>
    </>
  )
}

export default RecHomePage