import React from 'react'

const RecSearchCandidate = () => {
    return (
        <section className='rec-search-candidates py-5'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 head-area mb-4">
                        <h2>Search for candidates yourself with Karlatoon Resume    </h2>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-area">
                            <h5>Easily filter resumes by the qualifications you want, then subscribe to contact the candidates you like with Indeed Resume.</h5>
                            <form action="">
                                <label htmlFor="" className='mb-2'>What are you hiring for?</label>
                                <input type="text" className='form-control mb-3' placeholder='Job, title, skill, or company ' />
                                <label htmlFor="" className='mb-2'>What are you hiring?</label>
                                <input type="text" className='form-control mb-5' placeholder='City, state, Postal or remote' />
                                <button className='login-btn'>Login</button>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="form-img text-end">
                            <img src="./assets/images/matching-hiring/form.png" className='img-fluid' alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RecSearchCandidate