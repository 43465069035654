import React from 'react'
import './scss/CreateAnEmployerAccount.css'
import { Link } from 'react-router-dom'
const CreateAnEmployerAccountContent = () => {
    return (
        <>
            <section className='create-an-employer-ac-sec'>
                <div className='container'>
                    <div className='create-an-emp-inner'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading'>
                                        <h2>Create an employer Account</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='form-area'>
                                <form action='' method=''>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='form-inner-top'>
                                                <p>You haven’t post a job before, so you’ll need to create an employer account.</p>
                                                <a href='#'>Not here to post a job?</a>
                                            </div>
                                        </div>
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='input-label'>
                                                <label htmlFor='company-name'>Your company name</label>
                                                <input type="text" id="company-name" className='form-control' placeholder='Your company name' />
                                            </div>
                                        </div>
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='input-label'>
                                                <label htmlFor='company-no'>Your company’s number of employees</label>
                                                <select className='form-control'>
                                                    <option>Your company’s number of employees</option>
                                                    <option>Your company’s number of employees</option>
                                                    <option>Your company’s number of employees</option>
                                                    <option>Your company’s number of employees</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='input-label'>
                                                <label htmlFor='fnamelname'>Your first and last name</label>
                                                <input type="text" id="fnamelname" className='form-control' placeholder='Your first and last name' />
                                            </div>
                                        </div>
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='input-label'>
                                                <label htmlFor='phone-num'>Your phone number</label>
                                                <input type="text" id="phone-num" className='form-control' placeholder='Your phone number' />
                                            </div>
                                        </div>
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='input-label'>
                                                <label htmlFor='company-no'>How you heard about us</label>
                                                <select className='form-control'>
                                                    <option>How you heard about us</option>
                                                    <option>How you heard about us</option>
                                                    <option>How you heard about us</option>
                                                    <option>How you heard about us</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='input-label-checkbox'>
                                                <input type='checkbox' id='check-id' className='' />
                                                <label htmlFor='check-id'>By clicking this box and providing your telephone or wireless number, you agree to receive marketing and informational calls and texts from Karlatoon (including prerecorded or artificial voice messages and autodialed calls and texts) at the telephone or wireless number provided. Your agreement to this is not required to obtain any product or service.</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='bottom-button-dv'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-6 col-12 text-end'>
                                                <div className='bottom-btn'>
                                                    <Link to='/post-job' type='submit' className='a-tag-btn1'>Back</Link>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-12 text-start'>
                                                <div className='bottom-btn'>
                                                    <Link to='/like-to-post-job' type='submit' className='a-tag-btn2'>Continue</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CreateAnEmployerAccountContent