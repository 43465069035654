import React from 'react'
import "./Scss/CareerStartHereSearch.css"
const CareerStartHereSearchContent = () => {
    return (
        <>
            <section className='career-start-search-section'>
                <section className="career-section">
                    <div className="career-start-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="career-top-heading">
                                        <h2>
                                            Your <span>career</span> starts here
                                        </h2>
                                    </div>
                                    <div className="career-second-heading">
                                        <h6>
                                            Search <span> 17043</span> Job Postings in Afghanistan
                                        </h6>
                                    </div>
                                    <div>
                                        <div className="custom-form-section">
                                            <div className="search-custom">
                                                <img src="./assets/career-start/magnifyingglass.png"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control form-custom"
                                                    placeholder="Search with keywords "
                                                />
                                            </div>
                                            <div className="search-custom">
                                                <img
                                                    src="./assets/career-start/mappinline.png"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control form-custom"
                                                    placeholder="Location"
                                                />
                                            </div>
                                            <div className="search-custom">
                                                <img src="./assets/career-start/uim_bag.png" className="img-fluid" alt="" />
                                                <input
                                                    type="text"
                                                    className="form-control form-custom"
                                                    placeholder="Experience"
                                                />
                                            </div>
                                            <div className="">
                                                <button className="form-custom-btn">Search job</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default CareerStartHereSearchContent