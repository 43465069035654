import React, { useState } from 'react'

const CareerGuideNav = props => {
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
    return (
        <section className='career-guide-nav mb-4'>
            <div className="container">
                <div className="row">
                    <nav className="navbar navbar-expand-lg">
                        <a className="navbar-brand mx-3" href="#">Career Guide</a>
                        <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample06" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarsExample09">
                            <div className="navbar-nav ms-auto">
                                <a className="nav-item nav-link " href="#">All <span className="sr-only">(current)</span></a>
                                <a className="nav-item nav-link" href="#">Blogs</a>
                                <a className="nav-item nav-link" href="#">Interview Tips</a>
                                <a className="nav-item nav-link " href="#">Resume & Cover Letter</a>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </section>
    )
}

export default CareerGuideNav