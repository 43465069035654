import React from 'react'
import { Link } from 'react-router-dom'

const CareerSearch = () => {
    return (
        <section className="career-search">
            <div className="container">
                <div className="row">
                    <div className="career-title pt-5">
                        <h1>Career Guide</h1>
                        <h6>Turn your dreams into reality with the opinion of experts from industry specialists</h6>
                    </div>
                    <div className="custm-serch d-flex justify-content-center">
                        <div className="serach-input col-lg-10 my-5">
                            <img src="./assets/images/career-guide/search.png" alt="" />
                            <input type="search" className='form-control' placeholder='Career Guidance' />
                        </div>
                        <Link to={undefined} className='my-5'>Search Job</Link>
                    </div>
                    <div className="">
                        <ul className="search-list d-flex mx-auto justify-content-around ps-0">
                            <li>
                                <Link to={undefined}>Job search
                                    <img src="./assets/images/career-guide/search.png" alt="" />
                                </Link>
                            </li>
                            <li>
                                <Link to={undefined}>Resume
                                    <img src="./assets/images/career-guide/search.png" alt="" />
                                </Link>
                            </li>
                            <li>
                                <Link to={undefined}>Cover letter
                                    <img src="./assets/images/career-guide/search.png" alt="" />
                                </Link>
                            </li>
                            <li>
                                <Link to={undefined}>Career path

                                    <img src="./assets/images/career-guide/search.png" alt="" />
                                </Link>
                            </li>
                            <li>
                                <Link to={undefined}>Interviewing

                                    <img src="./assets/images/career-guide/search.png" alt="" />
                                </Link>
                            </li>
                            <li>
                                <Link to={undefined}>Career Development

                                    <img src="./assets/images/career-guide/search.png" alt="" />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default CareerSearch