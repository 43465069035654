import React from 'react'
import './scss/videoint.css'

const VideoIntegration = () => {
    return (
        <section className="videoint py-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="custm-card">
                            <h3>Video Integration</h3>
                            <ul>
                                <li>Virtual interviews between the job seekers and the recruiters</li>
                                <li> A visual communication channel that encourages to take
                                    virtual meeting without attending them in person</li>
                                <li>Video Resume Uploading</li>
                                <li> Pre-recorded interviews</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="custm-card">
                            <h3>Application Tracking & communication System</h3>
                            <ul>
                                <li>ATS is an all-inclusive system for keeping all the job applications in one place, helping you to manage, analyses, and track applications without any hassle via email & mobile notification</li>
                                <li>  The Instant Message tool enables Employers and Jobseeker to easily communicate internally once applicant accepted by a recruiter</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="custm-card">
                            <h3>Re-engagement system with candidates</h3>
                            <ul>
                                <li>Candidate Relationship Management system provide the mechanism to connect a recruiting manager with passive candidates to consider, and allows to offer automation capabilities to apply </li>
                                <li>Active Follow-Up</li>
                                <li>Single point of screening, scheduling, and re-engagement, to support hiring with passive candidates</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="custm-card">
                            <h3>Multilingual</h3>
                            <ul>
                                <li>Multi-Language Support feature to increase your brand reach towards the global job market and on world-wide placement community forum.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VideoIntegration