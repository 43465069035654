import React from 'react'
import './scss/blog-category.css'

const BlogCategory = () => {
    return (
        <section className='blog-category'>
            <div className="container">
                <div className="row">
                    <div className="search-box">
                        <h4>Search</h4>
                        <form action="">
                            <div className="custm-search">
                                <input type="search" className='form-control' placeholder='Search For Posts' />
                                <img src="./assets/images/blogs/search.png" alt="" />
                            </div>
                        </form>
                    </div>
                    <div className="category-box">
                        <h4>Categories</h4>
                        <h5>Information Technologies</h5>
                        <ul>
                            <li>Calling</li>
                            <li>NGO</li>
                        </ul>
                        <ul>
                            <li>Account</li>
                            <li>Electricians</li>
                        </ul>
                    </div>
                    <div className="recent-post">
                        <h3>Recent Post</h3>
                        <div className="custm-posts">
                            <div className="post-detail mb-4 d-flex justify-content-around">
                                <img src="./assets/images/blogs/p1.png" className='img-fluid' alt="" />
                                <div className="post-text">
                                    <h4>It is a long established fact</h4>
                                    <h5>Aug 09 2020</h5>
                                </div>
                            </div>
                            <div className="post-detail mb-4 d-flex justify-content-around">
                                <img src="./assets/images/blogs/p2.png" className='img-fluid' alt="" />
                                <div className="post-text">
                                    <h4>It is a long established fact</h4>
                                    <h5>Aug 09 2020</h5>
                                </div>
                            </div>
                            <div className="post-detail mb-4 d-flex justify-content-around">
                                <img src="./assets/images/blogs/p3.png" className='img-fluid' alt="" />
                                <div className="post-text">
                                    <h4>It is a long established fact</h4>
                                    <h5>Aug 09 2020</h5>
                                </div>
                            </div>
                            <div className="post-detail mb-4 d-flex justify-content-around">
                                <img src="./assets/images/blogs/p4.png" className='img-fluid' alt="" />
                                <div className="post-text">
                                    <h4>It is a long established fact</h4>
                                    <h5>Aug 09 2020</h5>
                                </div>
                            </div>
                            <div className="post-detail mb-4 d-flex justify-content-around">
                                <img src="./assets/images/blogs/p5.png" className='img-fluid' alt="" />
                                <div className="post-text">
                                    <h4>It is a long established fact</h4>
                                    <h5>Aug 09 2020</h5>
                                </div>
                            </div>
                            <div className="post-detail mb-4 d-flex justify-content-around">
                                <img src="./assets/images/blogs/p6.png" className='img-fluid' alt="" />
                                <div className="post-text">
                                    <h4>It is a long established fact</h4>
                                    <h5>Aug 09 2020</h5>
                                </div>
                            </div>
                            <div className="post-detail mb-4 d-flex justify-content-around">
                                <img src="./assets/images/blogs/p7.png" className='img-fluid' alt="" />
                                <div className="post-text">
                                    <h4>It is a long established fact</h4>
                                    <h5>Aug 09 2020</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="social my-5">
                        <h4>Follow</h4>
                        <div className="social-icon d-flex">
                            <img src="assets/images/blogs/f.png" className='img-fluid' alt="" />
                            <img src="assets/images/blogs/i.png" className='img-fluid' alt="" />
                            <img src="assets/images/blogs/t.png" className='img-fluid' alt="" />
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default BlogCategory