import React from 'react'
import './scss/BeforeFooterPlayStore.css'
const BeforeFooterPlayStore = () => {
    return (
        <>
            <section className='before-footer-play-sec'>
                <div className="second-bg-section mt-3">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className='mark-top'>
                                    <div className="second-bg-head mt-md-4 mt-4 mt-lg-0">
                                        <h2>Track your activities at your smartphone now</h2>
                                        <p>
                                            Download the app to manage your projects, keep track of the progress
                                            and complete tasks without procastinating. Stay on track and
                                            complete on time!
                                        </p>
                                    </div>
                                    <div className="row mt-5 align-items-center">
                                        <div className="col-lg-8 col-md-8 col-12">
                                            <div className='get-the-app-heading'>
                                                <h4>Get the App</h4>
                                            </div>
                                            <div className="play-app-stor-btns">
                                                <a className='goole-play-img'>
                                                    <img src="./assets/images_another/google-play.png" alt="" />
                                                </a>
                                                <a className="mx-3 app-store-dv">
                                                    <img src="./assets/images_another/app-store.png" alt="" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-12">
                                            <div className='qr-code-dv'>
                                                <img src="./assets/images_another/qr-code.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-12">
                                <div className='before-footer-rght-img'>
                                    <img src="./assets/images_another/footer-img.png" className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default BeforeFooterPlayStore