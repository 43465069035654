import './App.css';
import { BrowserRouter as Router, Routes, Navigate, Route, Link } from 'react-router-dom';
import Home from './Component/Seeker/Home/Home';
import PersonalDetail from './Component/Seeker/CvBuilder/PersonalDetail';
import EducationalDetails from './Component/Seeker/CvBuilder/EducationalDetails';
import ProfessionalDetail from './Component/Seeker/CvBuilder/ProfessionalDetail';
import ProjectDetail from './Component/Seeker/CvBuilder/ProjectDetail';
import KeySkills from './Component/Seeker/CvBuilder/KeySkills';
import Certification from './Component/Seeker/CvBuilder/Certification';
import SocialMedia from './Component/Seeker/CvBuilder/SocialMedia';
import AfterLoginHome from './Component/Seeker/AfterLoginProfile/AfterLoginHome';
import FindJob from './Component/Seeker/SidebarMenuPages/MyJob/FindJob';
import YouRegistereredSucJobSearch from './Component/Seeker/YouhaveRegisteredSuccessfullyJobSearch/YouRegistereredSucJobSearch';
import YouRegisterSucHomeReturn from './Component/Seeker/YouRegisterSucHomeReturn/YouRegisterSucHomeReturn';
import ChooseYourPlan from './Component/Seeker/ChooseYourPlan/ChooseYourPlan';
import ContactUs from './Component/Seeker/ContactUs/ContactUs';
import YourCareerStartHere from './Component/Seeker/YourCareerStartHere/YourCareerStartHere';
import CvForEmployer from './Component/Seeker/YourCareerStartHere/CvForEmployer/CvForEmployer';
import AnswerQuestionsFromEmployer from './Component/Seeker/YourCareerStartHere/AnswerQuestionsFromEmployer/AnswerQuestionsFromEmployer';
import ResumeShowScreen from './Component/Seeker/YourCareerStartHere/ResumeShowScreen/ResumeShowScreen';
import SubmittedSuccessfullyReturnToJob from './Component/Seeker/YourCareerStartHere/SubmittedSuccessfullyReturnToJob/SubmittedSuccessfullyReturnToJob';
import JobFindDescription from './Component/Seeker/JobFindDescription/JobFindDescription';
import BlogHome from './Component/Seeker/BlogPage/BlogList/BlogHome';
import BlogDetail from './Component/Seeker/BlogPage/BlogDetail/BlogDetail';
import FeaturesHome from './Component/Seeker/MatchingAndHiring/FeaturesHome';
import RecruiterAdvice from './Component/Seeker/RecruiterAdvice/RecruiterAdvice';
import CareerGuideHome from './Component/Seeker/CareerGuide/CareerGuideHome';
import CreateAnAccount from './Component/Seeker/CreateAnAccount/CreateAnAccount';
import SubscriptionPlan from './Component/Seeker/SubscriptionPlan/SubscriptionPlan';
import SignIn from './Component/Seeker/SignIn/SignIn';
import NewsHome from './Component/Seeker/News/NewsHome';
import CompanyListingPages from './Component/Seeker/ParticularCompanyPages/CompanyListingPages/CompanyListingPages';
import ProfilePerformanceHome from './Component/Seeker/ProfilePerformance/ProfilePerformanceHome';
import ImpactOfTechnology from './Component/Seeker/ImpactOfTechnology/ImpactOfTechnology';
import QuestionAnswer from './Component/Seeker/QuestionAnswer/QuestionAnswer';
import MyProfile from './Component/Seeker/MyProfile/MyProfile';
import ResumeBuilder from './Component/Seeker/ResumeBuilder/ResumeBuilder';
import CareerStartHereSearch from './Component/Seeker/YourCareerStartHere/CareerStartHereSearch/CareerStartHereSearch';
import ParticularCompanyHomePage from './Component/Seeker/ParticularCompanyPages/ParticularCompanyHomePage/ParticularCompanyHomePage';
import FindRightPlacesCompanyListing from './Component/Seeker/FindRightPlacesCompanies/FindRightPlacesCompanyListing/FindRightPlacesCompanyListing';
import HorizontalStepper from './Component/Seeker/HorizontalStepper/HorizontalStepper';
import CareerSearchResultListingAndDetails from './Component/Seeker/YourCareerStartHere/CareerSearchResultListingAndDetails/CareerSearchResultListingAndDetails';
import NotificationView from './Component/Seeker/NotificationView/NotificationView';
import BrowseCompaniesbyIndustry from './Component/Seeker/FindRightPlacesCompanies/BrowseCompaniesbyIndustry/BrowseCompaniesbyIndustry';
import Faq from './Component/Seeker/Faq/Faq';
import AboutUs from './Component/Seeker/AboutUs/AboutUs';
import BuildYourResume from './Component/Seeker/BuildYourResume/BuildYourResume';
import CareerStepper from './Component/Seeker/YourCareerStartHere/CareerStepper/CareerStepper';
import AddPersonalDetails from './Component/Seeker/YourCareerStartHere/AddPersonalDetails/AddPersonalDetails';

// ------------------------Recruiter Area Start-------------------------
import RecHomePage from './Component/Recruiter/RecruiterHome/RecHomePage';
// import CvBuilderHome from './Component/Seeker/CvBuilder/CvBuilderHome';
import CompanyDetails from './Component/Recruiter/FormFillingStpr/CompanyDetails/CompanyDetails';
import DocumentVarification from './Component/Recruiter/FormFillingStpr/DocumentVarification/DocumentVarification';
import Confirmation from './Component/Recruiter/FormFillingStpr/Confirmation/Confirmation';
import RecHome from './Component/Recruiter/Home/RecHome';
import RecChooseYourPlan from './Component/Recruiter/FormFillingStpr/ChooseYourPlan/RecChooseYourPlan';
import YouHaveRegisteredSuccessfully from './Component/Recruiter/FormFillingStpr/YouHaveRegisteredSuccessfully/YouHaveRegisteredSuccessfully';
import RegisterPopup from './Component/Recruiter/FormFillingStpr/YouHaveRegisteredSuccessfully/RegisterPopup';
import RecMatchingHiringHome from './Component/Recruiter/RecMatchingHiring/RecMatchingHiringHome';
import RecMatchingHiringSecHome from './Component/Recruiter/RecMatchingHiringSec/RecMatchingHiringSecHome';
import RecPostAJob from './Component/Recruiter/RecPostAJob/RecPostAJob';
import CreateAnEmployerAccount from './Component/Recruiter/RecPostAJob/CreateAnEmployerAccount/CreateAnEmployerAccount';
import LikeToPostYourJob from './Component/Recruiter/RecPostAJob/LikeToPostYourJob/LikeToPostYourJob';
import AddJobBasics from './Component/Recruiter/RecPostAJob/AddJobBasics/AddJobBasics';
import PlannedStartDateThisJob from './Component/Recruiter/RecPostAJob/PlannedStartDateThisJob/PlannedStartDateThisJob';
import AddJobDetails from './Component/Recruiter/RecPostAJob/AddJobDetails/AddJobDetails';
import AddPayAndBenefits from './Component/Recruiter/RecPostAJob/AddPayAndBenefits/AddPayAndBenefits';
import DescribeTheJob from './Component/Recruiter/RecPostAJob/DescribeTheJob/DescribeTheJob';
import SetPreferences from './Component/Recruiter/RecPostAJob/SetPreferences/SetPreferences';
import MakeITDealBreaker from './Component/Recruiter/RecPostAJob/MakeITDealBreaker/MakeITDealBreaker';
import PostAJobOverview from './Component/Recruiter/RecPostAJob/PostAJobOverview/PostAJobOverview';
import GetYourJobFrontMorePeople from './Component/Recruiter/RecPostAJob/GetYourJobFrontMorePeople/GetYourJobFrontMorePeople';
import SponsorYourJobwithFreeCredit from './Component/Recruiter/RecPostAJob/SponsorYourJobwithFreeCredit/SponsorYourJobwithFreeCredit';
import FreeJobPostCanOnlyGetApplications from './Component/Recruiter/RecPostAJob/FreeJobPostCanOnlyGetApplications/FreeJobPostCanOnlyGetApplications';
import RecruiterNotification from './Component/Recruiter/RecruiterNotification/RecruiterNotification';
import RecruiterContactUs from './Component/Recruiter/RecruiterContactUs/RecruiterContactUs';
import TransparentPricingHome from './Component/Recruiter/TransparentPricing/TransparentPricingHome';
import RecMyProfile from './Component/Recruiter/RecMyProfile/RecMyProfile';
import RecCandidateProfile from './Component/Recruiter/RecCandidateProfile/RecCandidateProfile';
import JobsStatus from './Component/Recruiter/JobsStatus/JobsStatus';
import RecTheImpactTechnology from './Component/Recruiter/RecTheImpactTechnology/RecTheImpactTechnology';
import FindYourNextGreatHireHome from './Component/Recruiter/FindYourNextGreatHire/FindYourNextGreatHireHome';
import AnalysisChartHome from './Component/Recruiter/AnalysisChart/AnalysisChartHome';
import SearchResumesHome from './Component/Recruiter/SearchResumes/SearchResumesHome';
import SearchCandidateDetailHome from './Component/Recruiter/SearchResumes/SearchCandidateDetail/SearchCandidateDetailHome';
import FindCandidates from './Component/Recruiter/FindCandidates/FindCandidates';
import CreateQuestionPopup from './Component/Recruiter/RecPostAJob/MakeITDealBreaker/CreateQuestionPopup/CreateQuestionPopup';
import SavedSearchHome from './Component/Recruiter/SearchResumes/SavedSearch/SavedSearchHome';
import ProjectsSearchHome from './Component/Recruiter/SearchResumes/ProjectsSearch/ProjectsSearchHome';
import ChooseYourSubscriptionPlan from './Component/Recruiter/ChooseYourSubscriptionPlan/ChooseYourSubscriptionPlan';
import LearningVideo from './Component/Recruiter/LearningVideo/LearningVideo';
import LearningVideoDetails from './Component/Recruiter/LearningVideo/LearningVideoDetails/LearningVideoDetails';
import RecMySubsCriptionPlan from './Component/Recruiter/RecMySubsCriptionPlan/RecMySubsCriptionPlan';
import RecChat from './Component/Recruiter/RecChat/RecChat';
import RecCompanyProfileDetails from './Component/Recruiter/RecCompanyProfileDetails/RecCompanyProfileDetails';
import CareerTestsOurTop from './Component/Seeker/CareerGuide/CareerTestsOurTop/CareerTestsOurTop';
import FeatureBlogListing from './Component/Seeker/CareerGuide/FeatureBlogListing/FeatureBlogListing';
import InterViewQuestionListing from './Component/Seeker/CareerGuide/InterViewQuestionListing/InterViewQuestionListing';
import ResumeListing from './Component/Seeker/CareerGuide/ResumeListing/ResumeListing';
import TermsAndCondition from './Component/Seeker/OurPolicies/TermsAndCondition/TermsAndCondition';
import PrivacyPolicy from './Component/Seeker/OurPolicies/PrivacyPolicy/PrivacyPolicy';
import AssessmentQuestion from './Component/Seeker/YourCareerStartHere/AssessmentScreens/AssessmentQuestion/AssessmentQuestion';
import AssessmentScore from './Component/Seeker/YourCareerStartHere/AssessmentScreens/AssessmentScore/AssessmentScore';
import AssessmentAllQuestionTime from './Component/Seeker/YourCareerStartHere/AssessmentScreens/AssessmentAllQuestionTime/AssessmentAllQuestionTime';
import RecForgetPassword from './Component/Recruiter/RecForgetPassword/RecForgetPassword';
import OtpSendEnter from './Component/Recruiter/RecForgetPassword/OtpSendEnter/OtpSendEnter';
import EnterNewPassWord from './Component/Recruiter/RecForgetPassword/EnterNewPassWord/EnterNewPassWord';
import RateAndReview from './Component/Seeker/RateAndReview/RateAndReview';

// import Homebanner from './Component/Seeker/Home/Home';
function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path='/' element={<Home />}></Route>
          <Route exact path='/personal-details' element={<PersonalDetail />}></Route>
          <Route exact path='/educational-details' element={<EducationalDetails />}></Route>
          <Route exact path='/professional-details' element={<ProfessionalDetail />}></Route>
          <Route exact path='/project-details' element={<ProjectDetail />}></Route>
          <Route exact path='/key-skills' element={<KeySkills />}></Route>
          <Route exact path='/certifications' element={<Certification />}></Route>
          <Route exact path='/social-media' element={<SocialMedia />}></Route>
          <Route exact path='/seeker-login-profile' element={<AfterLoginHome />}></Route>
          <Route exact path='/find-jobs' element={<FindJob />}></Route>
          <Route exact path='/you-have-registred-successfully' element={<YouRegistereredSucJobSearch />}></Route>
          <Route exact path='/you-have-registred-successfully-home-return' element={<YouRegisterSucHomeReturn />}></Route>
          <Route exact path='/choose-your-plan' element={<ChooseYourPlan />}></Route>
          <Route exact path='/contact-us' element={<ContactUs />}></Route>
          <Route exact path='/your-career-start-here' element={<YourCareerStartHere />}></Route>
          <Route exact path='/cv-for-employer' element={<CvForEmployer />}></Route>
          <Route exact path='/answer-questions-from-employer' element={<AnswerQuestionsFromEmployer />}></Route>
          <Route exact path='/resume-show-screen' element={<ResumeShowScreen />}></Route>
          <Route exact path='/register-successfully' element={<SubmittedSuccessfullyReturnToJob />}></Route>
          <Route exact path='/job-find-description' element={<JobFindDescription />}></Route>
          <Route exact path='/blogs' element={<BlogHome />}></Route>
          <Route exact path='/blog-detail' element={<BlogDetail />}></Route>
          <Route exact path='/matching-hiring' element={<FeaturesHome />}></Route>
          <Route exact path='/recruiter-advice' element={<RecruiterAdvice />}></Route>
          <Route exact path='/career-guide' element={<CareerGuideHome />}></Route>
          <Route exact path='/create-an-account' element={<CreateAnAccount />}></Route>
          <Route exact path='/subscription-plan' element={<SubscriptionPlan />}></Route>
          <Route exact path='/signin' element={<SignIn />}></Route>
          <Route exact path='/news' element={<NewsHome />}></Route>
          <Route exact path='/company-listing' element={<CompanyListingPages />}></Route>
          <Route exact path='/profile-performance' element={<ProfilePerformanceHome />}></Route>
          <Route exact path='/impact-technology' element={<ImpactOfTechnology />}></Route>
          <Route exact path='/my-profile' element={<MyProfile />}></Route>
          {/* <Route exact path='/question-answer' element={<QuestionAnswer />}></Route> */}
          <Route exact path='/resume-builder' element={<ResumeBuilder />}></Route>
          <Route exact path='/career-start-search' element={<CareerStartHereSearch />}></Route>
          <Route exact path='/particular-company-homepage' element={<ParticularCompanyHomePage />}></Route>
          <Route exact path='/find-right-company' element={<FindRightPlacesCompanyListing />}></Route>
          <Route exact path='/top-step' element={<HorizontalStepper />}></Route>
          <Route exact path='/career-result-description' element={<CareerSearchResultListingAndDetails />}></Route>
          <Route exact path='/notification-view' element={<NotificationView />}></Route>
          <Route exact path='/browse-company-by-industry' element={<BrowseCompaniesbyIndustry />}></Route>
          <Route exact path='/faq' element={<Faq />}></Route>
          <Route exact path='/about-us' element={<AboutUs />}></Route>
          <Route exact path='/build-resume' element={<BuildYourResume />}></Route>
          <Route exact path='/career-stepper' element={<CareerStepper />}></Route>
          <Route exact path='/add-personal-details' element={<AddPersonalDetails />}></Route>
          <Route exact path='career-test-listing' element={<CareerTestsOurTop/>} ></Route>
          <Route exact path='/feature-blog-listing' element={<FeatureBlogListing />}></Route>
          <Route exact path='/interview-question-listing' element={<InterViewQuestionListing />}></Route>
          <Route exact path='/resume-listing' element={<ResumeListing />}></Route>
          <Route exact path='/terms-conditions' element={<TermsAndCondition />}></Route>
          <Route exact path='/privacy-policy' element={<PrivacyPolicy />}></Route>
          <Route exact path='/assessment-questions' element={<AssessmentQuestion />}></Route>
          <Route exact path='/assessment-score' element={<AssessmentScore />}></Route>
          <Route exact path='/assessment-all-question' element={<AssessmentAllQuestionTime />}></Route>
          <Route exact path='/rate-and-review' element={<RateAndReview />}></Route>
          <Route path="*" element={<Navigate to="/" />} />
          {/* // ------------------------Recruiter Area Start------------------------- */}
          <Route exact path='/recruiter-login' element={<RecHomePage />}></Route>
          {/* <Route exact path='/cv-register' element={<CvBuilderHome />}></Route> */}
          <Route exact path='/company-details' element={<CompanyDetails />}></Route>
          <Route exact path='/document-varification' element={<DocumentVarification />}></Route>
          <Route exact path='/confirmation' element={<Confirmation />}></Route>
          <Route exact path='/rec-homepage' element={<RecHome />}></Route>
          <Route exact path='/rec-choose-your-plan' element={<RecChooseYourPlan />}></Route>
          <Route exact path='/rec-you-register-successfully' element={<YouHaveRegisteredSuccessfully />}></Route>
          <Route exact path='/rec-matching-hiring' element={<RecMatchingHiringHome />}></Route>
          <Route exact path='/rec-matching-hiring-sec' element={<RecMatchingHiringSecHome />}></Route>
          <Route exact path='/post-job' element={<RecPostAJob />}></Route>
          <Route exact path='/create-employer-account' element={<CreateAnEmployerAccount />}></Route>
          <Route exact path='/like-to-post-job' element={<LikeToPostYourJob />}></Route>
          <Route exact path='/add-job-basics' element={<AddJobBasics />}></Route>
          <Route exact path='/planned-start-date-job' element={<PlannedStartDateThisJob />}></Route>
          <Route exact path='/add-job-details' element={<AddJobDetails />}></Route>
          <Route exact path='/add-pay-benefits' element={<AddPayAndBenefits />}></Route>
          <Route exact path='/describe-the-job' element={<DescribeTheJob />}></Route>
          <Route exact path='/set-preferences' element={<SetPreferences />}></Route>
          <Route exact path='/make-it-deal-breaker' element={<MakeITDealBreaker />}></Route>
          <Route exact path='/post-a-job-overview' element={<PostAJobOverview />}></Route>
          <Route exact path='/get-job-front-people' element={<GetYourJobFrontMorePeople />}></Route>
          <Route exact path='/sponsor-job-with-free-credit' element={<SponsorYourJobwithFreeCredit />}></Route>
          <Route exact path='/free-job-post-get-application' element={<FreeJobPostCanOnlyGetApplications />}></Route>
          <Route exact path='/recruiter-notification' element={<RecruiterNotification />}></Route>
          <Route exact path='/recruiter-contact-us' element={<RecruiterContactUs />}></Route>
          <Route exact path='/transparent-pricing' element={<TransparentPricingHome />}></Route>
          <Route exact path='/rec-my-profile' element={<RecMyProfile />}></Route>
          <Route exact path='/rec-candidate-profile' element={<RecCandidateProfile />}></Route>
          <Route exact path='/job-status' element={<JobsStatus />}></Route>
          <Route exact path='/rec-impact-technology' element={<RecTheImpactTechnology />}></Route>
          <Route exact path='/find-your-next-great-hire' element={<FindYourNextGreatHireHome />}></Route>
          <Route exact path='/analysis-chart' element={<AnalysisChartHome />}></Route>
          <Route exact path='/search-resumes' element={<SearchResumesHome />}></Route>
          <Route exact path='/search-candidate-details' element={<SearchCandidateDetailHome />}></Route>
          <Route exact path='/saved-search' element={<SavedSearchHome />}></Route>
          <Route exact path='/find-candidate' element={<FindCandidates />}></Route>
          <Route exact path='/projects-search' element={<ProjectsSearchHome />}></Route>
          <Route exact path='/create-question-popup' element={<CreateQuestionPopup />}></Route>
          <Route exact path='/choose-subscription-plan' element={<ChooseYourSubscriptionPlan />}></Route>
          <Route exact path='/learning-video' element={<LearningVideo />}></Route>
          <Route exact path='/learning-video-details' element={<LearningVideoDetails />}></Route>
          <Route exact path='/rec-my-subscription-plan' element={<RecMySubsCriptionPlan />}></Route>
          <Route exact path='/rec-chat' element={<RecChat />}></Route>
          <Route exact path='/rec-company-pro-details' element={<RecCompanyProfileDetails />}></Route>
          <Route exact path='/rec-forget-password' element={<RecForgetPassword />}></Route>
          <Route exact path='/rec-otp-enter' element={<OtpSendEnter />}></Route>
          <Route exact path='/rec-enter-new-password' element={<EnterNewPassWord />}></Route>

        </Routes>
      </div>
    </Router>
  );
}

export default App;
