import React from 'react'
import Footer from '../Commons/Footer';
import Header from '../Commons/Header';
import Navbar from '../Commons/Navbar';
import RecLoginContent from './RecLoginContent';
import RegisterHeader from '../../Seeker/CvBuilder/CvBuilderHeader/RegisterHeader';
import RecHeader from '../Commons/AfterLogin/RecHeader';
const RecLogin = () => {
  return (
    <>
        {/* <Header/>
        <Navbar/> */}
        <RecHeader />
        <RecLoginContent/>
        <Footer/>
    </>
  )
}

export default RecLogin