import React from 'react'
import './scss/Alljob.css'
import JobFromRecruiter from './JobFromRecruiter/JobFromRecruiter'
import AppliedCard from './AppliedCard/AppliedCard'
import SavedCard from './SavedCard/SavedCard'
import ShortListed from './ShortListed/ShortListed'
import RejectedCard from './RejectedCard/RejectedCard'
const AllJob = () => {
  return (
    <>
        <JobFromRecruiter/>
        <AppliedCard/>
        <SavedCard/>
        <ShortListed/>
        <RejectedCard/>
    </>
  )
}

export default AllJob