import React from 'react'
import Header from '../Commons/Header'
import Navbar from '../Commons/Navbar'
import Footer from '../Commons/Footer'
import SponsorJob from '../RecMatchingHiringSec/SponsorJob/SponsorJob'
import HelpHire from './HelpHire/HelpHire'
import RecPostAJobBanner from './RecPostAJobBanner'
import WhySponsorAJob from './WhySponsorAJob/WhySponsorAJob'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'

const RecPostAJob = () => {
    return (
        <>
            <RecAfterLoginNavbar/>
            <RecPostAJobBanner/>
            <HelpHire />
            <SponsorJob />
            <WhySponsorAJob />
            <Footer />
        </>
    )
}

export default RecPostAJob