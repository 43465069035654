import React from 'react'
import '../scss/OurPolicies.css'
const TermsAndConditionContent = () => {
    return (
        <>
            <section className='terms-condition-sec'>
                <div className='container'>
                    <div className='term-condi-child-dv'>
                        <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12 text-center'>
                                <div className='top-heading'>
                                    <h2>Terms and Conditions</h2>
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className='last-updated'>
                                    <p>Last updated: February 16, 2023</p>
                                </div>
                                <div className='after-last-updated-content'>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                </div>
                                <div className='list-items'>
                                    <div className='list-items-heading'>
                                        <h5>1. Lorem Ipsum</h5>
                                        <ul>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='list-items'>
                                    <div className='list-items-heading'>
                                        <h5>2. Lorem Ipsum</h5>
                                        <ul>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='list-items'>
                                    <div className='list-items-heading'>
                                        <h5>3. Lorem Ipsum</h5>
                                        <ul>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='list-items'>
                                    <div className='list-items-heading'>
                                        <h5>4. Lorem Ipsum</h5>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TermsAndConditionContent