import React from 'react'
import { Link } from 'react-router-dom'

const LeadingRecruitingSoftware = () => {
    return (
        <>
            <section className="recruiting-software py-5">
                <div className="container">
                    <div className="row mb-5">
                        <div className="heading mb-3">
                            <h1>Hire with the World’s Leading Recruiting Software.</h1>
                            <p>Karlatoon’s all-in-one recruiting software helps you find the best candidates and turn them into employees.</p>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="custm-bud-img">
                                <img src="./assets/images/matching-hiring/rec-adv.png" className='img-fluid' alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="custm-bud-text pt-4">
                                <h4>A) Create your free account</h4>
                                <p>All you need is your email address & cellphone number to create an account and start building your job post.</p>

                                <h4>B) Build your job post</h4>
                                <p>Then just add a title, description, and location to your job post, and you're ready to go.</p>

                                <h4>C) Post your job</h4>
                                <p>Once you posted your job we will send your job post to millions of job seekers to reach you and our advance tools will help you to find dream talent.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="heading mb-3">
                            <h1>Helping you Hire From Start to Finish</h1>
                            <p>Karlatoon’s all-in-one recruiting software helps you find the best candidates and turn them into employees.</p>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="custm-bud-text pt-2">
                                <h4>A) Tools to help you hire faster.</h4>
                                <p>Spend less time searching for suitable candidates and more time connecting with potential hires.</p>

                                <h4>B) Your dashboard does it all.</h4>
                                <p>As soon as you post your first job, your dashboard lets you track, message, invite, and interview all from one place.</p>

                                <h4>C) Flexible budget options</h4>
                                <p>With no upfront fees or subscriptions, you have the flexibility to adjust your recruiting as hiring needs change.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="custm-bud-img">
                                <img src="./assets/images/matching-hiring/image-1.png" className='img-fluid' alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="heading mb-3">
                            <h1>Source and attract top talent</h1>
                            <p>Karlatoon’s all-in-one recruiting software helps you find the best candidates and turn them into employees.</p>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="custm-bud-img">
                                <img src="./assets/images/matching-hiring/image-2.png" className='img-fluid' alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="custm-bud-text">
                                <h4>One-click posting to 1 million + candidates and popular sites</h4>
                                <p>Get your job in front of millions of candidates & top sites. All with a single click. When you submit a job, Karlatoon automatically posts it to 1 million + users to ensure it shows up wherever people search for work, including Google, Indeed, LinkedIn and more. As applications come in, we collect and organize them, then add them to Karlatoon pipeline. So you can review candidates from hundreds of job sites, all in one place.</p>

                                <h4>Built-in passive candidate sourcing</h4>
                                <p>Search 1 million + candidate profiles to find exactly who you’re looking for.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="heading mb-3">
                            <h1>Karlatoon delivers a modern candidate experience</h1>
                            <p>Karlatoon’s all-in-one recruiting software helps you find the best candidates and turn them into employees.</p>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="custm-bud-text">
                                <h4>Customizable careers pages</h4>
                                <p>Boost your employer brand in just a few clicks with Karlatoon’s branded careers page builder. <Link to={undefined}>Read More...</Link></p>

                                <h4>Mobile-friendly application forms</h4>
                                <p>Make it easy to apply with one-click applications, mobile optimization and resume auto-fill.</p>

                                <h4>Language options</h4>
                                <p>Reach candidates in Persian (Dari) & Pashto languages with templates and careers pages. <Link to={undefined}>Read More...</Link></p>

                                <h4>Interview self-scheduling</h4>
                                <p>Let your candidates schedule a time that works best with Karlatoon’s self-schedule link.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="custm-bud-img">
                                <img src="./assets/images/matching-hiring/image-3.png" className='img-fluid' alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="custm-bud-img">
                                <img src="./assets/images/matching-hiring/rec-adv.png" className='img-fluid' alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="custm-bud-text">
                                <h4>Make the right hiring decisions</h4>
                                <p>Karlatoon will help to guide your hiring teams through fair and objective evaluations so you can mitigate bias, evaluate objectively and move the best candidates forward. <Link to={undefined}>Read More...</Link></p>

                                <h4>Video interviews & kits</h4>
                                <p>Screen at scale with one to one video interviews and live video integrations and Implement structured hiring processes for fair and consistent evaluations.</p>

                                <h4>Save time by interviewing seamlessly on Karlatoon</h4>
                                <p>Built for live virtual interviewing, our platform provides a frictionless experience for candidates, interviewers and recruiters alike.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-lg-4 col-md-6">
                            <div className="custm-vdo-sec">
                                <img src="./assets/images/matching-hiring/v1.png" className='img-fluid' alt="" />
                                <img src="./assets/images/matching-hiring/play.png" className='img-fluid' alt="" />
                                <h4><Link to="/rec-impact-technology">Flexible interview options</Link></h4>
                                <p>With no apps to download or links to manage, you can interview virtually or over the phone. Conduct multiple interviews and seamlessly add more recruitment managers to interview candidates.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="custm-vdo-sec">
                                <img src="./assets/images/matching-hiring/v2.png" className='img-fluid' alt="" />
                                <img src="./assets/images/matching-hiring/play.png" className='img-fluid' alt="" />
                                <h4><Link to="/rec-impact-technology">Candidate Assessments</Link></h4>
                                <p>Filter & shortlist candidates and save time to find your qualified applicant with automatic screening application system and gauge the aptitude of your candidate pool with built-in cognitive and personality tests</p>
                                <p>• Narrow down application pool, without slipping top candidates from the list</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="custm-vdo-sec">
                                <img src="./assets/images/matching-hiring/v3.png" className='img-fluid' alt="" />
                                <img src="./assets/images/matching-hiring/play.png" className='img-fluid' alt="" />
                                <h4><Link to="/rec-impact-technology">Screening questions</Link></h4>
                                <p>Create a list of general questions to analyze candidate’s eligibility. Your job post targeted to people who match your requirements and you will be notified of applicant who pass your screening questions.</p>
                                <p>• Failed candidates will receive an automatically rejected message via email</p>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="heading mb-3">
                            <h1>Hire at scale</h1>
                            <p>Karlatoon automates time consuming tasks and streamlines hiring from requisition to offer letter.</p>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="custm-bud-text">
                                <h4>Hiring plan</h4>
                                <p>Plan, track and manage your hiring and budget in one centralized workspace. <Link to={undefined}>Read More...</Link></p>

                                <h4>Karlatoon’s subscription plans</h4>
                                <p>Display Karlatoon’s subscription plan poster with a link to direct recruiter at Karlatoon’s pricing & subscription pages</p>

                                <h4>Video tutorials for recruiters</h4>
                                <p>Display tutorial video clips here.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="custm-bud-img">
                                <img src="./assets/images/matching-hiring/image-3.png" className='img-fluid' alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="heading mb-4">
                            <h1>Helping your hire from start to finish</h1>
                        </div>
                        <div className="col-lg-4">
                            <div className="help-hire-sec">
                                <h4>Tools to help you hire faster</h4>
                                <p>Spend less time searching for suitable candidates and more time connecting with potential hires.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="help-hire-sec">
                                <h4>Your dashboard does it all</h4>
                                <p>As soon as you post your first job, your dashboard lets you track, message, invite, and interview all from one place.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="help-hire-sec">
                                <h4>Flexible budget options</h4>
                                <p>With no upfront fees or subscriptions, you have the flexibility to adjust your recruiting as hiring needs change.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default LeadingRecruitingSoftware