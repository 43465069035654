import React from 'react'
import './scss/YouHaveRegisteredSuccessfully.css'
const RecYouRegisterButton = () => {
    return (
        <>
            <div className="btn-cus">
                <div className="custom-btn d-flex">
                    <button className="banner-btn">Create Post</button>
                </div>
            </div>
        </>
    )
}

export default RecYouRegisterButton