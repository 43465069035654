import React from 'react'
import './scss/JobFromRecruiter.css'
import { Link } from 'react-router-dom'
const JobFromRecruiter = () => {
    return (
        <>
            <section className='alljob-section'>
                <div className='container'>
                    <div className='job-from-rec'>
                        <div className='right-logo-flex'>
                            <div className='company-logo'>
                                <img src='./assets/images/find-jobs/company1.png' className='img-fluid company-logo-img' />
                            </div>
                            <div className='company-content'>
                                <p>Linear company</p>
                                <div className='post-cls'>
                                    <h4><Link to="/job-find-description">Software Engineer</Link></h4>
                                    <h6>New post</h6>
                                </div>
                                <div className='icon-working-show'>
                                    <ul>
                                        <li><img src='./assets/images/find-jobs/mappinline.png' className='img-fluid icon-working-img' /><span>Brussels</span></li>
                                        <li><img src='./assets/images/find-jobs/clock.png' className='img-fluid icon-working-img' /><span>Full time</span></li>
                                        <li><img src='./assets/images/find-jobs/currencydollar.png' className='img-fluid icon-working-img' /><span>50-55k</span></li>
                                        <li><img src='./assets/images/find-jobs/calendarblank.png' className='img-fluid icon-working-img' /><span>29 min ago</span></li>
                                    </ul>
                                </div>
                                <div className='last-para-content'>
                                    <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt. Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum tempor Lorem incididunt.</p>
                                </div>
                            </div>
                        </div>
                        <div className='left-btn-flex'>
                            <button className='left-btn'>Job From Recruiter</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default JobFromRecruiter