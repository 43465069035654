import React from 'react'
import { Link } from 'react-router-dom';
import BlogCategory from '../BlogCategory/BlogCategory';
import Footer from '../../Commons/Footer';
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar';

const BlogDetail = () => {
    return (
        <>
            <AfterNavbar />
            <section className='blog-list mt-4'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="blog-head text-center">
                            <h1>Blog Detail</h1>
                        </div>
                        <div className="row blog-info pt-5">
                            <div className="col-lg-9 col-md-7">
                                <div className="blog-card mb-5">
                                    <img src="./assets/images/blogs/blog1.png" className='img-fluid' alt="" />
                                    <div className="blog-category mt-3 d-flex justify-content-between">
                                        <div className='d-flex'>
                                            <div className="category d-flex align-items-center">
                                                <img src="./assets/images/blogs/pen.png" className='img-fluid' alt="" />
                                                <h5>Surf Auxion</h5>
                                            </div>
                                            <div className="date mx-3 d-flex align-items-center">
                                                <img src="./assets/images/blogs/calen.png" className='img-fluid' alt="" />
                                                <h5>Aug 09 2020</h5>
                                            </div>
                                        </div>
                                        <div className="like">
                                            <img src="./assets/images/blogs/like.png" alt="" />
                                            <img src="./assets/images/blogs/dis.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="blog-title py-3">
                                        <h2>Mauris at orci non vulputate diam tincidunt nec.</h2>
                                    </div>
                                    <div className="blog-para">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit facilisis quis auctor pretium ipsum, eu rutrum. Condimentum eu malesuada vitae ultrices in in neque, porta dignissim. Adipiscing purus, cursus vulputate id id dictum at.</p>
                                    </div>
                                    <div className="blog-para2 py-4">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit dapibus est, nunc, montes, lacus consequat integer viverra. Sit morbi etiam quam rhoncus. Velit in arcu platea donec vitae ante posuere malesuada.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit dapibus est, nunc, montes, lacus consequat integer viverra. Sit morbi etiam quam rhoncus. Velit in arcu platea donec vitae ante posuere malesuada.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit dapibus est, nunc,</p>
                                    </div>
                                    <div className="blog-para3 py-4">
                                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Commodo dictum sapien, amet, consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Commodo dictum sapien, amet, consequat toamk risusu”</p>
                                    </div>
                                </div>
                                <div className="blog-media row">
                                    <div className="col-lg-6">
                                        <div className="media-box1">
                                            <video src="movie.mp4" className='w-100' type="video/mp4" width="395" height="231" controls poster="./assets/images/blogs/m2.png"></video>
                                            <img src="./assets/images/blogs/play.png" className='img-fluid' alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="media-box2">
                                            <img src="./assets/images/blogs/m1.png" className='img-fluid' alt="" />
                                        </div>
                                    </div>
                                    <div className="textarea py-4">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit dapibus est, nunc, montes, lacus consequat integer viverra. Sit morbi etiam quam rhoncus. Velit in arcu platea donec vitae ante posuere malesuada.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit dapibus est, nunc, montes, lacus consequat integer viverra. Sit morbi etiam quam rhoncus. Velit in arcu platea donec vitae ante posuere malesuada.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit dapibus est, nunc,</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-5">
                                <BlogCategory />
                            </div>
                        </div>

                    </div>
                </div>

            </section>
            <Footer />
        </>

    )
}

export default BlogDetail