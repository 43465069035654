import React from 'react'
import './scss/Documentcss.css';
import RecHeader from '../../Commons/AfterLogin/RecHeader';
import Stepper from '../Stepper/Stepper';
import DocumentVarificationForm from './DocumentVarificationForm';
import Footer from '../../Commons/Footer';
import './scss/Documentcss.css';
const DocumentVarification = () => {
  return (
    <>
      <RecHeader/>
      <Stepper/>
      <DocumentVarificationForm/>
      <Footer/>
    </>
  )
}

export default DocumentVarification