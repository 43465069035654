import React from 'react'

const NewsSports = () => {
    return (
        <section className='sports mb-5'>
            <div className="container pb-5">
                <div className="row">
                    <div className="col-lg-9 py-4">
                        <div className="heading">
                            <h1>Sports</h1>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-lg-5 news-display pt-3">
                                <img src="./assets/images/news/spt.png" className='img-fluid' alt="" />
                                <ul className=' pb-1 d-flex'>
                                    <li>Craig Bator - </li>
                                    <li>27 Dec 2020</li>
                                </ul>
                                <h3>Solskjaer dismisses Klopp comments on Man Utd penalty record</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus lobortis augue condimentum maecenas. Metus at in fames vitae posuere ut vel vulputate ...</p>
                            </div>
                            <div className="col-lg-7 py-4 pt-3">
                                <div className="news-list row pb-3">
                                    <div className="col-lg-3">
                                        <img src="./assets/images/news/img1.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-7">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>South Africa hammer injury-hit Sri Lanka Player</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="news-list row pb-3">
                                    <div className="col-lg-3">
                                        <img src="./assets/images/news/img2.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-7">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>Southee-lead New Zealand have Pakistan on the ropes</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="news-list row pb-3">
                                    <div className="col-lg-3">
                                        <img src="./assets/images/news/img3.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-7">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>The only thing that overcomes hard luck is hard work</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="news-list row pb-3">
                                    <div className="col-lg-3">
                                        <img src="./assets/images/news/img4.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-7">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>Success is not a good teacher failure makes you humble</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="news-list row pb-3">
                                    <div className="col-lg-3">
                                        <img src="./assets/images/news/img5.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-7">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>Rahane-led India bury ghosts of Adelaide at Melbourne</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 py-4">
                        <div className="row">
                            <div className="title mt-2">
                                <h3>Stay Connected</h3>
                            </div>
                            <hr />
                            <div className="col-lg-6">
                                <div className="social-box cus-fb d-flex align-items-center my-2">
                                    <img src="./assets/images/news/fb.png" className='img-fluid mx-2' alt="" />
                                    <div className="followers">
                                        <h4>15000</h4>
                                        <h5>Followers</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="social-box cus-ins d-flex align-items-center my-2">
                                    <img src="./assets/images/news/ins.png" className='img-fluid mx-2' alt="" />
                                    <div className="followers">
                                        <h4>15000</h4>
                                        <h5>Followers</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="social-box cus-in d-flex align-items-center my-2">
                                    <img src="./assets/images/news/in.png" className='img-fluid mx-2' alt="" />
                                    <div className="followers">
                                        <h4>15000</h4>
                                        <h5>Followers</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="social-box cus-tw d-flex align-items-center my-2">
                                    <img src="./assets/images/news/tw.png" className='img-fluid mx-2' alt="" />
                                    <div className="followers">
                                        <h4>15000</h4>
                                        <h5>Followers</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="add pt-5">
                            <img src="./assets/images/news/Ad.png" className='img-fluid' alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NewsSports