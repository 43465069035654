import React from 'react'

const Benifits = () => {
    return (
        <section className='benifits'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='benifit-img'>
                            <img src="assets/images/homepage/benifits.png" className='img-fluid' />
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='key-benifits'>
                            <div className='heading-txt'>
                                <h1>Benefits</h1>
                            </div>
                            <div className='para-txt'>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices gravida</p>
                            </div>

                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='benifit-sec-lft'>
                                        <div className='key-points'>
                                            <div className='cust-image'>
                                                <img src="assets/images/homepage/3.png" className='img-fluid' />
                                            </div>
                                            <div className='text'>
                                                <p>Quality Job</p>
                                            </div>
                                        </div>
                                        <div className='key-points'>
                                            <div className='cust-image'>
                                                <img src="assets/images/homepage/2.png" className='img-fluid' />
                                            </div>
                                            <div className='text'>
                                                <p>No Extra Charge</p>
                                            </div>
                                        </div>
                                        <div className='key-points'>
                                            <div className='cust-image'>
                                                <img src="assets/images/homepage/3.png" className='img-fluid' />
                                            </div>
                                            <div className='text'>
                                                <p>Quality Job</p>
                                            </div>
                                        </div>
                                        <div className='key-points'>
                                            <div className='cust-image'>
                                                <img src="assets/images/homepage/2.png" className='img-fluid' />
                                            </div>
                                            <div className='text'>
                                                <p>No Extra Charge</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='benifit-sec-rit'>
                                        <div className='key-points'>
                                            <div className='cust-image'>
                                                <img src="assets/images/homepage/4.png" className='img-fluid' />
                                            </div>
                                            <div className='text'>
                                                <p>Top Companies</p>
                                            </div>
                                        </div>
                                        <div className='key-points'>
                                            <div className='cust-image'>
                                                <img src="assets/images/homepage/5.png" className='img-fluid' />
                                            </div>
                                            <div className='text'>
                                                <p>International Job</p>
                                            </div>
                                        </div>
                                        <div className='key-points'>
                                            <div className='cust-image'>
                                                <img src="assets/images/homepage/4.png" className='img-fluid' />
                                            </div>
                                            <div className='text'>
                                                <p>Top Companies</p>
                                            </div>
                                        </div>
                                        <div className='key-points'>
                                            <div className='cust-image'>
                                                <img src="assets/images/homepage/5.png" className='img-fluid' />
                                            </div>
                                            <div className='text'>
                                                <p>International Job</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Benifits