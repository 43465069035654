import React from 'react'
import './scss/SearchResumes.css'
import { Link } from 'react-router-dom'

const SearchResumes = () => {
    return (
        <>
            <section className='search-resume-sec-parent'>
                <section className="search-resumes-section">
                    <div className="container">
                        <div className="search-resumes-bg">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="search-resumes-display">
                                        <div className="search-heading">
                                            <h3> Search Resumes </h3>
                                        </div>
                                        <div className="srch mt-3">
                                            <Link to="/saved-search" className="search-btn1 mx-2">Saved Search</Link>
                                            <Link to="/projects-search" className="search-btn2 mx-2">Projects</Link>
                                            <Link to="/post-job" className="search-btn3 mx-2">Post a Job</Link>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="mt-4">
                                            <div className=" d-flex search-area">
                                                <div className="custom-form-section">
                                                    <div className="search-custom">
                                                        <img src="./assets/images/search-resume/MagnifyingGlass.png" className="img-fluid" alt="" />
                                                        <input type="text" className="form-control form-custom form-cus-one"
                                                            placeholder="Job title, skill, company, or search syntax" />
                                                    </div>
                                                    <div>
                                                        <div className="search-custom">
                                                            <img src="./assets/images/search-resume/MapPinLine .png" className="img-fluid" alt="" />
                                                            <input type="text" className="form-control form-custom form-cus-two"
                                                                placeholder="City, state, or Postal" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='text-center'>
                                                            <button className="form-custom-btn mb-2">Search job</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="advance-search-parent sm-mt-3">
                                                    <div className="advance-icon">
                                                        <p> <img src="./assets/images/search-resume/mi_filter.png" className="fitter-icon" alt="" /> Advance Search</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12"> 
                                        <div className="dropdown-custom-section mt-3">
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="./assets/images/search-resume/fi_filter.png" alt="" />
                                                    Hide Profile
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="./assets/images/search-resume/Calendar.png" alt="" />
                                                    Active In
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="./assets/images/search-resume/Calendar.png" alt="" />
                                                    Show
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="./assets/images/search-resume/Calendar.png" alt="" />
                                                    Select All
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="./assets/images/search-resume/Calendar.png" alt="" />
                                                    E-mail
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="./assets/images/search-resume/Calendar.png" alt="" />
                                                    Set Reminder
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">

                                                    Sort By
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="text-center">
                                                <img src="./assets/images/search-resume/search-bg-image.png" className="img-fluid" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default SearchResumes