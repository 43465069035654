import React from 'react'
import { Link } from 'react-router-dom'
import './scss/MakeITDealBreaker.css'
import Accordion from 'react-bootstrap/Accordion';
import CreateQuestionPopup from './CreateQuestionPopup/CreateQuestionPopup';
const MakeITDealBreakerContent = () => {
    return (
        <>
            <section className='make-it-deal-breaker-sec'>
                <div className='container'>
                    <form action='' method=''>
                        <div className='create-an-emp-inner'>
                            <div className='container'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-8 col-md-6 col-12'>
                                        <div className='heading'>
                                            <h2>Need to have this? Make it deal breaker.</h2>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-12 text-end'>
                                        <div className='heading2'>
                                            <a href='' className='maxi-cls'>Maximum 10 Questions</a>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-md-12 col-12'>
                                        <div className='after-main-head'>
                                            <a href='#' className='after-main-atag'>Write your own questions to ask applicants</a>
                                            <p>We don’t notify you of candidates that don’t meet your deal breaker qualifications. You can review them anytime on your candidate dashboard.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='question-parent-dv'>
                                    <div className='question-heading'>
                                        <h6>Application Question -1 : How many years of experience do you have?</h6>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-3 my-1'>
                                            <div className='question-options'>
                                                <div className='question-selection'>
                                                    <label htmlFor='q1option1'><span>A)</span> 1 Year</label>
                                                    <input type='radio' id='q1option1' name='question1' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-3 my-1'>
                                            <div className='question-options'>
                                                <div className='question-selection'>
                                                    <label htmlFor='q1option2'><span>B)</span> 2 Years</label>
                                                    <input type='radio' id='q1option2' name='question1' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-3 my-1'>
                                            <div className='question-options'>
                                                <div className='question-selection'>
                                                    <label htmlFor='q1option3'><span>C)</span> 3 Years</label>
                                                    <input type='radio' id='q1option3' name='question1' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-3 my-1'>
                                            <div className='question-options'>
                                                <div className='question-selection'>
                                                    <label htmlFor='q1option4'><span>D)</span> 3 Years + Above</label>
                                                    <input type='radio' id='q1option4' name='question1' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='question-parent-dv'>
                                    <div className='question-heading'>
                                        <h6>Application Question -2 : Do you speak?</h6>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-3 my-1'>
                                            <div className='question-options'>
                                                <div className='question-selection'>
                                                    <label htmlFor='q1option1'><span>A)</span> Pashto</label>
                                                    <input type='radio' id='q1option1' name='question1' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-3 my-1'>
                                            <div className='question-options'>
                                                <div className='question-selection'>
                                                    <label htmlFor='q1option2'><span>B)</span> Parsian</label>
                                                    <input type='radio' id='q1option2' name='question1' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-3 my-1'>
                                            <div className='question-options'>
                                                <div className='question-selection'>
                                                    <label htmlFor='q1option3'><span>C)</span> Hindi</label>
                                                    <input type='radio' id='q1option3' name='question1' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-3 my-1'>
                                            <div className='question-options'>
                                                <div className='question-selection'>
                                                    <label htmlFor='q1option4'><span>D)</span> English</label>
                                                    <input type='radio' id='q1option4' name='question1' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='create-an-emp-inner2'>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0" key="0">
                                    <Accordion.Header>Browse More Questions</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='row'>
                                            <div className='col-lg-4 col-md-4 col-12 my-2'>
                                                <div className='accordion-body-small-card'>
                                                    <div className='accordion-body-innner-card'>
                                                        <div className='card-heading'>
                                                            <button type="button" data-bs-toggle="modal"
                                                                data-bs-target="#create-question-project-modal" className='card-a-head'>Ability to commute/relocate</button>
                                                            <button type="button" data-bs-toggle="modal"
                                                                data-bs-target="#create-question-project-modal" className='border-none-btn' > <img src='./assets/recruiter-images/post-a-job/add-icon.png' /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 col-12 my-2'>
                                                <div className='accordion-body-small-card'>
                                                    <div className='accordion-body-innner-card'>
                                                        <div className='card-heading'>
                                                            <button type="button" data-bs-toggle="modal"
                                                                data-bs-target="#create-question-project-modal" className='card-a-head'>License/Certification</button>
                                                            <button type="button" data-bs-toggle="modal"
                                                                data-bs-target="#create-question-project-modal" className='border-none-btn' > <img src='./assets/recruiter-images/post-a-job/add-icon.png' /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 col-12 my-2'>
                                                <div className='accordion-body-small-card'>
                                                    <div className='accordion-body-innner-card'>
                                                        <div className='card-heading'>
                                                            <button type="button" data-bs-toggle="modal"
                                                                data-bs-target="#create-question-project-modal" className='card-a-head'>Willingness to travel</button>
                                                            <button type="button" data-bs-toggle="modal"
                                                                data-bs-target="#create-question-project-modal" className='border-none-btn' > <img src='./assets/recruiter-images/post-a-job/add-icon.png' /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 col-12 my-2'>
                                                <div className='accordion-body-small-card'>
                                                    <div className='accordion-body-innner-card'>
                                                        <div className='card-heading'>
                                                            <button type="button" data-bs-toggle="modal"
                                                                data-bs-target="#create-question-project-modal" className='card-a-head'>Education</button>
                                                            <button type="button" data-bs-toggle="modal"
                                                                data-bs-target="#create-question-project-modal" className='border-none-btn' > <img src='./assets/recruiter-images/post-a-job/add-icon.png' /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 col-12 my-2'>
                                                <div className='accordion-body-small-card'>
                                                    <div className='accordion-body-innner-card'>
                                                        <div className='card-heading'>
                                                            <button type="button" data-bs-toggle="modal"
                                                                data-bs-target="#create-question-project-modal" className='card-a-head'>Shift Availability</button>
                                                            <button type="button" data-bs-toggle="modal"
                                                                data-bs-target="#create-question-project-modal" className='border-none-btn' > <img src='./assets/recruiter-images/post-a-job/add-icon.png' /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 col-12 my-2'>
                                                <div className='accordion-body-small-card'>
                                                    <div className='accordion-body-innner-card'>
                                                        <div className='card-heading'>
                                                            <button type="button" data-bs-toggle="modal"
                                                                data-bs-target="#create-question-project-modal" className='card-a-head'>Create Custom Question</button>
                                                            <button type="button" data-bs-toggle="modal"
                                                                data-bs-target="#create-question-project-modal" className='border-none-btn' > <img src='./assets/recruiter-images/post-a-job/add-icon.png' /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 col-12 my-2'>
                                                <div className='accordion-body-small-card'>
                                                    <div className='accordion-body-innner-card'>
                                                        <div className='card-heading'>
                                                            <button type="button" data-bs-toggle="modal"
                                                                data-bs-target="#create-question-project-modal" className='card-a-head'>Experience</button>
                                                            <button type="button" data-bs-toggle="modal"
                                                                data-bs-target="#create-question-project-modal" className='border-none-btn' > <img src='./assets/recruiter-images/post-a-job/add-icon.png' /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 col-12 my-2'>
                                                <div className='accordion-body-small-card'>
                                                    <div className='accordion-body-innner-card'>
                                                        <div className='card-heading'>
                                                            <button type="button" data-bs-toggle="modal"
                                                                data-bs-target="#create-question-project-modal" className='card-a-head'>Skills Test</button>
                                                            <button type="button" data-bs-toggle="modal"
                                                                data-bs-target="#create-question-project-modal" className='border-none-btn' > <img src='./assets/recruiter-images/post-a-job/add-icon.png' /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 col-12 my-2'>
                                                <div className='accordion-body-small-card'>
                                                    <div className='accordion-body-innner-card'>
                                                        <div className='card-heading'>
                                                            <button type="button" data-bs-toggle="modal"
                                                                data-bs-target="#create-question-project-modal" className='card-a-head'>Language</button>
                                                            <button type="button" data-bs-toggle="modal"
                                                                data-bs-target="#create-question-project-modal" className='border-none-btn' > <img src='./assets/recruiter-images/post-a-job/add-icon.png' /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 col-12 my-2'>
                                                <div className='accordion-body-small-card'>
                                                    <div className='accordion-body-innner-card'>
                                                        <div className='card-heading'>
                                                            <button type="button" data-bs-toggle="modal"
                                                                data-bs-target="#create-question-project-modal" className='card-a-head'>Interview Availability</button>
                                                            <button type="button" data-bs-toggle="modal"
                                                                data-bs-target="#create-question-project-modal" className='border-none-btn' > <img src='./assets/recruiter-images/post-a-job/add-icon.png' /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 col-12 my-2'>
                                                <div className='accordion-body-small-card'>
                                                    <div className='accordion-body-innner-card'>
                                                        <div className='card-heading'>
                                                            <button type="button" data-bs-toggle="modal"
                                                                data-bs-target="#create-question-project-modal" className='card-a-head'>Create Assessments</button>
                                                            <button type="button" data-bs-toggle="modal"
                                                                data-bs-target="#create-question-project-modal" className='border-none-btn' > <img src='./assets/recruiter-images/post-a-job/add-icon.png' /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <CreateQuestionPopup />
                                    </Accordion.Body>
                                    <div className='pre-recorded-dv-parent'>
                                        <label>Pre-recorded Screen Questions</label>
                                        <div className='pre-rocorded-dv form-control'>
                                            <div className='pre-inner'>
                                                <input type='checkbox' placeholder='' id='pre-order-check' />
                                                <label htmlFor='pre-order-check'>Send pre-recorder screen questions by phone</label>
                                            </div>
                                            <div className='see-all-question'>
                                                <a href='#' className='pre-inner-a'>See All Questions</a>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Item>

                            </Accordion>


                        </div>

                        <div className='outside-page-submit-btn'>
                            <div className='bottom-button-dv'>
                                <div className='row justify-content-center'>
                                    <div className='col-lg-5 col-md-5 col-6 my-lg-1 my-md-1 my-2 text-center text-lg-end text-md-end'>
                                        <div className='bottom-btn'>
                                            <Link to='/set-preferences' type='submit' className='a-tag-btn1'>Back</Link>
                                        </div>
                                    </div>
                                    <div className='col-lg-2 col-md-2 col-6 my-lg-1 my-md-1 my-2 text-center'>
                                        <div className='bottom-btn'>
                                            <Link to='/post-a-job-overview' type='submit' className='a-tag-btn2'>Continue</Link>
                                        </div>
                                    </div>
                                    <div className='col-lg-5 col-md-5 col-6 my-lg-1 my-md-1 my-2 text-center text-lg-start text-md-start'>
                                        <div className='bottom-btn'>
                                            <Link to='#' type='submit' className='a-tag-btn1'>Preview</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default MakeITDealBreakerContent