import React from 'react'
import Navbar from '../Commons/Navbar'
import Header from '../Commons/Header'
import Footer from '../Commons/Footer'
import './scss/FindYourNextGreatHireHome.css'
import FindYourNextGreatHireBanner from './FindYourNextGreatHireBanner'
import LeadingRecruitingSoftware from './LeadingRecruitingSoftware'

const FindYourNextGreatHireHome = () => {
    return (
        <>
            <Header />
            <Navbar />
            <FindYourNextGreatHireBanner />
            <LeadingRecruitingSoftware />
            <Footer />
        </>
    )
}

export default FindYourNextGreatHireHome