import React from 'react'
import { Link } from 'react-router-dom'
import Slider from "react-slick";

const ProfileViewer = () => {

    const settings = {
        dots: false,
        infinite: true,
        autoplaySpeed: 3000,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false
                }
            }

        ]
    };
    return (
        <section className='profile-viewer my-4 pb-5' >
            <div className="container px-4 py-5">
                <div className="row">
                    <div className="title pb-4">
                        <h3>Profile Viewer</h3>
                    </div>

                    <Slider {...settings}>
                        <div className="slider-combine">
                            <div className="profile d-flex my-3">
                                <img src="./assets/images/profile/Avatar.png" className='img-fluid' alt="" />
                                <div className="profile-text ps-3">
                                    <h3>Hamish Marsh</h3>
                                    <h4>HR Manager <span>Grameenphone</span></h4>
                                    <Link to="/my-profile">View Profile</Link>
                                </div>
                            </div>
                        </div>
                        <div className="slider-combine">
                            <div className="profile d-flex my-3">
                                <img src="./assets/images/profile/Avatar.png" className='img-fluid' alt="" />
                                <div className="profile-text ps-3">
                                    <h3>Hamish Marsh</h3>
                                    <h4>HR Manager <span>Grameenphone</span></h4>
                                    <Link to="/my-profile">View Profile</Link>
                                </div>
                            </div>
                        </div>
                        <div className="slider-combine">
                            <div className="profile d-flex my-3">
                                <img src="./assets/images/profile/Avatar.png" className='img-fluid' alt="" />
                                <div className="profile-text ps-3">
                                    <h3>Hamish Marsh</h3>
                                    <h4>HR Manager <span>Grameenphone</span></h4>
                                    <Link to="/my-profile">View Profile</Link>
                                </div>
                            </div>
                        </div>
                        <div className="slider-combine">
                            <div className="profile d-flex my-3">
                                <img src="./assets/images/profile/Avatar.png" className='img-fluid' alt="" />
                                <div className="profile-text ps-3">
                                    <h3>Hamish Marsh</h3>
                                    <h4>HR Manager <span>Grameenphone</span></h4>
                                    <Link to="/my-profile">View Profile</Link>
                                </div>
                            </div>
                        </div>
                    </Slider>
                    {/* <div className="col-lg-4">
                        <div className="profile d-flex">
                            <img src="./assets/images/profile/Avatar.png" className='img-fluid' alt="" />
                            <div className="profile-text ps-3">
                                <h3>Hamish Marsh</h3>
                                <h4>HR Manager <span>Grameenphone</span></h4>
                                <Link to={undefined}>View Profile</Link>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

        </section >
    )
}

export default ProfileViewer