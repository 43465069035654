import React from 'react'
import RegisterHeader from '../../../Seeker/CvBuilder/CvBuilderHeader/RegisterHeader'
import OtpSendEnterContent from './OtpSendEnterContent'
import Footer from '../../Commons/Footer'

const OtpSendEnter = () => {
    return (
        <>
            <RegisterHeader />
            <OtpSendEnterContent />
            <Footer />
        </>
    )
}

export default OtpSendEnter