import React from 'react'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'
import PlannedStartDateThisJobContent from './PlannedStartDateThisJobContent'
import Footer from '../../Commons/Footer'

const PlannedStartDateThisJob = () => {
  return (
    <>
    <RecAfterLoginNavbar/>
    <PlannedStartDateThisJobContent/>
    <Footer/>
    </>
  )
}

export default PlannedStartDateThisJob