import React from 'react'
import './scss/ProjectsSearch.css'
import { Link } from 'react-router-dom'

const ProjectsSearch = () => {
    return (
        <>
            <section className='project-search-section'>
                <section className="search-resumes-section">
                    <div className="container">
                        <div className="search-resumes-bg">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="search-resumes-display">
                                        <div className="search-heading">
                                            <h3>
                                                Projects
                                            </h3>
                                        </div>
                                        <div>
                                            <Link to="/search-resumes" className="search-btn1 mx-2">Search Resume</Link>
                                            <Link to="/saved-search" className="search-btn2 mx-2">Saved Sarch</Link>
                                            <Link className="search-btn3 mx-2" to="/post-job">Post a Job</Link>
                                        </div>
                                    </div>
                                    <div className="tab-view-custom mt-4 d-flex justify-content-between align-items-center">
                                        <div className='tab-area'>
                                            <ul className="nav nav-tabs nav-tabs-custom mt-4 " id="myTab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active nav-link-custom" id="home-tab"
                                                        data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab"
                                                        aria-controls="home" aria-selected="true">All Projects</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link nav-link-custom-second" id="profile-tab"
                                                        data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab"
                                                        aria-controls="profile" aria-selected="false"> New Responses</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link nav-link-custom-second" id="contact-tab"
                                                        data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab"
                                                        aria-controls="contact" aria-selected="false">Archive</button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <button button type="button" className="btn btn-primary craete-btn" data-bs-toggle="modal" data-bs-target="#create-new-project-modal">+ Create Project</button>
                                        </div>
                                    </div>
                                    <div className="tab-content " id="myTabContent">
                                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                            <section className="mb-5">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div>
                                                                <div className="row  cust_row">
                                                                    <div className="col-lg-2 mt-2">
                                                                        <div className="photo-cus text-center ">
                                                                            <Link to='/rec-candidate-profile'>
                                                                                <img src="./assets/images/search-resume/Photo.png" />
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg">
                                                                        <div className="row">
                                                                            <div className="col-sm-auto col-lg-4 my-auto">
                                                                                <div className="text-left">
                                                                                    <Link to='/rec-candidate-profile'><h5>Jessica Jana</h5></Link>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-auto col-lg-8">
                                                                                <div className="cust_up float-end">
                                                                                    <ul>
                                                                                        <li className="list-inline-item">
                                                                                            <img src="./assets/images/search-resume/Vector.png" alt="" />
                                                                                        </li>

                                                                                        <li className="list-inline-item">
                                                                                            <img src="./assets/images/search-resume/email.png" alt="" />
                                                                                        </li>
                                                                                        <li className="list-inline-item">
                                                                                            <img src="./assets/images/search-resume/share.png" alt="" />
                                                                                        </li>
                                                                                        <li className="list-inline-item"><a href="#"
                                                                                            className="text-decoration-none"><strong>Comment | Delete</strong></a></li>
                                                                                        <li className="list-inline-item"><input type="checkbox" /></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <div className="text-left">
                                                                                    <span className="cust_main">Software Engineer </span>
                                                                                    <span className="bg-light p-2 ml-5 text-primary"> New Post</span>
                                                                                </div>
                                                                                <section className='project-search-sec'>
                                                                                    <ul className="text-left cust_lis">
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/mappinline.png" alt="" /> Brusses</li>
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/clock.png" alt="" /> Full Time</li>
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/currencydollar.png" alt="" /> 50-55K</li>
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/calendarblank.png" alt="" /> 1 day ago</li>
                                                                                    </ul>
                                                                                </section>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                                    Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                                    tempor Lorem incididunt.</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mt-4">
                                                                <div className="row  cust_row">
                                                                    <div className="col-lg-2 mt-2">
                                                                        <div className="photo-cus text-center">
                                                                            <Link to='/rec-candidate-profile'>
                                                                                <img src="./assets/images/search-resume/Photo2.png" />
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg">
                                                                        <div className="row">
                                                                            <div className="col-sm-auto col-lg-4 my-auto">
                                                                                <div className="text-left">
                                                                                    <Link to='/rec-candidate-profile'>
                                                                                        <h5>Alex Stanton</h5>
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-auto col-lg-8">
                                                                                <div className="cust_up float-end">
                                                                                    <ul>
                                                                                        <li className="list-inline-item">
                                                                                            <img src="./assets/images/search-resume/Vector.png" alt="" />
                                                                                        </li>

                                                                                        <li className="list-inline-item">
                                                                                            <img src="./assets/images/search-resume/email.png" alt="" />
                                                                                        </li>
                                                                                        <li className="list-inline-item">
                                                                                            <img src="./assets/images/search-resume/share.png" alt="" />
                                                                                        </li>
                                                                                        <li className="list-inline-item"><a href="#"
                                                                                            className="text-decoration-none"><strong>Comment | Delete</strong></a></li>
                                                                                        <li className="list-inline-item"><input type="checkbox" /></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <div className="text-left">
                                                                                    <span className="cust_main">Junior UI Designer </span>
                                                                                </div>
                                                                                <section className='project-search-sec'>
                                                                                    <ul className="text-left cust_lis">
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/mappinline.png" alt="" /> Brusses</li>
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/clock.png" alt="" /> Full Time</li>
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/currencydollar.png" alt="" /> 50-55K</li>
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/calendarblank.png" alt="" /> 1 day ago</li>
                                                                                    </ul>
                                                                                </section>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                                    Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                                    tempor Lorem incididunt.</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mt-4">
                                                                <div className="row  cust_row">
                                                                    <div className="col-lg-2 mt-2">
                                                                        <div className="photo-cus text-center">
                                                                            <Link to='/rec-candidate-profile'>
                                                                                <img src="./assets/images/search-resume/Photo3.png" />
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg">
                                                                        <div className="row">
                                                                            <div className="col-sm-auto col-lg-4 my-auto">
                                                                                <div className="text-left">
                                                                                    <Link to='/rec-candidate-profile'>
                                                                                        <h5>Antoine Griezmann</h5>
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-auto col-lg-8">
                                                                                <div className="cust_up float-end">
                                                                                    <ul>
                                                                                        <li className="list-inline-item">
                                                                                            <img src="./assets/images/search-resume/Vector.png" alt="" />
                                                                                        </li>

                                                                                        <li className="list-inline-item">
                                                                                            <img src="./assets/images/search-resume/email.png" alt="" />
                                                                                        </li>
                                                                                        <li className="list-inline-item">
                                                                                            <img src="./assets/images/search-resume/share.png" alt="" />
                                                                                        </li>
                                                                                        <li className="list-inline-item"><a href="#"
                                                                                            className="text-decoration-none"><strong>Comment | Delete</strong></a></li>
                                                                                        <li className="list-inline-item"><input type="checkbox" /></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <div className="text-left">
                                                                                    <span className="cust_main">Technical Support Engineer</span>
                                                                                </div>
                                                                                <section className='project-search-sec'>
                                                                                    <ul className="text-left cust_lis">
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/mappinline.png" alt="" /> Brusses</li>
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/clock.png" alt="" /> Full Time</li>
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/currencydollar.png" alt="" /> 50-55K</li>
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/calendarblank.png" alt="" /> 1 day ago</li>
                                                                                    </ul>
                                                                                </section>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                                    Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                                    tempor Lorem incididunt.</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mt-4">
                                                                <div className="row  cust_row">
                                                                    <div className="col-lg-2 mt-2">
                                                                        <div className="photo-cus text-center">
                                                                            <Link to='/rec-candidate-profile'>
                                                                                <img src="./assets/images/search-resume/Photo4.png" />
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg">
                                                                        <div className="row">
                                                                            <div className="col-sm-auto col-lg-4 my-auto">
                                                                                <div className="text-left">
                                                                                    <Link to='/rec-candidate-profile'>
                                                                                        <h5>Anna White</h5>
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-auto col-lg-8">
                                                                                <div className="cust_up float-end">
                                                                                    <ul>
                                                                                        <li className="list-inline-item">
                                                                                            <img src="./assets/images/search-resume/Vector.png" alt="" />
                                                                                        </li>
                                                                                        <li className="list-inline-item">
                                                                                            <img src="./assets/images/search-resume/email.png" alt="" />
                                                                                        </li>
                                                                                        <li className="list-inline-item">
                                                                                            <img src="./assets/images/search-resume/share.png" alt="" />
                                                                                        </li>
                                                                                        <li className="list-inline-item"><a href="#"
                                                                                            className="text-decoration-none"><strong>Comment | Delete</strong></a></li>
                                                                                        <li className="list-inline-item"><input type="checkbox" /></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <div className="text-left">
                                                                                    <span className="cust_main">Product Designer </span>
                                                                                </div>
                                                                                <section className='project-search-sec'>
                                                                                    <ul className="text-left cust_lis">
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/mappinline.png" alt="" /> Brusses</li>
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/clock.png" alt="" /> Full Time</li>
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/currencydollar.png" alt="" /> 50-55K</li>
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/calendarblank.png" alt="" /> 1 day ago</li>
                                                                                    </ul>
                                                                                </section>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                                    Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                                    tempor Lorem incididunt.</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mt-4">
                                                                <div className="row  cust_row">
                                                                    <div className="col-lg-2 mt-2">
                                                                        <div className="photo-cus text-center">
                                                                            <Link to='/rec-candidate-profile'>
                                                                                <img src="./assets/images/search-resume/Photo5.png" />
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg">
                                                                        <div className="row">
                                                                            <div className="col-sm-auto col-lg-4 my-auto">
                                                                                <div className="text-left">
                                                                                    <Link to='/rec-candidate-profile'>
                                                                                        <h5>Richard Kyle</h5>
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-auto col-lg-8">
                                                                                <div className="cust_up float-end">
                                                                                    <ul>
                                                                                        <li className="list-inline-item">
                                                                                            <img src="./assets/images/search-resume/Vector.png" alt="" />
                                                                                        </li>
                                                                                        <li className="list-inline-item">
                                                                                            <img src="./assets/images/search-resume/email.png" alt="" />
                                                                                        </li>
                                                                                        <li className="list-inline-item">
                                                                                            <img src="./assets/images/search-resume/share.png" alt="" />
                                                                                        </li>
                                                                                        <li className="list-inline-item"><a href="#"
                                                                                            className="text-decoration-none"><strong>Comment | Delete</strong></a></li>
                                                                                        <li className="list-inline-item"><input type="checkbox" /></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <div className="text-left">
                                                                                    <span className="cust_main">Copywriter (Growth) </span>
                                                                                </div>
                                                                                <section className='project-search-sec'>
                                                                                    <ul className="text-left cust_lis">
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/mappinline.png" alt="" /> Brusses</li>
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/clock.png" alt="" /> Full Time</li>
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/currencydollar.png" alt="" /> 50-55K</li>
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/calendarblank.png" alt="" /> 1 day ago</li>
                                                                                    </ul>
                                                                                </section>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                                    Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                                    tempor Lorem incididunt.</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mt-4">
                                                                <div className="row  cust_row">
                                                                    <div className="col-lg-2 mt-2">
                                                                        <div className="photo-cus text-center">
                                                                            <Link to='/rec-candidate-profile'>
                                                                                <img src="./assets/images/search-resume/Photo6.png" />
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg">
                                                                        <div className="row">
                                                                            <div className="col-sm-auto col-lg-4 my-auto">
                                                                                <div className="text-left">
                                                                                    <Link to='/rec-candidate-profile'>
                                                                                        <h5>Julia Philips</h5>
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-auto col-lg-8">
                                                                                <div className="cust_up float-end">
                                                                                    <ul>
                                                                                        <li className="list-inline-item">
                                                                                            <img src="./assets/images/search-resume/Vector.png" alt="" />
                                                                                        </li>
                                                                                        <li className="list-inline-item">
                                                                                            <img src="./assets/images/search-resume/email.png" alt="" />
                                                                                        </li>
                                                                                        <li className="list-inline-item">
                                                                                            <img src="./assets/images/search-resume/share.png" alt="" />
                                                                                        </li>
                                                                                        <li className="list-inline-item"><a href="#"
                                                                                            className="text-decoration-none"><strong>Comment | Delete</strong></a></li>
                                                                                        <li className="list-inline-item"><input type="checkbox" /></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <div className="text-left">
                                                                                    <span className="cust_main">Senior UX/UI Designer </span>
                                                                                </div>
                                                                                <section className='project-search-sec'>
                                                                                    <ul className="text-left cust_lis">
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/mappinline.png" alt="" /> Brusses</li>
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/clock.png" alt="" /> Full Time</li>
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/currencydollar.png" alt="" /> 50-55K</li>
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/calendarblank.png" alt="" /> 1 day ago</li>
                                                                                    </ul>
                                                                                </section>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                                    Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                                    tempor Lorem incididunt.</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mt-4">
                                                                <div className="row  cust_row">
                                                                    <div className="col-lg-2 mt-2">
                                                                        <div className="photo-cus text-center">
                                                                            <Link to='/rec-candidate-profile'>
                                                                                <img src="./assets/images/search-resume/Photo7.png" />
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg">
                                                                        <div className="row">
                                                                            <div className="col-sm-auto col-lg-4 my-auto">
                                                                                <div className="text-left">
                                                                                    <Link to='/rec-candidate-profile'>
                                                                                        <h5>Curious George</h5>
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-auto col-lg-8">
                                                                                <div className="cust_up float-end">
                                                                                    <ul>
                                                                                        <li className="list-inline-item">
                                                                                            <img src="./assets/images/search-resume/Vector.png" alt="" />
                                                                                        </li>

                                                                                        <li className="list-inline-item">
                                                                                            <img src="./assets/images/search-resume/email.png" alt="" />
                                                                                        </li>
                                                                                        <li className="list-inline-item">
                                                                                            <img src="./assets/images/search-resume/share.png" alt="" />
                                                                                        </li>
                                                                                        <li className="list-inline-item"><a href="#"
                                                                                            className="text-decoration-none"><strong>Comment | Delete</strong></a></li>
                                                                                        <li className="list-inline-item"><input type="checkbox" /></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <div className="text-left">
                                                                                    <span className="cust_main">Technical Support Engineer </span>
                                                                                </div>
                                                                                <section className='project-search-sec'>
                                                                                    <ul className="text-left cust_lis">
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/mappinline.png" alt="" /> Brusses</li>
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/clock.png" alt="" /> Full Time</li>
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/currencydollar.png" alt="" /> 50-55K</li>
                                                                                        <li className="list-inline-item "><img src="./assets/images/search-resume/calendarblank.png" alt="" /> 1 day ago</li>
                                                                                    </ul>
                                                                                </section>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                                    Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                                    tempor Lorem incididunt.</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mt-4">
                                                                <div className="row  cust_row">
                                                                    <div className="col-lg-2 mt-2">
                                                                        <div className="photo-cus text-center">
                                                                            <Link to='/rec-candidate-profile'>
                                                                                <img src="./assets/images/search-resume/Photo.png" />
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg">
                                                                        <div className="row">
                                                                            <div className="col-sm-auto col-lg-4 my-auto">
                                                                                <div className="text-left">
                                                                                    <Link to='/rec-candidate-profile'>
                                                                                        <h5>Curious George</h5>
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-auto col-lg-8">
                                                                                <div className="cust_up float-end">
                                                                                    <ul>
                                                                                        <li className="list-inline-item">
                                                                                            <img src="./assets/images/search-resume/Vector.png" alt="" />
                                                                                        </li>

                                                                                        <li className="list-inline-item">
                                                                                            <img src="./assets/images/search-resume/email.png" alt="" />
                                                                                        </li>
                                                                                        <li className="list-inline-item">
                                                                                            <img src="./assets/images/search-resume/share.png" alt="" />
                                                                                        </li>
                                                                                        <li className="list-inline-item"><a href="#"
                                                                                            className="text-decoration-none"><strong>Comment | Delete</strong></a></li>
                                                                                        <li className="list-inline-item"><input type="checkbox" /></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <div className="text-left">
                                                                                    <span className="cust_main">Technical Support Engineer</span>
                                                                                </div>
                                                                                <section>``
                                                                                    <ul className="text-left cust_lis">
                                                                                        <li className="list-inline-item "><i className="fa fa-map-marker m-2"
                                                                                            aria-hidden="true"></i>United States</li>
                                                                                        <li className="list-inline-item "><i className="fa fa-clock-o m-2"
                                                                                            aria-hidden="true"></i>Full Time</li>
                                                                                        <li className="list-inline-item "><i className="fa fa-usd m-2"
                                                                                            aria-hidden="true"></i>50-55K</li>
                                                                                        <li className="list-inline-item "><i className="fa fa-calendar-o m-2"
                                                                                            aria-hidden="true"></i>1 day ago</li>
                                                                                    </ul>
                                                                                </section>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                                    Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                                    tempor Lorem incididunt.</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                            section-section-page
                                        </div>
                                        <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                            section-third-page
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/* <!-- ------------------------popup start here-------------------------- --> */}
                <section className="create-new-project-section">
                    <div className="modal fade create-modal-dv" id="create-new-project-modal" tabindex="-1"
                        aria-labelledby="exampleModalLabel" aria-hidden="true" >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Create New</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <form action="" method="">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-12 mt-3">
                                                    <div className="form-group">
                                                        <label for="title">Tittle</label>
                                                        <input type="email" className="form-control" id="title"
                                                            placeholder="Add Tittle" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-12 mt-3">
                                                    <div className="form-group">
                                                        <label for="job-type">Job Type</label>
                                                        <select className="form-control" id="job-type">
                                                            <option>Events</option>
                                                            <option>Events</option>
                                                            <option>Events</option>
                                                            <option>Events</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-12 mt-3">
                                                    <div className="form-group">
                                                        <label for="job-type">Job Status</label>
                                                        <select className="form-control">
                                                            <option>Urgents</option>
                                                            <option>Events</option>
                                                            <option>Events</option>
                                                            <option>Events</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-12 mt-3">
                                                    <div className="form-group">
                                                        <label for="start-date">Start Date</label>
                                                        <input type="date" className="form-control" id="start-date" placeholder="" />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-12 mt-3">
                                                    <div className="form-group">
                                                        <label for="end-date">End Date</label>
                                                        <input type="date" className="form-control" id="end-date" placeholder="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-12 mt-3">
                                                    <div className="form-group">
                                                        <label for="desc">Description</label>
                                                        <textarea id="desc" className="form-control" rows="3" cols="50" placeholder="Describe yourself here..."></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-12 mt-3">
                                                    <div className="form-group">
                                                        <label for="location">Location</label>
                                                        <input type="text" className="form-control" id="location"
                                                            placeholder="Location" />

                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-12 mt-3">
                                                    <div className="form-group">
                                                        <label for="contact">Contact</label>
                                                        <input type="text" className="form-control" id="contact" placeholder="Contact" />

                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-12 mt-3">
                                                    <div className="footer-btn">
                                                        <button type="button" className="footer-modal-btn cancel" data-bs-dismiss="modal">Cancel</button>
                                                        <button type="button" className="footer-modal-btn create" >Create <img src="./assets/images/search-resume/right.png" /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- ------------------------popup end here---------------------------- --> */}
            </section>
        </>
    )
}

export default ProjectsSearch