import React from 'react'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import RecMySubsCriptionPlanContent from './RecMySubsCriptionPlanContent'
import Footer from '../Commons/Footer'

const RecMySubsCriptionPlan = () => {
    return (
        <>
            <RecAfterLoginNavbar />
            <RecMySubsCriptionPlanContent />
            <Footer />
        </>
    )
}

export default RecMySubsCriptionPlan