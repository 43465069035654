import React from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
    return (
        <>
            <section className='footer-parent-sec'>
                <div className='container'>
                    <div className='row align-items-center-md'>
                        <div className='col-lg-5 col-md-6 col-12'>
                            <div className='ft-left-dv'>
                                <img src='././././assets/recruiter-images/footer-img/ft-logo.png' />
                                <div className='ft-after-logo'>
                                    <div className='ft-a-tag'>
                                        <Link to='/about-us' className=''>About</Link>
                                        <Link to='/choose-your-plan' className=''>Pricing</Link>
                                        <Link to='/news' className=''>News</Link>
                                        <Link to='/contact-us' className=''>Contact</Link>
                                    </div>
                                    <div className='ft-a-tag-2'>
                                        <span>E-mail - </span><a href='#' className=''> info@karlatoon.com</a>
                                    </div>
                                    <div className='ft-a-tag-2'>
                                        <span>Address -</span><a href='#' className=''> H-26-Vijay Nagar Ghaziabad U.P.</a>
                                    </div>
                                    <div className='ft-a-tag border-bottom-none'>
                                        <a href='#' className=''>Download Now</a>
                                        <a href='#' className=''>License</a>
                                    </div>
                                    <div className='ft-a-tag-last'>
                                        <a href='#' className=''>
                                            <img src='././././assets/recruiter-images/footer-img/appstore.png' />
                                        </a>
                                        <a href='#' className=''>
                                            <img src='././././assets/recruiter-images/footer-img/playstore.png' />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2 d-md-none d-lg-block d-block'></div>
                        <div className='col-lg-5 col-md-6 col-12'>
                            <div className='ft-rt-dv'>
                                <div className='row inner-img-dv mb-5'>
                                    <div className='col-lg-6 inner-img'>
                                        <img src='././././assets/recruiter-images/footer-img/ft-right.png' className='img-fluid' />
                                    </div>
                                    <div className='col-lg-6 right-img-dv'>
                                        <h4>Get our weekly</h4>
                                        <h3>Newsletter</h3>
                                        <p>Join our newsletter and receive a weekly update of the top job openings directly in your inbox. Subscribe now!</p>
                                    </div>
                                </div>
                                <div className='ft-input'>
                                    <input type='text' name='' placeholder='Email Address' />
                                    <button>Subscribe</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='bottom-st-section'>
                <div className='bottom-strip-head'>
                    <div className='bottom-strip'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-12 text-lg-start text-md-start text-center'>
                                    <p>© 2023 Karlatoon. All rights reserved</p>
                                </div>
                                <div className='col-lg-6 col-md-6 col-12 text-lg-end text-md-end text-center'>
                                    <div className='right-policies'>
                                        <Link to="/terms-conditions" className=''>Terms & Conditions</Link>
                                        <Link to="/privacy-policy" className=''>Privacy Policy</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Footer