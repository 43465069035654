import React, {useEffect} from 'react'
import './scss/CreateAnAccountPopup.css'
const CreateAnAccountPopup = () => {
    useEffect(() => {
            function OTPInput() {
                const inputs = document.querySelectorAll('#otp > *[id]');
                for (let i = 0; i < inputs.length; i++) { inputs[i].addEventListener('keydown', function (event) { if (event.key === "Backspace") { inputs[i].value = ''; if (i !== 0) inputs[i - 1].focus(); } else { if (i === inputs.length - 1 && inputs[i].value !== '') { return true; } else if (event.keyCode > 47 && event.keyCode < 58) { inputs[i].value = event.key; if (i !== inputs.length - 1) inputs[i + 1].focus(); event.preventDefault(); } else if (event.keyCode > 64 && event.keyCode < 91) { inputs[i].value = String.fromCharCode(event.keyCode); if (i !== inputs.length - 1) inputs[i + 1].focus(); event.preventDefault(); } } }); }
            } OTPInput();

    }, [])
    
    return (
        <>
            <section className='create-an-account-popup'>
                <>
                    {/* Modal */}
                    <div className="modal fade" id="signup-otp" tabIndex={-1} aria-labelledby="exampleModalLabel"
                        aria-hidden="true" >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="container height-100 d-flex justify-content-center align-items-center">
                                        <div className="position-relative">
                                            <div className="card p-2">
                                                <div className="card-left">
                                                    <h6>Please enter OTP</h6>
                                                    <div className="para">
                                                        <span>We have sent an OTP on </span>
                                                        <small>9582977718</small>
                                                    </div>
                                                    <div id="otp" className="inputs d-flex flex-row ">
                                                        
                                                        <input className="m-2 text-center form-control rounded" type="text" id="first" maxLength={1} />
                                                        <input className="m-2 text-center form-control rounded" type="text" id="second" maxLength={1} />
                                                        <input className="m-2 text-center form-control rounded" type="text"  id="third" maxLength={1} />
                                                        <input  className="m-2 text-center form-control rounded" type="text" id="fourth" maxLength={1} />
                                                    </div>
                                                    <a href="#" className="resend">
                                                        Resend OTP
                                                    </a>
                                                    <div className="mt-4 otp-btn">
                                                        <button className="btn btn-danger px-4 validate">
                                                            Validate
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            </section>
        </>
    )
}

export default CreateAnAccountPopup