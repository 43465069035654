import React from 'react'
import './scss/Steppercss.css'
const Stepper = () => {
  const stepButtons = document.querySelectorAll('.step-button');
  const progress = document.querySelector('#progress');

  Array.from(stepButtons).forEach((button, index) => {
    button.addEventListener('click', () => {
      progress.setAttribute('value', index * 100 / (stepButtons.length - 1));//there are 3 buttons. 2 spaces.

      stepButtons.forEach((item, secindex) => {
        if (index > secindex) {
          item.classList.add('done');
        }
        if (index < secindex) {
          item.classList.remove('done');
        }
      })
    })
  })
  return (
    <>
      {/* <div className='my-5'>CareerStepper</div> */}
      <section className='stepper-section'>
        <div className="container mt-3 pt-5">
          <div className="cus-step">
            <div className="accordion" id="accordionExample">
              <div className="steps">
                <progress id="progress" value="0" max="100"> </progress>
                <div className="step-item">
                  <button className="step-button text-center" type="button" data-bs-toggle="collapse"
                    data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">

                  </button>
                  <div className="step-title mt-2">
                    Company Details
                  </div>
                </div>
                <div className="step-item">
                  <button className="step-button text-center collapsed" type="button" data-bs-toggle="collapse"
                    data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  </button>
                  <div className="step-title mt-2">
                    Documents Verification
                  </div>
                </div>
                <div className="step-item">
                  <button className="step-button text-center collapsed" type="button" data-bs-toggle="collapse"
                    data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  </button>
                  <div className="step-title mt-2">
                    Privacy Settings
                  </div>
                </div>
                <div className="step-item">
                  <button className="step-button text-center collapsed" type="button" data-bs-toggle="collapse"
                    data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  </button>
                  <div className="step-title mt-2">
                    Subscription Plan
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Stepper