import React from 'react'
import './scss/CareerSearchResultListingAndDetails.css'
import { Link } from 'react-router-dom'
const CareerSearchResultListingAndDetailsContent = () => {
    return (
        <>
            <section className='career-result-details-section'>
                <section className="career-section">
                    <div className="career-start-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="career-top-heading">
                                        <h2>
                                            Your <span> career </span> starts here
                                        </h2>
                                    </div>
                                    <div className="career-second-heading">
                                        <h6>
                                            Search <span> 17043 </span> Job Postings in Afghanistan
                                        </h6>
                                    </div>
                                    <div>
                                        <div className="custom-form-section">
                                            <div className="search-custom">
                                                <img
                                                    src="./assets/career-start/magnifyingglass.png"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control form-custom"
                                                    placeholder="Search with keywords"
                                                />
                                            </div>
                                            <div className="search-custom">
                                                <img
                                                    src="./assets/career-start/mappinline.png"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control form-custom"
                                                    placeholder="Location"
                                                />
                                            </div>
                                            <div className="search-custom">
                                                <img
                                                    src="./assets/career-start/uim_bag.png"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control form-custom"
                                                    placeholder="Experience"
                                                />
                                            </div>
                                            <div className="form-custom-btn">
                                                <button>Search job</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" dropdown-custom-btn">
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-custom dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Date Posted
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Last 24 hours
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Last 3 days
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Last 7 days
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Last 14 days
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Since your last visit
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-custom dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Miles
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Another action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Something else here
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-custom dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Pay / salary
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Another action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Something else here
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-custom dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Job Type
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Another action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Something else here
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-custom dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Shift &amp; schedule
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Another action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Something else here
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-custom dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Experience level
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Another action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Something else here
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-custom dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Location
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Another action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Something else here
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-custom dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Education
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Another action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Something else here
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="dropdown">
                                            <button
                                                className="btn btn-custom dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Company type
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Another action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Something else here
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <div className=" dropdown-second-custom-btn">
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-custom dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Location
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Another action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Something else here
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-custom dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Company
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Another action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Something else here
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-custom dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Education
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Another action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Something else here
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="dropdown">
                                            <button
                                                className="btn btn-custom dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Company type
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Another action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Something else here
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="career-second-section my-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="card-section">
                                    <div className="form-heading">
                                        <h3>1000 Search Results</h3>
                                    </div>
                                    <div className="form-desgin">
                                        <div className="row">
                                            <div className="col-lg-3 text-center">
                                                <div className="">
                                                    <img
                                                        src="./assets/career-start/frame-17.png"
                                                        className="img-fluid frame-img"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-9">
                                                <div className="ptn">
                                                    <p className="top-para">Linear company</p>
                                                    <div className="heading">
                                                        <h2> Software Engineer <span> <a href="#">New Post</a> </span>
                                                        </h2>
                                                    </div>
                                                    <div className="list-section">
                                                        <ul className="pad-custom ">
                                                            <li>
                                                                <img src="./assets/career-start/clock.png" alt="" />
                                                                <span>Full time</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/career-start/currency-dollar.png" alt="" />
                                                                <span>30-32k</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/career-start/calendarblank.png" alt="" />
                                                                <span> 1 day ago</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="para">
                                                        <p>
                                                            Mollit in laborum tempor Lorem incididunt irure. Aute eu
                                                            ex ad sunt. Pariatur sint culpa do incididunt eiusmod
                                                            eiusmod culpa. laborum tempor Lorem incididunt.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-desgin mt-2">
                                        <div className="row">
                                            <div className="col-lg-3 text-center">
                                                <div className="">
                                                    <img
                                                        src="./assets/career-start/frame-6.png"
                                                        className="img-fluid frame-img"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-9">
                                                <div className="ptn">
                                                    <p className="top-para">Linear company</p>
                                                    <div className="heading">
                                                        <h2>Software Engineer</h2>
                                                    </div>
                                                    <div className="list-section">
                                                        <ul className="pad-custom ">
                                                            <li>
                                                                <img src="./assets/career-start/clock.png" alt="" />
                                                                <span>Full time</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/career-start/currency-dollar.png" alt="" />
                                                                30-32k
                                                            </li>
                                                            <li>
                                                                <img src="./assets/career-start/calendarblank.png" alt="" />
                                                                <span>1 day ago</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="para">
                                                        <p>
                                                            Mollit in laborum tempor Lorem incididunt irure. Aute eu
                                                            ex ad sunt. Pariatur sint culpa do incididunt eiusmod
                                                            eiusmod culpa. laborum tempor Lorem incididunt.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-desgin mt-2">
                                        <div className="row">
                                            <div className="col-lg-3 text-center">
                                                <div className="">
                                                    <img
                                                        src="./assets/career-start/frame-card.png"
                                                        className="img-fluid frame-img"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-9">
                                                <div className="ptn">
                                                    <p className="top-para">Linear company</p>
                                                    <div className="heading">
                                                        <h2>Software Engineer</h2>
                                                    </div>
                                                    <div className="list-section">
                                                        <ul className="pad-custom ">
                                                            <li>
                                                                <img src="./assets/career-start/clock.png" alt="" />
                                                                <span>Full time</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/career-start/currency-dollar.png" alt="" />
                                                                <span>30-32k</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/career-start/calendarblank.png" alt="" /> <span>1 day ago</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="para">
                                                        <p>
                                                            Mollit in laborum tempor Lorem incididunt irure. Aute eu
                                                            ex ad sunt. Pariatur sint culpa do incididunt eiusmod
                                                            eiusmod culpa. laborum tempor Lorem incididunt.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-desgin mt-2">
                                        <div className="row">
                                            <div className="col-lg-3 text-center">
                                                <div className="">
                                                    <img
                                                        src="./assets/career-start/frame-7.png"
                                                        className="img-fluid frame-img"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-9">
                                                <div className="ptn">
                                                    <p className="top-para">Linear company</p>
                                                    <div className="heading">
                                                        <h2>Software Engineer</h2>
                                                    </div>
                                                    <div className="list-section">
                                                        <ul className="pad-custom ">
                                                            <li>
                                                                <img src="./assets/career-start/clock.png" alt="" />
                                                                <span> Full time</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/career-start/currency-dollar.png" alt="" />
                                                                <span> 30-32k</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/career-start/calendarblank.png" alt="" />
                                                                <span>1 day ago</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="para">
                                                        <p>
                                                            Mollit in laborum tempor Lorem incididunt irure. Aute eu
                                                            ex ad sunt. Pariatur sint culpa do incididunt eiusmod
                                                            eiusmod culpa. laborum tempor Lorem incididunt.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-desgin mt-2">
                                        <div className="row">
                                            <div className="col-lg-3 text-center">
                                                <div className="">
                                                    <img
                                                        src="./assets/career-start/frame-17.png"
                                                        className="img-fluid frame-img"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-9">
                                                <div className="ptn">
                                                    <p className="top-para">Linear company</p>
                                                    <div className="heading">
                                                        <h2>Software Engineer</h2>
                                                    </div>
                                                    <div className="list-section">
                                                        <ul className="pad-custom ">
                                                            <li>
                                                                <img src="./assets/career-start/clock.png" alt="" />
                                                                <span>Full time</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/career-start/currency-dollar.png" alt="" />
                                                                <span>30-32k</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/career-start/calendarblank.png" alt="" />
                                                                <span>1 day ago</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="para">
                                                        <p>
                                                            Mollit in laborum tempor Lorem incididunt irure. Aute eu
                                                            ex ad sunt. Pariatur sint culpa do incididunt eiusmod
                                                            eiusmod culpa. laborum tempor Lorem incididunt.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="pagintion-custom mt-5">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <nav aria-label="Page navigation example">
                                                    <ul className="pagination justify-content-center">
                                                        <li className="page-item">
                                                            <a className="page-link" href="#" aria-label="Previous">
                                                                <span aria-hidden="true">«</span>
                                                            </a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a className="page-link" href="#">
                                                                1
                                                            </a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a className="page-link" href="#">
                                                                2
                                                            </a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a className="page-link" href="#">
                                                                3
                                                            </a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a className="page-link" href="#">
                                                                4
                                                            </a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a className="page-link" href="#">
                                                                5
                                                            </a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a className="page-link" href="#" aria-label="Next">
                                                                <span aria-hidden="true">»</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="info-section">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="top-header">
                                                <div className="top-header-child">
                                                    <div className='top-header-company-logo'>
                                                        <img src="./assets/career-start/frame-17.png" className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="top-head">
                                                        <h3>Software Engineer</h3>
                                                        <p>
                                                            <span>at Facebook</span> <span>FULL-TIME</span>
                                                            <span>Featured</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="top-btn">
                                                    <a href='#'>How to Apply</a>
                                                </div>
                                            </div>
                                            <div className="bookmark-apply-dv">
                                                <div className='bookmark-icon'>
                                                    <img
                                                        src="./assets/career-start/icon-button.png"
                                                        className="img-fluid"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className='apply-btn-bookmark'>
                                                    <Link to='/your-career-start-here' className='apply-tag'>Apply now <img
                                                        src="./assets/career-start/right-arrow.png"
                                                        className="img-fluid"
                                                        alt=""
                                                    /></Link>
                                                </div>
                                            </div>
                                            <div className='salary-location-dv'>
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-12 border-right">
                                                        <div className="left-section text-center">
                                                            <h6>Salary (USD)</h6>
                                                            <h5>$100,000 - $120,000</h5>
                                                            <p>Yearly salary</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-12">
                                                        <div className="left-section2 text-center">
                                                            <img src="./assets/career-start/maptrifold.png" alt="" />
                                                            <h5>Job Location</h5>
                                                            <p>Dhaka, Bangladesh</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="left-card-second mt-5">
                                                <div className="mt-5 mx-4">
                                                    <div className="col-lg-12">
                                                        <div className="list-head">
                                                            <h3>Job Overview</h3>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-3 col-6">
                                                            <div className="card-pad">
                                                                <img
                                                                    src="./assets/career-start/calendar-card.png"
                                                                    className="img-fluid"
                                                                    alt=""
                                                                />
                                                                <p>Job Posted:</p>
                                                                <h6>14 Jun, 2021</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-3 col-6">
                                                            <div className="card-pad">
                                                                <img
                                                                    src="./assets/career-start/timer.png"
                                                                    className="img-fluid"
                                                                    alt=""
                                                                />
                                                                <p>Job expire:</p>
                                                                <h6>14 Jun, 2021</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-3 col-6">
                                                            <div className="card-pad">
                                                                <img
                                                                    src="./assets/career-start/stack.png"
                                                                    className="img-fluid"
                                                                    alt=""
                                                                />
                                                                <p>Job Level:</p>
                                                                <h6>Entry Level</h6>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4 col-md-3 col-6">
                                                            <div className="card-pad">
                                                                <img
                                                                    src="./assets/career-start/wallet.png"
                                                                    className="img-fluid"
                                                                    alt=""
                                                                />
                                                                <p>Experience</p>
                                                                <h6>$50k-80k/month</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-3 col-6">
                                                            <div className="card-pad">
                                                                <img
                                                                    src="./assets/career-start/calendar-card.png"
                                                                    className="img-fluid"
                                                                    alt=""
                                                                />
                                                                <p>Education</p>
                                                                <h6>Graduation</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="border-bottom-cus"></div>
                                                <div className="col-lg-12">
                                                    <div className="list-top-heading">
                                                        <div className="list-head">
                                                            <h3>Share this job:</h3>
                                                        </div>
                                                        <div className="card-list">
                                                            <div className="social-media-parent">
                                                                <a href='#'><img src="./assets/career-start/icon/copy-links.png" alt="" /></a>
                                                            </div>
                                                            <div className="social-media-parent">
                                                                <a href='#'><img src="./assets/career-start/icon/linkedin.png" alt="" /></a>
                                                            </div>
                                                            <div className="social-media-parent">
                                                                <a href='#'><img src="./assets/career-start/icon/facebook.png" alt="" /></a>
                                                            </div>
                                                            <div className="social-media-parent">
                                                                <a href='#'><img src="./assets/career-start/icon/twitter.png" alt="" /></a>
                                                            </div>
                                                            <div className="social-media-parent">
                                                                <a href='#'><img src="./assets/career-start/icon/message.png" alt="" /></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="info-section-heading">
                                            <div className='job-description-heading'>
                                                <h3>Job Description</h3>
                                            </div>
                                            <div className='info-dv-para-scroll'>
                                                <div className='info-dv-para'>
                                                    <p>
                                                        Velstar is a Shopify Plus agency, and we partner with brands
                                                        to help them grow, we also do the same with our people!
                                                    </p>
                                                    <p>
                                                        Here at Velstar, we don't just make websites, we create
                                                        exceptional digital experiences that consumers love. Our
                                                        team of designers, developers, strategists, and creators
                                                        work together to push brands to the next level. From
                                                        Platform Migration, User Experience & User Interface
                                                        Design, to Digital Marketing, we have a proven track record
                                                        in delivering outstanding eCommerce solutions and driving
                                                        sales for our clients.
                                                    </p>
                                                    <p>
                                                        The role will involve translating project specifications
                                                        into clean, test-driven, easily maintainable code. You will
                                                        work with the Project and Development teams as well as with
                                                        the Technical Director, adhering closely to project plans
                                                        and delivering work that meets functional &
                                                        non-functional requirements. You will have the opportunity
                                                        to create new, innovative, secure and scalable features for
                                                        our clients on the Shopify platform
                                                    </p>
                                                    <p>Want to work with us? You're in good company!</p>
                                                </div>
                                                <div className='requirement-dv'>
                                                    <div className='requirement-dv-heading'>
                                                        <h3>Requirements</h3>
                                                    </div>
                                                    <ul>
                                                        <li>
                                                            Great troubleshooting and analytical skills combined with
                                                            the desire to tackle challenges head-on
                                                        </li>
                                                        <li>
                                                            3+ years of experience in back-end development working
                                                            either with multiple smaller projects simultaneously or
                                                            large-scale applications
                                                        </li>
                                                        <li>
                                                            Experience with HTML, JavaScript, CSS, PHP, Symphony and/or
                                                            Laravel
                                                        </li>
                                                        <li>
                                                            Working regularly with APIs and Web Services (REST,
                                                            GrapthQL, SOAP, etc)
                                                        </li>
                                                        <li>
                                                            Have experience/awareness in Agile application development,
                                                            commercial off-the-shelf software, middleware, servers and
                                                            storage, and database management.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default CareerSearchResultListingAndDetailsContent