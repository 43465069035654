import React from 'react'
import './scss/FreeJobPostCanOnlyGetApplications.css'
import { Link } from 'react-router-dom'
const FreeJobPostCanOnlyGetApplicationsContent = () => {
    return (
        <>
            <section className='free-job-post-get-application-sec'>
                <div className='container'>
                    <div className='choose-you-plan-internal'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-12 text-center'>
                                <div className='heading-dv'>
                                    <h1>Free Job Post Can Only Get up to 10 Applications</h1>
                                    <div className='row'>
                                        <div className='col-lg-8 col-md-8 offset-lg-2 offset-lg-2'>
                                            <p>Upgrade to a sponsored job plan</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-lg-4 mt-md-4 mt-1 align-items-center'>
                            <div className='col-lg-6 col-md-6 col-12 mt-md-0 mt-lg-0 mt-3'>
                                <div className='left-side-dv'>
                                    <div className='content'>
                                        <h3>Post for Free</h3>
                                        <p className='second-p'>Boost your job post with a daily or monthly budget</p>
                                        {/* <h2>$50</h2>
                                        <p>Suggest per day</p> */}
                                        <ul>
                                            <li>Increases job post visibility Appear more often in of people looking for job like yours.</li>
                                        </ul>
                                    </div>
                                    <div className='content-bottom-btn'>
                                        <Link to='/post-job' className='get-started'>Continue </Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12 mt-md-0 mt-lg-0 mt-3'>
                                <div className='right-side-dv'>
                                    <div className='content'>
                                        <h3>Sponsor this Job</h3>
                                        <p className='second-p'>Appear more often in of people looking for job like yours.</p>
                                        {/* <h2>$50</h2>
                                        <p>Per application</p> */}
                                        <ul>
                                            <li>No limit on applications</li>
                                            <li>Increased reach in relevant search results on Karlatoon</li>
                                            <li>Match candidate whose resume fit your job description</li>
                                        </ul>
                                    </div>
                                    <div className='content-bottom-btn'>
                                        <a href='' className='get-started'>Sponsor</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </section>
        </>
    )
}

export default FreeJobPostCanOnlyGetApplicationsContent