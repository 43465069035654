import React from 'react'
// import './scss/BuildYourResume.css'
import BuildResumeBannner from './BuildResumeBannner'
import BuildResume3Step from './BuildResume3Step'
import BuildResumeSnapShotEditor from './BuildResumeSnapShotEditor'
import BuildResumeTemplet from './BuildResumeTemplet'
import BuildResumeAboutUs from './BuildResumeAboutUs'
import BuildResumeBenefits from './BuildResumeBenefits'
import BuildResumeTestimonials from './BuildResumeTestimonials'
const BuildYourResumeContent = () => {
  return (
    <>
        <BuildResumeBannner />
        <BuildResume3Step />
        <BuildResumeSnapShotEditor />
        <BuildResumeTemplet />
        <BuildResumeAboutUs />
        <BuildResumeBenefits />
        <BuildResumeTestimonials />
    </>
  )
}

export default BuildYourResumeContent