import React from 'react'
import { Link } from 'react-router-dom';
import BlogCategory from '../BlogCategory/BlogCategory';

const BlogList = () => {
    return (
        <section className='blog-list mt-4'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="blog-head text-center">
                        <h1>Blog</h1>
                    </div>
                    <div className="row blog-info pt-5">
                        <div className="col-lg-9 col-md-7">
                            <div className="blog-card mb-5">
                                <img src="./assets/images/blogs/blog1.png" className='img-fluid' alt="" />
                                <div className="blog-category mt-3 d-flex">
                                    <div className="category d-flex align-items-center">
                                        <img src="./assets/images/blogs/pen.png" className='img-fluid' alt="" />
                                        <h5>Surf Auxion</h5>
                                    </div>
                                    <div className="date mx-3 d-flex align-items-center">
                                        <img src="./assets/images/blogs/calen.png" className='img-fluid' alt="" />
                                        <h5>Aug 09 2020</h5>
                                    </div>
                                </div>
                                <div className="blog-title py-3">
                                    <h2>Mauris at orci non vulputate diam tincidunt nec.</h2>
                                </div>
                                <div className="blog-para">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit facilisis quis auctor pretium ipsum, eu rutrum. Condimentum eu malesuada vitae ultrices in in neque, porta dignissim. Adipiscing purus, cursus vulputate id id dictum at.</p>
                                </div>
                                <div className="read-btn d-flex justify-content-start">
                                    <Link to='/blog-detail'>Read More</Link>
                                </div>

                            </div>
                            <div className="blog-card mb-5">
                                <img src="./assets/images/blogs/blog2.png" className='img-fluid' alt="" />
                                <div className="blog-category mt-3 d-flex">
                                    <div className="category d-flex align-items-center">
                                        <img src="./assets/images/blogs/pen.png" className='img-fluid' alt="" />
                                        <h5>Surf Auxion</h5>
                                    </div>
                                    <div className="date mx-3 d-flex align-items-center">
                                        <img src="./assets/images/blogs/calen.png" className='img-fluid' alt="" />
                                        <h5>Aug 09 2020</h5>
                                    </div>
                                </div>
                                <div className="blog-title py-3">
                                    <h2>Mauris at orci non vulputate diam tincidunt nec.</h2>
                                </div>
                                <div className="blog-para">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit facilisis quis auctor pretium ipsum, eu rutrum. Condimentum eu malesuada vitae ultrices in in neque, porta dignissim. Adipiscing purus, cursus vulputate id id dictum at.</p>
                                </div>
                                <div className="read-btn d-flex justify-content-start">
                                    <Link to='/blog-detail'>Read More</Link>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-5">
                            <BlogCategory />
                        </div>
                    </div>

                </div>
            </div>

        </section>
    )
}

export default BlogList