import React from 'react'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'
import AddPayAndBenefitsContent from './AddPayAndBenefitsContent'
import Footer from '../../Commons/Footer'

const AddPayAndBenefits = () => {
  return (
    <>
        <RecAfterLoginNavbar/>
        <AddPayAndBenefitsContent/>
        <Footer/>
    </>
  )
}

export default AddPayAndBenefits