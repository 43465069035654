import React from 'react'
import RegisterHeader from '../CvBuilder/CvBuilderHeader/RegisterHeader'
import Footer from '../Commons/Footer'
import SignInContent from './SignInContent'

const SignIn = () => {
  return (
    <>
        <RegisterHeader />
        <SignInContent/>
        <Footer />
    </>
  )
}

export default SignIn