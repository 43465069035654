import React from 'react'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'
import AddJobBasicsContent from './AddJobBasicsContent'
import Footer from '../../Commons/Footer'

const AddJobBasics = () => {
  return (
    <>
        <RecAfterLoginNavbar/>
        <AddJobBasicsContent/>
        <Footer/>
    </>
  )
}

export default AddJobBasics