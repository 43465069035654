import React from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import Footer from '../../Commons/Footer'
import InterViewQuestionListingContent from './InterViewQuestionListingContent'

const InterViewQuestionListing = () => {
  return (
    <>
    <AfterNavbar />
    <InterViewQuestionListingContent/>
    <Footer />
    </>
  )
}

export default InterViewQuestionListing