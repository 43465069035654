import React from 'react'
import './scss/RecruiterNotification.css'
const RecruiterNotificationContent = () => {
  return (
    <>
      <section className='rec-notification-view-sec'>
        <div className="main-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="main-head">
                  <h2>Notifications</h2>
                </div>
              </div>
            </div>
            <div className="banner-section">
              <div className="row">
                <div className="col-lg-12">
                  <div className="point-section">
                    <div className="d-flex mx-3 align-items-center">
                      <div>
                        <img src="./assets/notification/aavatar.png" className="img-fluid" alt="" />
                      </div>
                      <div className="point-para mx-3">
                        <p>
                          Hamish Marsh <span>viewed your profile</span>
                        </p>
                        <a href="#">See All Views</a>
                      </div>
                    </div>
                    <div>
                      <div className="point-icon"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="point-section-second">
                    <div className="d-flex mx-3 align-items-center">
                      <div>
                        <img
                          src="./assets/notification/uninor-icon.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="point-para mx-3">
                        <p>
                          Grameenphone <span>is hiring</span>Product Designer
                        </p>
                        <a href="#">Apply now</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="point-section">
                    <div className="d-flex mx-3 align-items-center">
                      <div>
                        <img src="./assets/notification/aavatar.png" className="img-fluid" alt="" />
                      </div>
                      <div className="point-para mx-3">
                        <p>
                          Hamish Marsh <span>viewed your profile</span>
                        </p>
                        <a href="#">See All Views</a>
                      </div>
                    </div>
                    <div>
                      <div className="point-icon"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="point-section-second">
                    <div className="d-flex mx-3 align-items-center">
                      <div>
                        <img
                          src="./assets/notification/uninor-icon.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="point-para mx-3">
                        <p>
                          Grameenphone <span>is hiring</span>Product Designer
                        </p>
                        <a href="#">Apply now</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="point-section">
                    <div className="d-flex mx-3 align-items-center">
                      <div>
                        <img src="./assets/notification/aavatar.png" className="img-fluid" alt="" />
                      </div>
                      <div className="point-para mx-3">
                        <p>
                          Hamish Marsh <span>viewed your profile</span>
                        </p>
                        <a href="#">See All Views</a>
                      </div>
                    </div>
                    <div>
                      <div className="point-icon"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="point-section-third mt-2">
                    <div className="d-flex mx-3 align-items-center">
                      <div>
                        <img
                          src="./assets/notification/bird-avatar.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="point-para mx-3">
                        <p>
                          Grameenphone <span>is hiring</span>Product Designer
                        </p>
                        <a href="#">Apply now</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="point-section mt-2">
                    <div className="d-flex mx-3 align-items-center">
                      <div>
                        <img src="./assets/notification/aavatar.png" className="img-fluid" alt="" />
                      </div>
                      <div className="point-para mx-3">
                        <p>
                          Hamish Marsh <span>viewed your profile</span>
                        </p>
                        <a href="#">See All Views</a>
                      </div>
                    </div>
                    <div>
                      <div className="point-icon"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="point-section-second">
                    <div className="d-flex mx-3 align-items-center">
                      <div>
                        <img
                          src="./assets/notification/uninor-icon.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="point-para mx-3">
                        <p>
                          Grameenphone <span>is hiring</span>Product Designer
                        </p>
                        <a href="#">Apply now</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="point-section">
                    <div className="d-flex mx-3 align-items-center">
                      <div>
                        <img src="./assets/notification/aavatar.png" className="img-fluid" alt="" />
                      </div>
                      <div className="point-para mx-3">
                        <p>
                          Hamish Marsh <span>viewed your profile</span>
                        </p>
                        <a href="#">See All Views</a>
                      </div>
                    </div>
                    <div>
                      <div className="point-icon"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="point-section-second">
                    <div className="d-flex mx-3 align-items-center">
                      <div>
                        <img
                          src="./assets/notification/uninor-icon.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="point-para mx-3">
                        <p>
                          Grameenphone <span>is hiring</span>Product Designer
                        </p>
                        <a href="#">Apply now</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="point-section-second mt-2">
                    <div className="d-flex mx-3 align-items-center">
                      <div>
                        <img
                          src="./assets/notification/bird-avatar.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="point-para mx-3">
                        <p>
                          Grameenphone <span>is hiring</span>Product Designer
                        </p>
                        <a href="#">Apply now</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="point-section">
                    <div className="d-flex mx-3 align-items-center">
                      <div>
                        <img src="./assets/notification/aavatar.png" className="img-fluid" alt="" />
                      </div>
                      <div className="point-para mx-3">
                        <p>
                          Hamish Marsh <span>viewed your profile</span>
                        </p>
                        <a href="#">See All Views</a>
                      </div>
                    </div>
                    <div>
                      <div className="point-icon"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  )
}

export default RecruiterNotificationContent