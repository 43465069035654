import React from 'react'
import './scss/RecommendedJobsSlider.css'
import Slider from "react-slick";
const RecommendedJobsSlider = () => {
    const settingsRecommended = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                    arrows: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false
                }
            }

        ]
    }
    return (
        <>
            <section className='recommended-jobs-slider-job-section'>
                <div className="main-section">
                    <div className="container">
                        <div className="second-section">
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='similer-post-heading'>
                                        <h2 className='ms-3'>Recommended Jobs</h2>
                                    </div>
                                </div>
                            </div>
                            <Slider {...settingsRecommended} className='mb-5'>
                                <div className="col-lg-4">
                                    <div className="card">
                                        <div className="card-sub-dv">
                                            <div>
                                                <img src="./assets/images_another/frame-card.png" alt="" />
                                            </div>
                                            <div className="footer-heading mx-2">
                                                <h3>Technical Engineer</h3>
                                                <p><span></span>
                                                    <span>
                                                        <a href="">New post</a>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="footer-list-card">
                                            <ul>
                                                <li>
                                                    <img src="./assets/images_another/mappinline.png" alt="" />
                                                    Flaqin Canada
                                                </li>
                                                <li>
                                                    <img src="./assets/images_another/clock.png" alt="" />
                                                    Full time
                                                    <span>
                                                        <img src="./assets/images_another/currency-dollar.png" alt="" />
                                                        20-50k
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card">
                                        <div className="card-sub-dv">
                                            <div>
                                                <img src="./assets/images_another/frame-6.png" alt="" />
                                            </div>
                                            <div className="footer-heading mx-2">
                                                <h3>Product Designer</h3>
                                                <p> <span>Truviliq company</span>
                                                    <span>
                                                        <a href="">New post</a>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="footer-list-card">
                                            <ul>
                                                <li>
                                                    <img src="./assets/images_another/mappinline.png" alt="" />
                                                    Koliq USA
                                                </li>
                                                <li>
                                                    <img src="./assets/images_another/clock.png" alt="" />
                                                    Temporary
                                                    <span>
                                                        <img src="./assets/images_another/currency-dollar.png" alt="" />
                                                        50-60k
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card">
                                        <div className="card-sub-dv">
                                            <div>
                                                <img src="./assets/images_another/frame-7.png" alt="" />
                                            </div>
                                            <div className="footer-heading mx-2">
                                                <h3>Copywrite</h3>
                                                <p><span> Line Company</span>
                                                    <span>
                                                        <a href="">New post</a>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="footer-list-card">
                                            <ul>
                                                <li>
                                                    <img src="./assets/images_another/mappinline.png" alt="" />
                                                    Paityn New York
                                                </li>
                                                <li>
                                                    <img src="./assets/images_another/clock.png" alt="" />
                                                    Full time
                                                    <span>
                                                        <img src="./assets/images_another/currency-dollar.png" alt="" />
                                                        10-35k
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card">
                                        <div className="card-sub-dv">
                                            <div>
                                                <img src="./assets/images_another/frame-card.png" alt="" />
                                            </div>
                                            <div className="footer-heading mx-2">
                                                <h3>Technical Engineer</h3>
                                                <p><span> Line Company</span>
                                                    <span>
                                                        <a href="">New post</a>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="footer-list-card">
                                            <ul>
                                                <li>
                                                    <img src="./assets/images_another/mappinline.png" alt="" />
                                                    Flaqin Canada
                                                </li>
                                                <li>
                                                    <img src="./assets/images_another/clock.png" alt="" />
                                                    Full time
                                                    <span>
                                                        <img src="./assets/images_another/currency-dollar.png" alt="" />
                                                        20-50k
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </Slider>

                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default RecommendedJobsSlider