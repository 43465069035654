import React from 'react'
import { Link } from 'react-router-dom'


const RecMatchingHiringBannerSec = () => {
    return (
        <section className='rec-mt-hi-banner-sec'>
            <div className="container-fluid">
                <div className="row banner-area">
                    <div className="col-lg-7 col-md-7 banner-sec-lft">
                        <div className="banner-text">
                            <h1>Matching and hiring made easy</h1>
                            <p>We have all the tools you need to find the <br />  people who fit your team and to start <br /> hiring today.</p>
                            <Link to="/create-employer-account">Post a Job</Link>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-7 banner-sec-rt">
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RecMatchingHiringBannerSec