import React from 'react'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import RecCandidateProfileContent from './RecCandidateProfileContent'
import Footer from '../Commons/Footer'

const RecCandidateProfile = () => {
    return (
        <>
            <RecAfterLoginNavbar />
            <RecCandidateProfileContent />
            <Footer />
        </>
    )
}

export default RecCandidateProfile