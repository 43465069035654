import React from 'react'
import { Link } from 'react-router-dom'

const Entertainment = () => {
    return (
        <section className="entertainment">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 py-4">
                        <div className="heading">
                            <h1>Entertainment</h1>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-lg-5 news-display pt-3">
                                <img src="./assets/images/news/ent.png" className='img-fluid' alt="" />
                                <ul className=' pb-1 d-flex'>
                                    <li>Craig Bator - </li>
                                    <li>27 Dec 2020</li>
                                </ul>
                                <h3>Now Is the Time to Think About Your Small Business Success</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus lobortis augue condimentum maecenas. Metus at in fames vitae posuere ut vel vulputate....</p>
                            </div>
                            <div className="col-lg-7 py-4 pt-3">
                                <div className="news-list row pb-3">
                                    <div className="col-lg-3">
                                        <img src="./assets/images/news/list1.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-7">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>Penn’s expanding political climate gears up fo 2020 election</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="news-list row pb-3">
                                    <div className="col-lg-3">
                                        <img src="./assets/images/news/list2.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-7">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>Things to Look For in a Financial Trading Platform</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="news-list row pb-3">
                                    <div className="col-lg-3">
                                        <img src="./assets/images/news/list3.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-7">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>The only thing that overcomes hard luck is hard work</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="news-list row pb-3">
                                    <div className="col-lg-3">
                                        <img src="./assets/images/news/list4.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-7">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>Success is not a good teacher failure makes you humble</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="news-list row pb-3">
                                    <div className="col-lg-3">
                                        <img src="./assets/images/news/list5.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-7">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>At Value-Focused Hotels, the Free Breakfast Gets Bigger</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 py-4">
                        <nav>
                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Recent</button>
                                <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Trending</button>
                                <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Most Views</button>
                            </div>
                        </nav>
                        <div class="tab-content" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <div className="news-list row py-3">
                                    <div className="col-lg-4">
                                        <img src="./assets/images/news/tab1.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-8">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>Gluten-Free Almond Cake
                                                with Berries</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="news-list row pb-3">
                                    <div className="col-lg-4">
                                        <img src="./assets/images/news/tab2.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-8">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>Enjoy My Favourite Molten Chocolate Cake</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="news-list row pb-3">
                                    <div className="col-lg-4">
                                        <img src="./assets/images/news/tab3.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-8">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>They back return to you Kennedy Darlings</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="news-list row pb-3">
                                    <div className="col-lg-4">
                                        <img src="./assets/images/news/tab4.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-8">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>The art of racing with speed Motor GP</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="news-list row pb-3">
                                    <div className="col-lg-4">
                                        <img src="./assets/images/news/tab5.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-8">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>Home team takes the win – NBA 2020</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                <div className="news-list row py-3">
                                    <div className="col-lg-4">
                                        <img src="./assets/images/news/tab1.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-8">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>Gluten-Free Almond Cake
                                                with Berries</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="news-list row pb-3">
                                    <div className="col-lg-4">
                                        <img src="./assets/images/news/tab2.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-8">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>Gluten-Free Almond Cake
                                                with Berries</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="news-list row pb-3">
                                    <div className="col-lg-4">
                                        <img src="./assets/images/news/tab3.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-8">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>Gluten-Free Almond Cake
                                                with Berries</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="news-list row pb-3">
                                    <div className="col-lg-4">
                                        <img src="./assets/images/news/tab4.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-8">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>Gluten-Free Almond Cake
                                                with Berries</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="news-list row pb-3">
                                    <div className="col-lg-4">
                                        <img src="./assets/images/news/tab5.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-8">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>Gluten-Free Almond Cake
                                                with Berries</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                <div className="news-list row py-3">
                                    <div className="col-lg-4">
                                        <img src="./assets/images/news/tab1.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-8">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>Gluten-Free Almond Cake
                                                with Berries</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="news-list row pb-3">
                                    <div className="col-lg-4">
                                        <img src="./assets/images/news/tab2.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-8">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>Gluten-Free Almond Cake
                                                with Berries</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="news-list row pb-3">
                                    <div className="col-lg-4">
                                        <img src="./assets/images/news/tab3.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-8">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>Gluten-Free Almond Cake
                                                with Berries</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="news-list row pb-3">
                                    <div className="col-lg-4">
                                        <img src="./assets/images/news/tab4.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-8">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>Gluten-Free Almond Cake
                                                with Berries</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="news-list row pb-3">
                                    <div className="col-lg-4">
                                        <img src="./assets/images/news/tab5.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-8">
                                        <ul className='d-flex'>
                                            <li>Craig Bator - </li>
                                            <li>27 Dec 2020</li>
                                        </ul>
                                        <div className="para">
                                            <h3>Gluten-Free Almond Cake
                                                with Berries</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Entertainment