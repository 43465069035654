import React from 'react'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import LearningVideoContent from './LearningVideoContent'
import Footer from '../Commons/Footer'

const LearningVideo = () => {
  return (
    <>
    <RecAfterLoginNavbar/>
    <LearningVideoContent/>
    <Footer/>
    </>
  )
}

export default LearningVideo