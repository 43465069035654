import React from 'react'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import ChooseYourSubscriptionPlanContent from './ChooseYourSubscriptionPlanContent'
import Footer from '../Commons/Footer'

const ChooseYourSubscriptionPlan = () => {
  return (
    <>
        <RecAfterLoginNavbar/>
        <ChooseYourSubscriptionPlanContent/>
        <Footer/>
    </>
  )
}

export default ChooseYourSubscriptionPlan